import React, {ChangeEventHandler, CSSProperties, MouseEventHandler} from 'react';
import './styles.css';
import InputMask from 'react-input-mask';
import { FiCheckCircle, FiRotateCw, FiXCircle } from 'react-icons/fi';

interface Props {
    name         : string;
    type         : string;
    className   ?: string;
    labelName   ?: string;
    style       ?: CSSProperties;
    value       ?: any;
    dataType    ?: string;
    onChange    ?: ChangeEventHandler<HTMLInputElement>;
    onClick     ?: MouseEventHandler<HTMLInputElement>;
    placeholder ?: string;
    autoComplete?: boolean;
    maxLenght   ?: number;
    minLenght   ?: number;
    disabled    ?: boolean;
    readyOnly   ?: boolean;
    mask        ?: string ;
    error       ?: any;
    cnpj        ?: any;
}

function renderButton(cnpjState: number){
    switch (cnpjState) {
        case 0:
            return <></>

        case -1:
            return <FiRotateCw className="loading" size={26} />

        case 1:
            return <FiCheckCircle className="success" size={26} />

        case 2:
            return <FiXCircle className="error" size={26} />

        default:
            return <></>;
    }
}

 const InputLabel:React.FC<Props> = (props: Props) => (
    <div className={`inputLabel${props.className ? ' ' + props.className + (props.error ? ' hasError' : '') : (props.error ? ' hasError' : '')}`} style={props.style ? props.style : {}}>
        {props.labelName ? <label htmlFor={props.name}>{props.labelName}</label> : <></>}

        <input
            id={props.name}
            type={props.type}
            name={props.name}
            value={props.value!==null ? props.value : ''}
            data-type={props.dataType ? props.dataType : ''}
            onChange={props.onChange ? props.onChange : undefined}
            onClick={props.onClick ? props.onClick : undefined}
            placeholder={props.placeholder}
            autoComplete={!props.autoComplete ? 'new-password' : ''}
            maxLength={props.maxLenght}
            minLength={props.minLenght}
            disabled={props.disabled ? true : false}
            readOnly={props.readyOnly ? true : false}
        />

        {props.error ? <small>{props.error}</small> : <></>}
    </div>
);

export const InputLabelMask:React.FC<Props> = (props: Props) => (
    <div className={`mask inputLabel${props.className ? ' ' + props.className + (props.error ? ' hasError' : '') : (props.error ? ' hasError' : '')}`} style={props.style ? props.style : {}}>
        {props.labelName ? <label htmlFor={props.name}>{props.labelName}</label> : <></>}

        <InputMask
            id={props.name}
            mask={props.mask+''}
            type={props.type}
            name={props.name}
            value={props.value ? props.value : ''}
            data-type={props.dataType ? props.dataType : ''}
            onChange={props.onChange ? props.onChange : undefined}
            onClick={props.onClick ? props.onClick : undefined}
            placeholder={props.placeholder}
            maxLength={props.maxLenght}
            minLength={props.minLenght}
            disabled={props.disabled ? true : false}
            readOnly={props.readyOnly ? true : false}
        />
        {props.cnpj ? renderButton(props.cnpj): <></> }

        {props.error ? <small>{props.error}</small> : <></>}
    </div>
);


export default InputLabel;