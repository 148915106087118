import React from 'react';
import { useParams } from 'react-router-dom';
import './styles.css';

// Components
import AdminClientList from './list';
import AdminClientEdit from './edit';

const Clients: React.FC = () => {
    let params = useParams();
    let id     = params.id;

    return id && Number(id)>0 ?
        <AdminClientEdit /> :
        <AdminClientList />
}

export default Clients
