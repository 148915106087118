import React, { ChangeEvent, useContext, useState } from 'react'
import './styles.css'

//Component
import { Button, Checkbox, InputLabel, InputLabelMask } from '~/components/Forms';
import HeaderCreateAccount from '~/components/Forms/HeaderCreateAccount/index';
import api from '~/services/api';
import strings from '~/utils/strings';
import validate from './validateForm';

// Init State
const initialState = {
    name            : '',
    cnpj            : '',
    phone           : '',
    mail            : '',
    password        : '',
    confirm_password: '',
    company_name    : ''
}

var arrayErr = {
    name            : '',
    cnpj            : '',
    phone           : '',
    mail            : '',
    password        : '',
    confirm_password: ''
}

const BodyCreateAccount: React.FC<any> = props => {
    const [values, setValues]             = useState(initialState);
    const [cnpjState, setCnpjState]       = useState<any>(0);
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [err, setErr]                   = useState<any>(arrayErr);

	function onChange(event: ChangeEvent<HTMLInputElement>) {
        const {name, value} = event.target;

        if (name !== 'password' || 'confirm_password') {
            arrayErr ={...arrayErr,[name]:''}
            values.phone.replace(/[(]|[)]|[_]|[ ]|[-]/gm,'')
        }

        setValues({
            ...values,
            [name]: value
        })
    }
    async function onCnpj(event: ChangeEvent<HTMLInputElement>) {
        const {name, value} = event.target;
        setCnpjState(0);
        setValues({
            ...values,
            [name]: value
        })

        let tratedCnpj = strings.removeMaskCNPJ(value)

        if (tratedCnpj.length===14) {
            setCnpjState(-1);
            let ret = await getCnpj(tratedCnpj)

            if(ret.valid === true) { //Caso seja um cnpj válido e não exista nenhum cnpj na nossa base
                arrayErr ={...arrayErr, cnpj:''}
                props.setCompanyName(ret.data.razaoSocial)
                setCnpjState(1);
            } else {
                document?.getElementById("cnpj")?.focus();
                arrayErr ={...arrayErr, cnpj: ret.message}
                setCnpjState(2);
            }
        }
    }

    async function getCnpj(cnpj:string) {
        let ret:any = []
        await api.get('/verify/cnpj/' + cnpj).then((resp: any) => {
            ret = resp.data
        }).catch((err: any) => {

        });
        return ret
    }

    async function onSubmit() {
        let valid = validate.validateForm(values, arrayErr, setErr, setCnpjState, cnpjState)

        if (await valid) {
            props.setData({...props.data, ...values})
            props.setStep(2)
        }
    }

    return(
        <div id="step-create">
            <HeaderCreateAccount title='Criar sua conta Energizou' />

            <div>
                <InputLabel
                    name='name'
                    type='text'
                    labelName='Nome completo'
                    onChange={onChange}
                    value={values.name}
                    error={err.name}
                />

                <InputLabelMask
                    name='cnpj'
                    type='text '
                    placeholder='00.000.000/0000-00'
                    labelName='CNPJ'
                    onChange={onCnpj}
                    value={values.cnpj}
                    mask="99.999.999/9999-99"
                    error={err.cnpj}
                    cnpj={cnpjState}
                />

                <InputLabelMask
					name='phone'
					type='string'
					placeholder='(00) 00000-0000'
                    labelName='Telefone'
					onChange={onChange}
					value={values.phone}
					mask={'(99) 99999-9999'}
					error={err.phone}
				/>

                <InputLabel
                    name='mail'
                    type='mail'
                    labelName='E-mail'
                    onChange={onChange}
                    value={values?.mail}
                    error={err?.mail}
                />

                <InputLabel
                    name='password'
                    type={showPassword ? 'text' : 'password'}
                    placeholder='Senha'
                    onChange={onChange}
                    value={values.password}
                    error={err.password}
                />

                <InputLabel
                    name='confirm_password'
                    type={showPassword ? 'text' : 'password'}
                    placeholder='Confirmar Senha'
                    onChange={onChange}
                    value={values.confirm_password}
                    error={err.confirm_password}

                />

                <Checkbox
                    id='show_pass'
                    name='show_pass'
                    labelName='Mostrar senha'
                    onChange={() => setShowPassword(!showPassword)}
                />


                <div className='buttons'>
                    <button onClick={ () => onSubmit()}>Próximo</button>
                    <Button className='back' type={'link'} link='/login' text={'voltar'} ></Button>
                </div>
            </div>
	    </div>
    )
}

export default BodyCreateAccount;
