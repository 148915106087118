import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { AiFillContainer } from 'react-icons/ai';
import { BiHistory } from 'react-icons/bi';
import { BsFillBarChartFill, BsLightningChargeFill } from 'react-icons/bs';
import { HiUserCircle } from 'react-icons/hi';
import './styles.css';

const MenuMobile: React.FC<any> = () => {
    const location = useLocation();
    const [charts, setCharts] = useState(false);

    function gotToReports (event: React.MouseEvent<HTMLElement>) {
        event.preventDefault();
        const winScroll = document.getElementsByTagName('main')[0];
        winScroll && winScroll.scrollTo({top: 0, behavior: 'smooth'})
    }

    function gotToReportsCharts (event: React.MouseEvent<HTMLElement>) {
        event.preventDefault();
        const chartScroll = document.getElementById('chartsBox');
        const winScroll = document.getElementsByTagName('main')[0];
        winScroll && chartScroll && winScroll.scrollTo({top: chartScroll.offsetTop, behavior: 'smooth'})
    }

    function getScroll () {
        const winScroll   = document.getElementsByTagName('main')[0];
        const chartScroll = document.getElementById('chartsBox');
        const scrolled    = winScroll.scrollTop;

        if (chartScroll && scrolled>=(chartScroll.offsetTop-chartScroll.offsetHeight-100)) setCharts(true)
        else setCharts(false)
    }

    // UseEffects
    useEffect(() => {}, [charts])
    useEffect(() => {
        const winScroll = document.getElementsByTagName('main')[0];
        winScroll.addEventListener('scroll', getScroll)
        return () => {winScroll.removeEventListener('scroll', getScroll)}
    }, [])

    return (
        <div id="menu">
            <Link to='/admin/relatorios' onClick={location.pathname.indexOf('admin/relatorios')>=0 ? gotToReports : undefined} className={location.pathname.indexOf('admin/relatorios')>=0 && !charts ? ' active' : ''}>
                <AiFillContainer/>
                <span>Relatórios</span>
            </Link>

            <Link to='/admin/relatorios' onClick={location.pathname.indexOf('admin/relatorios')>=0 ? gotToReportsCharts : undefined} className={location.pathname.indexOf('admin/relatorios')>=0 && charts ? ' active' : ''}>
                <BsFillBarChartFill />
                <span>Gráficos</span>
            </Link>

            <div>
                <Link to='/admin' className={location.pathname==='/admin' || location.pathname === '/admin/' ? ' active' : ''}>
                    <BsLightningChargeFill/>
                </Link>
            </div>

            <Link to='/admin/historico' className={location.pathname.indexOf('admin/historico')>=0 ? ' active' : ''}>
                <BiHistory/>
                <span>Histórico</span>
            </Link>

            <Link to='/admin/perfil' className={location.pathname.indexOf('admin/perfil')>=0 ? ' active' : ''}>
                <HiUserCircle/>
                <span>Perfil</span>
            </Link>
        </div>
    );
}

export default MenuMobile;