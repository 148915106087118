import dateFormat from 'dateformat';

const dates = {
    getDateFormatted: function (date: string | Date, format?: string) {
        return dateFormat(date, format ? format : 'dd-mm-yyyy');
    },

    getFormatDate: function (date: Date | string, format: string) {
        let formated  = dateFormat(date, format);
        return formated;
    },

    getDateDBFormatted: function (date: string, init?: boolean) {
        let nDate = new Date(date);

        let year  = dateFormat(nDate, 'yyyy'),
            month = dateFormat(nDate, 'mm'),
            day   = dateFormat(nDate, 'dd');

        return year + '-' + month + '-' + (init ? '01' : day) + 'T03:00:00.000Z';
    },

    treatDates: function (date: any, type: string, years: number, format?: string) {
        let dt = new Date(date);
        dt.setFullYear(dt.getFullYear() + years);

        // Treat Dates
        let year  = dt.getFullYear();
        let month = type === 'init' ? '01' : '12';

        return this.getDateFormatted(year + '-' + month + '-01 03:00:00', format);
    },

    getNameDate: function (date: Date | string) {
        let year      = dateFormat(date, 'yyyy');
        let month     = dateFormat(date, 'mm');
        let monthName = this.getNameMonth(parseInt(month));

        return monthName + ' de ' + year;
    },

    getDateString: function (date: Date | string) {
        let year  = dateFormat(date, 'yyyy'),
            month = dateFormat(date, 'mm'),
            day   = dateFormat(date, 'dd');

        return {year, month, day};
    },

    getDateApi: function (date: Date | string) {
        let year  = dateFormat(date, 'yyyy'),
            month = dateFormat(date, 'mm');

        return year + '-' + month;
    },

    getDateCharts: function (date: Date | string) {
        let day   = dateFormat(date, 'dd');
        let year  = dateFormat(date, 'yyyy');
        let month = dateFormat(date, 'mm');
        let monthName = this.getNameMonth(parseInt(month));
        let monthNameShort = this.getShortNameMonth(parseInt(month));

        return {
            day,
            year,
            month,
            monthName: {
                full: monthName,
                short: monthNameShort
            }
        }
    },

    getNameMonth: function (month: number) {
        let monthArr = [
            "Janeiro",
            "Fevereiro",
            "Março",
            "Abril",
            "Maio",
            "Junho",
            "Julho",
            "Agosto",
            "Setembro",
            "Outubro",
            "Novembro",
            "Dezembro"
        ];


        return monthArr[month-1];
    },

    getShortNameMonth: function (month: number) {
        let monthArr = [
            "Jan",
            "Fev",
            "Mar",
            "Abr",
            "Mai",
            "Jun",
            "Jul",
            "Ago",
            "Set",
            "Out",
            "Nov",
            "Dez"
        ];

        return monthArr[month-1];
    },

    convertMsToTime: function (milliseconds: number) {
        let seconds = Math.floor(milliseconds / 1000);
        let minutes = Math.floor(seconds / 60);
        let hours = Math.floor(minutes / 60);
        let days = Math.floor(hours / 24);

        seconds = seconds % 60;
        minutes = minutes % 60;
        minutes = minutes % 60;
        hours   = hours % 24;

        return {
            days,
            hours,
            minutes,
            seconds
        };
    },

    verifyDateProposal: function (created_at:any){
        //Adiciono 5 dias no futuro para fazer a contagem regressiva
        //Proposta é valida apenas para os 5 dias após a assinatura
        let dateDB:any = new Date(created_at)
        dateDB.setDate(dateDB.getDate() + 5)
        dateDB = dateDB.getTime()

        let dateNow = new Date().getTime()

        let diffMilliseconds = new Date((dateDB-dateNow)).getTime()

        let daysAndHours = null
        if(diffMilliseconds > 0) daysAndHours = this.convertMsToTime(diffMilliseconds)
        else daysAndHours = this.convertMsToTime(0)

        return ({
            valid: diffMilliseconds > 0, //se maior que zero, ainda está valido
            created_at,
            expire_time : dateDB,
            time: daysAndHours
        })
    }
}

export default dates;