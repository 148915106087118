import * as yup from 'yup';

const schema = yup.object({
  id: yup.string().nullable(),
  company_id: yup.string().required(),
  type: yup.string().required(),
  adequacy_costing: yup.number().required(),
  json: yup.lazy((value:any) => {
    if (value.type === 'i50') {
      return yup.object({
        type: yup.string().equals(['i50']).required('Campo obrigatório'),
        discount: yup.number().equals([50]).required('Campo obrigatório'),
        year1: yup.number().required('Campo obrigatório'),
        year2: yup.number().required('Campo obrigatório'),
        year3: yup.number().required('Campo obrigatório'),
        year4: yup.number().required('Campo obrigatório'),
        year5: yup.number().required('Campo obrigatório'),
        year6: yup.number().required('Campo obrigatório'),
      });
    } else {
      return yup.object({
        plan4: yup.object({
          years: yup.number().required('Campo obrigatório'),
          value: yup.number().required('Campo obrigatório'),
        }),
        plan3: yup.object({
          years: yup.number().required('Campo obrigatório'),
          value: yup.number().required('Campo obrigatório'),
        }),
        plan2: yup.object({
          years: yup.number().required('Campo obrigatório'),
          value: yup.number().required('Campo obrigatório'),
        }),
        plan1: yup.object({
          years: yup.number().required('Campo obrigatório'),
          value: yup.number().required('Campo obrigatório'),
        }),
      });
    }
  }),
});

const validateData = async (data:any) => {
  try {
      // Validar os dados
      await schema.validate(data, { abortEarly: false });
  } catch (error) {
      // Lidar com erros de validação
      if (error instanceof yup.ValidationError) {
        const errors: { [key: string]: string } = {};
        error.inner.forEach((validationError) => {
            errors[validationError.path as string] = validationError.message;
        });
        return errors
      }
  }
};

export default validateData;