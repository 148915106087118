import React, { useState, useEffect } from 'react';
import './styles.css';
import api from './../../../../services/api';
import { Link } from 'react-router-dom';

const ConfirmEmailTemplate: React.FC<any> = props => {
    const [reactive, setReactive]  = useState<any>(props.id);

    function reactivateUser(){
		if (typeof reactive == 'number' ){
			return (<p>Caso necessário, <small style={{textDecoration:'underline', fontSize:'inherit', cursor:'pointer'}} onClick={() =>reactivateUserApi()}>clique aqui</small> para reenviar seu e-mail de ativação</p>)
		}else{
			return (<p>{reactive}</p>)
		}
	}


	function reactivateUserApi(){
		api.get('reactivate/'+reactive).then(async (resp: any) => {
			resp.data.activeMail == true ? setReactive('E-mail reenviado com sucesso') : setReactive('Ops, houve um erro! Recarregue a página e tente novamente')
        }).catch((err: any) => {
            setReactive(err.response.data)
        });
    }

    // Set Message
	useEffect(() => {
        setReactive(props.id)
    }, [props])

    return (
        <section id='confirm-mail'>
            <div>
                <h2>Verifique seu e-mail...</h2>
                <p>Enviamos um e-mail de verificação para {props.data?.mail}. Verifique sua caixa de entrada e de Spam e clique no link do e-mail para fazer login e continuar...</p>
                <p className='symbol'>@</p>
                {reactivateUser()}
            </div>
            <Link to='/login' ><p>Voltar ao login</p></Link>
        </section>
    )
}
export default ConfirmEmailTemplate
