import React from 'react';
import { useParams } from 'react-router-dom';

// Components
import AdminOppsList from './list';
import AdminOppsEdit from './edit';

const OpportunitiesHub: React.FC = () => {
    let params = useParams();
    let id     = params.id;

    return id && Number(id)>0 ?
        <AdminOppsEdit /> :
        <AdminOppsList />
}

export default OpportunitiesHub