import React, { useState } from 'react';
import './style.css';

interface Props {

    title:string,
    corr:boolean,
    api: string
    bill: string
}

const ItemTariff = (props: Props) => {
    const [click, setClick] = useState(false);

    if(props.corr === true){
        return (
            <div className='tariff corr'>
                <div className='title'>{props.title}</div>
                <div className='body'>
                    <label>{props.bill} <small>por kWh</small></label>
                    <p>{props.corr ? 'Correto':'Errado'}</p>
                </div>
            </div>)

    }else {
        return(
            <>
                 <div className='tariff err'>
                    <div className='title'>{props.title}</div>
                    <div className='body'>
                        <label>{props.bill} <small>por kWh</small></label>
                        <div>
                            <p>{props.corr ? 'Correto':'Errado'}</p>
                            <span onClick={()=> setClick(!click)}>{!click ? 'Ver mais': 'Ver menos'}</span>
                        </div>
                    </div>
                </div>
                {click ?
                <div className='tariff err-corr'>
                    <div className='title'>A tarifa correta é</div>
                    <div className='body'>
                        <label>{props.api} <small>por kWh</small></label>
                        <p>{props.corr ? 'Correto':'Errado'}</p>
                    </div>
                </div>
                :<></>}

            </>
        )
    }
}

export default ItemTariff;