import React, { useContext } from 'react';

import { GeneralContext } from '~/contexts';
import api from './../../../services/api';

import { useNavigate } from 'react-router-dom';

import { FiChevronRight } from 'react-icons/fi';

const StepProfile: React.FC<any> = ({setStep}: {setStep: Function}) => {
    const {user, logout} = useContext(GeneralContext);
    const navigate = useNavigate()

    function popup(){
        if (window.confirm("Deseja encerrar sua conta? Essa ação não poderá ser desfeita")) {

            api.delete('/users/' + user.id,{
                headers: { Authorization: user.token }
            }).then(resp => {
                alert('Usuário deletado')
                navigate('/login', {replace: true})
                logout()

            }).catch(err =>{
                alert('Falha ao deletar usuário')
            })
          } else {
            alert('Ação cancelada')
          }
    }

    return <div className='default'>
        <h1>Meus dados</h1>

        <div className="buttons">
            <button type="button" onClick={() => setStep('infos')}>
                <div className="infos">
                    <span>Informações Pessoais</span>
                    <span>Dados pessoais e informações da empresa</span>
                </div>

                <div className="btn">
                    <FiChevronRight />
                </div>
            </button>

            <button type="button" onClick={() => setStep('contacts')}>
                <div className="infos">
                    <span>Dados de Contato</span>
                    <span>Email, telefone de contato e senha</span>
                </div>

                <div className="btn">
                    <FiChevronRight />
                </div>
            </button>

            <button type="button" onClick={() => popup()}>
                <div className="infos">
                    <span style={{color:'#b84959'}}>Encerrar conta</span>
                </div>

                <div className="btn">
                    <FiChevronRight />
                </div>
            </button>
        </div>
    </div>;
}

export default StepProfile;