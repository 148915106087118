import React, { useContext, useEffect, useState } from 'react';
import '../main.css';
import './styles.css';
import ReactTooltip from 'react-tooltip';
import mobile from 'is-mobile';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Legend } from 'recharts';
import { FaInfoCircle } from 'react-icons/fa';
import { ModalContext } from '~/contexts/modal/index';
import dates from '~/utils/dates';
import numbers from '~/utils/numbers';
import Funcs from '../funcs';

const TotalValue:React.FC<any> = ({className, styles, months, infos}: any) => {
	const {setModalOpen, setModalTitle, setModalBody} = useContext(ModalContext);
	const [data, setData]         = useState<any>(null);
	const [interval, setInterval] = useState(0);

	const [month, setMonth]       = useState<any>(null);

	// Chart Funcions
	const renderCustomTooltip = ({ active, payload }: any) => {
		let total: any = null;
		payload.map((el: any) => total = el)

		// Treat Title
		let dateBase = total ? total.payload.date.split('-') : null;
		let title    = total ? dates.getNameMonth(dateBase[1]) + '/' + dateBase[0] : '';

		return <div className='tooltipChart'>
			<h4>{title}</h4>

			<div style={{color: total ? total.color : '#A0A0A0'}}><span>{total ? numbers.money(total.value, true, true) : ''}</span></div>
		</div>
	}

	const renderCustomLegend = ({ active, payload }: any) => {
		let total: any = null;
		payload.map((el: any) => total = el)

		return <>
			<div style={{color: '#666666'}}>
				<span className="color" style={{backgroundColor: total ? total.color : '#A0A0A0'}}></span>
				Total da Conta
			</div>
		</>
	}

	// Mount Functions
	useEffect(() => {
		setMonth(months)

		if (infos!==undefined) {
			let dataInfo = JSON.parse(JSON.stringify(infos)).reverse();

			switch (month) {
				case 12:
					setData(dataInfo)
					setInterval(2)
					break
				case 6:
					setData(dataInfo.slice(dataInfo.length-6))
					setInterval(1)
					break
				case 3:
					setData(dataInfo.slice(dataInfo.length-3))
					setInterval(0)
					break
			}
		}
    }, [month, months, infos]);

	function modalTooltip () {

		if(mobile()){
			setModalTitle('Valor total')
			setModalBody(<>
				<p>Valor total pago mês a mês na conta de luz.</p>
				<p className="warning">Esse gráfico <b>gera os dados apenas do mês das faturas de energia enviadas</b>. Para completar seu gráfico <b>envie novas contas de luz</b></p>
				<p className="notes">- Para a melhor experiência em gráficos faça o upload de pelo menos <b>3 Contas de Luz na página "Histórico"</b>.</p>
			</>)
			setModalOpen(true)
		}
    }

	return <div  className={`${className ? className : ''} totalValue`} style={styles}>
		<header>
			<div className="titles">
				<h3>Valor Total</h3>
				<h4>Da sua conta de luz</h4>
			</div>

			<div className="hover" data-tip="tooltip" data-for="totalValueInfo">
                <FaInfoCircle color='#c0c0c0' onClick={() => modalTooltip()}/>

				{!mobile()?
					<ReactTooltip id="totalValueInfo" className='infosBox big' place="left" type="light" effect="solid" clickable={true} delayHide={1000}>
						<p>Valor total pago mês a mês na conta de luz.</p>
						<p className="warning">Esse gráfico <b>gera os dados apenas do mês das faturas de energia enviadas</b>. Para completar seu gráfico <b>envie novas contas de luz</b></p>
						<p className="notes">- Para a melhor experiência em gráficos faça o upload de pelo menos <b>3 Contas de Luz na página "Histórico"</b>.</p>
					</ReactTooltip>
				:
					<></>
				}
            </div>
		</header>

		<section className="content">
			<ResponsiveContainer height={mobile() ? 250 : 350}>
				<BarChart
					data={data}
					margin={{
						top: 20,
						right: 30,
						left: 20,
						bottom: 5,
					}}
				>
					<CartesianGrid vertical={false}/>
					<XAxis dataKey="date" interval={interval} tick={Funcs.renderXAxisLabel} />
					<YAxis tick={props => Funcs.renderYAxisLabelCurr(props, true, false)}/>
					<Tooltip content={renderCustomTooltip} />
					{!mobile() && <Legend content={renderCustomLegend} />}

					<Bar dataKey="total" stackId="a" fill="#00509D" />
				</BarChart>
			</ResponsiveContainer>

			{
				mobile() && (
					<div className="legends">
						<div style={{color: '#666666'}}>
							<span className="color" style={{backgroundColor: '#00509D'}}></span>
							Total da Conta
						</div>
					</div>
				)
			}
		</section>
	</div>;
}

export default TotalValue;