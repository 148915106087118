import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { FaCalendar } from 'react-icons/fa';
import { GeneralContext } from '~/contexts';
import api from '~/services/api';
import './styles.css';

// Components
import Template from '~/pages/admin/Template';
import Loading from '~/components/Default/Loading';
import MoonChart from '~/components/Boxs/MoonChart';

const Consumption: React.FC = () => {
    const params    = useParams();
    const yearMonth = params.yearMonth;
    // const navigate  = useNavigate();

    // Contexts
    const { breadcrumbs, setBreadcrumbs } = useContext(GeneralContext);
    const {user}                          = useContext(GeneralContext);

    // States
    const [ready, setReady] = useState(false);
    const [data, setData]   = useState<any>(null);

    // Mount
    useEffect(() => {
        if (data===null) {
            api.get(`bills/audit/consuption${yearMonth ? `/${yearMonth}` : ''}`, {
                headers: { Authorization: user.token }
            }).then((resp: any) => {
                setData(resp.data)
                setReady(true)
            }).catch((err: any) => {
                const { status } = err.response;
                if (status===400) {
                    console.log('Error')
                    console.log(err.response)

                    // Ready
                    setTimeout(() => setReady(true), 2000);
                }
            });
        }
    }, [user, ready, data, yearMonth]);

    // Breadcrumbs
    useEffect(() => {
        !breadcrumbs && setBreadcrumbs({
            curr: 'Consumo',
            links: [
                { name: 'Dashboard', url: '/admin'},
                { name: 'Analise das Contas' }
            ]
        });
    }, [breadcrumbs]);

    // Unmount
    useEffect(() => () => setBreadcrumbs(null), [])

    return <Template page="Auditoria - Consumo" pageTitle="Consumo" className="cons">
        {
            ready && data ? (<>
                <div className="left">
                    <div className="title">
                        {
                            data.minConsumption<=data.currentConsumption && data.maxConsumption>data.currentConsumption ?
                            <>O seu consumo total para o mês de {data.month} de {data.year} está dentro do padrão identificado nos últimos 12 meses da sua fatura.</> :
                            <>Identificamos que o seu consumo total para o mês de {data.month} de {data.year} está fora do padrão identificado nos últimos 12 meses da sua fatura.</>
                        }
                    </div>

                    <div className='chart green border'>
                        <MoonChart
                            month={data.month}
                            monthValue={data.currentConsumption}
                            scale={{
                                init: data.minConsumption,
                                end: data.maxConsumption
                            }}
                        />
                    </div>

                    <small>* Para elaboração dessa análise, utilizamos os dados de maior consumo e menor consumo dos últimos 12 meses. Essa informação não é uma garantia que o seu consumo lido está adequado. Utilize esses dados como uma referência.</small>
                </div>

                <div className="right">
                    <div className="alert">
                        Sugerimos que você entre em contato com a sua distribuidora por meio dos canais oficiais de comunicação e solicite uma revisão dos valores da sua conta de energia.
                    </div>

                    <div className="date">
                        <FaCalendar /> <span>Última atualização de tarifa: <b>21.05.2022</b></span>
                    </div>
                </div>
            </>) : <Loading />
        }
    </Template>;
}

export default Consumption;