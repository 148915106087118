import React from 'react';
import './signing.css';

// Components
import { Button } from '~/components/Forms';

// Images
import bigCheck from '~assets/images/check_circle.png';
import { Link } from 'react-router-dom';

const SigningStep: React.FC<{download: Function, down: boolean}> = ({download, down}) => {
    return (
        <div className='signatureOpps'>
            <img src={bigCheck} alt="Reserva" />
            <h3>A proposta foi gerada com sucesso!</h3>

            <p>A proposta comercial da Energizou foi enviada para o seu e-mail.</p>
            {/* <p>Aproveite para já agendar uma reunião com a nossa equipe<br/>comercial para apresentação da proposta.</p> */}

            <Link to='/admin' className="box-save">
                Voltar ao início
            </Link>

            <Button
                type="button"
                text="Fazer download da proposta"
                className="box-info"
                func={() => download()}
                disabled={down}
            />
        </div>
    );
}

export default SigningStep;