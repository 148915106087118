import React from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { MixPanelProvider, GeneralProvider, ThemeProvider, MessagesProvider, ModalProvider } from '~/contexts';
import Routes from './routes';

function appHeight() {
	const doc = document.documentElement
	doc.style.setProperty('--vh', (window.innerHeight*.01) + 'px')
}

window.addEventListener('resize', appHeight);
appHeight();

const App: React.FC = () => {
	return (
		<HelmetProvider>
			<MixPanelProvider>
				<GeneralProvider>
					<ModalProvider>
						<MessagesProvider>
							<ThemeProvider initialTheme='light'>
								<Routes />
							</ThemeProvider>
						</MessagesProvider>
					</ModalProvider>
				</GeneralProvider>
			</MixPanelProvider>
		</HelmetProvider>
	)
}

export default App;
