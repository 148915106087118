import React, { useEffect, useContext } from 'react';
import { ShepherdTourContext } from 'react-shepherd';
import { GeneralContext } from '~/contexts';
import api from '~/services/api';

export const StartTour: React.FC<any> = ({setWizard}: {setWizard: Function}) => {
    const {user}   = useContext(GeneralContext);
    const tour     = useContext(ShepherdTourContext);

    useEffect(() => {
        if (tour && !tour.isActive()) {
            tour.start()

            tour.on('complete', async () => {
                api.put(`users/wizard`, '', {
                    headers: { Authorization: user.token }
                }).then((resp: any) => {
                    setWizard(false)
                })
            })

            tour.on('cancel', async () => {
                api.put(`users/wizard`, '', {
                    headers: { Authorization: user.token }
                }).then((resp: any) => {
                    setWizard(false)
                })
            })
        }
    }, [])

    return null
}