const data: any = {
    monthConsumption: [
        {
            date: "2022-05",
            peak_hours: 1050,
            off_peak_hours: 242200
        },
        {
            date: "2022-04",
            peak_hours: 700,
            off_peak_hours: 92050
        },
        {
            date: "2022-03",
            peak_hours: 700,
            off_peak_hours: 113050
        },
        {
            date: "2022-02",
            peak_hours: 700,
            off_peak_hours: 56700
        },
        {
            date: "2022-01",
            peak_hours: 350,
            off_peak_hours: 21350
        },
        {
            date: "2021-12",
            peak_hours: 1050,
            off_peak_hours: 70000
        },
        {
            date: "2021-11",
            peak_hours: 1050,
            off_peak_hours: 150850
        },
        {
            date: "2021-10",
            peak_hours: 1400,
            off_peak_hours: 210350
        },
        {
            date: "2021-09",
            peak_hours: 1400,
            off_peak_hours: 284200
        },
        {
            date: "2021-08",
            peak_hours: 1400,
            off_peak_hours: 309400
        },
        {
            date: "2021-07",
            peak_hours: 1400,
            off_peak_hours: 260050
        },
        {
            date: "2021-06",
            peak_hours: 1050,
            off_peak_hours: 158550
        }
    ],
    costPerKwh: [
        {
            date: "2022-05",
            energy: 0.42924032286366026,
            demand: 0.053713984909633264,
            flag: 0.005195121951219512,
            others: 0.029407898127491106,
            cost: 0.5175573278520041
        },
        {
            date: "2022-04",
            energy: 0.4337377603703044,
            demand: 0.2396859533558839,
            flag: 0.17317073170731703,
            others: 0.45616843256440925,
            cost: 1.3027628779979146
        },
        {
            date: "2022-03",
            energy: 0,
            demand: 0,
            flag: 0,
            others: 0,
            cost: 0
        },
        {
            date: "2022-02",
            energy: 0,
            demand: 0,
            flag: 0,
            others: 0,
            cost: 0
        },
        {
            date: "2022-01",
            energy: 0.42599540703199235,
            demand: 0.04942988137515205,
            flag: 0.17317073170731706,
            others: 0.09285155669896908,
            cost: 0.7414475768134304
        },
        {
            date: "2021-12",
            energy: 0,
            demand: 0,
            flag: 0,
            others: 0,
            cost: 0
        },
        {
            date: "2021-11",
            energy: 0,
            demand: 0,
            flag: 0,
            others: 0,
            cost: 0
        },
        {
            date: "2021-10",
            energy: 0,
            demand: 0,
            flag: 0,
            others: 0,
            cost: 0
        },
        {
            date: "2021-09",
            energy: 0,
            demand: 0,
            flag: 0,
            others: 0,
            cost: 0
        },
        {
            date: "2021-08",
            energy: 0,
            demand: 0,
            flag: 0,
            others: 0,
            cost: 0
        },
        {
            date: "2021-07",
            energy: 0,
            demand: 0,
            flag: 0,
            others: 0,
            cost: 0
        },
        {
            date: "2021-06",
            energy: 0,
            demand: 0,
            flag: 0,
            others: 0,
            cost: 0
        }
    ],
    totalValue: [
        {
            date: "2022-05",
            total: 125895.82
        },
        {
            date: "2022-04",
            total: 62467.48
        },
        {
            date: "2022-03",
            total: 0
        },
        {
            date: "2022-02",
            total: 0
        },
        {
            date: "2022-01",
            total: 84267
        },
        {
            date: "2021-12",
            total: 0
        },
        {
            date: "2021-11",
            total: 0
        },
        {
            date: "2021-10",
            total: 0
        },
        {
            date: "2021-09",
            total: 0
        },
        {
            date: "2021-08",
            total: 0
        },
        {
            date: "2021-07",
            total: 0
        },
        {
            date: "2021-06",
            total: 0
        }
    ],
    flags: [
        {
            flag: "Verde",
            year: "2022",
            month: "05",
            date: "2022-05",
            value: 0.0008
        },
        {
            flag: "Escassez Hídrica",
            year: "2022",
            month: "04",
            date: "2022-04",
            value: 0.142
        },
        {
            flag: "Escassez Hídrica",
            year: "2022",
            month: "03",
            date: "2022-03",
            value: 0.142
        },
        {
            flag: "Escassez Hídrica",
            year: "2022",
            month: "02",
            date: "2022-02",
            value: 0.142
        },
        {
            flag: "Escassez Hídrica",
            year: "2022",
            month: "01",
            date: "2022-01",
            value: 0.142
        },
        {
            flag: "Escassez Hídrica",
            year: "2021",
            month: "12",
            date: "2021-12",
            value: 0.142
        },
        {
            flag: "Escassez Hídrica",
            year: "2021",
            month: "11",
            date: "2021-11",
            value: 0.142
        },
        {
            flag: "Escassez Hídrica",
            year: "2021",
            month: "10",
            date: "2021-10",
            value: 0.142
        },
        {
            flag: "Escassez Hídrica",
            year: "2021",
            month: "09",
            date: "2021-09",
            value: 0.142
        },
        {
            flag: "Vermelha - Patamar 2",
            year: "2021",
            month: "08",
            date: "2021-08",
            value: 0.09492
        },
        {
            flag: "Vermelha - Patamar 2",
            year: "2021",
            month: "07",
            date: "2021-07",
            value: 0.09492
        },
        {
            flag: "Vermelha - Patamar 2",
            year: "2021",
            month: "06",
            date: "2021-06",
            value: 0.06243
        }
    ],
    demandAnalysis: [
        {
            date: "2022-05",
            demand: 645,
            value: 721
        },
        {
            date: "2022-04",
            demand: 645,
            value: 585
        },
        {
            date: "2022-03",
            demand: 645,
            value: 0
        },
        {
            date: "2022-02",
            demand: 645,
            value: 0
        },
        {
            date: "2022-01",
            demand: 180,
            value: 310
        },
        {
            date: "2021-12",
            demand: 645,
            value: 0
        },
        {
            date: "2021-11",
            demand: 645,
            value: 0
        },
        {
            date: "2021-10",
            demand: 645,
            value: 0
        },
        {
            date: "2021-09",
            demand: 645,
            value: 0
        },
        {
            date: "2021-08",
            demand: 645,
            value: 0
        },
        {
            date: "2021-07",
            demand: 645,
            value: 0
        },
        {
            date: "2021-06",
            demand: 645,
            value: 0
        }
    ]
}

export default data