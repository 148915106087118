import { FaHome, FaStoreAlt } from 'react-icons/fa';

const init = [
    {
        type: 'delay',
        time: 5000
    },
    {
        type: 'text',
        text: 'Olá #NAME#, tudo bem? Seja bem vindo ao Energizou App!'
    },
    {
        type: 'delay',
        time: 5000
    },
    {
        type: 'text',
        text: 'Vamos te ajudar a entender e reduzir o valor da sua conta de luz.',
    },
    {
        type: 'delay',
        time: 5000
    },
    {
        type: 'text',
        text: 'Mas antes, me diga qual conta você quer reduzir?',
    },
    {
        type: 'delay',
        time: 5000
    },
    {
        type: 'question',
        name: 'type',
        items: [
            {
                icon: <FaStoreAlt />,
                text: 'Da minha empresa',
                click: (user: any, button: Function) => button(user.id, 'Onboarding', 'Business'),
                step: 3
            }, {
                icon: <FaHome />,
                text: 'Da minha casa',
                click: (user: any, button: Function) => button(user.id, 'Onboarding', 'Home'),
                step: 2
            }
        ]
    }
]

export default init