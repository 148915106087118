
const TermsPrivacity: React.FC<any> = () => {

    return <>
        <p><b>Política de Privacidade e de Proteção de Dados</b></p>
        <p>A presente PPDP foi criada para reafirmar o compromisso da ENERGIZOU COMERCIALIZADORA DE ENERGIA S.A., inscrita no CNPJ 30.693.787/0001-85 (“Provedora”), com a proteção dos Dados Pessoais que são coletados de seus clientes e visitantes, inclusive através de meios digitais, com a finalidade de trazer ainda mais transparência e confiança a este relacionamento.</p>
        <p><b>1.	GLOSSÁRIO</b></p>
        <p>1.1. Para a interpretação e aplicação desta PPDP, os termos a seguir têm os seguintes significados, sem prejuízo dos termos e expressões adotados pela LGPD e Legislação Aplicável, que manterão os significados a eles atribuídos:</p>
        <p>a.	Agentes de Tratamento: Controlador e Operador.</p>
        <p>b.	ANPD: Autoridade Nacional de Proteção de Dados;</p>
        <p>c.	Controlador: pessoa natural ou jurídica, de direito público ou privado, a quem competem as decisões referentes ao Tratamento de Dados Pessoais; </p>
        <p>d.	Cookies: arquivo armazenado no computador para auxiliar a navegação em websites. Prática comum para personalizar a experiência do Titular que poderá modificar as configurações do seu navegador para aceitar ou rejeitar Cookie;</p>
        <p>e.	Dados Pessoais: informação relacionada a pessoa natural identificada ou identificável. Quando não devidamente destacado, a expressão Dados Pessoais também englobará a expressão Dados Pessoais Sensíveis;</p>
        <p>f.	Dados Pessoais Sensíveis: Dado Pessoal sobre origem racial ou étnica, convicção religiosa, opinião política, filiação a sindicato ou a organização de caráter religioso, filosófico ou político, dado referente à saúde ou à vida sexual, dado genético ou biométrico, quando vinculado ao Titular; </p>
        <p>g.	Legislação Aplicável: todas as disposições constitucionais, leis, medidas provisórias, decretos, resoluções, portarias, regulamentos e outras normas aplicáveis, incluindo a LGPD, a esta PPDP;</p>
        <p>h.	LGPD: a Lei nº 13.709, de 14 de agosto de 2018, e suas posteriores alterações e regulamentos;</p>
        <p>i.	Mercado de Livre Energia: denominado e estabelecido conforme as disposições do Artigo 15 da Lei nº 9.074/1995, conforme alterada, e regulações subsequentes;</p>
        <p>j.	Operador: pessoa natural ou jurídica, de direito público ou privado, que realiza o Tratamento de Dados Pessoais em nome do Controlador;</p>
        <p>k.	PPDP: A Política de Privacidade e Proteção de Dados Pessoais, instituída pela Provedora;</p>
        <p>l.	Provedora: conforme a definição do preâmbulo;</p>
        <p>m.	Titular: pessoa natural a quem se referem os Dados Pessoais que são objeto de Tratamento, incluindo qualquer representante (inclusive terceirizados) da empresa usuária aderente ao Sistema que eventualmente venha fornecer e compartilhar seus Dados Pessoais com a Provedora por força do Sistema; </p>
        <p>n.	Sistema: sistema tecnológico disponível na internet oferecido pela Provedora;</p>
        <p>o.	Termo: Termo de Condições Gerais de Uso da Provedora;</p>
        <p>p.	Tratamento: toda operação realizada com Dados Pessoais, como as que se referem à coleta, produção, recepção, classificação, utilização, acesso, reprodução, transmissão, distribuição, processamento, arquivamento, armazenamento, eliminação, avaliação ou controle da informação, modificação, comunicação, transferência, difusão ou extração.</p>
        <p>1.2. A utilização dos referidos termos definidos nesta PPDP, no plural ou no singular, no masculino ou no feminino, não altera os significados a eles atribuídos.</p>
        <p><b>2.	DA APLICABILIDADE E DA EXTENSÃO </b></p>
        <p>2.1.	Esta PPDP é relacionada ao Termo e aplicável a qualquer Titular que, de alguma forma, venha a utilizar o Sistema e/ou o website da Provedora, independente do território onde se localize.</p>
        <p><b>3.	DO TRATAMENTO E SEUS PRINCÍPIOS</b></p>
        <p>3.1.	A Provedora é a responsável pelo Tratamento dos Dados Pessoais coletados na forma desta PPDP, sendo o Controlador das informações dos Titulares. </p>
        <p>3.2.	O Tratamento dos Dados Pessoais ocorrerá sempre dentro dos termos da Legislação Aplicável, em especial a LGPD, e deverão observar a boa-fé e os seguintes princípios:</p>
        <p>• FINALIDADE: realização para propósitos legítimos, específicos, explícitos e informados ao Titular, sem possibilidade de Tratamento posterior de forma incompatível com essas finalidades;</p>
        <p>• ADEQUAÇÃO: compatibilidade com as finalidades informadas ao Titular, de acordo com o contexto do Tratamento;</p>
        <p>• NECESSIDADE: limitação ao mínimo necessário para a realização de suas finalidades, com abrangência dos dados pertinentes, proporcionais e não excessivos em relação às finalidades do Tratamento;</p>
        <p>• LIVRE ACESSO: garantia, aos Titulares, de consulta facilitada e gratuita sobre a forma e a duração do Tratamento, bem como sobre a integralidade de seus Dados Pessoais;</p>
        <p>• QUALIDADE DOS DADOS: garantia, aos Titulares, de exatidão, clareza, relevância e atualização dos dados, de acordo com a necessidade e para o cumprimento da finalidade de seu Tratamento;</p>
        <p>• TRANSPARÊNCIA: garantia, aos Titulares, de informações claras, precisas e facilmente acessíveis sobre a realização do Tratamento e os respectivos Agentes de Tratamento, observados os segredos comercial e industrial;</p>
        <p>• SEGURANÇA: utilização de medidas técnicas e administrativas aptas a proteger os Dados Pessoais de acessos não autorizados e de situações acidentais ou ilícitas de destruição, perda, alteração, comunicação ou difusão;</p>
        <p>• PREVENÇÃO: adoção de medidas para prevenir a ocorrência de danos em virtude do Tratamento;</p>
        <p>• NÃO DISCRIMINAÇÃO adoção de medidas para impedir a realização do Tratamento para fins discriminatórios ilícitos ou abusivos; e</p>
        <p>• RESPONSABILIZAÇÃO E PRESTAÇÃO DE CONTAS: demonstração, da adoção de medidas eficazes e capazes de comprovar a observância e o cumprimento das normas de proteção de Dados Pessoais e, inclusive, da eficácia dessas medidas.</p>
        <p><b>4.	DOS DADOS PESSOAIS TRATADOS E SUA FINALIDADE</b></p>
        <p>4.1.	Os seguintes Dados Pessoais do Titular poderão ser solicitados: (i) nome; (ii) endereço de e-mail corporativo; (iii) número de telefone; (v) documentos de identificação (RG, RNE, CPF); (vi) senha.</p>
        <p>4.2.	Os seguintes dados da empresa usuária aderente ao Sistema, da qual o Titular seja representante (inclusive terceirado), poderão ser solicitados:  (a) dados cadastrais: (i) razão social; (ii) CNPJ; (iii) endereço completo; (b) dados referentes à conta de energia: (i) número de instalação; (ii) histórico de consumo e demanda de energia elétrica; (iii) dados cadastrais da conta de energia elétrica (incluindo modalidade tarifária e grupo tarifário); (iv) alíquotas de tributos pagos; (v) valores pagos.</p>
        <p>4.3. Os Dados Pessoais do Titular são utilizados para fins de acesso ao Sistema como representante (inclusive terceirizado) da empresa usuária aderente. O Sistema tem por objetivo apresentar oportunidades de redução de custo de energia elétrica à empresa usuária, cujos dados serão utilizados também para fins de acesso ao Sistema como também para fins comerciais pela Provedora, para elaboração e envio de propostas, incluindo soluções relacionadas ao Mercado Livre de Energia e propagandas de serviços e produtos.</p>
        <p>4.4.	Outros dados poderão ser coletados automaticamente do Titular ao utilizar os serviços digitais da Provedora, como, por exemplo, dados atrelados a sua conexão, tais como: possibilidade de acesso à internet, IP de internet, IP local, local de conexão, nome de máquina, código de solicitações, dentre outras informações. Além disso, Cookies  utilizados pela Provedora, podem coletar outras informações.</p>
        <p>4.5.	Para o Tratamento dos Dados Pessoais abrangidos nesta PPDP são utilizadas as seguintes bases legais da LGPD, conforme o caso: (i) consentimento; (ii) garantia da prevenção à fraude e à segurança do Titular dos Dados Pessoais e da empresa usuária aderente ao Sistema, nos processos de identificação e autenticação de cadastro de sistemas eletrônicos; (iii) cumprimento de obrigação legal, regulatória ou contratual; (iv) análise/proteção de crédito; (v) para atender legítimos interesses da Provedora e/ou de terceiros.</p>
        <p>4.6.	Ao realizar o cadastro no Sistema e concordar com o Termo, o Titular possui ciência, concorda e autoriza com a coleta, tratamento e o armazenamento dos dados incluindo os Dados Pessoais que fornece ao Sistema no momento de cadastro e ao decorrer do uso do Sistema.</p>
        <p>4.7.	A empresa usuária aderente do Sistema deverá obter dos competentes Titulares que lhe representarão no Sistema as devidas autorizações para fornecer os seus respectivos Dados Pessoais à Provedora, conforme aplicável.</p>
        <p><b>5.	TEMPORALIDADE E ARMAZENAMENTO DOS DADOS</b></p>
        <p>5.1.	As informações coletadas pela Provedora serão automaticamente excluídas quando deixarem de ser úteis para os fins para os quais foram coletadas, ou quando o Titular solicitar a eliminação de seus Dados Pessoais, conforme aplicável. A exclusão das informações essenciais para gestão de acesso ao Sistema poderá implicar no término do cadastro, com o consequente cancelamento dos serviços prestados pela Provedora.</p>
        <p><b>6.	DO COMPARTILHAMENTO</b></p>
        <p>6.1.	O uso do Sistema é totalmente gratuito. No entanto, alguns serviços e/ou produtos adicionais oferecidos poderão eventualmente estar sujeitos a remuneração.</p>
        <p>6.2.	Caso empresas terceirizadas realizem o processamento de quaisquer dados coletados pela Provedora, as mesmas deverão obrigatoriamente respeitar as condições aqui estipuladas, incluindo as normas de segurança da informação da Provedora. </p>
        <p><b>7.	COOKIES</b></p>
        <p>7.1. A Provedora utiliza Cookies para facilitar o uso do Sistema, bem como para compilar informações sobre a utilização de nossos sites e serviços, auxiliando a melhorar suas estruturas e seu conteúdo. Os Cookies também podem ser utilizados para acelerar suas atividades e experiências futuras no Sistema. A qualquer momento o Titular poderá revogar seu consentimento quanto aos Cookies, pelo que deverá apagar os Cookies do Sistema utilizando as configurações de seu navegador de preferência.</p>
        <p>7.2.	Ao desativar os Cookies a disponibilidade de algumas ferramentas e funcionalidades do website e do Sistema pode ser afetada, comprometendo seu correto e esperado funcionamento. Outra consequência possível é remoção das preferências do Titular que eventualmente tiverem sido salvas prejudicando sua experiência.</p>
        <p><b>8.	DOS DIREITOS DO TITULAR</b></p>
        <p>8.1. Em cumprimento à regulamentação aplicável, no que diz respeito ao Tratamento de Dados Pessoais, a Provedora respeita e garante ao Titular, os seguintes direitos: (i) confirmação da existência de Tratamento; (ii) acesso aos dados; (iii) correção de dados incompletos, inexatos ou desatualizados; (iv) anonimização, bloqueio ou eliminação de dados desnecessários, excessivos ou tratados em desconformidade com a Legislação Aplicável; (v) portabilidade de seus dados a outro fornecedor de serviço ou produto, mediante requisição expressa pelo Titular; (vi) eliminação dos dados tratados com consentimento do Titular, quando aplicável; (vii) obtenção de informações sobre as entidades públicas ou privadas com as quais a Provedora compartilhou seus dados; (viii) informação sobre a possibilidade de não fornecer o seu consentimento, bem como de ser informado sobre as consequências, em caso de negativa; e (ix) revogação do consentimento.</p>
        <p><b>9.	DA SEGURANÇA</b></p>
        <p>9.1.	A Provedora emprega as melhores práticas de segurança e privacidade, visando garantir a confidencialidade das informações conforme previsto em lei.</p>
        <p>9.2.	Para manter suas informações seguras, a Provedora utiliza ferramentas físicas, eletrônicas e administrativas. Apesar de a Provedora tomar devidas medidas para prevenir acesso ou uso não autorizado de informações pessoais, a Provedora não pode garantir que a informação que coletada ou armazenada estará sempre protegida de acesso não autorizado.</p>
        <p>8.3.	É de responsabilidade do Titular a adoção de medidas de segurança razoáveis nos dispositivos que utiliza para acessar o Sistema. É possível que o site da Provedora contenha links e acessos seguros que redirecionem o Titular a sites externos. Dessa forma, é de responsabilidade do Titular consultar as políticas de privacidade de outros sites.</p>
        <p>9.4.	A Provedora não é responsável por eventuais links dispostos no Sistema que apontem sites externos que não sejam de sua propriedade. A Provedora não é responsável pelo conteúdo desses sites ou sequer pode ser considerada como responsável solidária dos proprietários desses sites.</p>
        <p><b>10.	REGRAS DE ACESSO E USO</b></p>
        <p>10.1.	O Titular é exclusivamente responsável pela exatidão e veracidade dos dados que forneceu, isentando a Provedora em caso de erros ou danos que resultem destas informações errôneas. O Titular garante que possui as devidas autorizações dos competentes Titulares para fornecer os Dados Pessoais à Provedora, conforme aplicável. É responsabilidade do Titular a constante atualização das informações e dados informados e/ou fornecidos à Provedora. No caso de dados inexatos, incompletos ou desatualizados, o Titular possui a ciência de que estará sujeito a suspensão do acesso a algumas funções, serviços fornecidos e/ou cancelamento pela Provedora de seu cadastro, independente de notificação.</p>
        <p>10.2.	A senha e o login de cada Titular são exclusivos e intransferíveis. É de responsabilidade exclusiva do Titular o zelo e sigilo sobre suas informações de login e senha.</p>
        <p><b>11.	ENCARREGADO DE PROTEÇÃO DE DADOS</b></p>
        <p>11.1.	Todas as solicitações, requerimentos, notificações, comunicados ou avisos, relativos a esta PPDP ou à LGPD devem ser encaminhadas pelo Titular, ou seu representante legal, por escrito à Provedora para o seguinte endereço eletrônico: dpo@energizou.com.br.</p>
        <p><b>12.	ATUALIZAÇÕES (REVISÕES)</b></p>
        <p>12.1.	Esta  PPDP deverá ser revisada e atualizada periodicamente de forma a sempre estar em conformidade com a Legislação Aplicável, ou sempre que a ANPD regular o assunto. A Provedora publicará em seu website a indicação de que este PPDP encontra-se com uma nova versão. Aludida indicação poderá ser realizada através da publicação da data da versão da PPDP, inclusa ao final deste documento.</p>
    </>
}

export default TermsPrivacity;
