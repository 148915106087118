interface ENV {
    stage: string;
    host: string;
    server: string;
    mixPanel: string;

    api: {
        host: string;
        token: string;
    }

    reading: {
        host: string;
        token: string;
    }

    crypto: {
        web: string;
        server: string;
    }
}

const env: ENV = {
    stage: process.env.REACT_APP_STAGE || '',
    host: process.env.REACT_APP_HOST || '',
    server: process.env.REACT_APP_SERVER || '',

    api: {
        host: process.env.REACT_APP_API_HOST || '',
        token: process.env.REACT_APP_API_TOKEN || '',
    },

    reading: {
        host: process.env.REACT_APP_READING_HOST || '',
        token: process.env.REACT_APP_READING_TOKEN || '',
    },

    crypto: {
        web: process.env.REACT_APP_CRYPTO_WEB || '',
        server: process.env.REACT_APP_CRYPTO_SERVER || ''
    },

    mixPanel: process.env.REACT_APP_MIXPANEL || ''

}

export default env;