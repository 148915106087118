import React from 'react';
import dates from '~/utils/dates';
import './signing.css';

// Components
import { Button } from '~/components/Forms';

// Images
import document from '~assets/images/document.png';
import { FiLoader } from 'react-icons/fi';
import { Link } from 'react-router-dom';

const FinishedStep: React.FC<{proposalSigned: any, download: Function, down: boolean}> = ({proposalSigned, download, down}) => {
    function convertDate(date:Date){
        let ret:any = dates.getDateString(date)
        ret = ret.day +'/'+ret.month+'/'+ ret.year

        return ret
    }

    let dateProposalValidaty = dates.verifyDateProposal(proposalSigned.created_at)

    return (
        <div className='signatureOpps finished'>
            <img src={document} alt="Documento" />
            <h3>Visualizar proposta comercial</h3>

            <p>A proposta comercial gerada pela Energizou em {convertDate(proposalSigned.created_at)} ainda está válida.<br/>A proposta irá expirar em <b>{ dateProposalValidaty.time.days } dias e {dateProposalValidaty.time.hours } horas</b>.</p>

            <Link to='/admin' className="box-save">
                Voltar ao início
            </Link>

            {down ?
                <Button
                    type="button"
                    text="Fazendo download da proposta"
                    className="box-info"
                    icon={<FiLoader style={{animation: 'spin 5s linear infinite'}} size={12} />}
                    disabled={true}
                /> :
                <Button
                    type="button"
                    text="Fazer download da proposta"
                    className="box-info"
                    func={() => download()}
                    disabled={down}
                />
            }
        </div>
    );
}

export default FinishedStep;