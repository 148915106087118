import React, { ChangeEvent, useContext, useEffect, useState } from 'react';
import { FaSave } from 'react-icons/fa';
import { GeneralContext } from '~/contexts';
import api from '~/services/api';
import './styles.css';

// Components
import Template from '~/pages/admin/Template';
import LoadingSimple from '~/components/Default/Loading/Simple';
import { InputLabel, Button } from '~/components/Forms';

const CalcPremises: React.FC = () => {
    const {user}                  = useContext(GeneralContext);
    const [ready, setReady]       = useState(false);
    const [config, setConfig]     = useState<any>(null);
    const [upPrices, setUpPrices] = useState<boolean>(false);
    const [prices, setPrices]     = useState<any>(null);
    const [conv, setConv]         = useState<any>(null);
    const [i50, setI50]           = useState<any>(null);
    const [i100, setI100]         = useState<any>(null);
    const [messages, setMessages] = useState<any>(<></>);
    const [saving, setSaving]     = useState<any>(false);
    const [savingP, setSavingP]   = useState<any>(false);

    function configsApi () {
        if (config) return;

        api.get('/configs', {
            headers: { Authorization: user.token }
        }).then(resp => {
            setConfig(resp.data)
            setReady(true)
        })
    }

    function priceApi () {
        if (prices) return;

        api.get('/prices', {
            headers: { Authorization: user.token }
        }).then(resp => {
            setPrices(resp.data)
            setConv(resp.data[0])
            setI50 (resp.data[1])
            setI100(resp.data[2])
            setReady(true)
        })
    }

    function saveConfig () {
        setSaving(true)

        let data = {
            id: 1,
            contract_duration: config.contract_duration,
            minimum_demand: config.minimum_demand,
            spread_i50: config.spread_i50,
            spread_i100: config.spread_i100,
            system_security_charge: config.system_security_charge,
            reserve_energy_charge: config.reserve_energy_charge,
        };

        api.post('/configs', data, {headers: { Authorization: user.token }})
        .then(resp => {
            setSaving(false)
            setMessages(<div className="msg">Configuração salva com sucesso!</div>)
            !upPrices && setTimeout(() => setMessages(<></>), 5000);

            // Save Prices if change i50 and i100
            if (upPrices) {
                setTimeout(() => savePrices(), 3000)
                setUpPrices(false)
            }
        }).catch(err =>{
            setSaving(false)
            setMessages(<div className="msg err">Ocorreu um erro ao salvar!</div>)
            setTimeout(() => setMessages(<></>), 5000);
            // console.log(err)
        })
    }

    async function savePrices () {
        setSavingP(true)

        let retConv = await savePrice(conv)
        let retI50  = await savePrice(i50)
        let retI100 = await savePrice(i100)

        if (retConv && retI50 && retI100) {
            setMessages(<div className="msg">Preços salvos com sucesso!</div>)
            setTimeout(() => setMessages(<></>), 5000);
        } else {
            setMessages(<div className="msg err">Ocorreu um erro ao salvar um ou mais preços!</div>)
            setTimeout(() => setMessages(<></>), 5000);
        }

        setSavingP(false)
    }

    async function savePrice (obj: any) {
        let data = {
            type    : obj.type,
            discount: obj.discount,
            year1   : obj.year1,
            year2   : obj.year2,
            year3   : obj.year3,
            year4   : obj.year4,
            year5   : obj.year5,
            year6   : obj.year6
        }

        return await api.post('/prices', data, {headers: { Authorization: user.token }})
        .then(resp => true)
        .catch(err => false)
    }

    function onChange (event: ChangeEvent<HTMLInputElement>) {
        const {name, value, dataset} = event.target;

        if (dataset.type==='conv') {
            setConv({...conv, [name]: value})
            setI50({...i50, [name]: parseInt(value)+parseInt(config.spread_i50)})
            setI100({...i100, [name]: parseInt(value)+parseInt(config.spread_i100)})
        } else if (dataset.type==='i50') {
            setI50({...i50, [name]: value})
        } else if (dataset.type==='i100') {
            setI100({...i100, [name]: value})
        } else {
            setConfig({...config, [name]: value})

            if (dataset.type==='config_i50') {
                changeConfigPrice('i50', parseInt(value))
                setUpPrices(true)
            } else if (dataset.type==='config_i100') {
                changeConfigPrice('i100', parseInt(value))
                setUpPrices(true)
            }
        }
    }

    function changeConfigPrice (type: string, value: number) {
        let convValues = JSON.parse(JSON.stringify(conv))

        if (type==='i50') {
            setI50({
                ...i50,
                year1: convValues.year1 ? parseInt(convValues.year1) + value : null,
                year2: convValues.year2 ? parseInt(convValues.year2) + value : null,
                year3: convValues.year3 ? parseInt(convValues.year3) + value : null,
                year4: convValues.year4 ? parseInt(convValues.year4) + value : null,
                year5: convValues.year5 ? parseInt(convValues.year5) + value : null,
                year6: convValues.year6 ? parseInt(convValues.year6) + value : null
            })
        } else if (type==='i100') {
            setI100({
                ...i100,
                year1: convValues.year1 ? parseInt(convValues.year1) + value : null,
                year2: convValues.year2 ? parseInt(convValues.year2) + value : null,
                year3: convValues.year3 ? parseInt(convValues.year3) + value : null,
                year4: convValues.year4 ? parseInt(convValues.year4) + value : null,
                year5: convValues.year5 ? parseInt(convValues.year5) + value : null,
                year6: convValues.year6 ? parseInt(convValues.year6) + value : null
            })
        }
    }

    useEffect(() => {
        if (config===null) configsApi()
        if (prices===null) priceApi()
    }, [ready, prices, config]);

    useEffect(() => {}, [saving]);

    return <Template page="Administrador - Premissas de Cálculo" pageTitle="Gerenciamento de Premissas de Cálculo" className="ccp">
        <div className="header">
            <h1>Premissas de Cálculo</h1>
            { messages }
        </div>

        {
            ready ? (
                <div className="premisesBox">
                    <div className='configs-form'>
                        <div className="header">
                            <h2>Configurações</h2>

                            {
                                !saving ?
                                <Button
                                    type="button"
                                    className={`box save${savingP ? ' disabled' : ''}`}
                                    icon={<FaSave />}
                                    text="Salvar"
                                    func={() => saveConfig()}
                                    disabled={savingP ? true : false}
                                /> :
                                <Button
                                    type="button"
                                    className='box saving'
                                    icon={<FaSave />}
                                    text="Salvando..."
                                />
                            }
                        </div>

                        {
                            config ? (
                                <div className='inputs'>
                                    <InputLabel
                                        name="contract_duration"
                                        type="number"
                                        labelName='Duração do contrato'
                                        value={config.contract_duration}
                                        onChange={onChange}
                                        disabled={saving || savingP ? true : false}
                                    />

                                    <InputLabel
                                        name="minimum_demand"
                                        type="number"
                                        labelName='Demanda mínima'
                                        value={config.minimum_demand}
                                        onChange={onChange}
                                        disabled={saving || savingP ? true : false}
                                    />

                                    <InputLabel
                                        name="spread_i50"
                                        type="number"
                                        labelName='I50'
                                        dataType='config_i50'
                                        value={config.spread_i50}
                                        onChange={onChange}
                                        disabled={saving || savingP ? true : false}
                                    />

                                    <InputLabel
                                        name="spread_i100"
                                        type="number"
                                        labelName='I100'
                                        dataType='config_i100'
                                        value={config.spread_i100}
                                        onChange={onChange}
                                        disabled={saving || savingP ? true : false}
                                    />

                                    <InputLabel
                                        name="system_security_charge"
                                        type="number"
                                        labelName='ESS'
                                        value={config.system_security_charge}
                                        onChange={onChange}
                                        disabled={saving || savingP ? true : false}
                                    />

                                    <InputLabel
                                        name="reserve_energy_charge"
                                        type="number"
                                        labelName='Reserva de energia'
                                        value={config.reserve_energy_charge}
                                        onChange={onChange}
                                        disabled={saving || savingP ? true : false}
                                    />
                                </div>
                            ) : <></>
                        }
                    </div>

                    <hr/>

                    <div className='prices-form'>
                        <div className="header">
                            <h2>Preços</h2>

                            {
                                !savingP ?
                                <Button
                                    type="button"
                                    className={`box save${saving ? ' disabled' : ''}`}
                                    icon={<FaSave />}
                                    text="Salvar"
                                    func={() => savePrices()}
                                    disabled={saving ? true : false}
                                /> :
                                <Button
                                    type="button"
                                    className='box saving'
                                    icon={<FaSave />}
                                    text="Salvando..."
                                />
                            }
                        </div>

                        {
                            prices ? (<>
                                <div className='inputs'>
                                    <InputLabel
                                        name="type"
                                        type="text"
                                        labelName='Tipo'
                                        value={conv.type}
                                        disabled={true}
                                    />

                                    <InputLabel
                                        name="type"
                                        type="text"
                                        labelName='Desconto'
                                        value={conv.discount}
                                        disabled={true}
                                    />

                                    <InputLabel
                                        name="year1"
                                        type="number"
                                        labelName='Ano 1'
                                        dataType="conv"
                                        value={conv.year1}
                                        onChange={onChange}
                                        disabled={saving || savingP ? true : false}
                                    />

                                    <InputLabel
                                        name="year2"
                                        type="number"
                                        labelName='Ano 2'
                                        dataType="conv"
                                        value={conv.year2}
                                        onChange={onChange}
                                        disabled={saving || savingP ? true : false}
                                    />

                                    <InputLabel
                                        name="year3"
                                        type="number"
                                        labelName='Ano 3'
                                        dataType="conv"
                                        value={conv.year3}
                                        onChange={onChange}
                                        disabled={saving || savingP ? true : false}
                                    />

                                    <InputLabel
                                        name="year4"
                                        type="number"
                                        labelName='Ano 4'
                                        dataType="conv"
                                        value={conv.year4}
                                        onChange={onChange}
                                        disabled={saving || savingP ? true : false}
                                    />

                                    <InputLabel
                                        name="year5"
                                        type="number"
                                        labelName='Ano 5'
                                        dataType="conv"
                                        value={conv.year5}
                                        onChange={onChange}
                                        disabled={saving || savingP ? true : false}
                                    />

                                    <InputLabel
                                        name="year6"
                                        type="number"
                                        labelName='Ano 6'
                                        dataType="conv"
                                        value={conv.year6}
                                        onChange={onChange}
                                        disabled={saving || savingP ? true : false}
                                    />
                                </div>

                                <hr/>

                                <div className='inputs'>
                                    <InputLabel
                                        name="type"
                                        type="text"
                                        labelName='Tipo'
                                        value={i50.type}
                                        disabled={true}
                                    />

                                    <InputLabel
                                        name="type"
                                        type="text"
                                        labelName='Desconto'
                                        value={i50.discount}
                                        disabled={true}
                                    />

                                    <InputLabel
                                        name="year1"
                                        type="number"
                                        labelName='Ano 1'
                                        dataType="i50"
                                        value={i50.year1}
                                        onChange={onChange}
                                        disabled={true}
                                    />

                                    <InputLabel
                                        name="year2"
                                        type="number"
                                        labelName='Ano 2'
                                        dataType="i50"
                                        value={i50.year2}
                                        onChange={onChange}
                                        disabled={true}
                                    />

                                    <InputLabel
                                        name="year3"
                                        type="number"
                                        labelName='Ano 3'
                                        dataType="i50"
                                        value={i50.year3}
                                        onChange={onChange}
                                        disabled={true}
                                    />

                                    <InputLabel
                                        name="year4"
                                        type="number"
                                        labelName='Ano 4'
                                        dataType="i50"
                                        value={i50.year4}
                                        onChange={onChange}
                                        disabled={true}
                                    />

                                    <InputLabel
                                        name="year5"
                                        type="number"
                                        labelName='Ano 5'
                                        dataType="i50"
                                        value={i50.year5}
                                        onChange={onChange}
                                        disabled={true}
                                    />

                                    <InputLabel
                                        name="year6"
                                        type="number"
                                        labelName='Ano 6'
                                        dataType="i50"
                                        value={i50.year6}
                                        onChange={onChange}
                                        disabled={true}
                                    />
                                </div>

                                <hr/>

                                <div className='inputs'>
                                    <InputLabel
                                        name="type"
                                        type="text"
                                        labelName='Tipo'
                                        value={i100.type}
                                        disabled={true}
                                    />

                                    <InputLabel
                                        name="type"
                                        type="text"
                                        labelName='Desconto'
                                        value={i100.discount}
                                        disabled={true}
                                    />

                                    <InputLabel
                                        name="year1"
                                        type="number"
                                        labelName='Ano 1'
                                        dataType="i100"
                                        value={i100.year1}
                                        onChange={onChange}
                                        disabled={true}
                                    />

                                    <InputLabel
                                        name="year2"
                                        type="number"
                                        labelName='Ano 2'
                                        dataType="i100"
                                        value={i100.year2}
                                        onChange={onChange}
                                        disabled={true}
                                    />

                                    <InputLabel
                                        name="year3"
                                        type="number"
                                        labelName='Ano 3'
                                        dataType="i100"
                                        value={i100.year3}
                                        onChange={onChange}
                                        disabled={true}
                                    />

                                    <InputLabel
                                        name="year4"
                                        type="number"
                                        labelName='Ano 4'
                                        dataType="i100"
                                        value={i100.year4}
                                        onChange={onChange}
                                        disabled={true}
                                    />

                                    <InputLabel
                                        name="year5"
                                        type="number"
                                        labelName='Ano 5'
                                        dataType="i100"
                                        value={i100.year5}
                                        onChange={onChange}
                                        disabled={true}
                                    />

                                    <InputLabel
                                        name="year6"
                                        type="number"
                                        labelName='Ano 6'
                                        dataType="i100"
                                        value={i100.year6}
                                        onChange={onChange}
                                        disabled={true}
                                    />
                                </div>
                            </>) : <></>
                        }
                    </div>
                </div>
            ) : <LoadingSimple />
        }
    </Template>;
}

export default CalcPremises;