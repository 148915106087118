import React, { useContext, useEffect, useState } from 'react';
import { FaTimes } from 'react-icons/fa';
import { GeneralContext, ModalContext } from '~/contexts';
import api from '~/services/api';

// Components
import Template from '~/pages/admin/Template';
import LoadingSimple from '~/components/Default/Loading/Simple';
import { FiPlus } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import { ClientLineSpecialCondition } from '~/components/Boxs/Client';

const SpecialConditionList: React.FC = () => {
    const {user}                        = useContext(GeneralContext);
    const [ready, setReady]             = useState(false);
    const [clients, setClients]         = useState<any>(null);
    const [clientsList, setClientsList] = useState<any>([]);
    const [search, setSearch]           = useState('');
    const [messages, setMessages]    = useState<any>(<></>);
    const Modal        = useContext(ModalContext);

    function onChangeSearch (event: any) {
        let data = event.target.value;
        let clientsFilter = clientsList.filter((el: any) =>

            el.company.name.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").indexOf(data.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, ""))>=0 ||
            (el.company.provider && el.company.provider.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").indexOf(data.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, ""))>=0) ||
            (el.company.modality && el.company.modality.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").indexOf(data.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, ""))>=0)
            ? true : false
        )

        setSearch(data)
        setClients(clientsFilter);
    }

    function delSpecialCondition (id: number) {

        api.delete(`/special/${id}`, {headers: { Authorization: user.token }}).then(resp => {
            setClients(null)
            Modal.setModalOpen(false)
            setMessages(<div className="msg">Dados Excluido com sucesso!!</div>)
            setTimeout(() => setMessages(<></>), 3000);
        }).catch(() => {
            setMessages(<div className="msg err">Ocorreu um erro ao deletar!</div>)
            setTimeout(() => setMessages(<></>), 3000);
        })
    }

    function openModal (idCondition: number, name: string) {

        Modal.setModalClass('modalProposalInative enz')
        Modal.setModalTitle('Excluindo Conta de Energia')
        Modal.setModalBody(<>
            <div>Deseja realmente execluir essa condição especial ativa da empresa {name}?</div>
            <div className="alert"><b>* Tenha cuidado, esse processo é irreversível!</b></div>

            <footer>
                <button id="btnSave" type="button" onClick={()=>{delSpecialCondition(idCondition)}} >Excluir</button>
                <button id="btnCancel" type="button" onClick={() => Modal.setModalOpen(false)}>Cancelar</button>
            </footer>
        </>)

        Modal.setModalOpen(true)
    }

    useEffect(() => {
        if (clients===null) {
            setReady(false)

            api.get(`/special`, {headers: { Authorization: user.token }}).then(resp => {
                setTimeout(() => {
                    setClientsList(resp.data)
                    setClients(resp.data)
                    setReady(true)
                }, 1000);
            })
        }
    }, [user, ready, clients]);

    return <Template page="Administrador - Condições especiais" pageTitle="Condições especiais" className="cli">
        <div className="messageInfo">
            {messages}
        </div>
        <div className="header">
            <div className='titleAndButton'>
                <h1>Condições especiais ativas</h1>
            </div>

            <div className="searchBox">
                <input type="text" name="search" id="search" placeholder='Filtrar Clientes...' value={search} onChange={onChangeSearch} disabled={ready ? false : true} />

                { search!=='' && <button onClick={() => {
                    setSearch('')
                    setClients(clientsList)
                }}>
                    <FaTimes />
                </button> }
            </div>
        </div>

        <div className="clientsBox">
            <header>
                <div className="name">Nome</div>
                <div className="btns"></div>
            </header>

            <section>
                {
                    ready ? (
                         clients && clients.length>0 ?
                            clients.map((el: any) => (
                                <ClientLineSpecialCondition
                                    key={`clients_${el.company.id}`}
                                    id={el.company.id}
                                    photo={el.company.photo}
                                    name={el.company.name}
                                    delete={() => openModal(el.company.id,el.company.name)}
                                />)) :
                            <div className='noData'>Não foi possível localizar clientes</div>
                    ) : <LoadingSimple />
                }
            </section>
        </div>
    </Template>;
}

export default SpecialConditionList;