import React, { ReactElement, useContext, useEffect, useState } from 'react';
import { GeneralContext, MixPanelContext } from '~/contexts';
import api from '~/services/api';
import './styles.css';
import { Link } from 'react-router-dom';

// Variables
let step = 0;

const InitList = ({ steps, currStep, currQuest, setStep, points, setPoints, setData, data, opps, setOpps, errBill }: any) => {
    const {user}                        = useContext(GeneralContext);
    const {buttonClick}                 = useContext(MixPanelContext);
    const [screen, setScreen]           = useState<ReactElement|null>();
    const [screenQuest, setScreenQuest] = useState<ReactElement|null>();

    async function load () {
        setPoints(true)
        let infos = steps[step]
        step++

        // If not exists infos
        if (!infos) {setPoints(false); return}

        // Get the screen info
        let scr = await getScreen(infos)
        setPoints(false)
        if (infos.type==='question' || infos.type==='modal') setScreenQuest(scr)
        else setScreen(<>{screen}{scr}</>)
    }

    function activeBill(id:number){
        api.put(`/bills/active/${id}`, '',{
            headers: { Authorization: user.token }
        }).then(resp => {
            setOpps(data.opportunities.length)
            setStep(5)

        }).catch(err =>{
            console.log(err)
        })
    }

    function anchorUpload (){
        let element: HTMLElement = document.getElementsByName('file')[0] as HTMLElement;
        element.click();
    }

    function helpAdmin(id: Number){
        setStep(7)
        api.put(`/bills/help/${id}`,'', {
            headers: { Authorization: user.token }
        }).then(resp => {
            // console.log(resp.data)
        })
    }

    async function getScreen (infos: any) {
        switch (infos.type) {
            case 'image':
                return <img src={infos.url} alt={infos.alt} />
            case 'text':
                return <div className={infos.class ? infos.class : 'balon'}>
                    <div className='text'>
                        { infos.icon && infos.icon }
                        { infos.image && <img src={infos.image.url} alt={infos.image.alt} /> }
                        {
                            infos.text
                            .replace('#NAME#', user.name.replace('|', ' '))
                            .replace('#FILE#', data.file)
                            .replace('#VALUE#', data.value)
                            .replace('#ECONOMY#', data.economy)
                        }
                    </div>
                </div>
            case 'question':
                return <div className='question'>
                    { infos.title ? <h3>{infos.title}</h3> : '' }
                    {
                        infos.items.map((el: any, index: number) => (<button
                            key={`button_${index}`}
                            type='button'
                            onClick={() => {
                                if (el.file) anchorUpload()
                                if (el.click) el.click(user, buttonClick)
                                if (el.step) {
                                    setStep(el.step)
                                    setData && setData({...data, [infos.name]: el.text})
                                }
                            }}
                        >
                            { el.icon && el.icon }
                            { infos.image && <img src={infos.image.url} alt={infos.image.alt} /> }
                            { el.text }
                        </button>))
                    }
                    <div className='text'>{infos.text}</div>
                </div>
            case 'modal':
                return <div className='modal'>
                    <div className="box">
                        { infos.icon && <div className="icon">{infos.icon}</div> }
                        { infos.image && <img src={infos.image.url} alt={infos.image.alt} /> }
                        <div className='text'>{infos.text.replace('#OPPS#', opps)}</div>
                        { infos.button && infos.button(data) }
                    </div>
                </div>
            case 'modalError':
                return <div className='modal'>
                    <div className="box">
                        { infos.icon && <div className="icon">{infos.icon}</div> }
                        { infos.image && <img src={infos.image.url} alt={infos.image.alt} /> }
                        <div className='text'>{infos.text1}</div>
                        <div className='text'>{infos.text2}</div>
                        { infos.approve && <button type='button' onClick={() => { activeBill(data.id)} }>
                            Confirmar
                        </button>}
                        { !infos.approve && <button type='button' onClick={() => { helpAdmin(errBill.id)} }>
                            Seguir de outra forma
                        </button>}
                        <button
                            className='cancel'
                            type='button'
                            onClick={() => {
                                anchorUpload()
                            }}
                        >
                            Tentar novamente
                        </button>
                        <p className='text'>Caso queira entender mais sobre as possibilidades de erros na leitura da sua conta de luz acesse <a target="_blank" href='https://energizouhelp.zendesk.com/hc/pt-br/articles/10104476917389-Erros-de-leitura-no-Energizou-App'>esse link</a></p>
                    </div>
                </div>
            case 'helpAdmin':

                return <div className='modal'>
                    <div className="box">
                        { infos.image && <img src={infos.image.url} alt={infos.image.alt} /> }
                        <div className='text'>{infos.text1}</div>
                        <div className='text'>{infos.text2}</div>
                        <Link to="/logout" type='button'>
                            Concluir
                        </Link>
                    </div>
                </div>
            case 'delay':
                await sleep(infos.time)
                break;
        }

        return <></>
    }

    useEffect(() => {step=0}, [])
    useEffect(() => {}, [currStep, points, data])
    useEffect(() => {load()}, [screen, screenQuest])

    return screen || screenQuest ? <>
        {screen}
        {currStep===currQuest ? screenQuest : ''}
    </> : <></>;
}

function sleep (ms: number) {
    return new Promise((resolve) => {
        setTimeout(resolve, ms);
    });
}

export default InitList;