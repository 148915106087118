import mail from '../images/mail.png'

const HelpAdmin = [
    {
        type: 'helpAdmin',
        image: {
            url: mail,
            alt: 'E-mail'
        },
        text1: 'Fique atento a sua caixa de e-mail',
        text2: 'Não se preocupe, nossa equipe irá cadastrar as informações da sua fatura em até 1 dia útil e te avisaremos assim que tudo estiver pronto'
    }
]
export default HelpAdmin