import React, { useEffect, useState, useContext } from 'react';
import { FiLoader } from 'react-icons/fi';
import { GeneralContext } from '~/contexts';
import './requestProposal.css';

// Components
import Loading from '~/components/Default/Loading';
import { Button } from '~/components/Forms';

// Images
import signature from '~assets/images/leitura-de-conta.png';

const RequestProposalStep: React.FC<any> = ({data, down, toSign}) => {
    const {user}            = useContext(GeneralContext);
    const [ready, setReady] = useState(false);

    useEffect(() => {
        setTimeout(() => setReady(true), 2000);
    }, []);

    return ready ? (
        <div className='requestProposalOpps'>
            <h1>
                Acessar proposta comercial da Energizou
            </h1>

            <img src={signature} alt="Proposta Comercial" />

            <p>Clique no botão abaixo e acesse a proposta para migrar para o<br/>Mercado Livre de Energia com a Energizou.</p>

            {down ?
                <Button
                    type="button"
                    text="Gerando proposta"
                    className="box load"
                    icon={<FiLoader style={{animation: 'spin 5s linear infinite'}} size={12} />}
                    disabled={true}
                /> :
                <Button
                    className="box"
                    text="Acessar proposta"
                    func={() => toSign('Curva de preço')}
                    disabled={down}
            />}

            <div className="desc">A proposta comercial gerada é indicativa e está sujeita à assinatura de um contrato definitivo de<br/> compra e venda de energia. A proposta possui validade de 5 dias corridos desde a sua criação.</div>
        </div>
    ) : <Loading />;
}

export default RequestProposalStep;