import React, { useContext, useEffect, useState } from 'react';
import { GeneralContext } from '~/contexts';
import mobile from 'is-mobile';
import api from '~/services/api';
import './styles.css';

// Components
import Template from '~/pages/admin/Template';
import Report from './report';
import Wizard from './wizard';

const Reports: React.FC = () => {
    const {user} = useContext(GeneralContext);
    const [wizard, setWizard] = useState<boolean | null>(null);

    useEffect(() => {
        if (wizard===null) {
            api.get(`users/wizard`, {
                headers: { Authorization: user.token }
            }).then((resp: any) => setWizard(resp.data===0 ? true : false))
        }
    }, [wizard])

    return <Template page="Relatórios" pageTitle="Relatórios" className={`rep${wizard ? ' wizard' : ''}`}>
        {
            wizard!==null ? (
                wizard && !mobile()?
                <Wizard setWizard={setWizard} /> :
                <Report wizard={wizard}/>
            ) : <></>
        }
    </Template>;
}

export default Reports;