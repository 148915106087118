import React, {useContext} from 'react';
import { Link } from 'react-router-dom';
import { MixPanelContext, GeneralContext } from '~/contexts';

const home = [
    {
        type: 'text',
        class: 'resp',
        text: 'Da minha casa'
    },
    {
        type: 'delay',
        time: 5000
    },
    {
        type: 'text',
        text: 'A solução Energizou App ainda não está disponível para clientes residenciais. Caso queira, você pode reiniciar o cadastro para enviar uma conta empresarial.'
        // text: 'A solução Energizou App ainda não está disponível para clientes residenciais. Caso queira, você pode se inscrever na nossa Newsletter para acompanhar novos lançamentos do nosso produto ou reiniciar o cadastro para enviar uma conta empresarial.'
    },
    {
        type: 'delay',
        time: 5000
    },
    {
        type: 'question',
        items: [
            {
                icon: '',
                text: 'Concluir como residencial',
                step: 2.1
            },
            {
                icon: '',
                text: 'Enviar uma conta empresarial',
                click: () => {document.location.reload()},
            }
        ]
    },
]

export default home