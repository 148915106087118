import React, { ChangeEvent, useState } from 'react';
import '../main.css';
import './styles.css';

// Charts
import MonthConsumption from '../MonthConsumption';
import CostPerKwh from '../CostPerKwh';
import TotalValue from '../TotalValue';
import TariffsFlags from '../TariffsFlags';
import DemandAnalysis from '../DemandAnalysis';

const ChartsBox:React.FC<any> = ({data}) => {
    const [chart, setChart]   = useState('monthConsumption')
    const [months, setMonths] = useState(6)

    function getChart () {
        switch (chart) {
            case 'costPerKwh':
                return <CostPerKwh months={months} infos={data.costPerKwh} />

            case 'totalValue':
                return <TotalValue months={months} infos={data.totalValue} />

            case 'tariffsFlags':
                return <TariffsFlags months={months} infos={data.flags} />

            case 'demandAnalysis':
                return <DemandAnalysis months={months} infos={data.demandAnalysis} />

            default:
                return <MonthConsumption months={months} infos={data.monthConsumption}/>
        }
    }

    function onChangeChart (event: ChangeEvent<HTMLSelectElement>) {
        const {value} = event.currentTarget;
        setChart(value)
    }

    function changeMonths (months: number) {
        setMonths(months)
    }

    return <div className="chartsBox dash">
        <div className="header">
            <div className='monthSelectBox'>
                <button type="button" className={months===3 ? 'active' : ''} onClick={() => changeMonths(3)}>3M</button>
                <button type="button" className={months===6 ? 'active' : ''} onClick={() => changeMonths(6)}>6M</button>
                <button type="button" className={months===12 ? 'active' : ''} onClick={() => changeMonths(12)}>12M</button>
            </div>

            <div className="selectChart">
                <label htmlFor="">Gráfico:</label>
                <select name="typeChart" className='typeChart' onChange={onChangeChart}>
                    <option value="monthConsumption">Consumo Mensal</option>
                    <option value="costPerKwh">Custo por kWh</option>
                    <option value="totalValue">Valor Total</option>
                    <option value="tariffsFlags">Bandeira Tarifária</option>
                    <option value="demandAnalysis">Análise de Demanda</option>
                </select>
            </div>
        </div>

        <div className="content">
            {
                data && getChart()
            }
        </div>
    </div>
}

export default ChartsBox;