import { createContext, ReactNode, useEffect, useState } from "react";
import api from "~/services/api";

// Login
import cryptos from '~/utils/cryptos';
import storage from '~/utils/storage';

interface UserInterface {
    id: number;
    role: string,
    company: {
        id: number;
        name: string;
        cnpj: string;
        signed: boolean;
        plan: any;
    } | null;
    name: string;
    mail: string;
    active: boolean;
    token: string;
    refreshToken: string;
}

interface GeneralContextData {
    logged: boolean;
    breadcrumbs: any;
    setBreadcrumbs: Function;
    user: UserInterface;
    clients: any[] | null;
    client: number | null;
    setClient: Function;
    login: Function;
    logout: Function;
    checkBill: Function;
    checkToken: Function;
}

interface GeneralProviderProps {
    children: ReactNode;
}

// User Init
const userInit: UserInterface = {
    id: 0,
    role: 'guest',
    company: null,
    name: 'Guest',
    mail: '',
    active: true,
    token: '',
    refreshToken: ''
}

export const GeneralContext = createContext({} as GeneralContextData)

export function GeneralProvider ({ children }: GeneralProviderProps) {
    const [ready, setReady]             = useState<boolean>(false);
    const [logged, setLogged]           = useState<boolean>(false);
    const [breadcrumbs, setBreadcrumbs] = useState<any>(null);
    const [clients, setClients]         = useState<any>(null);
    const [client, setCClient]          = useState<any>(null);
    const [user, setUser]               = useState<UserInterface>(userInit);

    function login (userData: any) {
        let data = cryptos.encryptWeb(userData)
        storage.set('UToken', data)
        setUser(userData)
        setLogged(true)
    }

    function logout () {
        storage.remove('UToken')
        setUser(userInit)
        setLogged(false)
    }

    async function checkBill () {
        if (user.role==='admin') return true;

        // Check if user has a bill
        let admin = await api.get('bills/check', {
            headers: { Authorization: user.token }
        }).then((resp: any) => resp.data);

        // Check if user is admin or is user and has bill
        return admin ? true : false;
    }

    async function checkToken (user: any) {
        let data: any = null;
        let status    = await api.post('validateToken', {data: cryptos.encryptServer({token: user.token, refresh: user.refreshToken})})
        .then(async resp => {
            if (resp.data.valid && resp.data.user==="") {
                data = user;
                setUser(user)
            } else {
                let userData = cryptos.decryptServer(resp.data.user);
                    data     = cryptos.encryptWeb(userData);

                storage.set('UToken', data)
                setUser(userData)
            }

            setLogged(true)
            return true
        }).catch(error => {
            storage.remove('UToken')
            setUser(userInit)
            setLogged(false)
            return false
        })

        if (status===true && data.role==='admin') {
            await api.get('companies', {
                headers: { Authorization: user.token }
            }).then((resp: any) => {
                let clientLocal = storage.get('client');
                if (!clientLocal) {
                    clientLocal = resp.data[0].id;
                    storage.set('client', clientLocal)
                }

                setCClient(clientLocal)
                setClients(resp.data)
                setReady(true)
            });
        } else {
            storage.remove('client')
            setReady(true)
        }
    }

    function setClient (id: number) {
        storage.set('client', id)
        setCClient(id);
        setReady(false);
    }

    useEffect(() => {
        if (!ready) {
            let data = storage.get('UToken');

            if (data) {
                let userData = cryptos.decryptWeb(data);
                checkToken(userData);
            } else {
                setUser(userInit)
                setLogged(false)
                setReady(true)
            }
        }else if(logged && user.role === 'admin' && !clients){
            setReady(false)
        }
    }, [ ready, logged]);

    useEffect(() => {}, [breadcrumbs]);

    return (
        <GeneralContext.Provider value={{ logged, breadcrumbs, setBreadcrumbs, user, login, logout, checkBill, checkToken, clients, client, setClient }}>
            { ready ? children : <></> }
        </GeneralContext.Provider>
    )
}