import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';

// Import Pages Auth
import {Login, Logout, Forgot, NewPassword} from '~/pages/auth';

// Import Error
import Error404 from '~/pages/Error404';

// Import Pages Site
import {
    CreateUser,
    Onboarding,
    Analisy,
    Terms
} from '~/pages/site';

// Import Pages Admin
import {
    AdminReports,
    AdminTariff,
    AdminConsumption,
    AdminOpportunities,
    AdminOpportunitiesInfos,
    AdminHistoric,
    AdminProfile,
    AdminClients,
    AdminClientsCreate,
    AdminOpps,
    AdminCalcPremises,
    AdminClientsBills,
    AdminClientsErrors,
    AdminProviders,
    AdminHelp,
    AdminProposals,
    AdminSpecialCondition,
} from '~/pages/admin';

const SystemRoutes: React.FC = () => {
    return (
        <BrowserRouter>
            <Routes>
                {/* Auth */}
                <Route path="/login" element={<Login />}>
                    <Route path=":text" element={<Login />} />
                </Route>
                <Route path="/logout" element={<Logout />} />
                <Route path="/lembrar-senha" element={<Forgot />} />
                <Route path="/nova-senha/:token" element={<NewPassword />}>
                    <Route path=":text" element={<NewPassword />} />
                </Route>

                {/* Site */}
                <Route path="/" element={<Navigate to="/admin" replace />} />
                <Route path="/onboarding" element={<Onboarding/>} />
                <Route path="/criar-conta" element={<CreateUser/>} />
                <Route path="/contato" element={<Analisy/>} />
                <Route path="/termos" element={<Terms/>} />
                <Route path="/404" element={<Error404/>} />

                {/* Admin */}
                <Route path="/admin" element={<AdminOpportunities />}>
                    <Route path="d/:yearMonth" element={<AdminOpportunities />} />
                </Route>
                <Route path="/admin/:id" element={<AdminOpportunitiesInfos />} />

                <Route path="/admin/relatorios" element={<AdminReports />}>
                    <Route path=":yearMonth" element={<AdminReports />} />
                </Route>

                <Route path="/admin/auditoria/tarifas" element={<AdminTariff />}>
                    <Route path=":yearMonth" element={<AdminTariff />} />
                </Route>

                <Route path="/admin/auditoria/consumo" element={<AdminConsumption />}>
                    <Route path=":yearMonth" element={<AdminConsumption />} />
                </Route>

                <Route path="/admin/historico" element={<AdminHistoric />} />
                <Route path="/admin/perfil" element={<AdminProfile />} />

                {/* Close Routes */}
                <Route path="/admin/s/clientes" element={<AdminClients />}>
                    <Route path=":id" element={<AdminClients />} />
                </Route>
                <Route path="/admin/s/especial" element={<AdminSpecialCondition />}>
                    <Route path=":id" element={<AdminSpecialCondition />} />
                </Route>

                <Route path="/admin/s/clientes/:id/bills" element={<AdminClientsBills />}>
                    <Route path="check/:bill" element={<AdminClientsBills />} />
                </Route>
                <Route path="/admin/s/clientes/:id/errors" element={<AdminClientsErrors />} />
                <Route path="/admin/s/clientes/new" element={<AdminClientsCreate />} />

                <Route path="/admin/s/oportunidades" element={<AdminOpps />}>
                    <Route path=":id" element={<AdminOpps />} />
                </Route>

                <Route path="/admin/s/configs" element={<AdminCalcPremises />}>
                    <Route path=":id" element={<AdminCalcPremises />} />
                </Route>

                <Route path="/admin/s/providers" element={<AdminProviders />} />

                <Route path="/admin/s/helpAdmin" element={<AdminHelp />} />
                <Route path="/admin/s/proposals" element={<AdminProposals />} />

                <Route path="/admin/404" element={<Error404/>} />

                {/* Error */}
                <Route path="/admin/*" element={<Navigate to="/admin/404" replace />} />
                <Route path="/*" element={<Navigate to="/404" replace />} />
            </Routes>
        </BrowserRouter>
    )
}

export default SystemRoutes;