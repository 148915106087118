import React from 'react';
import { FaExternalLinkAlt } from 'react-icons/fa';
import depositions from './depositions'

// Images
import icon from '../../images/step3.png';
// Images
import cbre from '~/assets/images/clients/CBRE.png';
import Gratt from '~/assets/images/clients/Gratt.png';
import Hersil from '~/assets/images/clients/Hersil.png';
import anima from '~/assets/images/clients/anima.png';
import Mackenzie from '~/assets/images/clients/Mackenzie.png';
import Megh from '~/assets/images/clients/Megh.png';
import Metafrio from '~/assets/images/clients/Metafrio.png';
import Radiante from '~/assets/images/clients/Radiante.png';
import RedeTVPB from '~/assets/images/clients/RedeTV-PB.png';
// import RedeTV from '~/assets/images/clients/RedeTV.png';
import Shopping_Council from '~/assets/images/clients/Shopping_Council.png';
import Unisa from '~/assets/images/clients/Unisa.png';
import Vinhos_Rodon from '~/assets/images/clients/Vinhos_Rodon.png';


const Step3: React.FC<any> = ({setStep}: {setStep: Function}) => {
    return <>
        <div className="infos">
            <div className="texts">
                <h1>Sobre a Energizou</h1>
                <div>
                    <p>A Energizou é uma comercializadora de energia. Somos autorizados pela Aneel (Agência Nacional de Energia Elétrica) a vender energia para consumidores finais.</p>
                    <p>Estamos presentes em todo o território nacional, com mais de 100 clientes de diversos segmentos, como: faculdades, condomínios comerciais, residenciais e logísticos, hospitais, indústrias e shoppings.</p>
                </div>

                <ul>
                    <a href="https://energizou.com.br/" target="_blank" rel="noreferrer">
                        <span>Conheça a Energizou Comercializadora!</span>
                        <FaExternalLinkAlt />
                    </a>
                </ul>

                <div className='examples'>
                    <div>
                        <h3>Nossos clientes</h3>
                        <p>Algumas empresas que<br/>acreditam em um<br/>futuro de <br/>possibilidades</p>
                    </div>
                    <div className='my_clients'>
                        <a href='https://www.mackenzie.br/' target='_blank' rel="noreferrer" >
                            <img  src={ Mackenzie} alt='Mackenzie' />
                        </a>

                        <a href='http://www.shoppingcouncil.com.br/' target='_blank' rel="noreferrer" >
                            <img src={ Shopping_Council } alt='Shopping_Council'/>
                        </a>

                        <a href='http://www.vinhosrandon.com.br/' target='_blank' rel="noreferrer" >
                            <img src={ Vinhos_Rodon } alt='Vinhos_Rodon' />
                        </a>

                        <a href='https://hersil.com.br/' target='_blank' rel="noreferrer" >
                            <img  src={Hersil} alt='Hersil' />
                        </a>

                        <a href='https://www.cbre.com.br/' target='_blank' rel="noreferrer" >
                            <img  src={ cbre } alt='cbre' />
                        </a>

                        <a href='https://www.metalfrio.com.br/' target='_blank' rel="noreferrer" >
                            <img  src={ Metafrio } alt='Metafrio' />
                        </a>

                        <a href = 'https://megh.com.br/' target='_blank' rel="noreferrer" >
                            <img  src={ Megh } alt='Megh' />
                        </a>

                        <a href='https://industriaradiante.com.br/' target='_blank' rel="noreferrer" >
                            <img  src={ Radiante } alt='Radiante' />
                        </a>

                        <a href='https://www.gratt.com.br/' target='_blank' rel="noreferrer" >
                            <img src={ Gratt } alt='Gratt' />
                        </a>

                        <a href='https://animaeducacao.com.br/' target='_blank' rel="noreferrer" >
                            <img  src={anima} alt='Anima Educação' />
                        </a>

                        <a href = 'https://www.unisa.br/' target='_blank' rel="noreferrer" >
                            <img  src={Unisa} alt='Unisa' />
                        </a>

                        <a href='https://www.redetv.uol.com.br/' target='_blank' rel="noreferrer" >
                            <img  src={ RedeTVPB } alt='RedeTVPB' />
                        </a>
                    </div>
                </div>

            </div>

            <div className="buttons">
                <button className='prev' onClick={() => setStep(2)}>
                    Voltar
                </button>

                <button className='next' onClick={() => setStep(4)}>
                    Próximo
                </button>
            </div>
        </div>

        <div className="icon">
            <img src={icon} alt="Icone da Sessão" />
        </div>
    </>;
}

export default Step3;