import api from "~/services/api";

const validate = {


    validateForm: async function (values:any, arrayErr:any, setErr:any, setCnpjState:any, cnpjState:any) {
        let valid = true
        let mailIsValid = this.validateEmail(values?.mail)
        let phoneReplace = values.phone.replace(/[(]|[)]|[_]|[ ]|[-]/gm,'')

        //Lugar onde é feita a validação chamada na func onSubmit
        //Verificação feita campo por campo, está ao contrário devido a funcionalidade focus()
        //Quaisquer validações, será aplicado aqui

        if (values.password === values.confirm_password) {
            if (values.password.length <= 7) {
                arrayErr ={...arrayErr, password:'As senhas devem conter pelo menos 8 caracteres'}
                arrayErr ={...arrayErr, confirm_password:'As senhas devem conter pelo menos 8 caracteres'}
                document?.getElementById("password")?.focus();

                valid = false
            } else {
                arrayErr ={...arrayErr, password:''}
                arrayErr ={...arrayErr, confirm_password:''}
            }
        }

        if (!values.confirm_password) {
            arrayErr ={...arrayErr, confirm_password:'Senhas não se correspondem'}
            document?.getElementById("password")?.focus();
            valid = false
        }

        if (!values.password) {
            arrayErr ={...arrayErr, password:'Senha inválida'}
            arrayErr ={...arrayErr, confirm_password:'Senha inválida'}
            document?.getElementById("password")?.focus();
            valid = false
        }

        if (values.password !== values.confirm_password) {
            arrayErr ={...arrayErr, password:'Senhas não se correspondem'}
            arrayErr ={...arrayErr, confirm_password:'Senhas não se correspondem'}
            document?.getElementById("password")?.focus();
            valid = false

        } else if (values.password.length <= 7) {
            arrayErr ={...arrayErr, password:'As senhas devem conter pelo menos 8 caracteres'}
            arrayErr ={...arrayErr, confirm_password:'As senhas devem conter pelo menos 8 caracteres'}
            document?.getElementById("password")?.focus();
            valid = false
        }

        if (!values.mail || !mailIsValid) {
            arrayErr ={...arrayErr, mail:'E-mail inválido'}
            document?.getElementById("mail")?.focus();
            valid = false
        } else {
            let exists = await this.mailExists(values.mail)
            if (exists) {
                arrayErr ={...arrayErr, mail:'E-mail já existe em nossa base'}
                document?.getElementById("mail")?.focus();
                valid = false

            } else {
                arrayErr ={...arrayErr, mail:''}
            }
        }

        if (cnpjState !== 1) {
            arrayErr ={...arrayErr, cnpj: arrayErr.cnpj}
            document?.getElementById("fantasy_name")?.focus();
            valid = false
            setCnpjState(2)
        } else {
            arrayErr ={...arrayErr, cnpj:''}
        }

        if (!values.name) {
            arrayErr ={...arrayErr, name:'Campo vazio'}
            document?.getElementById("name")?.focus();
            valid = false
        } else arrayErr ={...arrayErr, name:''}

        if(values.phone.replace(/[(]|[)]|[_]|[ ]|[-]/gm,'').length === 0){
            arrayErr ={...arrayErr, phone:'Número de telefone inválido'}
            document?.getElementById("phone")?.focus();
            valid = false
        }else{ arrayErr ={...arrayErr, phone:''}}

        if(phoneReplace.length <= 9 || phoneReplace.length === 0){
            arrayErr ={...arrayErr, phone:'Número de telefone inválido'}
            document?.getElementById("phone")?.focus();
            valid = false
        }else{
			arrayErr ={...arrayErr, phone:''}
		}

        setErr(arrayErr)
        return valid
    },
    validateEmail: function (mail:string) {
        return mail.match(
          /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    },
    mailExists: async function (mail:string) {
        let ret = await api.get('verify/mail/' + mail)
        return ret.data
    },
}

export default validate;