import React, { useContext, useEffect, useState } from 'react';
import ReactTooltip from 'react-tooltip';
import mobile from 'is-mobile';
import { BarChart, Bar,  XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Legend } from 'recharts';
import { FaInfoCircle } from 'react-icons/fa';
import '../main.css';
import './styles.css';
import { ModalContext } from '~/contexts';
import dates from '~/utils/dates';
import numbers from '~/utils/numbers';
import Funcs from '../funcs';

const CostPerKwh:React.FC<any> = ({className, styles, months, infos}: any) => {
    const {setModalOpen, setModalTitle, setModalBody} = useContext(ModalContext);
	const [data, setData]         = useState<any>(null);
	const [interval, setInterval] = useState(0);
	const [month, setMonth]       = useState<any>(null);

	let modality = ''
	// Chart Funcions
	const renderCustomTooltip = ({ active, payload }: any) => {
		let energy: any        = null;
		let charges: any       = null;
		let flag: any          = null;
		let demandPeak: any    = null;
		let demandOffPeak: any = null;
		let others: any        = null;

		payload.map((el: any, idx: number) => {
			if (el.dataKey==='energy') energy 					  = el;
			else if (el.dataKey==='charges') charges 			  = el;
			else if (el.dataKey==='flag') flag       			  = el;
			else if (el.dataKey==='demand.peak') demandPeak 	  = el;
			else if (el.dataKey==='demand.offPeak') demandOffPeak = el;
			else others = el;
			return null;
		})

		// Treat Title
		let dateBase = energy ? energy.payload.date.split('-') : null;
		let title    = energy ? dates.getNameMonth(dateBase[1]) + '/' + dateBase[0] : '';
		modality = energy ? energy.payload.tariff_modality : '';

		return <div className='tooltipChart'>
			<h4>{title}</h4>
			{modality ? <h5><b>Modalidade:</b> {modality}</h5> : <></>}

			{
				energy && demandPeak && demandOffPeak && flag && others && (energy.value+demandPeak.value+demandOffPeak.value+flag.value+others.value)>0 ? <>
					<div style={{color: energy ? energy.color : '#888888'}}><b>Energia:</b> <span>{energy ? numbers.money(energy.value, true, true, 3) : ''}</span></div>
					<div style={{color: charges ? charges.color : '#888888'}}><b>Encargos:</b> <span>{charges ? numbers.money(charges.value, true, true, 3) : ''}</span></div>
					<div style={{color: flag ? flag.color : '#888888'}}><b>Bandeira:</b> <span>{flag ? numbers.money(flag.value, true, true, 3) : ''}</span></div>
					{
						modality==='Azul' ? <>
							<div style={{color: demandPeak ? demandPeak.color : '#888888'}}><b>Demanda Ponta:</b> <span>{demandPeak ? numbers.money(demandPeak.value, true, true, 3) : ''}</span></div>
							<div style={{color: demandOffPeak ? demandOffPeak.color : '#888888'}}><b>Demanda Fora Ponta:</b> <span>{demandOffPeak ? numbers.money(demandOffPeak.value, true, true, 3) : ''}</span></div>
						</> :
						<div style={{color: demandOffPeak ? demandOffPeak.color : '#888888'}}><b>Demanda:</b> <span>{demandOffPeak ? numbers.money(demandOffPeak.value, true, true, 3) : ''}</span></div>
					}
					<div style={{color: others ? others.color : '#888888'}}><b>Outros:</b> <span>{others ? numbers.money(others.value, true, true, 3) : ''}</span></div>
					<hr />
					<div style={{color: '#888888'}}><b>Total:</b> <span>{energy && demandOffPeak && flag && others ? numbers.money((energy.value+demandPeak.value+demandOffPeak.value+flag.value+others.value), true, true, 3) : ''}</span></div>
				</> : <div className='alert'>Não há dados<br />para este mês!</div>
			}
		</div>
	}

	const renderCustomLegend = ({ active, payload }: any) => {
		let energy: any        = null;
		let charges: any       = null;
		let flag: any          = null;
		let demandPeak: any    = null;
		let demandOffPeak: any = null;
		let others: any        = null;

		payload.map((el: any, idx: number) => {
			if (el.dataKey==='energy') energy 					  = el;
			else if (el.dataKey==='charges') charges 			  = el;
			else if (el.dataKey==='flag') flag       			  = el;
			else if (el.dataKey==='demand.peak') demandPeak 	  = el;
			else if (el.dataKey==='demand.offPeak') demandOffPeak = el;
			else others = el;
			return null;
		})

		return <div className="box">
			<div className="line">
				<div style={{color: '#666666'}}>
					<span className="color" style={{backgroundColor: energy ? energy.color : '#888888'}}></span>
					Energia
				</div>

				<div style={{color: '#666666'}}>
					<span className="color" style={{backgroundColor: charges ? charges.color : '#888888'}}></span>
					Encargos
				</div>

				<div style={{color: '#666666'}}>
					<span className="color" style={{backgroundColor: flag ? flag.color : '#888888'}}></span>
					Bandeira
				</div>
			</div>
			<div className="line">
				{
					modality==='Azul' ? <>
						<div style={{color: '#666666'}}>
							<span className="color" style={{backgroundColor: demandPeak ? demandPeak.color : '#888888'}}></span>
							Demanda Ponta
						</div>

						<div style={{color: '#666666'}}>
							<span className="color" style={{backgroundColor: demandOffPeak ? demandOffPeak.color : '#888888'}}></span>
							Demanda Fora Ponta
						</div>
					</> : <div style={{color: '#666666'}}>
						<span className="color" style={{backgroundColor: demandOffPeak ? demandOffPeak.color : '#888888'}}></span>
						Demanda
					</div>
				}

				<div style={{color: '#666666'}}>
					<span className="color" style={{backgroundColor: others ? others.color : '#888888'}}></span>
					Outros
				</div>
			</div>
		</div>
	}

	function modalTooltip () {
		if(mobile()){
			setModalTitle('Custo por kWh')
			setModalBody(<>
				<p>Este gráfico <b>apresenta a composição de preço da Conta de Luz</b>, ou seja, o <b>valor que cada um dos elementos</b> (Geração de Energia, Demanda, Bandeira Tarifária e outros) <b>representa no preço final da conta</b>.</p>
				<ul>
					<li><a href="https://energizouhelp.zendesk.com/hc/pt-br/articles/10142598945421-Os-5-componentes-presentes-na-conta-de-energia" target="_blank" rel="noreferrer">
						<b>Saiba mais</b> sobre cada um dos componentes da tarifa</a>
					</li>
				</ul>
				<p className="warning">Este gráfico apresenta a composição da tarifa efetiva das contas de luz enviadas, ou seja, o valor que cada um dos componentes (Energia, Encargos, Demanda, Bandeira Tarifária e Outros Custos) representa do valor final.</p>
				<p className="notes"><b>Caso tenha dúvidas</b>, entre em contato conosco clicando no botão do canto inferior direito.</p>
			</>)
			setModalOpen(true)
		}
    }

	// Mount Functions
	useEffect(() => {
		setMonth(months)

		if (infos!==undefined) {
			let dataInfo = JSON.parse(JSON.stringify(infos)).reverse();

			switch (month) {
				case 12:
					setData(dataInfo)
					setInterval(2)
					break
				case 6:
					setData(dataInfo.slice(dataInfo.length-6))
					setInterval(1)
					break
				case 3:
					setData(dataInfo.slice(dataInfo.length-3))
					setInterval(0)
					break
			}
		}
    }, [month, months, infos]);

    return <div className={`${className ? className : ''} costKwh`} style={styles}>
		<header>
			<div className="titles">
				<h3>Custo por kWh</h3>
				<h4>Custo em R$ para cada kWh consumido</h4>
			</div>

			<div className="hover" data-tip="tooltip" data-for="costKwhInfo">
				<FaInfoCircle color='#c0c0c0' onClick={() => modalTooltip()}/>

				{!mobile()?
					<ReactTooltip id="costKwhInfo" className='infosBox big' place="left" type="light" effect="solid" clickable={true} delayHide={1000}>
						<p>Este gráfico <b>apresenta a composição de preço da Conta de Luz</b>, ou seja, o <b>valor que cada um dos elementos</b> (Geração de Energia, Demanda, Bandeira Tarifária e outros) <b>representa no preço final da conta</b>.</p>
						<ul>
							<li><a href="https://energizouhelp.zendesk.com/hc/pt-br/articles/10142598945421-Os-5-componentes-presentes-na-conta-de-energia" target="_blank" rel="noreferrer">
								<b>Saiba mais</b> sobre cada um dos componentes da tarifa</a>
							</li>
						</ul>
						<p className="warning">Este gráfico apresenta a composição da tarifa efetiva das contas de luz enviadas, ou seja, o valor que cada um dos componentes (Energia, Encargos, Demanda, Bandeira Tarifária e Outros Custos) representa do valor final.</p>
						<p className="notes"><b>Caso tenha dúvidas</b>, entre em contato conosco clicando no botão do canto inferior direito.</p>
					</ReactTooltip>
				:
					<></>
				}
			</div>
		</header>

		<section className="content">
			<ResponsiveContainer height={mobile() ? 250 : 350}>
				<BarChart
					data={data}
					margin={{
						top: 20,
						right: 30,
						left: 20,
						bottom: 5
					}}
				>
					<CartesianGrid vertical={false}/>
					<XAxis dataKey="date" interval={interval} tick={Funcs.renderXAxisLabel} />
					<YAxis tick={props => Funcs.renderYAxisLabelCurr(props, true, true, 3)}/>
					<Tooltip content={renderCustomTooltip} />
					{!mobile() && <Legend content={renderCustomLegend} />}

					<Bar dataKey="others" stackId="a" fill="#003063" />
					<Bar dataKey="demand.offPeak" stackId="a" fill="#1E4980" />
					<Bar dataKey="demand.peak" stackId="a" fill="#35639F" />
					<Bar dataKey="flag" stackId="a" fill="#4B7EBE" />
					<Bar dataKey="charges" stackId="a" fill="#619BDE" />
					<Bar dataKey="energy" stackId="a" fill="#77B9FF" />
				</BarChart>
			</ResponsiveContainer>

			{
				mobile() && (
					<div className="legends">
						<div style={{color: '#666666'}}>
							<span className="color" style={{backgroundColor: '#77B9FF'}}></span>
							Energia
						</div>

						<div style={{color: '#666666'}}>
							<span className="color" style={{backgroundColor: '#619BDE'}}></span>
							Encargos
						</div>

						<div style={{color: '#666666'}}>
							<span className="color" style={{backgroundColor: '#4B7EBE'}}></span>
							Bandeira
						</div>

						{
							data[data.length-1].tariff_modality==='Azul' ? <>
								<div style={{color: '#666666'}}>
									<span className="color" style={{backgroundColor: '#35639F'}}></span>
									Demanda Ponta
								</div>

								<div style={{color: '#666666'}}>
									<span className="color" style={{backgroundColor: '#1E4980'}}></span>
									Demanda Fora Ponta
								</div>
							</> : <div style={{color: '#666666'}}>
								<span className="color" style={{backgroundColor: '#1E4980'}}></span>
								Demanda
							</div>
						}

						<div style={{color: '#666666'}}>
							<span className="color" style={{backgroundColor: '#003063'}}></span>
							Outros
						</div>
					</div>
				)
			}
		</section>
	</div>;
}

export default CostPerKwh;