import React, { ChangeEvent, useContext, useEffect, useState } from 'react';
import { GeneralContext } from '~/contexts';
import api from '~/services/api';
import '../main.css';
import './styles.css';

// Charts
import MonthConsumption from '../MonthConsumption';
import CostPerKwh from '../CostPerKwh';
import TotalValue from '../TotalValue';
import TariffsFlags from '../TariffsFlags';
import DemandAnalysis from '../DemandAnalysis';
import Lottie from 'react-lottie-player';

// Loading
import loading from './images/loading.json';

const ChartsBox:React.FC<any> = props => {
    const {user, client}      = useContext(GeneralContext);
    const [chart, setChart]   = useState('monthConsumption')
    const [months, setMonths] = useState(6)
    const [data, setData]     = useState<any>(null)
    const [ready, setReady]   = useState(false)

    function getChart () {
        switch (chart) {
            case 'costPerKwh':
                return <CostPerKwh months={months} infos={data.costPerKwh} />

            case 'totalValue':
                return <TotalValue months={months} infos={data.totalValue} />

            case 'tariffsFlags':
                return <TariffsFlags months={months} infos={data.flags} />

            case 'demandAnalysis':
                return <DemandAnalysis months={months} infos={data.demandAnalysis} />

            case 'demandAnalysisPeak':
                return <DemandAnalysis months={months} infos={data.demandAnalysis} type="peak" />

            case 'demandAnalysisOffPeak':
                return <DemandAnalysis months={months} infos={data.demandAnalysis} type="offPeak" />

            default:
                return <MonthConsumption months={months} infos={data.monthConsumption}/>
        }
    }

    function onChangeChart (event: ChangeEvent<HTMLSelectElement>) {
        const {value} = event.currentTarget;
        setChart(value)
    }

    function changeMonths (months: number) {
        setMonths(months)
    }

    useEffect(() => {}, [chart])

    useEffect(() => {
		if (data===null) {
            // getData();

            api.get(`report/charts${user.role==='admin' ? `/c/${client}` : ''}/${props.date}`, {
                headers: { Authorization: user.token }
            })
            .then((resp: any) => {
                setTimeout(() => {
                    setData(resp.data);
                    setReady(true);
                }, 1000);
            }).catch((err: any) => {
                const { status } = err.response;
                if (status===400) {
                    console.log('Error')
                    console.log(err.response)

                    // Ready
                    setReady(true);
                }
            });
        }
	}, [ready, data])

    return <div className="chartsBox dash">
        <div className="header">
            <div className='monthSelectBox'>
                <button type="button" className={months===3 ? 'active' : ''} onClick={() => changeMonths(3)}>3M</button>
                <button type="button" className={months===6 ? 'active' : ''} onClick={() => changeMonths(6)}>6M</button>
                <button type="button" className={months===12 ? 'active' : ''} onClick={() => changeMonths(12)}>12M</button>
            </div>

            <div className="selectChart">
                <label htmlFor="">Gráfico:</label>
                <select name="typeChart" className='typeChart' onChange={onChangeChart} disabled={!ready}>
                    <option value="monthConsumption">Consumo Mensal</option>
                    <option value="costPerKwh">Custo por kWh</option>
                    <option value="totalValue">Valor Total</option>
                    <option value="tariffsFlags">Bandeira Tarifária</option>
                    {
                        ready && data && data.modality==='Verde' ?
                            <option value="demandAnalysis">Análise de Demanda</option> :
                            <>
                                <option value="demandAnalysisPeak">Análise de Demanda Ponta</option>
                                <option value="demandAnalysisOffPeak">Análise de Demanda Fora Ponta</option>
                            </>
                    }
                </select>
            </div>
        </div>

        <div className="content">
            {
                ready ?
                    data && getChart()
                : (
                    <div className='loading'>
                        <Lottie animationData={loading} loop play className="icon" />
                    </div>
                )
            }
        </div>
    </div>
}

export default ChartsBox;