import React from 'react';
import Lottie from 'react-lottie-player';
import './styles.css';

// Images
import points from './points.json';

const Points: React.FC = () => {
    return <div className='points'>
        <Lottie
            animationData={points}
            loop play
            speed={1.5}
            className="icon"
        />
    </div>;
}

export default Points;