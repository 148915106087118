import Button from './components/Button';

// Images
import check from '../images/check.png';

const FinalApproved = [
    {
        type: 'modal',
        image: {
            url: check,
            alt: 'OK'
        },
        text: 'Você têm pelo menos #OPPS# oportunidades de economia na sua conta!',
        button: (type: string) => <Button link="/admin" text="Ver as oportunidades" type={type} />
    }
]

export default FinalApproved