import React, { ChangeEvent, FormEvent, useContext, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import ptBRLocale from 'date-fns/locale/pt-BR';
import { FaBan, FaSave } from 'react-icons/fa';
import { GeneralContext } from '~/contexts';
import api from '~/services/api';
import Dates from '~/utils/dates';
import validateData from './validation';

// Components
import Template from '~/pages/admin/Template';
import {Checkbox, Selectbox, InputLabel, Button} from '~/components/Forms';

// Init Data
const initData = {
    id              : undefined,
    type            : '',
    company_id      : '',
    adequacy_costing: 0,
    name            : '',
    curva: {
        year1Init: '',
        year1End: '',
        year1: null,
        year2: null,
        year3: null,
        year4: null,
        year5: null,
        year6: null,
    },
    plan : {
        year_1 :  null,
        value_1:  null,
        year_2 :  null,
        value_2:  null,
        year_3 :  null,
        value_3:  null,
        year_4 :  null,
        value_4:  null
    },
}

const SpecialConditionCreate: React.FC = () => {
    const {user, checkToken, breadcrumbs, setBreadcrumbs} = useContext(GeneralContext);
    const submitRef                     = useRef<HTMLButtonElement>(null);
    const [ready, setReady]             = useState(false);
    const [data, setData]               = useState<any>(null);
    const [errors, setErrors]           = useState<any>(null);
    const [datesOpen, setDatesOpen]     = useState<any>({year1Init: false, year1End: false});
    const navigate                      = useNavigate();
    const [buttonReady, setButtonReady] = useState<any>(true);
    const [messages, setMessages]       = useState<any>(<></>);
    // Parameter
    let params = useParams();
    let id     = params.id;

    function onChange(event: ChangeEvent<HTMLInputElement|HTMLButtonElement>) {
        let {name, value, type} = event.target;
        change(name, value, type);
    }

    function onChangeDate (name: string, date: any) {
        let initDate = Dates.getDateFormatted(date, 'yyyy-mm') + '-01 03:00:00';
        let endDate  = Dates.getDateFormatted(date, 'yyyy') + '-12-01 03:00:00';

        changeDate(name, initDate, endDate)
    }

    function onChangeCheckbox(event: any) {
        let isChecked = event.target.checked;
        setData({...data, adequacy_costing :isChecked ? 1 : 0})
    }

    function change(name: string, value: any, type?: string) {
        let split    = name.split('.');
        let newData  = JSON.parse(JSON.stringify(data));
        if (split.length>1) newData[split[0]][split[1]] = value;
        else newData[name] = type==='checkbox' ? Number(value) : value;

        // Set Data
        setData({...data, ...newData});
    }

    function changeDate(name: string, init: any, end: any) {
        let split    = name.split('.');
        let newData  = JSON.parse(JSON.stringify(data));

        // Set New Dates
        newData[split[0]][split[1] + 'Init'] = init;
        newData[split[0]][split[1] + 'End']  = end;

        console.log({...data, ...newData})

        // Set Data
        setData({...data, ...newData});
    }

    function _validateNumber(item: any){
        return item !== '' && item !== null ? Number(item) : null;
    }

    async function onSubmit (event: FormEvent<HTMLFormElement>) {
        setButtonReady(false)
		event.preventDefault();

        let dataJson
        if (data.type === "curva") {
            console.log(data.curva)
            dataJson = {
                type     : 'i50',
                discount : 50,
                year1Init: Dates.getDateFormatted(data.curva.year1Init, 'yyyy-mm') + '-01 03:00:00',
                year1End : Dates.getDateFormatted(data.curva.year1End, 'yyyy-mm') + '-01 03:00:00',
                year1    : _validateNumber(data.curva.year1),
                year2    : _validateNumber(data.curva.year2),
                year3    : _validateNumber(data.curva.year3),
                year4    : _validateNumber(data.curva.year4),
                year5    : _validateNumber(data.curva.year5),
                year6    : _validateNumber(data.curva.year5)
            }
        } else {
            dataJson = {
                plan1:{
                    years: _validateNumber(data.plan.year_1),
                    value: _validateNumber(data.plan.value_1)
                },
                plan2:{
                    years: _validateNumber(data.plan.year_2),
                    value: _validateNumber(data.plan.value_2)
                },
                plan3:{
                    years: _validateNumber(data.plan.year_3),
                    value: _validateNumber(data.plan.value_3)
                },
                plan4:{
                    years: _validateNumber(data.plan.year_4),
                    value: _validateNumber(data.plan.value_4)
                },
            }
        }

        let dataSave = {
            id              : data.id,
            company_id      : data.company_id,
            type            : data.type,
            adequacy_costing: data.adequacy_costing,
            json            : dataJson,
        };

        let errorsValidate = await validateData(dataSave)
        setErrors(errorsValidate)

        if(errorsValidate === undefined) await sendData(dataSave)
        else {
            setMessages(<div className="msg err">Não foi possivel o cadastro da condição especial!</div>)
            setTimeout(() => setMessages(<></>), 3000);
        }

        setButtonReady(true)
	}

    async function sendData (dataSave: any) {
        await api.post('/special', dataSave, {
            headers: { Authorization: user.token }
        }).then(resp => {
            setMessages(<div className="msg">Cadastro da condição especial realizado com sucesso!</div>)
            setTimeout(() => {
              setMessages(<></>)
              navigate(`/admin/s/clientes`);
            } , 2000);
        }).catch(async err => {
            setMessages(<div className="msg err">Ocorreu um erro ao salvar condição especial!</div>)
            setTimeout(() => setMessages(<></>), 3000);
            if (err.response.status===400) {
                setMessages(<div className="msg err">Ocorreu um erro ao salvar condição especial!</div>)
                setTimeout(() => setMessages(<></>), 3000);
                setErrors(err.response.data.errors)
            }
            else if (err.response.status===403) {
                let status = await checkToken(user)
                if (status) submitRef.current?.click()
                else navigate(`/login`, {replace: true})
            }
        })
    }

    // async function priceApi () {
    //     api.get('/prices', {
    //         headers: { Authorization: user.token }
    //     }).then(resp => {
    //         setI50 (resp.data[1])
    //     })
    // }

    useEffect(() => {
        ready && !breadcrumbs && setBreadcrumbs({
            curr: 'Condição especial',
            links: [
                { name: 'Lista de Clientes', url: '/admin/s/clientes'}
            ]
        });

        if (data===null) {
            setReady(false)

            api.get(`/special/${id}`, {headers: { Authorization: user.token }}).then(resp => {
                let base    = resp.data;

                initData.name             = base.company.name;
                initData.company_id       = base.company.id;
                if(base.type){
                    initData.id               = base.id;
                    initData.type             = base.type;
                    initData.adequacy_costing = base.adequacy_costing;
                    switch (base.type) {
                        case 'curva':
                            initData.curva = base.json
                            break;
                        case 'plano':
                            initData.plan  = {
                                year_1 :  base.json.plan1.years,
                                value_1:  base.json.plan1.value,
                                year_2 :  base.json.plan2.years,
                                value_2:  base.json.plan2.value,
                                year_3 :  base.json.plan3.years,
                                value_3:  base.json.plan3.value,
                                year_4 :  base.json.plan4.years,
                                value_4:  base.json.plan4.value,
                            }
                            break
                        default:
                            break;
                    }
                }

                setTimeout(() => {
                    setData(initData)
                    setReady(true)
                }, 1000);
            })
        }

    }, [user, id, ready, data]);

    // Unmount
    useEffect(() => () => setBreadcrumbs(null), [])

    return <Template page="Administrador - Cliente - Edição de Dados" pageTitle="Gerenciamento de Clientes" className="cli-special-condition">
        <div className="header">
            <h1>Condição especial</h1>
        </div>
        <div className="messageInfo">
            {messages}
        </div>

        {
            ready ? (
                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ptBRLocale}>
                    <form method="POST" onSubmit={onSubmit}>
                        <div className="fields">
                            <div className="infos">
                                <div className="header-infos">
                                    <div className="left">
                                        <h2 className='text-3xl'>{data.name}</h2>
                                        <Checkbox
                                            id='adequacy_costing'
                                            name='adequacy_costing'
                                            labelName='Custeio da adequação'
                                            onChange={onChangeCheckbox}
                                            checked={data.adequacy_costing ? 1 : 0}
                                        />

                                        <Selectbox
                                            name='type'
                                            onChange={change}
                                            values={[{
                                                name: 'Curva de preço',
                                                value: 'curva'
                                            }, {
                                                name: 'Planos de energia',
                                                value: 'plano'
                                            }]}
                                            value={data.type}
                                        />
                                    </div>
                                    <div className="btns">
                                        <Button
                                            type="submit"
                                            className={`${buttonReady ? 'save' : 'btnLoad'}`}
                                            icon={<FaSave />}
                                            text={buttonReady ? "Salvar Alterações" : 'Salvando...'}
                                            refButton={submitRef}
                                            disabled={buttonReady ? false : true}
                                        />

                                        <Button
                                            type="link"
                                            link="/admin/s/clientes"
                                            className='cancel'
                                            icon={<FaBan />}
                                            text="Cancelar"
                                        />
                                    </div>
                                </div>

                                {
                                    data.type==='curva' && (
                                        <>
                                            <div className="planLine">
                                                <div className='planGroup'>
                                                    <InputLabel
                                                        className='typeDis'
                                                        name="type"
                                                        type="text"
                                                        labelName='Tipo'
                                                        value={'i50'}
                                                        disabled={true}
                                                    />
                                                    <InputLabel
                                                        className='typeDis'
                                                        name="desconto-total"
                                                        type="text"
                                                        labelName='Desconto'
                                                        value={'50'}
                                                        disabled={true}
                                                    />
                                                </div>

                                                <div className='planGroup'>
                                                    <div className="dateBox">
                                                        <InputLabel
                                                            name="curva.year1Init"
                                                            type="text"
                                                            labelName='Ano 1 Início'
                                                            dataType="i50"
                                                            onClick={() => {setDatesOpen({year1Init: !datesOpen.year1Init, year1End: false})}}
                                                            value={data?.curva?.year1Init ? Dates.getDateFormatted(data?.curva?.year1Init, 'mm/yyyy') : ''}
                                                            error={errors && errors['json.year1Init']}
                                                            readyOnly={true}
                                                        />
                                                        <DatePicker
                                                            format='MM/yyyy'
                                                            open={datesOpen.year1Init}
                                                            onClose={() => setDatesOpen({year1Init: false, year1End: false})}
                                                            value={new Date(data?.curva?.year1Init)}
                                                            slotProps={{textField: {size: 'small'}}}
                                                            onChange={(date) => onChangeDate('curva.year1', date)}
                                                            views={['year', 'month']}
                                                        />
                                                    </div>

                                                    <InputLabel
                                                        className="dateYM"
                                                        name="curva.year1End"
                                                        type="text"
                                                        labelName='Ano 1 Final'
                                                        dataType="i50"
                                                        value={data?.curva?.year1Init ? Dates.treatDates(data?.curva?.year1Init, 'end', 0, 'mm/yyyy') : ''}
                                                        disabled
                                                    />

                                                    <InputLabel
                                                        name="curva.year1"
                                                        className='noMargin'
                                                        type="number"
                                                        labelName='Ano 1 Valor'
                                                        dataType="i50"
                                                        value={data?.curva?.year1}
                                                        onChange={onChange}
                                                        error={errors && errors['json.year1']}
                                                    />
                                                </div>

                                                <div className='planGroup'>
                                                    <InputLabel
                                                        className="dateYM"
                                                        name="curva.year2Init"
                                                        type="text"
                                                        labelName='Ano 2 Início'
                                                        dataType="i50"
                                                        value={data?.curva?.year1Init ? Dates.treatDates(data?.curva?.year1Init, 'init', 1, 'mm/yyyy') : ''}
                                                        disabled
                                                    />

                                                    <InputLabel
                                                        className="dateYM"
                                                        name="curva.year2End"
                                                        type="text"
                                                        labelName='Ano 2 Final'
                                                        dataType="i50"
                                                        value={data?.curva?.year1Init ? Dates.treatDates(data?.curva?.year1Init, 'end', 1, 'mm/yyyy') : ''}
                                                        disabled
                                                    />

                                                    <InputLabel
                                                        name="curva.year2"
                                                        type="number"
                                                        labelName='Ano 2 Valor'
                                                        dataType="i50"
                                                        value={data?.curva?.year2}
                                                        onChange={onChange}
                                                        error={errors && errors['json.year2']}
                                                    />
                                                </div>

                                                <div className='planGroup'>
                                                    <InputLabel
                                                        className="dateYM"
                                                        name="curva.year3Init"
                                                        type="text"
                                                        labelName='Ano 3 Início'
                                                        dataType="i50"
                                                        value={data?.curva?.year1Init ? Dates.treatDates(data?.curva?.year1Init, 'init', 2, 'mm/yyyy') : ''}
                                                        disabled
                                                    />

                                                    <InputLabel
                                                        className="dateYM"
                                                        name="curva.year3End"
                                                        type="text"
                                                        labelName='Ano 3 Final'
                                                        dataType="i50"
                                                        value={data?.curva?.year1Init ? Dates.treatDates(data?.curva?.year1Init, 'end', 2, 'mm/yyyy') : ''}
                                                        disabled
                                                    />

                                                    <InputLabel
                                                        name="curva.year3"
                                                        type="number"
                                                        labelName='Ano 3 Valor'
                                                        dataType="i50"
                                                        value={data?.curva?.year3}
                                                        onChange={onChange}
                                                        error={errors && errors['json.year3']}
                                                    />
                                                </div>

                                                <div className='planGroup'>
                                                    <InputLabel
                                                        className="dateYM"
                                                        name="curva.year4Init"
                                                        type="text"
                                                        labelName='Ano 4 Início'
                                                        dataType="i50"
                                                        value={data?.curva?.year1Init ? Dates.treatDates(data?.curva?.year1Init, 'init', 3, 'mm/yyyy') : ''}
                                                        disabled
                                                    />

                                                    <InputLabel
                                                        className="dateYM"
                                                        name="curva.year4End"
                                                        type="text"
                                                        labelName='Ano 4 Final'
                                                        dataType="i50"
                                                        value={data?.curva?.year1Init ? Dates.treatDates(data?.curva?.year1Init, 'end', 3, 'mm/yyyy') : ''}
                                                        disabled
                                                    />

                                                    <InputLabel
                                                        name="curva.year4"
                                                        type="number"
                                                        labelName='Ano 4 Valor'
                                                        dataType="i50"
                                                        value={data?.curva?.year4}
                                                        onChange={onChange}
                                                        error={errors && errors['json.year4']}
                                                    />
                                                </div>

                                                <div className='planGroup'>
                                                    <InputLabel
                                                        className="dateYM"
                                                        name="curva.year5Init"
                                                        type="text"
                                                        labelName='Ano 5 Início'
                                                        dataType="i50"
                                                        value={data?.curva?.year1Init ? Dates.treatDates(data?.curva?.year1Init, 'init', 4, 'mm/yyyy') : ''}
                                                        disabled
                                                    />

                                                    <InputLabel
                                                        className="dateYM"
                                                        name="curva.year5End"
                                                        type="text"
                                                        labelName='Ano 5 Final'
                                                        dataType="i50"
                                                        value={data?.curva?.year1Init ? Dates.treatDates(data?.curva?.year1Init, 'end', 4, 'mm/yyyy') : ''}
                                                        disabled
                                                    />

                                                    <InputLabel
                                                        name="curva.year5"
                                                        type="number"
                                                        labelName='Ano 5 Valor'
                                                        dataType="i50"
                                                        value={data?.curva?.year5}
                                                        onChange={onChange}
                                                        error={errors && errors['json.year5']}
                                                    />
                                                </div>

                                                <div className='planGroup'>
                                                    <InputLabel
                                                        className="dateYM"
                                                        name="curva.year6Init"
                                                        type="text"
                                                        labelName='Ano 6 Início'
                                                        dataType="i50"
                                                        value={data?.curva?.year1Init ? Dates.treatDates(data?.curva?.year1Init, 'init', 5, 'mm/yyyy') : ''}
                                                        disabled
                                                    />

                                                    <InputLabel
                                                        className="dateYM"
                                                        name="curva.year6End"
                                                        type="text"
                                                        labelName='Ano 6 Final'
                                                        dataType="i50"
                                                        value={data?.curva?.year1Init ? Dates.treatDates(data?.curva?.year1Init, 'end', 5, 'mm/yyyy') : ''}
                                                        disabled
                                                    />

                                                    <InputLabel
                                                        name="curva.year6"
                                                        type="number"
                                                        labelName='Ano 6 Valor'
                                                        dataType="i50"
                                                        value={data?.curva?.year6}
                                                        onChange={onChange}
                                                        error={errors && errors['json.year6']}
                                                    />
                                                </div>
                                            </div>
                                        </>
                                    )
                                }
                                {
                                    data.type==='plano' && (
                                        <>
                                            <div className="planLine">
                                                <div className='planGroup'>
                                                    <p>Plano 1</p>
                                                    <InputLabel
                                                        name="plan.year_1"
                                                        type="number"
                                                        labelName='Valor em anos'
                                                        value={data?.plan?.year_1}
                                                        onChange={onChange}
                                                        error={errors && errors['json.plan1.years']}
                                                    />
                                                    <InputLabel
                                                        name="plan.value_1"
                                                        type="number"
                                                        labelName='Porcentagem de desconto (%)'
                                                        value={data?.plan?.value_1}
                                                        onChange={onChange}
                                                        error={errors && errors['json.plan1.value']}
                                                    />
                                                </div>
                                                <div className='planGroup'>
                                                    <p>Plano 2</p>
                                                    <InputLabel
                                                        name="plan.year_2"
                                                        type="number"
                                                        labelName='Valor em anos'
                                                        value={data?.plan?.year_2}
                                                        onChange={onChange}
                                                        error={errors && errors['json.plan2.years']}

                                                    />
                                                    <InputLabel
                                                        name="plan.value_2"
                                                        type="number"
                                                        labelName='Porcentagem de desconto (%)'
                                                        value={data?.plan?.value_2}
                                                        onChange={onChange}
                                                        error={errors && errors['json.plan2.value']}

                                                    />
                                                </div>
                                                <div className='planGroup'>
                                                    <p>Plano 3</p>
                                                    <InputLabel
                                                        name="plan.year_3"
                                                        type="number"
                                                        labelName='Valor em anos'
                                                        value={data?.plan?.year_3}
                                                        onChange={onChange}
                                                        error={errors && errors['json.plan3.years']}
                                                    />

                                                    <InputLabel
                                                        name="plan.value_3"
                                                        type="number"
                                                        labelName='Porcentagem de desconto (%)'
                                                        value={data?.plan?.value_3}
                                                        onChange={onChange}
                                                        error={errors && errors['json.plan3.value']}

                                                    />
                                                </div>
                                                <div className='planGroup'>
                                                    <p>Plano 4</p>
                                                    <InputLabel
                                                        name="plan.year_4"
                                                        type="number"
                                                        labelName='Valor em anos'
                                                        value={data?.plan?.year_4}
                                                        onChange={onChange}
                                                        error={errors && errors['json.plan4.years']}

                                                    />

                                                    <InputLabel
                                                        name="plan.value_4"
                                                        type="number"
                                                        labelName='Porcentagem de desconto (%)'
                                                        value={data?.plan?.value_4}
                                                        onChange={onChange}
                                                        error={errors && errors['json.plan4.value']}

                                                    />
                                                </div>
                                            </div>
                                        </>
                                    )
                                }
                            </div>

                            {/* <div className="contact">
                                <h2 className='text-3xl'>Dados de Contato</h2>

                                <InputLabel
                                    name='contact.mail'
                                    value={data.contact.mail}
                                    type='mail'
                                    labelName='Email'
                                    onChange={onChange}
                                    error={errors && errors['contact.mail'] && errors['contact.mail'][0]}
                                />

                                <InputLabelMask
                                    name='contact.phone'
                                    value={data.contact.phone}
                                    type='string'
                                    placeholder='(00) 00000-0000'
                                    mask={'(99) 99999-9999'}
                                    labelName='Telefone'
                                    onChange={onChange}
                                    error={errors && errors['contact.phone'] && errors['contact.phone'][0]}
                                />
                            </div> */}
                        </div>
                    </form>
                </LocalizationProvider>
            ) : <></>
        }
    </Template>;
}

export default SpecialConditionCreate;