import React, { useContext, useEffect, useState } from 'react';
import "./styles.css";
import ReactTooltip from "react-tooltip";
import mobile from 'is-mobile';
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, ResponsiveContainer, Tooltip, Legend } from "recharts";
import { FaInfoCircle } from "react-icons/fa";
import { ModalContext } from "~/contexts";
import dates from "~/utils/dates";
import numbers from "~/utils/numbers";
import sort_by from '~/utils/sort'
import Funcs from "../funcs";

// Colors
const colorsTarifs = {green: '#10B981', yellow: '#CCB802', red1: '#D87979', red2: '#D03F3F', scarcity: '#751BBF'}

const TariffsFlags:React.FC<any> = ({className, styles, months, infos}: any) => {
	const {setModalOpen, setModalTitle, setModalBody} = useContext(ModalContext);
	const [data, setData]         = useState<any>(null);
	const [interval, setInterval] = useState(0);
	const [month, setMonth]       = useState<any>(null);

	// Functions Flags
	const flag = (flag: string) => {
		switch (flag) {
			case 'Verde':
				return colorsTarifs.green
			case 'Amarela':
				return colorsTarifs.yellow
			case 'Vermelha - Patamar 1':
				return colorsTarifs.red1
			case 'Vermelha - Patamar 2':
				return colorsTarifs.red2
			case 'Escassez Hídrica':
				return colorsTarifs.scarcity
		}
	}

	const flagRelevance = (flag: string) => {
		switch (flag) {
			case 'Verde':
				return 1
			case 'Amarela':
				return 2
			case 'Vermelha - Patamar 1':
				return 3
			case 'Vermelha - Patamar 2':
				return 4
			case 'Escassez Hídrica':
				return 5
		}
	}

	// Chart Funcions
	const renderCustomTooltip = ({ active, payload }: any) => {
		let total: any = null;
		payload.map((el: any) => total = el)

		// Treat Title
		let dateBase = total ? total.payload.date.split('-') : null;
		let title    = total ? dates.getNameMonth(dateBase[1]) + '/' + dateBase[0] : '';

		return <div className='tooltipChart'>
			<h4>{title}</h4>

			<div style={{color: total ? flag(total.payload.flag) : '#666666'}}><b>Bandeira:</b> <span>{ total ? total.payload.flag : ''}</span></div>
			<div style={{color: '#666666'}}><b>Custo por cada kWh:</b> <span>{total ? numbers.money((total.payload.value<0.0009 ? 0 : total.payload.value), true, true, 4) : ''}</span></div>
		</div>
	}

	const renderCustomLegend = ({ active, payload }: any) => {
		let data: any  = [];
		let total: any = null;
		payload.map((el: any) => total = el)

		if (total.payload.values) {
			total.payload.values.map((el: any) => {
				let exists = data.some((dt: any) => dt.flag===el.flag)
				if (!exists) data.push({...el, rel: (flagRelevance(el.flag))})
			})
		}

		data.sort(sort_by({name: 'rel'}));

		return data.length>0 ? data.map((el: any, idx: number) => <div key={`leg_${idx}`} style={{color: '#666666'}}>
			<span className="color" style={{backgroundColor: flag(el.flag)}}></span>
			{el.flag}
		</div>) : <></>
	}

	const renderLegends = () => {
		let flags: any = [];

		data && data.map((el: any, idx: number) => {
			let exists = flags.some((dt: any) => dt.flag===el.flag)
			if (!exists) flags.push({...el, rel: (flagRelevance(el.flag))})
		})

		flags.sort(sort_by({name: 'rel'}));

		return flags.length>0 ? flags.map((el: any, idx: number) => <div key={`leg_${idx}`} style={{color: '#666666'}}>
			<span className="color" style={{backgroundColor: flag(el.flag)}}></span>
			{el.flag}
		</div>) : <></>
	}

	// Mount Functions
	useEffect(() => {
		setMonth(months)

		if (infos!==undefined) {
			let dataInfo = JSON.parse(JSON.stringify(infos)).reverse();

			switch (month) {
				case 12:
					setData(dataInfo)
					setInterval(2)
					break
				case 6:
					setData(dataInfo.slice(dataInfo.length-6))
					setInterval(1)
					break
				case 3:
					setData(dataInfo.slice(dataInfo.length-3))
					setInterval(0)
					break
			}
		}
    }, [month, months, infos]);

	function modalTooltip () {

		if(mobile()){
			setModalTitle('Bandeira tarifária')
			setModalBody(<>
				<p>O gráfico de bandeira tarifária demonstra o <b>custo adicional</b> por cada kWh consumido relativo as condições e Geração de Energia.</p>
				<ul>
					<li><a href="https://energizouhelp.zendesk.com/hc/pt-br/articles/10140965202957-Bandeira-Tarif%C3%A1ria" target="_blank" rel="noreferrer"><b>Saiba mais</b> sobre Bandeira Tarifária</a></li>
				</ul>
				<p className="info">Esse gráfico gera os dados dos <b>últimos 12 meses</b> utilizando <b>apenas uma fatura de energia</b>.</p>
				<p className="notes">Para a melhor experiência em <b>gráficos</b> faça o upload de pelo menos <b>3 Contas de Luz na página "Histórico"</b>.</p>
			</>)
			setModalOpen(true)
		}
    }


	return <div  className={`${className ? className : ''} monthCons`} style={styles}>
		<header>
            <div className="titles">
                <h3>Bandeira tarifária</h3>
                <h4>Custo adicional por cada kWh</h4>
            </div>

			<div className="hover" data-tip="tooltip" data-for="tariffsFlagInfo">
				<FaInfoCircle color='#c0c0c0' onClick={() => modalTooltip()}/>
				{!mobile()?
					<ReactTooltip id="tariffsFlagInfo" className='infosBox big' place="left" type="light" effect="solid" clickable={true} delayHide={1000}>
						<p>O gráfico de bandeira tarifária demonstra o <b>custo adicional</b> por cada kWh consumido relativo as condições e Geração de Energia.</p>
						<ul>
							<li><a href="https://energizouhelp.zendesk.com/hc/pt-br/articles/10140965202957-Bandeira-Tarif%C3%A1ria" target="_blank" rel="noreferrer"><b>Saiba mais</b> sobre Bandeira Tarifária</a></li>
						</ul>
						<p className="info">Esse gráfico gera os dados dos <b>últimos 12 meses</b> utilizando <b>apenas uma fatura de energia</b>.</p>
						<p className="notes">Para a melhor experiência em <b>gráficos</b> faça o upload de pelo menos <b>3 Contas de Luz na página "Histórico"</b>.</p>
					</ReactTooltip>
				:
					<></>
				}
			</div>
        </header>

		<section className="content">
			<ResponsiveContainer height={mobile() ? 250 : 350}>
				<BarChart
					data={data}
					margin={{
						top: 20,
						right: 30,
						left: 20,
						bottom: 5
					}}
				>
					<CartesianGrid  vertical={false}/>
					<XAxis dataKey="date" interval={interval} tick={Funcs.renderXAxisLabel} />
					<YAxis tick={props => Funcs.renderYAxisLabelCurr(props, true, true, 4)}/>
					<Tooltip content={renderCustomTooltip} />
					{!mobile() && <Legend content={renderCustomLegend} />}

					<Bar dataKey="value" values={data}>
						{ data && data.map((el: any, idx: number) => <Cell key={`cell-${idx}`} fill={flag(el.flag)} />) }
					</Bar>
				</BarChart>
			</ResponsiveContainer>

			{mobile() && <div className="legends">{renderLegends()}</div>}
		</section>
	</div>;
}

export default TariffsFlags;