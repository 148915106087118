import React, { useContext, useState } from 'react';
import { FaDownload, FaTrash } from 'react-icons/fa';
import { RiLoader2Line } from 'react-icons/ri';
import { GeneralContext } from '~/contexts';
import dates from '~/utils/dates';
import api from '~/services/api';
import './styles.css';

// Images
import icon from './images/icon.svg';
import { Button } from '~/components/Forms';

interface Props {
    bill: number;
    id: number;
    date: string;
    status: string;
    provider: any;
    modality: any;
    company: any;
    path: string;
    delBill?: Function;
}

const BillBox:React.FC<any> = (props: Props) => {
    const {user} = useContext(GeneralContext);
    const [downReady, setDownReady] = useState<Boolean>(true);
    const dateFormat = dates.getDateCharts(props.date);

    function treatStatus (status: string) {
        switch (status) {
            case 'validated':
                return 'Validado';

            case 'errors':
                return 'Com Erros';

            default:
                return 'Aguardando';
        }
    }

    function download (path: string) {
        setDownReady(false);

        api.get(`bills/${path}`, {
            headers: { Authorization: user.token},
            responseType: 'blob'
        }).then(resp => {
            let url      = window.URL.createObjectURL(new Blob([resp.data]));
            let link     = document.createElement('a');
            let fileName = `${props.company.name} - ${dateFormat.monthName.full} de ${dateFormat.year}.pdf`;

            setTimeout(() => {
                link.href = url;
                link.setAttribute("id", "download");
                link.setAttribute('download', fileName); //or any other extension
                document.body.appendChild(link);
                link.click();
                link.parentNode?.removeChild(link);
                setDownReady(true);
            }, 1000);
        })
    }

    return <div className='billBox'>
        <div className="icon">
            <img src={icon} alt="Icon" />
        </div>

        <div className="blank"></div>

        <div className="provider">{props.provider ? props.provider : '-'}</div>

        <div className={`modality ${props.modality==='Azul' ? 'blue' : 'green'}`}>
            <span>{props.modality ? props.modality : '-'}</span>
        </div>

        <div className="date">
            {dateFormat.monthName.full} de {dateFormat.year}
        </div>

        <div className={`status${props.status!=='not_rated' ? ' ' + props.status : ''}`}>
            <span className='mark'></span>
            <span>{treatStatus(props.status)}</span>
        </div>

        <div className="buttons">
            <Button
                type="link"
                link={`/admin/s/clientes/${props.id}/bills/check/${props.bill}`}
                className='check'
                icon={null}
                text="Analisar"
                disabled={props.status==='not_rated' ? false : true}
            />

            <Button
                type="button"
                className={`down${!downReady ? ' load' : ''}`}
                icon={!downReady ? <RiLoader2Line size={18} /> : <FaDownload />}
                text={null}
                func={() => download(props.path)}
                disabled={!downReady ? true : false}
            />

            <Button
                type="button"
                className='del'
                icon={<FaTrash />}
                text={null}
                onClick={() => props.delBill && props.delBill(props.id)}
                disabled={!props.delBill ? true : false}
            />
        </div>
    </div>;
}

export default BillBox;