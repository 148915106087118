import React from 'react';

// Images
import icon from '../../images/step4.png';

const Step3: React.FC<any> = ({setStep, description}: {setStep: Function, description: string}) => {
    return <>
        <div className="infos">
            <div className="texts">
                <h3>Como implementar?</h3>
                <div dangerouslySetInnerHTML={{__html: description}} />
            </div>

            <div className="buttons">
                <button className='prev' onClick={() => setStep(3)}>
                    Voltar
                </button>
            </div>
        </div>

        <div className="icon">
            <img src={icon} alt="Icone da Sessão" />
        </div>
    </>;
}

export default Step3;