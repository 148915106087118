import React, { useEffect } from 'react';
import './styles.css';

interface Props {
    name: string;
    values: any[];
    value: string;
    onChange?: Function;
}

const Selectbox: React.FC<Props> = (props) => {
    function onClick(value: string) {
        if (value!==props.value) {
            props.onChange && props.onChange(props.name, value)
        }
    }

    useEffect(() => {}, [props])

    return <div className="selectbox">
        {
            props.values.map(el => <button
                className={props.value===el.value ? 'active' : ''}
                key={`select_${el.value}`}
                type="button"
                onClick={() => onClick(el.value)}
            >
                {el.name}
            </button>)
        }
    </div>
}

export default Selectbox;


