import React, { ChangeEventHandler, FormEventHandler, useEffect, useRef, useState } from 'react';

// Components
import { InputLabel, Button, InputLabelMask } from '~/components/Forms';

const StepContact: React.FC<any> = (
    {setStep, data, onChange, onSubmit, err}:
    {setStep: Function, data: any, onChange: ChangeEventHandler, onSubmit: FormEventHandler, err:any}
) => {

    const formRef = useRef<HTMLFormElement>(null);

    return <div className='contact'>
        <h1 className='text-3xl'>Dados de Contato</h1>

        <form method="POST" onSubmit={onSubmit} ref={formRef}>
            <InputLabel
                name='contact.mail'
                value={data.contact.mail}
                type='mail'
                labelName='Email'
                onChange={onChange}
                error={err?.mail}
            />

            <InputLabelMask
				name='contact.phone'
                value={data.contact.phone}
                mask={'(99) 99999-9999'}
				type='string'
                labelName='Telefone'
                onChange={onChange}
			/>

            <hr/>

            <InputLabel
				name='password.password'
                value={data.password.password}
				type='password'
                autoComplete={false}
                labelName='Senha atual'
                onChange={onChange}
                error={err?.password}
			/>
            <InputLabel
				name='password.new_password'
                value={data.password.new_password}
				type='password'
                labelName='Nova senha'
                onChange={onChange}
                error={err?.new_password}
                minLenght={8}
			/>
            <InputLabel
				name='password.confirm_password'
                value={data.password.confirm_password}
				type='password'
                labelName='Confirmar senha'
                onChange={onChange}
                error={err?.confirm_password}
                minLenght={8}
			/>
        </form>

        <div className="buttons flex flex-stretch">
            <Button
                type="button"
                func={() => setStep('')}
                className="cancel"
                text="Voltar"
            />

            <Button
                type="button"
                func={() => {formRef.current && formRef.current.requestSubmit()}}
                className="save box"
                text="Salvar"
            />
        </div>
    </div>;
}

export default StepContact;