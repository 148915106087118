import React, { ChangeEventHandler, FormEventHandler, useRef } from 'react';

// Components
import {InputLabel, Button} from '~/components/Forms';

const StepInfos: React.FC<any> = (
    {setStep, data, onChange, onSubmit}:
    {setStep: Function, data: any, onChange: ChangeEventHandler, onSubmit: FormEventHandler}
) => {
    const formRef = useRef<HTMLFormElement>(null);

    return <div className='infos'>
        <h1 className='text-3xl'>Informações Pessoais</h1>

        <form method="POST" onSubmit={onSubmit} ref={formRef}>
            <InputLabel
                name='user.name'
                value={data.user.name}
                type='text'
                labelName='Nome'
                onChange={onChange}
            />

            {
                data.role==='user' && (
                    <>
                        <hr />

                        <InputLabel
                            name='company.name'
                            value={data.company.name}
                            type='text'
                            labelName='Nome da empresa'
                            onChange={onChange}
                        />

                        <InputLabel
                            name='company.cnpj'
                            value={data.company.cnpj}
                            type='text'
                            disabled
                            placeholder='12.000.000/0001-09'
                            labelName='CNPJ'
                            onChange={onChange}
                        />
                    </>
                )
            }
        </form>

        <div className="buttons">
            <Button
                type="button"
                func={() => setStep('')}
                className="cancel"
                text="Voltar"
            />

            <Button
                type="button"
                func={() => {formRef.current && formRef.current.requestSubmit()}}
                className="save box"
                text="Salvar"
            />
        </div>
    </div>;
}

export default StepInfos;