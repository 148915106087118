import React, { ChangeEvent, FormEvent, useContext, useEffect, useState } from 'react';
import { GeneralContext } from '~/contexts';
import api from '~/services/api';
import './styles.css';

// Components
import Template from '~/pages/admin/Template';
import Loading from '~/components/Default/Loading';
import StepProfile from './StepProfile';
import StepInfos from './StepInfos';
import StepContact from './StepContact';

// Init Data
const initData = {
    id: 0,
    role: '',

    user: {
       name: ''
    },
    company: {
        name: '',
        fantasy: '',
        cnpj: ''
    },
    contact: {
        mail: '',
        phone: ''
    },
    password:{
        password: '',
        new_password: '',
        confirm_password:''
    }
}

var arrayErr = {
    cnpj            : '',
    mail            : '',
    password        : '',
    new_password    : '',
    confirm_password: ''
}

const Profile: React.FC = () => {
    const {user}            = useContext(GeneralContext);
    const [ready, setReady] = useState(false);
    const [step, setStep]   = useState('');
    const [data, setData]   = useState<any>(null);
    const [err, setErr]     = useState<any>(arrayErr);

    function loadStep () {
        switch (step) {
            case 'infos':
                return <StepInfos setStep={setStep} data={data} onChange={onChange} onSubmit={onSubmit} err={err}/>

            case 'contacts':
                return <StepContact setStep={setStep} data={data} onChange={onChange} onSubmit={onSubmit} err={err}/>

            default:
                return <StepProfile setStep={setStep} />
        }
    }

    function onChange(event: ChangeEvent<HTMLInputElement>) {
        const {name, value} = event.target;

        // Treat Data
        let split    = name.split('.');
        let newData  = JSON.parse(JSON.stringify(data));
        newData[split[0]][split[1]] = value;

        // Set Data
        setData({...data, ...newData});
    }

    function onSubmit (event: FormEvent<HTMLFormElement>) {
		event.preventDefault();

        let dataSave = {
            id         : data.id,
            type       : data.role,
            name       : data.user.name,
            mail       : data.contact.mail,
            phone      : data.contact.phone,
            password   : data.password.password,
            newPassword: data.password.new_password,
            company    : data.role==='user' ? {
                name   : data.company.name,
                fantasy: data.company.fantasy,
                cnpj   : data.company.cnpj
                }      : null
        };

        if(data.password.password !== '' && !data.password.confirm_password && !data.password.new_password){
            arrayErr = {...arrayErr, password:'Para alterar a senha, preencha os campos abaixo'}
            arrayErr = {...arrayErr, new_password:'Campo vazio'}
            arrayErr = {...arrayErr, confirm_password:'Campo vazio'}
            setErr(arrayErr)
            return null
        }else if(data.password.new_password !== data.password.confirm_password){
            arrayErr = {...arrayErr, new_password:'Senhas não se coincidem'}
            arrayErr = {...arrayErr, confirm_password:'Senhas não se coincidem'}
            setErr(arrayErr)
            return null
        }else if(data.password.new_password === data.password.confirm_password){
            arrayErr = {...arrayErr, new_password:''}
            arrayErr = {...arrayErr, confirm_password:''}
            setErr(arrayErr)
        }else if (data.password.password == '' && data.password.new_password == '' ||data.password.confirm_password == ''){
            arrayErr = {...arrayErr, password:' '}
            arrayErr = {...arrayErr, new_password:' '}
            arrayErr = {...arrayErr, confirm_password:' '}
            setErr(arrayErr)
            return null
        }

        api.put('/users', dataSave, {
            headers: { Authorization: user.token }
        }).then(resp => {
            setStep('')
            setData(null)
        }).catch(err => {
            let ret = err.response.data.errors.user

            if(ret.errPassword === true)
                arrayErr = {...arrayErr, password:'Senha inválida'}
            else if(ret.errPassword === false)
                arrayErr = {...arrayErr, password:''}

            if(ret.errMail === true)
                arrayErr = {...arrayErr, mail:'E-mail já existente'}
            else if(ret.errMail === false)
                arrayErr = {...arrayErr, mail:''}

            setErr(arrayErr)
        })
	}

    // Mount
    useEffect(() => {
        if(step===''){
            setData(null)
            setErr(null)
        }
    }, [step]);

    // Mount
    useEffect(() => {
        data===null && api.get(`/users/${user.id}`, {
            headers: { Authorization: user.token }
        }).then(resp => {
            let base    = resp.data;
            let nameArr = base.name.split('|');
            // console.log(base)

            // Set User Data
            initData.id              = base.id;
            initData.role            = base.type;
            initData.user.name       = nameArr[0];
            initData.contact.mail    = base.mail;
            initData.contact.phone   = base.phone;
            initData.company.name    = base.company ? base.company.name : '';
            initData.company.fantasy = base.company ? base.company.fantasy : '';
            initData.company.cnpj    = base.company ? base.company.cnpj : '';

            setData(initData)
            setReady(true)
        })
    }, [step, data]);

    return <Template page="Perfil" pageTitle="Perfil" className={`prof${step!=='' ? ' ' + step : ''}`}>
        { ready ? loadStep() : <Loading /> }
    </Template>;
}

export default Profile;