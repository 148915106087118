import React, { useEffect, useState, useContext} from 'react';
import '../main.css';
import './styles.css';
import ReactTooltip from 'react-tooltip';
import { FaInfoCircle } from 'react-icons/fa';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Legend } from 'recharts';
import mobile from 'is-mobile';
import { ModalContext } from '~/contexts';
import dates from '~/utils/dates';
import numbers from '~/utils/numbers';
import Funcs from '../funcs';

const MonthConsumption:React.FC<any> = ({months, infos}: any) => {
    const {setModalOpen, setModalTitle, setModalBody} = useContext(ModalContext);
	const [data, setData]         = useState<any>(null);
	const [interval, setInterval] = useState(0);
	const [month, setMonth]       = useState<any>(null);

	// Chart Funcions
	const renderCustomTooltip = ({ active, payload }: any) => {
		let offPeakHours: any = null;
		let peakHours: any    = null;

		if(!payload) return<></>

		payload && payload.map((el: any, idx: number) => {
			if (idx===0) offPeakHours = el;
			else peakHours = el;
		})

		// Treat Title
		let dateBase = offPeakHours ? offPeakHours.payload.date.split('-') : null;
		let title    = offPeakHours ? dates.getNameMonth(dateBase[1]) + '/' + dateBase[0] : '';

		return <div className='tooltipChart'>
			<h4>{title}</h4>

			<div style={{color: offPeakHours ? offPeakHours.color : '#A0A0A0'}}><b>Fora Ponta:</b> <span>{offPeakHours ? numbers.money(offPeakHours.value, false, false) : ''}</span></div>
			<div style={{color: peakHours ? peakHours.color : '#999999'}}><b>Ponta:</b> <span>{peakHours ? numbers.money(peakHours.value, false, false) : ''}</span></div>
		</div>
	}

	const renderCustomLegend = ({ active, payload }: any) => {
		let offPeakHours: any = null;
		let peakHours: any    = null;

		payload.map((el: any, idx: number) => {
			if (idx===0) offPeakHours = el;
			else peakHours = el;
		})

		return <>
			<div style={{color: '#666666'}}>
				<span className="color" style={{backgroundColor: offPeakHours ? offPeakHours.color : '#A0A0A0'}}></span>
				Fora Ponta
			</div>

			<div style={{color: '#666666'}}>
				<span className="color" style={{backgroundColor: peakHours ? peakHours.color : '#888888'}}></span>
				Ponta
			</div>
		</>
	}

	function modalTooltip () {

		if(mobile()){
			setModalTitle('Consumo de energia mensal')
			setModalBody(<>
			<p>Este gráfico mostra o consumo de energia mensal Ponta e Fora Ponta medido em KWh.</p>
				<ul>
					<li><b>Consumo Ponta:</b> Energia consumida em horário de ponta.</li>
					<li><b>Consumo Fora Ponta:</b> Energia consumida fora horário de ponta.</li>
					<li><a href="https://energizouhelp.zendesk.com/hc/pt-br/articles/9650754439053-Consumo-Ponta-e-Consumo-Fora-Ponta" target="_blank" rel="noreferrer"><b>Saiba mais</b> sobre Consumo ponta e fora ponta</a></li>
				</ul>
				<p className="info">Esse gráfico gera os dados dos últimos <b>12 meses</b> utilizando <b>apenas uma fatura de energia</b>.</p>
				<p className="notes">Para a melhor experiência em <b>gráficos</b> faça o upload de pelo menos <b>3 Contas de Luz na página "Histórico"</b>.</p>
			</>)
			setModalOpen(true)
		}
    }

	// Mount Functions
	useEffect(() => {
		setMonth(months)

		if (infos!==undefined) {
			let dataInfo = JSON.parse(JSON.stringify(infos)).reverse();

			switch (month) {
				case 12:
					setData(dataInfo)
					setInterval(2)
					break
				case 6:
					setData(dataInfo.slice(dataInfo.length-6))
					setInterval(1)
					break
				case 3:
					setData(dataInfo.slice(dataInfo.length-3))
					setInterval(0)
					break
			}
		}
    }, [month, months, infos]);

    return <div className={`monthCons`} >
		<header>
			<div className="titles">
				<h3>Consumo de energia mensal</h3>
				<h4>Ponta e Fora Ponta em kWh</h4>
			</div>

			<div className="hover" data-tip="tooltip" data-for="monthConsumptionInfo">
                <FaInfoCircle color='#c0c0c0' onClick={() => modalTooltip()}/>
				{!mobile()?
					<ReactTooltip id="monthConsumptionInfo" className='infosBox big' place="left" type="light" effect="solid" clickable={true} delayHide={1000}>
						<p>Este gráfico mostra o consumo de energia mensal Ponta e Fora Ponta medido em KWh.</p>
						<ul>
							<li><b>Consumo Ponta:</b> Energia consumida em horário de ponta.</li>
							<li><b>Consumo Fora Ponta:</b> Energia consumida fora horário de ponta.</li>
							<li><a href="https://energizou.com.br" target="_blank" rel="noreferrer"><b>Saiba mais</b> sobre Consumo ponta e fora ponta</a></li>
						</ul>
						<p className="info">Esse gráfico gera os dados dos últimos <b>12 meses</b> utilizando <b>apenas uma fatura de energia</b>.</p>
						<p className="notes">Para a melhor experiência em <b>gráficos</b> faça o upload de pelo menos <b>3 Contas de Luz na página "Histórico"</b>.</p>
					</ReactTooltip>
				:
					<></>
                }
            </div>
		</header>

		<section className="content">
			<ResponsiveContainer height={mobile() ? 250 : 350}>
				<BarChart
					data={data}
					margin={{
						top: 20,
						right: 30,
						left: 20,
						bottom: 5,
					}}
				>
					<CartesianGrid vertical={false}/>
					<XAxis dataKey="date" interval={interval} tick={Funcs.renderXAxisLabel} />
					<YAxis tick={Funcs.renderYAxisLabel}/>
					<Tooltip content={renderCustomTooltip} />
					{!mobile() && <Legend content={renderCustomLegend} />}

					<Bar dataKey="off_peak_hours" stackId="a" fill="#ADC7E0" />
					<Bar dataKey="peak_hours" stackId="a" fill="#002A57" />
				</BarChart>
			</ResponsiveContainer>

			{
				mobile() && (
					<div className="legends">

						<div style={{color: '#666666'}}>
							<span className="color" style={{backgroundColor: '#002A57'}}></span>
							Ponta
						</div>
						<div style={{color: '#666666'}}>
							<span className="color" style={{backgroundColor: '#ADC7E0'}}></span>
							Fora Ponta
						</div>
					</div>
				)
			}
		</section>
	</div>
}

export default MonthConsumption;