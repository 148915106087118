import React, { useContext, useState } from 'react';

import mobile from 'is-mobile';
import { FaExchangeAlt, FaInfoCircle } from 'react-icons/fa';
import Numbers from '~/utils/numbers';
import './styles.css';

// Functions
import Funcs from './funcs';
import { ModalContext } from '~/contexts';

// Variables
let status = '';

const MonthlyReport:React.FC<any> = ({styles, date, data}: any) => {
	const [open, setOpen]   = useState<any>(false);
    const {setModalOpen} = useContext(ModalContext);


    function openModal (data?: any) {
        setModalOpen(false)
    }

    function getPercMonthlyCons () {
		let infos = Funcs.getPercMonthlyCons(data.from, data.to)
		status 	  = infos.status
		return infos.data
	}

  	return <div className="monthlyReport" style={styles}>
		<header>
			<div className="titles">
				<h3>Relatório de comparação<br /> mensal da sua conta de luz</h3>
			</div>

			<div className="hover">
				<FaInfoCircle color='#c0c0c0'/>
			</div>
		</header>

        <section className='content'>
            <div className="line head">
                <div className="left">
                    {getPercMonthlyCons()}
                </div>

                <div className="right">
                    <span>Escolha um outro mês<br/>para comparar</span>

                    <div className='boxs'>
                        <div className="select">
                            <div className='selectBox'>
                                <select name="to" disabled>
                                    <option>{data.to.reference_date}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {mobile() && !open && (
                <div className="more" onClick={()=>setOpen(!open)}><span>+ Leia mais</span></div>
            )}

            {mobile() && open && (
                <div className='content_box'>
                    <div className="infos">
                        <p>Valor da sua conta de luz em {data.from.reference_date} : {Numbers.money(data.from.total_value)}</p>
                        <p>Valor da sua conta de luz em {data.to.reference_date} : {Numbers.money(data.to.total_value)}</p>
                    </div>

                    <div className="upPeriod">
                        <h5>{status} no período pelos seguintes motivos:</h5>

                        { // Custo da Demanda
                        Funcs.demandCost(data.from, data.to, openModal)}

                        { // Custo do consumo ponta
                        Funcs.consumptionPeakHoursCost(data.from, data.to, openModal)}

                        { // Custo do consumo fora ponta
                        Funcs.consumptionOffPeakHoursCost(data.from, data.to, openModal)}

                        { // Custo da bandeira tarifária
                        Funcs.flagTariffCost(data.from, data.to, openModal)}

                        { // Outros custos
                        Funcs.othersCost(data.from, data.to, openModal)}
                    </div>

                    <div className="notes">
                        ¹ - Energia reativa, PIS/COFINS, ICMS, multas e aditivos
                    </div>
                </div>
            )}

            {!mobile() && (
                    <div className='content_box'>
                        <div className="infos">
                            <p>Valor da sua conta de luz em {data.from.reference_date} : {Numbers.money(data.from.total_value)}</p>
                            <p>Valor da sua conta de luz em {data.to.reference_date} : {Numbers.money(data.to.total_value)}</p>
                        </div>

                        <div className="upPeriod">
                            <h5>{status} no período pelos seguintes motivos:</h5>

                            { // Custo da Demanda
                            Funcs.demandCost(data.from, data.to, openModal)}

                            { // Custo do consumo ponta
                            Funcs.consumptionPeakHoursCost(data.from, data.to, openModal)}

                            { // Custo do consumo fora ponta
                            Funcs.consumptionOffPeakHoursCost(data.from, data.to, openModal)}

                            { // Custo da bandeira tarifária
                            Funcs.flagTariffCost(data.from, data.to, openModal)}

                            { // Outros custos
                            Funcs.othersCost(data.from, data.to, openModal)}
                        </div>

                        <div className="notes">
                            ¹ - Energia reativa, PIS/COFINS, ICMS, multas e aditivos
                        </div>
                    </div>
            )}

            {mobile() && open && (
                <div className="more" onClick={()=>setOpen(!open)}><span>Ler menos</span></div>
            )}

        </section>
	</div>
}

export default MonthlyReport;