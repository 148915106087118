import React, { useContext, useEffect, useState } from 'react';
import { GeneralContext } from '~/contexts';
import api from '~/services/api';
import './styles.css';

// Components
import Template from '~/pages/admin/Template';
import LoadingSimple from '~/components/Default/Loading/Simple';
import OppsLine from '~/components/Boxs/Opps';

const Opportunities: React.FC = () => {
    const {user}            = useContext(GeneralContext);
    const [ready, setReady] = useState(false);
    const [opps, setOpps]   = useState<any>(null);

    useEffect(() => {
        if (opps===null) {
            setReady(false)

            api.get('/opportunities', {
                headers: { Authorization: user.token }
            }).then(resp => {
                setTimeout(() => {
                    setOpps(resp.data)
                    setReady(true)
                }, 1000);
            })
        }
    }, [ready, opps]);

    return <Template page="Administrador - Oportunidades" pageTitle="Gerenciamento de Oportunidades" className="opps">
        <div className="header">
            <h1>Oportunidades</h1>
        </div>

        <div className="oppsBox">
            <header>
                <div className="name">Nome</div>
                <div className="date">Última Modificação</div>
                <div className="btns"></div>
            </header>

            <section>
                {
                    ready ? (
                        opps && opps.length>0 ?
                            opps.map((el: any) => <OppsLine key={`opps_${el.id}`} {...el} />) :
                            <div className='noData'>Não foi possível localizar oportunidades</div>
                    ) : <LoadingSimple />
                }
            </section>
        </div>
    </Template>;
}

export default Opportunities;