import warning from '../images/warning.png'

const FinalDisapproved = [
    {
        type: 'modalError',
        image: {
            url: warning,
            alt: 'OK'
        },
        text1: 'Ops, há algo de errado na sua leitura…',
        text2: 'Você pode tentar enviar o arquivo novamente ou seguir o cadastro de outra forma'
    }
]
export default FinalDisapproved