import React, { useContext, useEffect } from 'react';
import { FaBolt, FaSignInAlt } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { GeneralContext } from '~/contexts';
import './styles.css';

const Analisy: React.FC<any> = () => {
    const {logged} = useContext(GeneralContext);
    useEffect(() => {}, []);

    return <main className='analisy'>
        {
            logged ?
            <section id='confirm-mail-contact'>
                <div>
                    <h2>Fique atento em seu e-mail...</h2>
                    <p>Em breve entraremos em contato</p>
                    <p className='symbol'>@</p>
                </div>

             </section> :
            <Link to={'/login'}>Entrar</Link>
        }
    </main>
}

export default Analisy;