import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { GeneralContext } from '~/contexts';
import api from '~/services/api';
import './styles.css';

// Components
import AdminClientBillsList from './list';
import AdminClientBillsCheck from './check';

const ClientsBills: React.FC = () => {
    let params = useParams();
    let id     = params.id;
    let bill   = params.bill;

    // States
    const {user}              = useContext(GeneralContext);
    const [ready, setReady]   = useState(false);
    const [client, setClient] = useState<any>(null);

    useEffect(() => {
        if (client===null) {
            setReady(false)

            api.get(`/users/${id}`, {headers: { Authorization: user.token }}).then(resp => {
                setClient(resp.data)
                setReady(true)
            })
        }
    }, [id, user, ready, client]);

    return ready ? (
        bill && Number(bill)>0 ?
        <AdminClientBillsCheck client={client} /> :
        <AdminClientBillsList client={client} />
    ) : <></>
}

export default ClientsBills