import React from 'react';

// Images
import icon from '../../images/step1.png';
import { FaExternalLinkAlt } from 'react-icons/fa';

const Step1: React.FC<any> = ({idOportunity, setStep, description}: {idOportunity:number, setStep: Function, description: string}) => {
    return <>
        <div className="infos">

            <div className="texts">
                <h3>O que é?</h3>
                <div dangerouslySetInnerHTML={{__html: description}} />

                <ul>
                    {(idOportunity === 1 || idOportunity === 7) &&
                    <a target="_blank" rel="noreferrer" href='https://energizouhelp.zendesk.com/hc/pt-br/articles/9650064960653-Grupo-Tarif%C3%A1rio-de-energia'>
                        <li>
                            <span>O que é Grupo tarifário?</span>
                            <FaExternalLinkAlt />
                        </li>
                    </a>
                    }
                    {(idOportunity === 1 || idOportunity === 6 ||idOportunity === 2) &&
                    <a target="_blank" rel="noreferrer" href='https://energizouhelp.zendesk.com/hc/pt-br/articles/9650123235981-Demanda-Contratada'>

                        <li>
                            <span>O que é demanda contratada?</span>
                            <FaExternalLinkAlt />
                        </li>
                    </a>
                    }
                    {(idOportunity === 1) &&
                    <a target="_blank" rel="noreferrer" href='https://energizouhelp.zendesk.com/hc/pt-br/articles/9650291635725-Modalidade-Tarif%C3%A1ria'>

                        <li>
                            <span>O que é modalidade tarifária?</span>
                            <FaExternalLinkAlt />
                        </li>
                    </a>
                    }
                    {(idOportunity === 7) &&
                    <a target="_blank" rel="noreferrer" href='https://energizouhelp.zendesk.com/hc/pt-br/articles/9650959789325-Tarifa-de-Energia-TE-e-Tarifa-de-Uso-dos-Sistemas-de-Distribui%C3%A7%C3%A3o-TUSD-'>

                        <li>
                            <span>O que é TE e TUSD?</span>
                            <FaExternalLinkAlt />
                        </li>
                    </a>
                    }
                    {(idOportunity === 6) &&
                    <a target="_blank" rel="noreferrer" href='https://energizouhelp.zendesk.com/hc/pt-br/articles/9650595429773-Ultrapassagem-de-Demanda'>

                        <li>
                            <span>O que é ultrapassagem de demanda? </span>
                            <FaExternalLinkAlt />
                        </li>
                    </a>
                    }
                </ul>
            </div>

            <div className="buttons">
                <button className='next' onClick={() => setStep(2)}>
                    Próximo
                </button>
            </div>
        </div>

        <div className="icon">
            <img src={icon} alt="Icone da Sessão" />
        </div>
    </>;
}

export default Step1;