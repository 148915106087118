import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import dateformat from 'dateformat';
import api from '~/services/api';
import { MixPanelContext, GeneralContext, ModalContext } from '~/contexts';
import './styles.css';
import ReactTooltip from 'react-tooltip';
import { boolean } from 'yup';

interface Props {
    client: string;
    idProposal: string;
    signed: string;
    type: string | { name: string, years: number, value: string };
    date: string;
    path: string;
    pathPpt: string;
    setMessages: React.Dispatch<React.SetStateAction<any>>
    setReady: React.Dispatch<React.SetStateAction<any>>
}

const ProposalBox: React.FC<Props> = ({ client, idProposal, signed, type, date, path, pathPpt, setMessages, setReady }: Props) => {
    const { user } = useContext(GeneralContext);
    const { buttonClick } = useContext(MixPanelContext);
    const Modal        = useContext(ModalContext);
    // Treat Dates
    const dateCurr = new Date();
    const datePropEnd = new Date(date);
    datePropEnd.setDate(datePropEnd.getDate() + 5);

    function downloadFile(path: string, type: string) {
        api.get(`proposals/${path}`, {
            headers: { Authorization: user.token },
            responseType: 'blob'
        }).then((resp: any) => {
            let url = window.URL.createObjectURL(new Blob([resp.data]));
            let link = document.createElement('a');
            let fileName = `Proposta Comercial - ${client}_${dateformat(date, 'mm-yyyy')} .${type}`;
            setMessages(<div className="msgP">Download do arquivo {type} realizado com sucesso!</div>)
            setTimeout(() => setMessages(<></>), 3000);

            link.href = url;
            link.setAttribute("id", "download");
            link.setAttribute('download', fileName); //or any other extension
            document.body.appendChild(link);
            link.click();
            link.parentNode?.removeChild(link);
        }).catch(err => {
            
            setMessages(<div className="msgP err">Ocorreu um erro no Download do arquivo {type}!</div>)
            setTimeout(() => setMessages(<></>), 3000);
        });
    }
    async function disableProposal(idDisableProposa: string, signed:string) {
        if(signed!== "-1"){
            await api.put(`proposal/disableManualProposal/${idDisableProposa}`, '',{
                headers: { Authorization: user.token },
            }).then((resp: any) => {
                Modal.setModalOpen(false)
                setMessages(<div className="msgP">Proposta Desativada com sucesso !</div>)
                setTimeout(() => setMessages(<></>), 3000);
                setReady(false)
            }).catch(err => {
                
                setMessages(<div className="msgP err">Ocorreu um erro ao Desativada proposta!</div>)
                setTimeout(() => setMessages(<></>), 3000);
                Modal.setModalOpen(false)
            });
        }else{
            setMessages(<div className="msgP err">Ocorreu um erro ao Desativada proposta!</div>)
            setTimeout(() => setMessages(<></>), 3000);
            Modal.setModalOpen(false)
           
        }
        

    }

    function openModal (idDisableProposa: string, signed:string) {

        Modal.setModalOpen(false);

    
                Modal.setModalClass('modalProposalInative enz')
                Modal.setModalTitle('Excluindo Conta de Energia')
                Modal.setModalBody(<>
                    <div>Deseja realmente Inativar essa proposta do usuario <i>"{ client }"?</i></div>
                    <div className="alert"><b>* Tenha cuidado, esse processo é irreversível!</b></div>

                    <footer>
                        <button id="btnSave" type="button" onClick={()=>{disableProposal(idDisableProposa,signed)}} >Inativar</button>
                        <button id="btnCancel" type="button" onClick={() => Modal.setModalOpen(false)}>Cancelar</button>
                    </footer>
                </>)

                // delBill(id)
                Modal.setModalOpen(true)
              
    }

    return <div className='proposalBox'>
        <div
            data-for="tooltip_proposal_hover"
            className={`status ${signed === "0" ? 'active' : 'ended'}`}
            data-tip={signed === "0" ? 'Proposta Ativa' : 'Proposta Inativa'}
            data-type={signed === "0" ? 'success' : 'error'}
        />

        <div className="infos">
            <div className="client">
                <small>Cliente</small>
                <span>{client}</span>
            </div>

            <div className="type">
                <small>Tipo de Proposta</small>
                <span>{typeof type !== "string" ? `Plano ${type.name}` : type}</span>
            </div>

            <div className="date">
                <small>Data da Proposta</small>
                <span>{dateformat(date, 'dd/mm/yyyy')}</span>
            </div>
        </div>

        <div className="buttons">
            {
                path ? <Link to="/admin/s/proposals" className='download' onClick={() => {
                    downloadFile(path, 'pdf');
                    buttonClick(user.id, 'Proposta Comercial', 'Download de Proposta Comercial em PDF', path);
                }}>
                    Download PDF
                </Link> : <button type="button" className='download' disabled>
                    Download PDF
                </button>
            }

            {
                pathPpt ? <Link to="/admin/s/proposals" className='download' onClick={() => {
                    downloadFile(pathPpt, 'pptx');
                    buttonClick(user.id, 'Proposta Comercial', 'Download de Proposta Comercial em PPT', pathPpt);
                }}>
                    Download PPT
                </Link> : <button type="button" className='download' disabled>
                    Download PPT
                </button>
            }
            {
            signed === '0' ? <button type="button" className='download' onClick={() => { 
                 openModal(idProposal,signed)}} >
                Inativar 
            </button> : <button type="button" className='download' disabled >
                Inativa
            </button>
            }
           

        </div>

        <ReactTooltip
            id="tooltip_proposal_hover"
            place="right"
            effect="solid"
            type="info"
        />
    </div>;
}

export default ProposalBox;