import React from 'react';

// Images
import icon from '../../images/step1.png';

const Step1: React.FC<any> = ({setStep, company}: {setStep: Function, company:any}) => {
    return <>
        <div className="infos freeMarket">
            <div className="texts">
                <h1>O que é Mercado Livre de Energia?</h1>
                <div>
                    <p>Tradicionalmente, empresas como o(a) {company?.name} contratam energia diretamente da sua distribuidora de energia. Esse modelo de contratação também é chamado de Mercado Cativo de Energia. Nessa modalidade, o consumidor é cobrado por meio de uma tarifa de energia definida pela Aneel (Agência Nacional de Energia Elétrica).</p>
                    <p>O Mercado Livre de Energia é uma outra modalidade do setor elétrico que <b>permite que empresas possam contratar, de forma livre, diferentes fornecedores de energia elétrica.</b> Nesse ambiente, os consumidores são livres para negociar as condições comerciais diretamente com comercializadoras de energia, como a Energizou.</p>
                </div>
                <h1>Porque entrar no Mercado Livre de Energia</h1>
                <div>
                    <p>A adesão ao Mercado Livre de Energia pode trazer inúmeros benefícios para o(a) {company?.name}, sendo a principal vantagem a redução de custos com energia elétrica. Ao negociar diretamente com os fornecedores, é possível conseguir preços mais competitivos e personalizar o contrato conforme as necessidades da empresa.</p>
                </div>
            </div>

            <div className="buttons">
                <button className='next' onClick={() => setStep(2)}>
                    Próximo
                </button>
            </div>
        </div>

        <div className="icon">
            <img src={icon} alt="Icone da Sessão" />
        </div>
    </>;
}

export default Step1;