import React, { useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FaBolt, FaFolder, FaShieldAlt, FaSignal, FaSignOutAlt, FaMoneyCheckAlt } from 'react-icons/fa';
import { GeneralContext } from '~/contexts';
import './styles.css';

// Get Version
const version = require('~/../package.json').version;

const Menu: React.FC = () => {
    const {user}   = useContext(GeneralContext);
    const location = useLocation();

    return <aside>
        <nav>
            <Link to="/admin" className={location.pathname === '/admin' || location.pathname === '/admin/' || location.pathname.search(/\/admin\/([0-9]+)/gm)>=0 || location.pathname.search(/\/admin\/d\/([0-9\-])/gm)>=0 ? ' active' : ''}>
                <FaSignal />
                <span>Oportunidades</span>
            </Link>

            <Link to="/admin/relatorios" className={location.pathname.indexOf('admin/relatorios')>=0 || location.pathname.search(/\/admin\/relatorios\/([0-9\-]+)/gm)>=0 || location.pathname.indexOf('admin/auditoria')>=0 ? ' active' : ''}>
                <FaBolt />
                <span>Relatórios</span>
            </Link>

            <Link to="/admin/historico" className={location.pathname.indexOf('admin/historico')>=0 ? ' active' : ''}>
                <FaFolder />
                <span>Histórico</span>
            </Link>

            {
                user.role==='admin' ? (<>
                    <Link to="/admin/s/clientes" className={location.pathname.indexOf('/s/')>=0 ? ' active' : ''}>
                        <FaShieldAlt />
                        <span>Administrador</span>
                    </Link>

                    {
                        location.pathname.indexOf('/s/')>=0 ? (
                            <div className="submenu">
                                <Link to="/admin/s/clientes" className={location.pathname.indexOf('s/clientes')>=0 ? ' active' : ''}>
                                    <span>Clientes</span>
                                </Link>

                                <Link to="/admin/s/oportunidades" className={location.pathname.indexOf('s/oportunidades')>=0 ? ' active' : ''}>
                                    <span>Oportunidades</span>
                                </Link>

                                <Link to="/admin/s/configs" className={location.pathname.indexOf('s/configs')>=0 ? ' active' : ''}>
                                    <span>Premissas de Cálculo</span>
                                </Link>

                                <Link to="/admin/s/providers" className={location.pathname.indexOf('s/providers')>=0 ? ' active' : ''}>
                                    <span>Planos / Distribuidoras</span>
                                </Link>
                                <Link to="/admin/s/helpAdmin" className={location.pathname.indexOf('s/helpAdmin')>=0 ? ' active' : ''}>
                                    <span>Solicitações de leitura</span>
                                </Link>
                                <Link to="/admin/s/proposals" className={location.pathname.indexOf('s/proposals')>=0 ? ' active' : ''}>
                                    <span>Propostas Comerciais</span>
                                </Link>
                                <Link to="/admin/s/especial" className={location.pathname.indexOf('s/especial')>=0 ? ' active' : ''}>
                                    <span>Condições especiais ativas</span>
                                </Link>
                            </div>
                        ): <></>
                    }
                </>) : <></>
            }
        </nav>

        <footer>
            <div>
                {/* <Link to="/logout" className='config'>
                    <FaCog />
                </Link> */}

                <Link to="/logout" className='logout'>
                    <FaSignOutAlt />
                </Link>
            </div>

            <span>Version {version}</span>
        </footer>
    </aside>;
}

export default Menu;