import React from 'react';

// Images
import upper from './images/up.png';
import down from './images/down.png';

const funcs = {
    getPercMonthlyCons: function (from: any, to: any) {
		let perc:any = 0;
        let type = '';

        let fromTotal = from.total_value;
        let toTotal   = to.total_value;
        let diff      = fromTotal-toTotal;

        perc = (diff*100)/toTotal;
        // perc = Math.round((diff*100)/toTotal);
        if (fromTotal > toTotal){
            type = 'Aumentou';
        }
        else {
            perc = perc*-1
            type = 'Diminuiu';
        }

        if(perc <= 99.99){
            perc = perc.toFixed(1)
            perc = perc.replace('.',',')
        }else{
            perc = perc.toFixed(0)
        }

		return {
            status: type,
            data: (<>
                <div className="upDown">
                    <span>{type}</span>
                    {/* <span className='number'>{perc}%</span> */}
                    <span className='number'>{perc}%</span>
                </div>

                <img src={fromTotal > toTotal ? upper : down} alt={type} />
            </>)
        }
	},
    formatNumber: function (number:any) {
        let numberFormat = String(Math.round(number))
        let arr = numberFormat.split('')
        let negative = false

        if (arr[0] === '-'){
            numberFormat = numberFormat.replace("-", "")
            arr = numberFormat.split('')
            negative = true
        }

        if(numberFormat.length <= 3)
            numberFormat = 'R$ ' + numberFormat

        else if(numberFormat.length === 4)
            numberFormat = 'R$ ' + arr[0] +' mil'

        else if(numberFormat.length === 5)
            numberFormat = 'R$ ' + arr[0] +arr[1] + ' mil'

        else if(numberFormat.length === 6)
            numberFormat = 'R$ ' + arr[0] +arr[1] + arr[2] + ' mil'

        else if(numberFormat.length === 7)
            numberFormat = 'R$ ' + arr[0] +'.' +arr[1] +' M'

        else if(numberFormat.length === 8)
            numberFormat = 'R$ ' + arr[0] + arr[1] +'.' + arr[2] + ' M'

        if(negative === false)
            return numberFormat
        else
        return '- '+numberFormat

    },

    demandCost: function (from: any, to: any, modal:any) {
        let message    = '';
        let demandFrom = from.demand+from.demand_no_icms;
        let demandTo   = to.demand+to.demand_no_icms;
        let difference = demandFrom-demandTo

        if (difference<0 && from.tariffs.demand===to.tariffs.demand)
            message = 'Demanda utilizada foi menor'
        else if (difference>0 && from.tariffs.demand===to.tariffs.demand)
            message = 'Demanda utilizada foi maior'
        else if (from.tariffs.demand!==to.tariffs.demand)
            message = 'Reajuste tarifário'

        return this._getTmpl(
            'Custo da demanda',
            message,
            difference,
            modal,
            demandFrom,
            demandTo
        )
    },

    demandCostPeak: function (from: any, to: any, modal:any) {
        let message    = '';
        let demandFrom = from.demand.peak+from.demand_no_icms.peak;
        let demandTo   = to.demand.peak+to.demand_no_icms.peak;
        let difference = demandFrom-demandTo

        if (difference<0 && from.tariffs.demand===to.tariffs.demand)
            message = 'Demanda utilizada ponta foi menor'
        else if (difference>0 && from.tariffs.demand===to.tariffs.demand)
            message = 'Demanda utilizada ponta foi maior'
        else if (from.tariffs.demand!==to.tariffs.demand)
            message = 'Reajuste tarifário'

        return this._getTmpl(
            'Custo da demanda ponta',
            message,
            difference,
            modal,
            demandFrom,
            demandTo
        )
    },

    demandCostOffPeak: function (from: any, to: any, modal:any) {
        let message    = '';
        let demandFrom = from.demand.offPeak+from.demand_no_icms.offPeak;
        let demandTo   = to.demand.offPeak+to.demand_no_icms.offPeak;
        let difference = demandFrom-demandTo

        if (difference<0 && from.tariffs.demand===to.tariffs.demand)
            message = 'Demanda utilizada fora ponta foi menor'
        else if (difference>0 && from.tariffs.demand===to.tariffs.demand)
            message = 'Demanda utilizada fora ponta foi maior'
        else if (from.tariffs.demand!==to.tariffs.demand)
            message = 'Reajuste tarifário'

        return this._getTmpl(
            'Custo da demanda fora ponta',
            message,
            difference,
            modal,
            demandFrom,
            demandTo
        )
    },

    consumptionPeakHoursCost: function (from: any, to: any, modal:any) {
        let message  = '';
        let difference = from.consumption_peak_hours - to.consumption_peak_hours;

        if (difference<0 && from.tariffs.consumption===to.tariffs.consumption)
            message = 'Consumo ponta foi menor'
        else if (difference>0 && from.tariffs.consumption===to.tariffs.consumption)
            message = 'Consumo ponta foi maior'
        else if (from.tariffs.consumption!==to.tariffs.consumption)
            message = 'Reajuste tarifário'

        return this._getTmpl(
            'Custo do consumo ponta',
            message,
            difference,
            modal,
            from.consumption_peak_hours,
            to.consumption_peak_hours
        )
    },

    consumptionOffPeakHoursCost: function (from: any, to: any, modal:any) {
        let message  = '';
        let difference =  from.consumption_off_peak_hours - to.consumption_off_peak_hours;

        if (difference<0 && from.tariffs.consumptionOffTip===to.tariffs.consumptionOffTip)
            message = 'Consumo fora ponta foi menor'
        else if (difference>0 && from.tariffs.consumptionOffTip===to.tariffs.consumptionOffTip)
            message = 'Consumo fora ponta foi maior'
        else if (from.tariffs.consumptionOffTip!==to.tariffs.consumptionOffTip)
            message = 'Reajuste tarifário'

        return this._getTmpl(
            'Custo do consumo fora ponta',
            message,
            difference,
            modal,
            from.consumption_off_peak_hours,
            to.consumption_off_peak_hours
        )
    },

    flagTariffCost: function (from: any, to: any, modal:any) {
        let message  = '';
        let difference = from.tariff_flag - to.tariff_flag;

        if (from.tariffs.flag<to.tariffs.flag)
            message = 'Bandeira tarifária foi menor'

        else if (from.tariffs.flag>to.tariffs.flag)
            message = 'Bandeira tarifária foi maior'

        else if (difference<0 && from.tariffs.flag===to.tariffs.flag)
            message = 'Consumo total foi menor'

        else if (difference>0 && from.tariffs.flag===to.tariffs.flag)
            message = 'Consumo total foi maior'

        return this._getTmpl(
            'Custo da bandeira tarifária',
            message,
            difference,
            modal,
            from.tariff_flag,
            to.tariff_flag
        )
    },

    othersCost: function (from: any, to: any, modal:any) {
        let message    = '';
        let difference = from.other_costs - to.other_costs;

        if (difference<0) message = 'Impostos e/ou outras taxas foram menores¹'
        else if (difference>0) message = 'Impostos e/ou outras taxas foram maiores¹'

        return this._getTmpl(
            '¹ - Outros ',
            message,
            difference,
            modal,
            from.other_costs,
            to.other_costs
        )
    },

    _getTmpl: function (
        title: string,
        message: string,
        difference: any,
        modal:any,
        valueFrom:any ,
        valueTo:any) {

        return <div className="line">
            <div className="name">
                <span>{title}</span>
            </div>
            <div className={`perc ${difference > 0 ? 'red' : (difference < 0 && 'green')}`}>
                {this.formatNumber(difference) }
            </div>

            <div className='detail'
                onClick={()=> modal(
                    title,
                    valueFrom,
                    valueTo,
                    difference,
                    message)}>
                Detalhes
            </div>
        </div>
    }
}

export default funcs;