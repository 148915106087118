import React, {useState} from 'react';
import { Collapse } from 'react-collapse';
import './styles.css';

interface Props {
    title: string;
    description: string;
    index: number;
    collapseOpen: number;
    setCollapseOpen: Function;
}

const ConditionsBox: React.FC<Props> = ({title, description, index, collapseOpen, setCollapseOpen}: Props) => {
    let isOpened = collapseOpen===index ? true : false;

    return <div className="conditionsBox">
        <div className="headLine">
            <div className="name">{title}</div>
            { !isOpened && <button className="more" onClick={() => setCollapseOpen(index)}>Ver mais</button> }
        </div>

        <Collapse isOpened={isOpened}>
            <div className="description">{description}</div>
        </Collapse>
    </div>;
}

export default ConditionsBox;