import React, { useContext, useEffect, useRef, useState } from 'react';
import MediumEditor from 'medium-editor/dist/js/medium-editor';
import { useParams } from 'react-router-dom';
import { FaBan, FaSave } from 'react-icons/fa';
import { GeneralContext } from '~/contexts';
import api from '~/services/api';

// Styles
import 'medium-editor/dist/css/medium-editor.min.css';
import 'medium-editor/dist/css/themes/beagle.min.css';
import './styles.css';

// Components
import Template from '~/pages/admin/Template';
import { Button } from '~/components/Forms';

const OpportunitiesEdit: React.FC = () => {
    const {user, breadcrumbs, setBreadcrumbs} = useContext(GeneralContext);
    const [ready, setReady] = useState(false);
    const [tab, setTab]     = useState('which_is');
    const [data, setData]   = useState<any>(null);

    // Refs
    const whichIsRef        = useRef<HTMLDivElement>(null);
    const whyRef            = useRef<HTMLDivElement>(null);
    const calculationsRef   = useRef<HTMLDivElement>(null);
    const implementationRef = useRef<HTMLDivElement>(null);

    // Parameter
    let params = useParams();
    let id     = params.id;

    function loadEditor () {
        new MediumEditor('.oppsEdit > main > #editBox > .contents [data-editable="true"]');
    }

    function save () {
        console.log('Enviando dados...');

        api.post(`/opportunities`, {
            id, name: data.name,
            which_is: whichIsRef.current?.innerHTML || '',
            why: whyRef.current?.innerHTML || '',
            calculations: calculationsRef.current?.innerHTML || '',
            implementation: implementationRef.current?.innerHTML || ''
        }, {
            headers: { Authorization: user.token }
        }).then(resp => {
            // console.log(resp.data)
        })
	}

    useEffect(() => {
        ready && !breadcrumbs && setBreadcrumbs({
            curr: 'Edição de Oportunidade',
            links: [
                { name: 'Lista de Oportunidades', url: '/admin/s/oportunidades'}
            ]
        });

        data===null && api.get(`/opportunities/${id}`, {
            headers: { Authorization: user.token }
        }).then(resp => {
            setData(resp.data)
            setReady(true)
        })

        data!==null && loadEditor()
    }, [user, id, data]);

    // Unmount
    useEffect(() => () => setBreadcrumbs(null), [])

    return <Template page="Administrador - Oportunidades - Edição de Dados" pageTitle="Gerenciamento de Clientes" className="oppsEdit">
        <div className="header">
            <h1>Edição de Oportunidade{data!==null && (<small> - {data.name}</small>)}</h1>
        </div>

        {
            ready && data!==null ? (
                <div id="editBox">
                    <div className="header">
                        <button
                            type="button"
                            className={`box${tab==='which_is' ? ' active' : ''}`}
                            onClick={() => setTab('which_is')}
                        >
                            O que é?
                        </button>

                        <button
                            type="button"
                            className={`box${tab==='why' ? ' active' : ''}`}
                            onClick={() => setTab('why')}
                        >
                            Por que?
                        </button>

                        <button
                            type="button"
                            className={`box${tab==='calculations' ? ' active' : ''}`}
                            onClick={() => setTab('calculations')}
                        >
                            Como funcionam os cálculos
                        </button>

                        <button
                            type="button"
                            className={`box${tab==='implementation' ? ' active' : ''}`}
                            onClick={() => setTab('implementation')}
                        >
                            Como implementar?
                        </button>
                    </div>

                    <div className="contents">
                        <div className={`box${tab==='which_is' ? ' active' : ''}`} data-name="which_is" data-editable="true" ref={whichIsRef} dangerouslySetInnerHTML={{ __html: data.which_is }} />
                        <div className={`box${tab==='why' ? ' active' : ''}`} data-name="why" data-editable="true" ref={whyRef} dangerouslySetInnerHTML={{ __html: data.why }} />
                        <div className={`box${tab==='calculations' ? ' active' : ''}`} data-name="calculations" data-editable="true" ref={calculationsRef} dangerouslySetInnerHTML={{ __html: data.calculations }} />
                        <div className={`box${tab==='implementation' ? ' active' : ''}`} data-name="implementation" data-editable="true" ref={implementationRef} dangerouslySetInnerHTML={{ __html: data.implementation }} />

                        <div className="btns">
                            <Button
                                type="button"
                                func={save}
                                className='save'
                                icon={<FaSave />}
                                text="Salvar Alterações"
                            />

                            <Button
                                type="link"
                                link="/admin/s/oportunidades"
                                className='cancel'
                                icon={<FaBan />}
                                text="Cancelar"
                            />
                        </div>
                    </div>
                </div>
            ) : <></>
        }
    </Template>;
}

export default OpportunitiesEdit;