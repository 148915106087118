import React from 'react';
import { useParams } from 'react-router-dom';
import './styles.css';

// Components
import AdminConditionSpecialList from './list';
import AdminConditionSpecialEdit from './edit';

const AdminSpecialCondition: React.FC = () => {
    let params = useParams();
    let id     = params.id;

    return id && Number(id)>0 ?
        <AdminConditionSpecialEdit /> :
        <AdminConditionSpecialList />
}

export default AdminSpecialCondition
