import React from 'react';

// Images
import icon from '../../images/step2.png';

const Step2: React.FC<any> = ({setStep, description}: {setStep: Function, description: string}) => {
    return <>
        <div className="infos">
            <div className="texts">
                <h3>Por que?</h3>
                <div dangerouslySetInnerHTML={{__html: description}} />
            </div>

            <div className="buttons">
                <button className='prev' onClick={() => setStep(1)}>
                    Voltar
                </button>

                <button className='next' onClick={() => setStep(3)}>
                    Próximo
                </button>
            </div>
        </div>

        <div className="icon">
            <img src={icon} alt="Icone da Sessão" />
        </div>
    </>;
}

export default Step2;