import React, { ChangeEvent, useContext, useEffect, useRef, useState } from 'react';
import path from "path";
import { Link, useNavigate } from 'react-router-dom';
import { FaSignOutAlt } from 'react-icons/fa';
import api from '~/services/api';
import Upload from '~/services/forms/upload';
import './styles.css';

// Timeline
import { Init, UserHome, Business, Home, LastLoad, FinalApproved, FinalDisapproved, HelpAdmin } from './steps';

// Contexts
import { GeneralContext, MixPanelContext } from '~/contexts';

// Components
import Timeline from './components/Timeline';
import Points from './components/Points';

// Images
import logo from '~/assets/images/logo.svg';
import person1 from './images/persona1.png';
import person2 from './images/persona2.png';
import person3 from './images/persona3.png';
import person4 from './images/persona4.png';

// Init Data
const initData = {
    type   : '',
    file   : '',
    value  : '',
    economy: ''
}

const Onboarding: React.FC = () => {
    const {user, checkBill, logout} = useContext(GeneralContext);
    const {pageView, buttonClick}   = useContext(MixPanelContext);
    const navigate                  = useNavigate();
    const [userDB, setUserDB]       = useState<any>();
    const [step, setStep]           = useState(0);
    const [person, setPerson]       = useState<any>(person1);
    const [opps, setOpps]           = useState<any>(null);
    const [points, setPoints]       = useState(false);
    const [ready, setReady]         = useState(false);
      //Caso seja zero, é porque ainda não tem retorno
      //Caso seja um, foi porque deu certo
      //Caso seja dois, foi porque deu errado
    const [retUpload, setRetUpload] = useState<number>(0);
    const [data, setData]           = useState<any>(initData);
    const [dataBill, setDataBill]   = useState<any>();
    const [errBill, setErrBill]     = useState<any>();
    const messagesEndRef            = useRef<HTMLDivElement>(null);

    async function onChange(event: ChangeEvent<HTMLInputElement>) {
        if(step === 6) setOpps(null)
        if (event.currentTarget.files && event.currentTarget.files.length>0) {
            let err       = _validatorExtention(event?.currentTarget.files[0].name);
            let nameInput = event.currentTarget.name
            let item: any = {};

            if (!err) {
                item[nameInput] = event.currentTarget.files[0].name
                item[nameInput+'_complete'] = event.currentTarget.files
                setData({...data, ...item})
                setStep(4)
                buttonClick(user.id, 'Onboarding', 'Arquivo Enviado')

                await Upload.sendFile(user, event.currentTarget.files[0]).then(async resp => {
                    if (resp.valid) {
                        if (resp.data && resp.data.earq_id !== null) {
                            let count = 0;
                            var timer = setInterval(async function () {
                                count++;

                                // Check Bill
                                let bill = await Upload.checkBill(user, resp.data.id);

                                // Check Ending Reading
                                if (count===9 || bill.reading==="Read") {
                                    setRetUpload(1)
                                    setDataBill(bill)
                                    clearInterval(timer);
                                }
                            }, 20000)
                        } else if (resp.data && resp.data.opportunities) {
                            if(resp.errors === null){
                                setRetUpload(1)
                                setDataBill(resp.data)
                            }
                        } else {
                            setErrBill(resp.bill)
                            setRetUpload(2)
                            setOpps(0)
                        }
                    } else {
                        setErrBill(resp.bill)
                        setRetUpload(2)
                        setOpps(0)
                    }
                }).catch(err =>{
                    setRetUpload(2)
                    setOpps(0)
                })

            } else {
                alert('Envie arquivos apenas no formato .pdf')
            }
        }
    }

    function _validatorExtention (file: string) {
        let _validFileExtensions = [".pdf"];
        let err = false;
        let ext = path.extname(file).toLocaleLowerCase();
        if (!_validFileExtensions.includes(ext)) err = true;

        return err;
    }

    useEffect(() => {
        if(step === 0){
            let persons:any = {
                0:person1,
                1:person1,
                2:person2,
                3:person3,
                4:person4,
            }

            let random = Math.floor(Math.random() * 4)
            setPerson(persons[random])
        }
    },[])

    // Check login and bill to redirect
    useEffect(() => {
        if (user.token) checkBill().then((resp: any) => resp && navigate('/admin', {replace: true}))
        else navigate('/login', {replace: true})
    }, [])

    //init chat automatic
    useEffect(() => {
       if(step === 0) setTimeout(() => {
            setStep(1)
            setReady(true)
        }, 1500)
    }, [])

    // Check user type account
    useEffect(() => {
        if(!userDB) api.get('/users/' + user.id, {
            headers: { Authorization: user.token }
        }).then(resp => {
            setUserDB(resp.data)
        }).catch(err =>{
            console.log(err)
        })
    }, [])

    // Move screen to change step
    useEffect(() => {
        messagesEndRef.current && messagesEndRef.current.scrollIntoView({ behavior: "smooth" })
        if (step===0) pageView(user.id, "Onboarding")
    }, [ready, step, points, data, retUpload])

    //Set Opportunities
    useEffect(() => {
        if (dataBill) setOpps(dataBill.opportunities.length)
    }, [dataBill])

    useEffect(() => {
        if(step===0.1) {
            if (window.confirm("Deseja encerrar sua conta? Essa ação não poderá ser desfeita")) {
                api.delete('/users/' + user.id, {
                    headers: { Authorization: user.token }
                }).then(resp => {
                    alert('Usuário deletado')
                    navigate('/login', {replace: true})
                    logout()
                }).catch(err => {
                    console.log(err)
                })
            } else {
                setStep(1)
            }
        } else if(step===2.1) {
            if (window.confirm("Deseja finalizar seu cadastro")) {
                // Update User
                api.put('/users/type_account/' + user.id, '', {
                    headers: { Authorization: user.token }
                }).then(resp => {
                    navigate('/login', {replace: true})
                    logout()
                }).catch(err => {
                    console.log(err)
                })
            } else {
                setStep(2)
            }
        }
    }, [step])

    useEffect(() => {
        if (step===4 && retUpload!==0 && opps!==null) setTimeout(() =>{
            switch (retUpload){
                case 1:  //Approved
                    setStep(5)
                    break;

                case 2:  //Error read
                    setStep(6)
                    break;

                default:
                    setStep(6)
                    break;
            }
        },
        4000)
    }, [step, retUpload, dataBill, opps])

    return <main id="onboarding">
        <div className="infos">
            <Link to="/logout" className='logout' title='Faça logout'>
                <span>Sair</span>
                <FaSignOutAlt />
            </Link>

            <img src={logo} alt="Energizou" />
        </div>
        <input
            style={{display:'none'}}
            id='file' name='file'
            type='file'
            onChange={onChange}
        ></input>

        <div className="chatbot">
            <header>
                <img src={person} alt="Foto Energizou" />
                <h2>Energizou</h2>
            </header>

            <section>
                { // If user is home
                step>0 && userDB.type_account==='home' && <Timeline
                    steps={UserHome}
                    currStep={step}
                    currQuest={1}
                    setStep={setStep}
                    points={points}
                    setPoints={setPoints}
                    setData={setData}
                    data={data}
                />
                }

                { // Initial
                step>0 && userDB.type_account==='business' && <Timeline
                    steps={Init}
                    currStep={step}
                    currQuest={1}
                    setStep={setStep}
                    points={points}
                    setPoints={setPoints}
                    setData={setData}
                    data={data}
                /> }

                { // Home
                (step===2 || step===2.2) && <Timeline
                    steps={Home}
                    currStep={step}
                    currQuest={2}
                    setStep={setStep}
                    points={points}
                    setPoints={setPoints}
                    setData={setData}
                    data={data}
                /> }


                { // Business
                (step>=3) && <Timeline
                    steps={Business}
                    currStep={step}
                    currQuest={3}
                    setStep={setStep}
                    points={points}
                    setPoints={setPoints}
                    setData={setData}
                    data={data}
                /> }

                { // Last Loading
                (step>=4) && <Timeline
                    steps={LastLoad}
                    currStep={step}
                    currQuest={4}
                    setStep={setStep}
                    points={points}
                    setPoints={setPoints}
                    data={data}
                /> }

                { // Approved
                (step===5) && <Timeline
                    steps={FinalApproved}
                    currStep={step}
                    currQuest={5}
                    setStep={setStep}
                    points={points}
                    setPoints={setPoints}
                    data={data}
                    opps={opps}
                /> }
                {  // Disapproved error read
                (step===6) && <Timeline
                    steps={FinalDisapproved}
                    currStep={step}
                    currQuest={6}
                    setStep={setStep}
                    points={points}
                    setPoints={setPoints}
                    data={data}
                    errBill={errBill}
                /> }
                {  // Help Admin to create bill
                (step===7) && <Timeline
                    steps={HelpAdmin}
                    currStep={step}
                    currQuest={7}
                    setStep={setStep}
                    points={points}
                    setPoints={setPoints}
                    data={data}
                    errBill={errBill}
                /> }

                { !ready && <div className="buttons">
                    <button className="init" type="button" onClick={() => {setReady(true); setStep(1)}}>
                        Iniciando conversa...
                    </button>
                </div>}

                <div ref={messagesEndRef} />
                { points && <Points /> }
            </section>
        </div>
    </main>
}

export default Onboarding;