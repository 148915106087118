import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import api from '~/services/api';
import { MixPanelContext, GeneralContext } from '~/contexts';
import './styles.css';

// Components
import BodyCreateAccount from './Steps/CreateAccount';
import Terms from './Steps/Terms';
import ConfirmEmail from './Steps/ConfirmMail';
import storage from '~/utils/storage';

// Initial Data
const initialState = {
    name            : '',
    cnpj            : '',
    phone           : '',
    mail            : '',
    password        : '',
    confirm_password: ''
};

const CreateUser: React.FC<any> = () => {
    const navigate                      = useNavigate();
    const {user, checkBill}             = useContext(GeneralContext);
    const [step, setStep]               = useState<number>(1);
    const [data, setData]               = useState(initialState);
    const [companyName, setCompanyName] = useState<any>();
    const [id, setId]                   = useState();

    function getStep () {
        switch (step) {
            case 1:
                return <BodyCreateAccount step={step} setStep={setStep} data={data} setData={setData} setCompanyName={setCompanyName}/>
            case 2:
                return <Terms setStep={setStep}/>
            case 3:
                return <ConfirmEmail setStep={setStep} data={data} id={id}/>
        }
    }

    function buildData() {
        if(step===3){
            let ret = {
                type    : 'user',
                name    : data.name,
                mail    : data.mail,
                phone   : data.phone,
                referral: 'Orgânico',
                active  : 0,
                password: data.password,
                company : {
                    name: companyName,
                    cnpj: data.cnpj,

                }
            }

            // Get Referral
            let referral = storage.get('referral');
            if (referral) ret.referral = referral;

            onSubmit(ret)
        }
    }

    function onSubmit (ret: any) {
        if (ret) {
            api.post('users', ret, {
                headers: { Authorization: user.token}
            })
            .then((resp: any) => {
                setId(resp.data.id)
            })
            .catch((err: any) => {
                const { status } = err.response;
                if (status===400) {
                    const error = err.response.data.errors;
                    console.log(error)
                }
            });
        }
	}

    useEffect(() => {buildData()}, [step])
    // useEffect(() => {console.log(data)}, [data])

    useEffect(() => {
        if (user.token) checkBill().then((resp: any) =>
            resp ?
            navigate('/admin', {replace: true}) :
            navigate('/onboarding', {replace: true})
        )
    }, [user]);

    return <main className='create-user'>
        {getStep()}
    </main>
}

export default CreateUser;