import api from "../api";

const Upload = {
    checkBill: async function (user: any, bill: number) {
        let ret: any = false;

        await api.get(`bills/${bill}`, {
            headers: {
                'Authorization': user.token
            }
        }).then(resp => {
            ret = resp.data
        }).catch(err => {
            ret = false
        });

        return ret;
    },

    sendFile: async function (user: any,  data: Blob) {
        let ret: any = false
        let formData = new FormData()
        formData.append('file', data)
        formData.append('company_id', user.company.id)

        await api.post('bills/file', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': user.token
            }
        }).then(resp => {
            ret = resp.data
        }).catch(err => {
            ret = false
        });

        return ret
    },

    sendFiles: async function (user: any,  data: FileList ) {
        let ret: any = true
        let formData = new FormData()

        Array.from(data).forEach(file => {formData.append('bills', file)})
        formData.append('company_id', user.company.id)

        await api.post('bills/files', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': user.token
            }
        }).then(resp => {
            resp.data.map((el: any) => ret = !el.valid ? false : el.data)
        }).catch(err => {
            ret = false
        });

        return ret
    }
}

export default Upload;