import React, { useEffect, ReactNode } from 'react';
import { createContext } from 'react';

interface MessagesContextData {
    alertClass: string;
    alert: string;
    setAlert: Function;
    uploadFile: boolean;
    setUploadFile: Function;
}

interface MessagesProviderProps {
    children: ReactNode;
}

export const MessagesContext = createContext({} as MessagesContextData)

export function MessagesProvider ({children}: MessagesProviderProps) {
    const [alert, setAlertMessage]    = React.useState('');
    const [alertClass, setAlertClass] = React.useState('');
    const [uploadFile, setUploadFile] = React.useState(false);

    function setAlert (message: string, className?: string) {
        setAlertMessage(message)
        if (className) {
            setAlertClass(className)
            setTimeout(() => setAlertClass(className + ' in'), 100);
        }
    }

    useEffect(() => {
        if (alert!=='') {
            setInterval(async () => {
                setAlertClass(alertClass.replace(' in', ''))

                await setTimeout(() => {
                    setAlertClass('')
                    setAlertMessage('')
                }, 1000)
            }, 10000)
        }
    }, [alert]);

    return (
        <MessagesContext.Provider value={{ alert, setAlert, alertClass, uploadFile, setUploadFile }}>
            {children}
        </MessagesContext.Provider>
    );
};

function sleep(time: number) {
    return new Promise(function(resolve) {
        setTimeout(resolve, time);
    });
}