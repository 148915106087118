// Images
import Clock from '../components/Clock';

const billPrice = [
    {
        type: 'text',
        class: 'resp',
        text: '#FILE#'
    },
    {
        type: 'delay',
        time: 5000
    },
    {
        type: 'text',
        text: 'Ok, agora vamos fazer o diagnóstico da sua conta de luz.'
    },
    {
        type: 'delay',
        time: 5000
    },
    {
        type: 'text',
        text: 'Esse processo pode demorar até 2 minutos.'
    },
    {
        type: 'modal',
        icon: <Clock />,
        text: 'Enviando a conta para a Judite analisar'
    },

    //LOAD
    {
        type: 'modal',
        icon: <Clock />,
        text: 'Esse processo pode demorar até 5 minutos'
    },
]

export default billPrice