import dates from '~/utils/dates';

let date   = new Date();
let name1  = dates.getNameDate(date);
let dBase1 = dates.getDateCharts(date);

date.setMonth(date.getMonth()-1)
let name2  = dates.getNameDate(date);
let dBase2 = dates.getDateCharts(date);

const datesList = [{
    id: 1,
    date: {
        name: name1,
        slug: dBase1.year + '-' + dBase1.month
    }
}, {
    id: 2,
    date: {
        name: name2,
        slug: dBase2.year + '-' + dBase2.month
    }
}]

const data: any = {
    currDate: {
        year: dBase1.year,
        month: dBase1.month,
        monthName: dBase1.monthName.full,
        day: dBase1.day
    },
    lastDate: dBase2.year + '-' + dBase2.month,
    bill: {
        id: 42,
        provider: "Cemig",
        type: "Cative",
        path: "calacalcariolagamarindcomltda/518638768ab562725e9f34af158883cf.pdf",
        reference_date: "2022-05-01T03:00:00.000Z",
        read_dates_last: "2022-04-30T03:00:00.000Z",
        read_dates_current: "2022-05-31T03:00:00.000Z",
        read_dates_next: "2022-06-30T03:00:00.000Z",
        bill_number: "079849703",
        supplied_voltage_group: "A",
        supplied_voltage_type: "A4",
        tariff_modality: "Verde",
        contracted_energy_demand: 645,
        icms: 18,
        pis: 0.75,
        cofins: 3.47,
        total_value_due: 125895.82,
        bill_items: {
            demand: {
                amount: 721,
                value: {
                    total: 13641.58,
                    icms: 2455.48
                },
                tariff: {
                    icms: 18.92039,
                    noIcms: 15.51472
                }
            },
            demandNoIcms: null,
            demandOvertaking: {
                amount: 76,
                value: {
                    total: 2875.89,
                    icms: 517.66
                },
                tariff: {
                    icms: 37.84078,
                    noIcms: 31.02944
                }
            },
            consumptionPeakHoursTUSD: {
                amount: 1050,
                value: {
                    total: 2116.38,
                    icms: 380.95
                },
                tariff: {
                    icms: 0,
                    noIcms: 0
                }
            },
            consumptionOffPeakHoursTUSD: {
                amount: 242200,
                value: {
                    total: 106896.63,
                    icms: 19241.39
                },
                tariff: {
                    icms: 0,
                    noIcms: 0
                }
            },
            consumptionPeakHoursTE: {
                amount: 1050,
                value: {
                    total: 2116.38,
                    icms: 380.95
                },
                tariff: {
                    icms: 2.01562,
                    noIcms: 1.65281
                }
            },
            consumptionOffPeakHoursTE: {
                amount: 242200,
                value: {
                    total: 106896.63,
                    icms: 19241.39
                },
                tariff: {
                    icms: 0.44136,
                    noIcms: 0.36191
                }
            },
            reactiveEnergyPeakHours: {
                amount: 350,
                value: {
                    total: 121.77,
                    icms: 21.92
                },
                tariff: {
                    icms: 0.34799,
                    noIcms: 0.28535
                }
            },
            reactiveEnergyOffPeakHours: {
                amount: 700,
                value: {
                    total: 243.57,
                    icms: 43.84
                },
                tariff: {
                    icms: 0.34799,
                    noIcms: 0.28535
                }
            },
            injectedEnergyPeakHours: null,
            injectedEnergyOffPeakHours: null,
            others: {
                multa: null,
                juros: null,
                correcao: null,
                installment: null,
                cosip: null,
                IgpmCorrection: null
            }
        },
        status: "not_rated",
        status_desc: null,
        signed: 0,
        active: 1,
        company: {
            id: 12,
            name: "G M F - Industria e Comercio de Ferragens Ltda",
            cnpj: "01.256.266/0001-62",
            signed: 0,
            plan: null,
            user: null,
            bills: null,
            errors: null
        },
        consumptions: [
            {
                id: 528,
                date: "2022-05-01T03:00:00.000Z",
                demand: 645,
                peak_hours: 1050,
                off_peak_hours: 242200,
                days: null
            },
            {
                id: 529,
                date: "2022-04-01T03:00:00.000Z",
                demand: 645,
                peak_hours: 700,
                off_peak_hours: 92050,
                days: null
            },
            {
                id: 530,
                date: "2022-03-01T03:00:00.000Z",
                demand: 645,
                peak_hours: 700,
                off_peak_hours: 113050,
                days: null
            },
            {
                id: 531,
                date: "2022-02-01T03:00:00.000Z",
                demand: 645,
                peak_hours: 700,
                off_peak_hours: 56700,
                days: null
            },
            {
                id: 532,
                date: "2022-01-01T03:00:00.000Z",
                demand: 645,
                peak_hours: 350,
                off_peak_hours: 21350,
                days: null
            },
            {
                id: 533,
                date: "2021-12-01T03:00:00.000Z",
                demand: 645,
                peak_hours: 1050,
                off_peak_hours: 70000,
                days: null
            },
            {
                id: 534,
                date: "2021-11-01T03:00:00.000Z",
                demand: 645,
                peak_hours: 1050,
                off_peak_hours: 150850,
                days: null
            },
            {
                id: 535,
                date: "2021-10-01T03:00:00.000Z",
                demand: 645,
                peak_hours: 1400,
                off_peak_hours: 210350,
                days: null
            },
            {
                id: 536,
                date: "2021-09-01T03:00:00.000Z",
                demand: 645,
                peak_hours: 1400,
                off_peak_hours: 284200,
                days: null
            },
            {
                id: 537,
                date: "2021-08-01T03:00:00.000Z",
                demand: 645,
                peak_hours: 1400,
                off_peak_hours: 309400,
                days: null
            },
            {
                id: 538,
                date: "2021-07-01T03:00:00.000Z",
                demand: 645,
                peak_hours: 1400,
                off_peak_hours: 260050,
                days: null
            },
            {
                id: 539,
                date: "2021-06-01T03:00:00.000Z",
                demand: 645,
                peak_hours: 1050,
                off_peak_hours: 158550,
                days: null
            },
            {
                id: 540,
                date: "2021-05-01T03:00:00.000Z",
                demand: 645,
                peak_hours: 700,
                off_peak_hours: 64050,
                days: null
            }
        ],
        opportunities: [
            {
                id: 139,
                economy: 40134.45,
                percentage: 31.88,
                bill: null,
                opportunity: {
                    id: 3,
                    name: "Mercado Livre de Energia",
                    which_is: "<p>Os consumidores de energia de Alta Tensão, tradicionalmente compram energia diretamente da sua distribuidora, pagando as tarifas reguladas pela ANEEL. Porém, de acordo com a leitura dos dados da sua conta de energia, você pode migrar para o Ambiente de Contratação Livre, também chamado de Mercado Livre de Energia, e escolher seu próprio fornecedor de energia.</p>"
                }
            },
            {
                id: 140,
                economy: 365.34,
                percentage: 0.29,
                bill: null,
                opportunity: {
                    id: 5,
                    name: "Multa por geração de energia reativa",
                    which_is: "<p>Alguns equipamentos, como motores e transformadores, utilizam dois tipos de energia para funcionar. A energia ativa, é a energia utilizada para executar trabalho, já a energia reativa, é o componente da energia elétrica que não realiza trabalho, mas é consumida pelos equipamentos para que eles funcionem.</p><p>No exemplo de um motor, a energia ativa é a energia responsável pelo movimento de rotação, já a energia reativa, é consumida pelos equipamentos com o objetivo de formar os campos eletromagnéticos necessários para o funcionamento.</p><p>Na conta de energia, o cliente é cobrado tanto pela energia ativa utilizada, quando pela energia reativa também. Essa segunda, se estiver presente em níveis excessivos, podem atrapalhar o sistema de distribuição da concessionária.</p><p>Nesses casos a multa por excedente de energia reativa é cobrada, gerando um custo adicional na fatura de energia.</p>"
                }
            },
            {
                id: 141,
                economy: 2875.89,
                percentage: 2.28,
                bill: null,
                opportunity: {
                    id: 6,
                    name: "Multa por ultrapassagem de demanda",
                    which_is: "<p>Ultrapassagem de demanda é uma cobrança adicional identificada na fatura de energia que ocorre quando a demanda registrada é superior a demanda contratada.</p><p>A multa por ultrapassagem de demanda é valorada pelo dobro da tarifa regular.</p><p>Um fator importante é que as empresas têm uma flexibilidade de 5% acima do volume contratado da demanda. Então para um montante de 100kW, até o uso de 105kW a unidade não teria a cobrança da multa.</p>"
                }
            }
        ],
        flagTariff: 0
    },
    dates: datesList
}

export default data