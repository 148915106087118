import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { FiTrash2, FiCheckCircle } from 'react-icons/fi';
import dateformat from 'dateformat';
import api from '~/services/api';
import { MixPanelContext, GeneralContext } from '~/contexts';
import './styles.css';

// Images
import icon from './images/icon.svg';
import ReactTooltip from 'react-tooltip';
import { BsFillPencilFill } from 'react-icons/bs';

interface Props {
    id       : number;
    provider : string;
    modality : string;
    date     : string;
    path     : string;
    admin    : boolean;
    signed   : boolean;
    byApi    : boolean;
    earq_id  : string;
    status   : string;
    delBill ?: Function | null;
    setMessages:React.Dispatch<React.SetStateAction<any>>
}

const HistoricBox: React.FC<Props> = ({id, provider, modality, date, path, admin, signed, byApi, earq_id, status, delBill, setMessages}: Props) => {
    const {user} = useContext(GeneralContext);
    const {buttonClick} = useContext(MixPanelContext);

    function downloadFile () {
        api.get(`bill/${path}`, {
            headers: { Authorization: user.token },
            responseType: 'blob'
        }).then((resp: any) => {
            let url      = window.URL.createObjectURL(new Blob([resp.data]));
            let link     = document.createElement('a');
            let fileName = `${user.company ? `Conta - ${user.company.name}` : 'Conta'}_${dateformat(date, 'mm-yyyy')} .pdf`;
            setMessages(<div className="msg">Download da fatura realizado com sucesso!</div>)
            setTimeout(() => setMessages(<></>), 3000);
            link.href = url;
            link.setAttribute("id", "download");
            link.setAttribute('download', fileName); //or any other extension
            document.body.appendChild(link);
            link.click();
            link.parentNode?.removeChild(link);
        }).catch(err => {
            console.log(err);
            setMessages(<div className="msg err">Ocorreu um erro no Download!</div>)
            setTimeout(() => setMessages(<></>), 3000);
        });
    }

    function type() {
        if (byApi || earq_id) {
            return <Link to="/admin/historico" className='download' onClick={() => {
                downloadFile();
                buttonClick(user.id, 'Histórico', 'Download Conta', path);
            }}>
                Download
            </Link>
        } else {
            return admin ? <div className='manual short'>
                Manual
            </div> : <div className='manual'>
                <sup>Cadastro</sup>
                Manual
            </div>
        }
    }

    return <div className='historicBox'>
        <div className="icon">
            <img src={icon} alt="Icon" />
        </div>

        <div className="infos">
            {
                status === 'Read' ? <>
                    <div className="provider">
                        <small>Distribuidora</small>
                        <span>{provider}</span>
                    </div>

                    <div className="modality">
                        <small>Modalidade</small>
                        <span>{modality}</span>
                    </div>

                    <div className="date">
                        <small>Data da Conta</small>
                        <span>{dateformat(date, 'mm.yyyy')}</span>
                    </div>
                </> : <div className="waiting">
                        <span><b>Aguardando Leitura</b><i>{ earq_id ? ` (${earq_id.substring(4) })` : '' }</i></span>
                </div>
            }
        </div>

        <div className="buttons">
            { type() }

            {
                signed ? (<>
                    <span className='check' data-tip="tooltip" data-for="signed">
                        <FiCheckCircle />
                    </span>

                    <ReactTooltip id="signed" effect="solid" clickable={true} delayHide={1000}>
                        Conta utilizada para assinatura do plano
                    </ReactTooltip>
                </>) : (
                    <button type="button" className='del' disabled={!delBill ? true : false} onClick={() => {
                        delBill && delBill(id);
                        buttonClick(user.id, 'Histórico', 'Deletar Conta', path);
                    }}>
                        <FiTrash2 />
                    </button>
                )
            }
        </div>
    </div>;
}

export default HistoricBox;