import logo from '~/assets/images/logo.svg';

// Tour Options
const tourOptions = {
    defaultStepOptions: {
        cancelIcon: {
            showCancelLink: false,
            enabled: false
        }
    },
    useModalOverlay: true,
    tourName: 'Relatórios'
};

// Steps
const stepsButtons = {
    init: [
        {
            classes: 'btnInit',
            text: 'Começar',
            type: 'next'
        }
    ],
    middle: [
        {
            classes: 'btnSkip',
            text: 'Pular',
            type: 'cancel'
        },
        {
            classes: 'btnNext',
            text: 'Próximo',
            type: 'next'
        }
    ],
    end: [
        {
            classes: 'btnSuccess',
            text: 'Concluir',
            type: 'complete'
        }
    ]
}

const stepsConfigsMin = {
    highlightClass: 'highlight',
    scrollTo: false,
}

const stepsConfigs = {
    modalOverlayOpeningPadding: 10,
    modalOverlayOpeningRadius: 10,
    canClickTarget: false,
    ...stepsConfigsMin
}

const steps: any = [
    {
        id: 'intro',
        buttons: stepsButtons.init,
        classes: 'stepBox initial',
        ...stepsConfigsMin,
        text: [
            `<img class="logo" src="${logo}" />
            <h3>Bem-vindo aos Relatórios!</h3>
            <p>Vamos mostrar como os recursos dessa página podem ajudá-lo a gerenciar e analisar o valor da sua conta de luz.</p>`
        ]
    },
    {
        id: 'Date Reference',
        attachTo: { element: '.referenceDate', on: 'bottom' },
        buttons: stepsButtons.middle,
        classes: 'stepBox',
        ...stepsConfigs,
        text: [
            `<p>Escolha qual fatura quer analisar selecionando o mês de refência da conta.</p>
            <p><b>IMPORTANTE:</b> Para entregar a melhor experiência em relatórios pedimos que envie pelo menos duas contas de luz.</p>
            <div class="stepCount">Passo 1 de 7</div>`
        ]
    },
    {
        id: 'Monthly Report',
        attachTo: { element: '.monthlyReport', on: 'right' },
        buttons: stepsButtons.middle,
        classes: 'stepBox',
        ...stepsConfigs,
        text: [
            `<p class="warning"><b>IMPORTANTE:</b> Para entregar a melhor experiência em relatórios pedimos que envie pelo menos duas contas de luz.</p>
            <p>Este quadro compara diferentes faturas de energia de acordo com o mês selecionado.</p>
            <p>Para utilizá-lo basta selecionar o mês de referência no topo da sua tela e, logo após, selecionar no quadro outro mês para comparação.</p>
            <p>Para entender o motivo do aumento ou decréscimo, cliem em <b>"Detalhes"</b>.</p>
            <p>💡 Nota: O aumento percentual do valor da conta será representado em <b>vermelho</b> e a redução será representada em <b>verde</b>.</p>
            <div class="stepCount">Passo 2 de 7</div>`
        ]
    },
    {
        id: 'Opportunities',
        attachTo: { element: '.oppsEcoBox', on: 'bottom' },
        buttons: stepsButtons.middle,
        classes: 'stepBox',
        ...stepsConfigs,
        text: [
            `<p>Este quadro quantas oportunidades de economia foram identificadas na conta de luz e a somatória aproximada do total de economia em reais.</p>
            <div class="stepCount">Passo 3 de 7</div>`
        ]
    },
    {
        id: 'Audit',
        attachTo: { element: '.tariffsBox', on: 'top' },
        buttons: stepsButtons.middle,
        classes: 'stepBox',
        ...stepsConfigs,
        text: [
            `<p>Esse quadro apresenta as tarifas vigentes cobradas pela sua distribuidora.</p>
            <p>O valor das tarifas vigentes é apresentado em reais por cada KWh para TE e TUSD e reais por kW para demanda.</p>
            <p class="info">Clique em "+ Saiba mais" <b>para entender melhor</b> cada uma das tarifas.</p>
            <div class="stepCount">Passo 4 de 7</div>`
        ]
    },
    {
        id: 'Charts',
        attachTo: { element: '.chartsBox', on: 'left' },
        buttons: stepsButtons.middle,
        classes: 'stepBox',
        ...stepsConfigs,
        text: [
            `<p>Este é o painel dos gráficos, ele é gerado a partir da leitura dos dados da sua conta de luz.</p>
            <p><b>IMPORTANTE:</b> Para entregar a melhor experiência em gráficos pedimos que envie pelo menos duas contas de luz.</p>
            <div class="stepCount">Passo 5 de 7</div>`
        ]
    },
    {
        id: 'ChartsMonths',
        attachTo: { element: '.chartsBox > .header', on: 'left' },
        buttons: stepsButtons.middle,
        classes: 'stepBox',
        ...stepsConfigs,
        text: [
            `<p>Este é o painel dos gráficos, aqui você pode alterar a seleção de meses do gráfico e alterar o tipo de gráfico que quer visualizar</p>
            <p><b>IMPORTANTE:</b> Para entregar a melhor experiência em gráficos pedimos que envie pelo menos duas contas de luz.</p>
            <div class="stepCount">Passo 6 de 7</div>`
        ]
    },
    {
        id: 'BoxInfos',
        attachTo: { element: '.chartsBox > .content > div > header > .hover', on: 'left' },
        buttons: stepsButtons.end,
        classes: 'stepBox',
        ...stepsConfigs,
        text: [
            `<p>Clicando nesse botão você pode visualizar mais explicações sobre os gráficos e sobre os quadros da página Relatórios</p>
            <p><b>IMPORTANTE:</b> Para entregar a melhor experiência em gráficos pedimos que envie pelo menos duas contas de luz.</p>
            <div class="stepCount">Passo 7 de 7</div>`
        ]
    },
];

export {
    tourOptions,
    steps,
}