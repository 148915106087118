const strings = {

    //Remove símbolos do cnpj
    removeMaskCNPJ: function (cnpj: string) {
        let ret = '';

        ret = cnpj.replace(/_|\.|\/|-/gmi, '').trim()

        return ret;
    },
}

export default strings;