import React, { useContext, useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { GeneralContext } from '~/contexts';
import Template from '~/pages/admin/Template';
import api from '~/services/api';
import './styles.css';

// Components
import Loading from '~/components/Default/Loading';
import OpportunitesFreeMarket from './FreeMarket';
import OpportunitesModel from './Model';

const OpportunitiesSteps: React.FC = () => {
    const params = useParams();
    const id = params.id;

    // Contexts
    const { breadcrumbs, setBreadcrumbs, user, client } = useContext(GeneralContext);

    // States
    const [ready, setReady] = useState(false);
    const [plans, setPlans] = useState<any>(null);
    const [specialCondition, setSpecialCondition] = useState<any>(null);
    const [data, setData] = useState<any>(null);
    const [amount, setAmount] = useState<any>(null);
    const [step, setStep] = useState<any>(1);
    const [searchParams] = useSearchParams();
    const [company, setCompany] = useState<any>();
    const [messages, setMessages] = useState<any>(<></>);
    function getPageName() {
        let opStep = "";
        if (data.opportunity.id === 3) {
            switch (step) {
                case 1:
                    opStep = "O que é?";
                    break;

                case 2:
                    opStep = "Por que?";
                    break;

                case 3:
                    opStep = "Como funciona os cálculos?";
                    break;

                case 4:
                    opStep = "Como implementar?";
                    break;

                case 5:
                    opStep = "Planos / Solicitação de Proposta";
                    break;

                case 6:
                    opStep = "Termo de Reserva Comercial";
                    break;

                case 7:
                    opStep = "Download do Termo de Reserva Comercial";
                    break;

                default:
                    opStep = "Passo " + step;
                    break;
            }
        } else {
            switch (step) {
                case 1:
                    opStep = "O que é?";
                    break;

                case 2:
                    opStep = "Por que?";
                    break;

                case 3:
                    opStep = "Como funcionam os cálculos";
                    break;

                case 4:
                    opStep = "Como implementar?";
                    break;

                case 5:
                    opStep = "Solicitação de Proposta";
                    break;

                default:
                    opStep = "Passo " + step;
                    break;
            }
        }

        return `Oportunidades - ${data.opportunity.name} - ${opStep}`;
    }

    useEffect(() => {
        if (!company) {
            let company_id = user.company?.id ? user.company?.id : localStorage.getItem('client')
            api.get(`companies/${Number(company_id)}`, {
                headers: { Authorization: user.token }
            }).then((resp: any) => {
                setCompany(resp.data)
                // console.log(resp.data)
            }).catch((err: any) => {
                const { status } = err.response;
                if (status === 400) {
                    console.log('Error')
                    console.log(err.response)
                }
            });
        }
    }, [])

    useEffect(() => {
        let step = searchParams.get("step")
        if (step === '5') setStep(5)
    }, [])

    async function getPlansProvider() {
        await api.get(`plans/n/${data.bill.provider}`, {
            headers: { Authorization: user.token }
        }).then((resp: any) => {
            // setTimeout(() => {
            setPlans(resp.data)
            setReady(true)
            // }, 2000);
        }).catch((err: any) => {
            const { status } = err.response;
            if (status === 400) {
                // const error = err.response.data.errors;
                // setErrors(error);
                console.log('Error')
                console.log(err.response)

                // Ready
                setTimeout(() => setReady(true), 2000);
            }
        });

    }

    async function getSpecialCondition() {
        let company_id = user.company?.id ? user.company?.id : localStorage.getItem('client')
        api.get(`special/${company_id}`, {
            headers: { Authorization: user.token }
        }).then((resp: any) => {
            setSpecialCondition(resp.data)

            // setTimeout(() => {
            // setPlans(resp.data)
            // setReady(true)
            // }, 2000);
        }).catch((err: any) => {
            const { status } = err.response;
            if (status === 400) {
                // const error = err.response.data.errors;
                // setErrors(error);
                console.log('Error')
                console.log(err.response)

                // Ready
                setTimeout(() => setReady(true), 2000);
            }
        });
    }


    // Mount
    useEffect(() => {
        ready && !breadcrumbs && setBreadcrumbs({
            curr: data.opportunity.name,
            links: [
                { name: 'Oportunidades', url: '/admin/oportunidades' }
            ]
        });

        if (data === null) {
            api.get(`bills/opp/${id}`, {
                headers: { Authorization: user.token }
            }).then((resp: any) => {
                setData(resp.data)
            }).catch((err: any) => {
                const { status } = err.response;
                if (status === 400) {
                    // const error = err.response.data.errors;
                    // setErrors(error);
                    console.log('Error')
                    console.log(err.response)

                    // Ready
                    setTimeout(() => setReady(true), 2000);
                }
            });
        }

        if (data && plans === null) {
            getPlansProvider()
            getSpecialCondition()
        }

        if (amount === null) {
            let url = user.role === 'admin' ? `bills/c/${client}` : 'bills/m';
            api.get(url, {
                headers: { Authorization: user.token }
            }).then((resp: any) => {
                let retAmount = Math.round((resp.data.bill.bill_items.consumptionPeakHoursTE.amount + resp.data.bill.bill_items.consumptionOffPeakHoursTE.amount) / 1000)
                setAmount(retAmount)
            }).catch((err: any) => {
                const { status } = err.response;
                if (status === 400) {
                    // const error = err.response.data.errors;
                    // setErrors(error);
                    console.log('Error')
                    console.log(err.response)

                }
            });
        }
    }, [ready, data, amount, user]);

    // Unmount
    useEffect(() => () => setBreadcrumbs(null), [])

    return <Template page={data ? getPageName() : undefined} pageTitle="Oportunidades" className="opp model" classMain={!ready ? 'load' : '' + (data.opportunity.id === 3 ? ' freeMarket' : '') + (step > 4 ? ` step${step}` : '')}>
        {<>
            <div className="messageInfo">
            {messages}
        </div>
            {ready ? (
                <>
                    {/* <h1>{data.opportunity.name}</h1> */}

                    {
                        data.opportunity.id === 3 ?
                            <OpportunitesFreeMarket data={data} plans={plans} step={step} setStep={setStep} company={company} amount={amount} specialCondition={specialCondition} setMessages={setMessages} /> :
                            <OpportunitesModel data={data} step={step} setStep={setStep} />
                    }
                </>
            ) : <Loading />}
        </>}
    </Template>;
}

export default OpportunitiesSteps;