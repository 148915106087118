import React from 'react';
import './styles.css'

// Images
import logo from '~/assets/images/logo.svg'
import { Link } from 'react-router-dom';

interface Props {
    title: string
    subtitle?: string
    center?:boolean
}

const HeaderCreateAccount: React.FC<Props> = props => (
    <div className='header-create-account'>
        <div>
            <Link to={'/login'}>
                <img src={logo} alt="Energizou" />
            </Link>
        </div>

        <h2 className={`${props.center? 'text-center':''}`}>
            {props.title}
        </h2>

        {props.subtitle ?
            <h3>
                {props.subtitle}
            </h3> : <></>
        }
    </div>
);

export default HeaderCreateAccount;
