import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { FaCalendar } from 'react-icons/fa';
import { GeneralContext } from '~/contexts';
import api from '~/services/api';
import './styles.css';

// Components
import Template from '~/pages/admin/Template';
import Loading from '~/components/Default/Loading';
import ItemTariff from '~/components/Boxs/Tariff/Item';

const Tariff: React.FC = () => {
    const params    = useParams();
    const yearMonth = params.yearMonth;

    // Context
    const {user, client, breadcrumbs, setBreadcrumbs} = useContext(GeneralContext);

    // States
    const [ready, setReady] = useState(false);
    const [data, setData]   = useState<any>(null);
    const [corr, setCorr]   = useState<Boolean>(true);


    // Mount
    useEffect(() => {
        if (data===null) {
            api.get(`bills/audit/tariff${user.role==='admin' ? `/c/${client}` : ''}${yearMonth ? `/${yearMonth}` : ''}`, {
                headers: { Authorization: user.token }
            }).then((resp: any) => {
                setData(resp.data)
                setReady(true)
            }).catch((err: any) => {
                const { status } = err.response;
                if (status===400) {
                    console.log('Error')
                    console.log(err.response)

                    // Ready
                    setTimeout(() => setReady(true), 2000);
                }
            });
        }
    }, [user, ready, data, yearMonth, client]);

    // Verify error
    useEffect(() => {
        {data?.tariffs?.find(function(el:any){
            if(el.corr === false)
                setCorr(false)
        })}
    }, [ready, data]);

    // Breadcrumbs
    useEffect(() => {
        !breadcrumbs && setBreadcrumbs({
            curr: 'Consumo',
            links: [
                { name: 'Dashboard', url: '/admin'},
                { name: 'Analise das Contas' }
            ]
        });
    }, [breadcrumbs]);

    // Unmount
    useEffect(() => () => setBreadcrumbs(null), [])

    return <Template page="Auditoria - Tarifas" pageTitle="Histórico" className="tariff">
        {
            ready && data ? (<>
                <h2>Últimos 30 dias</h2>

                <div className="container">
                    <div className="left">
                        {data.tariffs.map((el:any, id:any) =>(
                            <ItemTariff
                                key={id}
                                title={el.title}
                                corr={el.corr}
                                api={el.api}
                                bill ={el.bill}
                            />
                        ))
                        }

                    </div>

                    <div className="right">
                        {corr ?
                        <>
                            <div className="corr">
                            Nenhuma ação precisa ser realizada.
                            </div>
                        </>
                        :<>
                            <div className="title">
                                Detectamos que alguma tarifa está errada de acordo com a Resolução Normativa vigente definida pela Agência Nacional de Energia Elétrica, Aneel.
                            </div>
                            <div className="alert">
                                Sugerimos que você entre em contato com a sua distribuidora por meio dos canais oficiais de comunicação e solicite uma revisão dos valores da sua conta de energia.
                            </div>
                        </>}
                        <div className="date">
                            <FaCalendar /> <span>Última atualização de tarifa: <b>{data.lastUpdate}</b></span>
                        </div>
                    </div>
                </div>
            </>) : <Loading />
        }
    </Template>;
}

export default Tariff;