import React, { useContext, useEffect, useState } from 'react';
import { FaTimes } from 'react-icons/fa';
import { GeneralContext } from '~/contexts';
import api from '~/services/api';

// Components
import Template from '~/pages/admin/Template';
import LoadingSimple from '~/components/Default/Loading/Simple';
import ClientLine from '~/components/Boxs/Client';
import { FiPlus } from 'react-icons/fi';
import { Link } from 'react-router-dom';

const Clients: React.FC = () => {
    const {user}                        = useContext(GeneralContext);
    const [ready, setReady]             = useState(false);
    const [clients, setClients]         = useState<any>(null);
    const [clientsList, setClientsList] = useState<any>([]);
    const [search, setSearch]           = useState('');

    function onChangeSearch (event: any) {
        let data = event.target.value;
        let clientsFilter = clientsList.filter((el: any) =>
            el.company?.name.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").indexOf(data.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, ""))>=0 ||
            el.name.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").indexOf(data.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, ""))>=0 ||
            (el.provider && el.provider.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").indexOf(data.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, ""))>=0) ||
            (el.modality && el.modality.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").indexOf(data.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, ""))>=0)
            ? true : false
        )

        setSearch(data)
        setClients(clientsFilter);
    }

    function delUser (id: number) {
        setReady(false)
        let clientData = clients.filter((el: any) => el.id===id)[0]
        console.log(`Deletando o usuário ${clientData.name.replace(/\|/gm, ' ')}`)

        api.delete(`/users/${id}`, {headers: { Authorization: user.token }}).then(resp => {
            let clientsFilter     = clients.filter((el: any) => el.id!==id)
            let clientsListFilter = clientsList.filter((el: any) => el.id!==id)

            setClientsList(clientsListFilter)
            setClients(clientsFilter)
            setReady(true)
        })
    }

    useEffect(() => {
        if (clients===null) {
            setReady(false)

            api.get(`/users?type=full`, {headers: { Authorization: user.token }}).then(resp => {
                setTimeout(() => {
                    setClientsList(resp.data)
                    setClients(resp.data)
                    setReady(true)
                }, 1000);
            })
        }
    }, [user, ready, clients]);

    return <Template page="Administrador - Clientes" pageTitle="Gerenciamento de Clientes" className="cli">
        <div className="header">
            <div className='titleAndButton'>
                <h1>Clientes</h1>
                <Link to="new" className="box">
                    <button><FiPlus size={10}/>Cadastrar usuário</button>
                </Link>

            </div>

            <div className="searchBox">
                <input type="text" name="search" id="search" placeholder='Filtrar Clientes...' value={search} onChange={onChangeSearch} disabled={ready ? false : true} />

                { search!=='' && <button onClick={() => {
                    setSearch('')
                    setClients(clientsList)
                }}>
                    <FaTimes />
                </button> }
            </div>
        </div>

        <div className="clientsBox">
            <header>
                <div className="name">Nome</div>
                <div className="btns"></div>
            </header>

            <section>
                {
                    ready ? (
                        clients && clients.length>0 ?
                            clients.map((el: any) => <ClientLine
                                key={`clients_${el.id}`}
                                id={el.id}
                                company_id={el.company?.id}
                                photo={el.photo}
                                name={el.company ? el.company.name : el.name }
                                provider={el.provider ? el.provider : '-'}
                                modality={el.modality ? el.modality : '-'}
                                bills={el.company && el.company.bills ? true : false}
                                errors={el.company && el.company.errors ? true : false}
                                delete={delUser}
                            />) :
                            <div className='noData'>Não foi possível localizar clientes</div>
                    ) : <LoadingSimple />
                }
            </section>
        </div>
    </Template>;
}

export default Clients;