import React, { useContext, ChangeEvent } from 'react';
import ReactTooltip from 'react-tooltip';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { FiBell } from 'react-icons/fi';
import { FaUser, FaChevronLeft, FaSignOutAlt } from 'react-icons/fa';
import mobile from 'is-mobile';
import { GeneralContext, ThemeContext } from '~/contexts';
import './styles.css';

// Images
import logo from '~/assets/images/logo.svg';
import logoDark from '~/assets/images/logo_white.svg';
import userProfile from '~/assets/images/user.jpg';
import { Button } from '~/components/Forms';

const Header: React.FC = () => {
    const { breadcrumbs, user, clients, client, setClient } = useContext(GeneralContext);
    const { theme } = useContext(ThemeContext);
    const navigate  = useNavigate();
    const location  = useLocation();

    // Check if page is internal
    let checkBread = location.pathname.search(/\/admin\/([0-9])/gm)>=0 || location.pathname.search(/\/admin\/auditoria\//gm)>=0;

    function getBreads () {
        return breadcrumbs ? (<>
            <div className="links">
                {
                    breadcrumbs.links && breadcrumbs.links.length>0 && breadcrumbs.links.map((el: any) => el.url ?
                        <Link key={`bread_${el.name}`} to={el.url}>{el.name}</Link> :
                        <span key={`bread_${el.name}`}>{el.name}</span>
                    )
                }
            </div>
            <div className="curr">{breadcrumbs.curr}</div>
        </>) : ""
    }

    function getClients () {
        clients && clients.sort(function (a, b) {
            if (a.name < b.name) {
              return -1;
            }
            if (a.name > b.name) {
              return 1;
            }
            return 0;
          });
        return clients && clients.map((el: any, idx: number) => <option key={`clients_${idx}`} value={el.id}>{el.name}</option>);
    }

    function onChange (event: ChangeEvent<HTMLSelectElement>) {
        let {value} = event.currentTarget;
        setClient(value)
    }

    return <header>
        {
            mobile() && (
                checkBread ? (
                    <Button
                        type="button"
                        className="back"
                        icon={<FaChevronLeft />}
                        func={() => navigate(-1)}
                    />
                ) : <div className="blank"></div>
            )
        }

        <div className="logo">
            <Link to='/admin'>
                <img src={theme==='light' ? logo : logoDark} alt="Energizou"/>
            </Link>
        </div>

        {
            !mobile() ? (
                <div className="section">
                    <div className="breadBox">
                        {
                            checkBread && <Button
                                type="button"
                                className="back"
                                icon={<FaChevronLeft />}
                                func={() => navigate(-1)}
                            />
                        }

                        <div className="breadcrumbs">
                            { getBreads() }
                        </div>
                    </div>

                    <div className="buttonsBox">
                        {
                            user.role==='admin' && clients ? <select
                                name="clients"
                                value={client || 0}
                                onChange={onChange}
                                disabled={clients.length>1 ? false : true}
                            >
                                { getClients() }
                            </select> : <></>
                        }

                        {/* <button
                            type="button" className='theme flex items-center content-center rounded-full text-sm text-gray-600'
                            onClick={() => setTheme(theme === 'dark' ? 'light' : 'dark')}
                        >
                            { theme==='dark' ? <FiMoon /> : <FiSun /> }
                        </button> */}

                        <button type="button" className='bell'>
                            <FiBell />
                        </button>

                        <div className="foto" data-tip="tooltip" data-for="userBox">
                            <img src={userProfile} alt="Foto do Usuário" />

                            <ReactTooltip id="userBox" place="bottom" type="light" effect="solid" clickable={true} delayHide={1000}>
                                <Link to="/admin/perfil">
                                    <div className="icon">
                                        <FaUser />
                                    </div>

                                    <div className="infos">
                                        <span>Perfil - Informações do Usuário</span>
                                        <span>Pessoais, empresa e contato</span>
                                    </div>
                                </Link>
                            </ReactTooltip>
                        </div>
                    </div>
                </div>
            ) : (
                <Button
                    type="button"
                    className="logout"
                    icon={<FaSignOutAlt />}
                    func={() => navigate('/logout', {replace: true})}
                />
            )
        }
    </header>;
}

export default Header;