import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { MixPanelContext, GeneralContext } from '~/contexts';

const Logout: React.FC = () => {
    const navigate       = useNavigate()
    const {user, logout} = useContext(GeneralContext)
    const {loggedOut}    = useContext(MixPanelContext);

    useEffect(() => {
        // Logout
        logout()
        navigate('/login', {replace: true})

        // Amplitude Event
        loggedOut(user.id, user.role)
    })

    return null
}

export default Logout;