import React, { useContext, useEffect, useState } from 'react';
import path from 'path';
import dates from '~/utils/dates';
import numbers from '~/utils/numbers';

// Components
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import Step4 from './Step4';
import Plans from '../TermsAndPlans/plans';
import RequestProposal from '../TermsAndPlans/requestProposal';
import Signing from '../TermsAndPlans/signing';
import consumptionUtils from '~/utils/consumptionPlan';
import api from '~/services/api';
import { GeneralContext } from '~/contexts';
import Finished from '../TermsAndPlans/finished';

const OpportunitiesFreeMarket: React.FC<any> = ({ data, plans, step, setStep, company, amount, specialCondition, setMessages }) => {
    let planStarter = specialCondition?.type === 'plano' ? specialCondition.json.plan4 : plans.plan4;
    const { user } = useContext(GeneralContext);
    const [plan, setPlan] = useState<any>(planStarter);
    const [term, setTerm] = useState<string>('');
    const [down, setDown] = useState(false);
    const [proposalSigned, setProposalSigned] = useState<any>(null);


    async function verifyProposal() {
        await api.get(`proposal/${company.id}`, {
            headers: { Authorization: user.token }
        })
            .then((resp: any) => {
                let valid =
                resp.data.message ? setProposalSigned(false) :
                dates.verifyDateProposal(resp.data.created_at).valid

                if (valid) {
                    setProposalSigned(resp.data)
                    setTerm(resp.data.path)
                } else setProposalSigned(false)
            })
/*             .catch((err: any) => {
                setProposalSigned(false);
            }); */

    }

    async function toSign(plan_type: any) {
        setDown(true);

        let dataDB = {
            company_id: company.id,
            plan_type: plan_type,
            user_id: user.id,
            pipe: {
                organization: company.name,
                cnpj: company.cnpj,
                name: user.name,
                mail: user.mail,
                amount: amount
            },
            bill: data.bill,

        }

        await api.post('proposal', dataDB, {
            headers: { Authorization: user.token }
        })
            .then((resp: any) => {
                setTerm(resp.data.term.path)
                setDown(false)
                setStep(6)
            }).catch((err: any) => {
                const { status } = err.response;
                setMessages(<div className="msg err">Ocorreu um erro ao gerar proposta!</div>)
                setTimeout(() => setMessages(<></>), 3000);
                setDown(false)
            });
    }

    function loadStep() {
        let consumptionPH = data.bill.bill_items.consumptionPeakHoursTE.amount;
        let consumptionOffPH = data.bill.bill_items.consumptionOffPeakHoursTE.amount;
        let consTotal = consumptionPH + consumptionOffPH;

        switch (step) {
            case 1:
                return <Step1 company={company} setStep={setStep} description={_treatDescription(data.opportunity.which_is)} />

            case 2:
                return <Step2 setStep={setStep} description={_treatDescription(data.opportunity.why)} />

            case 3:
                return <Step3 setStep={setStep} description={_treatDescription(data.opportunity.calculations)} />

            case 4:
                return <Step4 company={company} consumption={consTotal} setStep={setStep} description={_treatDescription(data.opportunity.implementation)} />

            case 5:
                if (proposalSigned === null) verifyProposal()
                if (proposalSigned !== null && proposalSigned !== false) return <Finished proposalSigned={proposalSigned} download={downloadFile} down={down} />

                if (specialCondition?.type === 'plano') {
                    return <Plans plans={specialCondition.json} adequacyCosting={specialCondition.adequacy_costing} setStep={setStep} down={down} toSign={toSign} setPlan={setPlan} plan={plan} />

                } else if (specialCondition?.type === 'curva') {
                    return <RequestProposal data={data.bill} toSign={toSign} down={down} />

                } else if (consTotal <= consumptionUtils.getConsumptionLimit()) {
                    return <Plans plans={plans} adequacyCosting={0} setStep={setStep} down={down} toSign={toSign} setPlan={setPlan} plan={plan} />
                } else {
                    return <RequestProposal data={data.bill} toSign={toSign} down={down} />
                }

            case 6:
                ///Assinando////Em andamento
                return <Signing download={downloadFile} down={down} />

            case 7:
                ////Assinando////Finalizando
                return <Finished proposalSigned={proposalSigned} download={downloadFile} down={down} />

            default:
                return <Step1 company={company} setStep={setStep} description={_treatDescription(data.opportunity.which_is)} />
        }
    }

    function _treatDescription(description: string) {
        let desc = '';

        // Items
        let dateRef = dates.getDateCharts(data.bill.reference_date);
        desc = description.replace('{nome da distribuidora}', '');
        desc = desc.replace('{mês de referência}', dateRef.monthName.full + ' de ' + dateRef.year).replace('{mês selecionado}', dateRef.monthName.full + ' de ' + dateRef.year);
        desc = desc.replace('{valor de economia em R$}', numbers.money(data.economy, false));

        if (data.bill.tariff_modality === 'Azul') {
            let demPeakCon = data.bill.contracted_energy_demand.peakHours;
            let demOffPeakCon = data.bill.contracted_energy_demand.offPeakHours;
            let demPeakFat = data.bill.bill_items.demandPeak.amount;
            let demOffPeakFat = data.bill.bill_items.demandOffPeak.amount;
            let demPeakOver = data.bill.bill_items.demandOvertakingPeak ? data.bill.bill_items.demandOvertakingPeak.amount : 0;
            let demOffPeakOver = data.bill.bill_items.demandOvertakingOffPeak ? data.bill.bill_items.demandOvertakingOffPeak.amount : 0;
            let demPeakWICMS = data.bill.bill_items.demandNoIcmsPeak ? data.bill.bill_items.demandNoIcmsPeak.amount : 0;
            let demOffPeakWICMS = data.bill.bill_items.demandNoIcmsOffPeak ? data.bill.bill_items.demandNoIcmsOffPeakH.amount : 0;

            desc = desc.replace('{demanda contratada + demanda ultrapassada}', `${demPeakOver > 0 ? Math.round(demPeakCon + demPeakOver) + ' kW na ponta' + (demOffPeakCon > 0 ? ' e ' : '') : ''}${demOffPeakOver > 0 && Math.round(demOffPeakCon + demOffPeakOver) + ' kW na fora ponta'}`);
            desc = desc.replace('{demanda faturada + demanda s/ ICMS}', `${demPeakFat + demPeakWICMS} kW na ponta e ${demOffPeakFat + demOffPeakWICMS} kW na fora ponta`);
            desc = desc.replace('{demanda faturada}', `${demPeakFat} kW na ponta e ${demOffPeakFat} kW na fora ponta`);
            desc = desc.replace('{demanda s/ ICMS}', `${demPeakWICMS} kW na ponta e ${demOffPeakWICMS} kW na fora ponta`);
        } else {
            let demCon = parseFloat(data.bill.contracted_energy_demand);
            let demFat = data.bill.bill_items.demand.amount;
            let demWICMS = data.bill.bill_items.demandNoIcms ? data.bill.bill_items.demandNoIcms.amount : 0;

            desc = desc.replace('{demanda contratada + demanda ultrapassada}', (demCon + demWICMS) + ' kW');
            desc = desc.replace('{demanda faturada + demanda s/ ICMS}', (demFat + demWICMS) + ' kW');
            desc = desc.replace('{demanda faturada}', demFat + ' kW');
            desc = desc.replace('{demanda s/ ICMS}', demWICMS + ' kW');
        }

        return desc;
    }

    function downloadFile() {
        setDown(true);
        let name = path.basename(term);

        api.get(`term/${term}`, {
            headers: { Authorization: user.token },
            responseType: 'blob'
        }).then((resp: any) => {
            let url = window.URL.createObjectURL(new Blob([resp.data]));
            let link = document.createElement('a');
            let fileName = name;

            link.href = url;
            link.setAttribute("id", "download");
            link.setAttribute('download', fileName); //or any other extension
            document.body.appendChild(link);
            link.click();
            link.parentNode?.removeChild(link);
            setDown(false);
            setMessages(<div className="msg">Download da proposta realizado com sucesso!</div>)
            setTimeout(() => setMessages(<></>), 3000);
        }).catch(err => {
            console.log(err);
            setDown(false);
            setMessages(<div className="msg err">Ocorreu um erro ao fazer download da proposta!</div>)
            setTimeout(() => setMessages(<></>), 3000);
        });
    }

    useEffect(() => () => {
        if (proposalSigned === null) verifyProposal()
    }, [])

    return step < 5 ? (<>
        <div className="headLine">
            <div className="perc">
                <div className="info">
                    <span className='lab'>Até</span>
                    <span className="number">{numbers.money(data.percentage, false, true, 1)}%</span>
                </div>
                <div className="label">Percentual de economia</div>
            </div>

            <div className="eco">
                <div className="info">{numbers.money(data.economy, true, false)}</div>
                <div className="label">Economia mensal estimada</div>
            </div>
        </div>

        <div className="content">
            {loadStep()}
        </div>

        <div className="progress">
            <div className={`point${step >= 1 ? " active" : ""}`}>
                <div className="pin">{step < 1 ? "1" : ""}</div>
                <div className="text">O que é?</div>
            </div>

            <div className={`line${step > 1 ? ' active' : ''}`}></div>

            <div className={`point${step >= 2 ? " active" : ""}`}>
                <div className="pin">{step < 2 ? "2" : ""}</div>
                <div className="text">Como funciona?</div>
            </div>

            <div className={`line${step > 2 ? ' active' : ''}`}></div>

            <div className={`point${step >= 3 ? " active" : ""}`}>
                <div className="pin">{step < 3 ? "3" : ""}</div>
                <div className="text">Quem somos?</div>
            </div>

            <div className={`line${step > 3 ? ' active' : ''}`}></div>

            <div className={`point${step >= 4 ? " active" : ""}`}>
                <div className="pin">{step < 4 ? "4" : ""}</div>
                <div className="text">Como entrar?</div>
            </div>
        </div>
    </>) : loadStep();
}

export default OpportunitiesFreeMarket;