import React, { useContext, useEffect, useState } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import mobile from 'is-mobile';
import { MixPanelContext, GeneralContext } from '~/contexts';
import api from '~/services/api';
import './styles.css';

// Components
import Template from '~/pages/admin/Template';
import Loading from '~/components/Default/Loading';
import numbers from '~/utils/numbers';

import alteracaoModalidade from './images/icones-home/alteracao-modalidade-tarifaria.svg';
import demandaNaoUtilizada from './images/icones-home/demanda-nao-utilizada.svg';
import multaAtraso from './images/icones-home/multa-atraso.svg';
import energiaReativa from './images/icones-home/energia-reativa.svg';
import ultrapassagemDemanda from './images/icones-home/ultrapassagem-demanda.svg';
import icmsTusd from './images/icones-home/icms-tusd.svg';
import consumptionUtils from '~/utils/consumptionPlan';

const Opportunities: React.FC = () => {
    const params                              = useParams();
    const [yearMonth, setYearMonth]           = useState(params.yearMonth);
    const {user, client}                      = useContext(GeneralContext);
    const {buttonClick}                       = useContext(MixPanelContext);
    const [ready, setReady]                   = useState(false);
    const [data, setData]                     = useState<any>(null);
    const [dataFreeMarket, setDataFreeMarket] = useState<any>(null);
    const [consTotal, setConsTotal]           = useState<number>(0);
    const navigate                            = useNavigate();

    function changeMonth(event: any) {
        let value = event.target.value;

        if (value!=='' && value!==yearMonth) {
            setYearMonth(value)
            if (value===data.lastDate) navigate('/admin');
            else navigate(`/admin/d/${value}`);

            setReady(false)
            setData(null)
        }
    }

    function getIcon(id: Number){
        let arrIcon = [
            {'id'  : 1, 'name': alteracaoModalidade},
            {'id'  : 2, 'name': demandaNaoUtilizada},
            {'id'  : 3, 'name': icmsTusd},
            {'id'  : 4, 'name': multaAtraso},
            {'id'  : 5, 'name': energiaReativa},
            {'id'  : 6, 'name': ultrapassagemDemanda},
            {'id'  : 7, 'name': icmsTusd},
        ]
        let ret = arrIcon.filter(function(item:any) { return item.id === id });
        return ret[0].name
    }

    useEffect(() => {
        if (data===null) {
            let url = user.role==='admin' ? `bills/c/${client}` : 'bills/m';
            api.get(url + (yearMonth ? `/${yearMonth}` : ''), {
                headers: { Authorization: user.token }
            }).then((resp: any) => {
                setTimeout(() => {
                    setData(resp.data)
                    setConsTotal(resp.data.bill.bill_items.consumptionOffPeakHoursTE.amount +
                        resp.data.bill.bill_items.consumptionPeakHoursTE.amount)
                    setReady(true)
                }, 2000);
            }).catch((err: any) => {
                const { status } = err.response;
                if (status===400) {
                    // const error = err.response.data.errors;
                    // setErrors(error);
                    console.log('Error')
                    console.log(err.response)

                    // Ready
                    setTimeout(() => setReady(true), 2000);
                }
            });
        }
    }, [ready, data]);

    useEffect(() => {
        if (data) {
            let oppFreeMarketBase = data.bill.opportunities.filter((el: any) => el.opportunity.id===3);
            let oppFreeMarket     = oppFreeMarketBase.length>0 ? oppFreeMarketBase[0] : null;

            // Set Free Market Data
            if (oppFreeMarket) setDataFreeMarket(oppFreeMarket)
        }
    }, [data, ready]);

    return <Template page="Oportunidades" pageTitle="Oportunidades" className="opp">
        {
            ready ? (
                data!==null ? (
                    <>
                        <div className='header'>
                            <h1 className='text-3xl'>Oportunidades de economia</h1>

                            <div>
                                {!mobile() && <h3>Mês de referência:</h3>}
                                {
                                    data.dates.length>=1 ? (
                                        <select onChange={changeMonth} value={yearMonth ? yearMonth : data.lastDate}>
                                            { data.dates.map((el: any) => <option key={`sel_${el.id}`} value={el.date.slug}>{el.date.name}</option>) }
                                        </select>
                                    ) : <></>
                                }
                            </div>
                        </div>

                        <div id='container-ops'>
                            {
                                dataFreeMarket ?
                                    <div className='free-market'>
                                        <div className='rounded'>
                                            <h2 className='title'>Mercado livre de energia</h2>
                                            <p>Uma ótima notícia! A <b>{data?.bill?.company.name}</b> é elegível ao Mercado Livre de Energia. Veja abaixo quanto você pode economizar...</p>
                                        </div>

                                        <div className="content">
                                            <span className='title'>Possibilidade de economia</span>
                                            <div className='month'>
                                                <label>Mensal</label>
                                                <label className='value'>{numbers.money(dataFreeMarket.economy, true, true)}</label>
                                            </div>
                                            <div className='year'>
                                                <label>Anual</label>
                                                <label className='value'>{numbers.money(dataFreeMarket.economy * 12, true, true)}</label>
                                            </div>
                                        </div>

                                        <div className='rounded eco'>
                                            <span className='title'>Percentual de economia</span>
                                            <label>{dataFreeMarket.percentage}%</label>
                                        </div>

                                        <div className="links">
                                            <Link
                                                to={`${dataFreeMarket.id}`}
                                                className='explanation'
                                                onClick={() => buttonClick(user.id, 'Oportunidades', 'Ver Mercado Livre de Energia', yearMonth)}
                                            >
                                                O que é Mercado Livre de Energia?
                                            </Link>
                                            <Link
                                                to={`${dataFreeMarket.id}?step=5`}
                                                className='plans'
                                                onClick={() => buttonClick(user.id, 'Oportunidades', (!dataFreeMarket.fixed ? 'Solicitar proposta' : 'Ver planos'), yearMonth)}
                                            >{consTotal <= consumptionUtils.getConsumptionLimit() ? 'Acessar planos de energia' : 'Acessar proposta'}</Link>
                                        </div>

                                        <div className='bkg'></div>
                                    </div>
                                : <></>
                            }

                            <div className='right'>
                                <div className="rounded">
                                    <h2 className='title'>Oportunidades na sua conta</h2>
                                    <p>Além do Mercado Livre de Energia explore outros serviços para reduzir o custo da sua conta de luz.</p>
                                </div>

                                {
                                    data.bill.opportunities && data.bill.opportunities.length>0 ?
                                        <div className="contents">
                                            {
                                                dataFreeMarket &&
                                                    <div className='box-ops'>
                                                        <img src={getIcon(3)} alt="Icone da Oportunidade" />
                                                        <label>Mercado livre de energia</label>
                                                        <Link to={`/admin/${dataFreeMarket.id}`} onClick={() => buttonClick(user.id, 'Oportunidades', 'Ver mercado livre de energia', yearMonth)}>Saiba mais +</Link>
                                                    </div>
                                            }

                                            {
                                                data.bill.opportunities.map(
                                                    (el: any, index: number) => el.opportunity.id !== 3 && (
                                                        <div className='box-ops' key={`opps_${index}`}>
                                                            <img src={getIcon(el.opportunity.id)} alt="Icone da Oportunidade" />
                                                            <label>{el.opportunity.name}</label>
                                                            <Link to={`/admin/${el.id}`} onClick={() => buttonClick(user.id, 'Oportunidades', `Ver ${el.opportunity.name}`, yearMonth)}>Saiba mais +</Link>
                                                        </div>
                                                    )
                                                )
                                            }
                                        </div>
                                    : <div className='noData'>Não foi possível localizar oportunidades</div>
                                }
                            </div>
                        </div>
                    </>
                ) : <div className='noData'>Não foi possível localizar nenhuma conta</div>
            ) : <Loading />
        }
    </Template>;
}

export default Opportunities;