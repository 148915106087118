import React from 'react';
import { FaEdit, FaTrash } from 'react-icons/fa';
import './styles.css';

// Components
import { Button } from '~/components/Forms';

// Props
interface Props {
    id: string | number;
    provider: any;
    type: string;
    plan1: any;
    plan2: any;
    plan3: any;
    plan4: any;
    edit: Function;
    delete: Function;
}

const ProviderLine:React.FC<Props> = (props: Props) => {
    return <div className="planLine">
        <div className="provider">{props.provider.name}</div>
        <div className="type">{props.type}</div>
        <div className="plan"><b>Anos:</b> {props.plan1.years}&nbsp;&nbsp;<i>({props.plan1.value}%)</i></div>
        <div className="plan"><b>Anos:</b> {props.plan2.years}&nbsp;&nbsp;<i>({props.plan2.value}%)</i></div>
        <div className="plan"><b>Anos:</b> {props.plan3.years}&nbsp;&nbsp;<i>({props.plan3.value}%)</i></div>
        <div className="plan"><b>Anos:</b> {props.plan4.years}&nbsp;&nbsp;<i>({props.plan4.value}%)</i></div>

        <div className="btns">
            <Button
                type="link"
                func={() => props.edit(props)}
                className='edit'
                icon={<FaEdit />}
                text="Editar"
            />

            <Button
                type="button"
                func={() => props.delete(props.id)}
                className='del'
                icon={<FaTrash />}
                text="Excluir"
            />
        </div>
    </div>;
}

export default ProviderLine;