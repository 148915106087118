import React, { useContext, useEffect, useState } from 'react';
import ReactTooltip from 'react-tooltip';
import Lottie from 'react-lottie-player';
import { FaInfoCircle } from 'react-icons/fa';
import mobile from 'is-mobile';
import { GeneralContext, ModalContext } from '~/contexts';
import api from '~/services/api';
import './styles.css';

// Components
import { Button } from '~/components/Forms';

// Images
import loading from './images/loading.json';

const TariffBox: React.FC<any> = ({style, bill, yearMonth}: any) => {
    const {setModalOpen, setModalTitle, setModalBody} = useContext(ModalContext);
    const {user, client}    = useContext(GeneralContext);
    const [ready, setReady] = useState(false);
    const [data, setData]   = useState<any>(null);

    function openModalTariff (el:any) {
        setModalTitle(el.title)

        if (el.id===1 ) {
            setModalBody(
                <div className="modal-tariffs">
                    <span>O que isso significa?</span>
                    <p>Tarifa Energética (TE) Ponta é o valor pago por cada unidade de energia consumida (KWh). É relacionado ao custo para gerar a energia consumida no horário ponta.</p>
                    <a className='link' href='https://energizouhelp.zendesk.com/hc/pt-br/articles/9650959789325-Tarifa-de-Energia-TE-e-Tarifa-de-Uso-dos-Sistemas-de-Distribui%C3%A7%C3%A3o-TUSD-' target='_blank' rel="noreferrer">Aprenda o que é TE e TUSD</a>
                    <a className='link' href='https://energizouhelp.zendesk.com/hc/pt-br/articles/9650754439053-Consumo-Ponta-e-Consumo-Fora-Ponta' target='_blank' rel="noreferrer">Aprenda o que é Ponta e Fora Ponta</a>
                    <Button
                        type="button"
                        className="edit"
                        func={() => setModalOpen(false)}
                        text="Fechar"
                    />
                </div>
            )
        } else if (el.id===2) {
            setModalBody(
                <div className="modal-tariffs">
                    <span>O que isso significa?</span>
                    <p>Tarifa Energética (TE) Fora Ponta é o valor pago por cada unidade de energia consumida (KWh). É relacionado ao custo para gerar a energia consumida no horário fora ponta.</p>
                    <a className='link' href='https://energizouhelp.zendesk.com/hc/pt-br/articles/9650959789325-Tarifa-de-Energia-TE-e-Tarifa-de-Uso-dos-Sistemas-de-Distribui%C3%A7%C3%A3o-TUSD-' target='_blank' rel="noreferrer">Aprenda o que é TE e TUSD</a>
                    <a className='link' href='https://energizouhelp.zendesk.com/hc/pt-br/articles/9650754439053-Consumo-Ponta-e-Consumo-Fora-Ponta' target='_blank' rel="noreferrer">Aprenda o que é Ponta e Fora Ponta</a>
                    <Button
                        type="button"
                        className="edit"
                        func={() => setModalOpen(false)}
                        text="Fechar"
                    />
                </div>
            )
        } else if (el.id===3) {
            setModalBody(
                <div className="modal-tariffs">
                    <span>O que isso significa?</span>
                    <p>Tarifa de Uso dos Sistemas de Distribuição (TUSD) Ponta é o valor pago por cada unidade de energia consumida (KWh). É relacionado ao custo para transportar a energia consumida no horário ponta.</p>
                    <a className='link' href='https://energizouhelp.zendesk.com/hc/pt-br/articles/9650959789325-Tarifa-de-Energia-TE-e-Tarifa-de-Uso-dos-Sistemas-de-Distribui%C3%A7%C3%A3o-TUSD-' target='_blank' rel="noreferrer">Aprenda o que é TE e TUSD</a>
                    <a className='link' href='https://energizouhelp.zendesk.com/hc/pt-br/articles/9650754439053-Consumo-Ponta-e-Consumo-Fora-Ponta' target='_blank' rel="noreferrer">Aprenda o que é Ponta e Fora Ponta</a>
                    <Button
                        type="button"
                        className="edit"
                        func={() => setModalOpen(false)}
                        text="Fechar"
                    />
                </div>
            )
        } else if (el.id===4) {
            setModalBody(
                <div className="modal-tariffs">
                    <span>O que isso significa?</span>
                    <p>Tarifa de Uso dos Sistemas de Distribuição (TUSD) Fora Ponta é o valor pago por cada unidade de energia consumida (KWh). É relacionado ao custo para transportar a energia consumida no horário fora ponta.</p>
                    <a className='link' href='https://energizouhelp.zendesk.com/hc/pt-br/articles/9650959789325-Tarifa-de-Energia-TE-e-Tarifa-de-Uso-dos-Sistemas-de-Distribui%C3%A7%C3%A3o-TUSD-' target='_blank' rel="noreferrer">Aprenda o que é TE e TUSD</a>
                    <a className='link' href='https://energizouhelp.zendesk.com/hc/pt-br/articles/9650754439053-Consumo-Ponta-e-Consumo-Fora-Ponta' target='_blank' rel="noreferrer">Aprenda o que é Ponta e Fora Ponta</a>
                    <Button
                        type="button"
                        className="edit"
                        func={() => setModalOpen(false)}
                        text="Fechar"
                    />
                </div>
            )
        } else if (el.id===5) {
            setModalBody(
                <div className="modal-tariffs">
                    <span>O que isso significa?</span>
                    <p>Demanda é o valor pago por cada unidade de demanda contratada (kW). É relacionado ao custo para reservar a potência máxima utilizada pelo cliente.</p>
                    <a className='link' href='https://energizouhelp.zendesk.com/hc/pt-br/articles/9650123235981-Demanda-Contratada' target='_blank' rel="noreferrer">Aprenda o que é Demanda Contratada</a>
                    <Button
                        type="button"
                        className="edit"
                        func={() => setModalOpen(false)}
                        text="Fechar"
                    />
                </div>
            )
        }

        setModalOpen(true)
    }

    function modalTooltip () {

        if(mobile()){
            setModalTitle('Tarifas')
            setModalBody(<>
                <p>Esse quadro apresenta as tarifas vigentes cobradas pela sua distribuidora.</p>
                <p>O valor das tarifas vigentes é apresentado em reais por cada KWh para TE e TUSD e reais por kW para demanda.</p>
                <p className="info">Clique em "+ Saiba mais" <b>para entender melhor</b> cada uma das tarifas.</p>
            </>)
            setModalOpen(true)
        }
    }

    // Mount
    useEffect(() => {
        if (data===null) {
            api.get(`bills/audit/tariff${user.role==='admin' ? `/c/${client}` : ''}${yearMonth ? `/${yearMonth}` : ''}`, {
                headers: { Authorization: user.token }
            }).then((resp: any) => {
                setData(resp.data)
                setReady(true)
            }).catch((err: any) => {
                const { status } = err.response;
                if (status===400) {
                    console.log('Error')
                    console.log(err.response)

                    // Ready
                    setTimeout(() => setReady(true), 2000);
                }
            });
        } else if (data!=='') setTimeout(() => setReady(true), 1000);
	}, [user, client, bill, data, ready, yearMonth])

    return <div className="tariffsBox" style={style}>
        <header>
            <div className="titles">
                <h3>Tarifas vigentes</h3>
                <h4>{bill.provider}</h4>
            </div>

            <div className="hover" data-tip="tooltip" data-for="auditInfo">
                <FaInfoCircle color='#c0c0c0' onClick={() => modalTooltip()}/>
                {!mobile()?
                    <ReactTooltip id="auditInfo" className='infosBox' place="bottom" type="light" effect="solid" clickable={true} delayHide={1000}>
                        <p>Esse quadro apresenta as tarifas vigentes cobradas pela sua distribuidora.</p>
                        <p>O valor das tarifas vigentes é apresentado em reais por cada KWh para TE e TUSD e reais por kW para demanda.</p>
                        <p className="info">Clique em "+ Saiba mais" <b>para entender melhor</b> cada uma das tarifas.</p>
                    </ReactTooltip>
                :
                    <></>
                }
            </div>
        </header>

        <section className='content'>

            {
                ready && data ? data.tariffs.map(
                    (el: any, idx: number) => <div onClick={ () => openModalTariff(el)} key={`tarBox_${idx}`} className='itemTariff'>
                        <div className='content_box'>
                            <h4>{el.title}</h4>
                            <label>{el.api}</label>
                        </div>

                        <div className="click">
                            <p>+ <span>Saiba mais</span></p>
                        </div>
                    </div>
                ) : (
                    <div className='loading'>
                        <Lottie animationData={loading} loop play className="icon" />
                    </div>
                )
            }
        </section>
    </div>
}

export default TariffBox;