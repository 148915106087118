import React, { useContext, useEffect, useState } from 'react';
import { FaTimes } from 'react-icons/fa';
import { GeneralContext } from '~/contexts';
import api from '~/services/api';
import './styles.css';

// Components
import Template from '~/pages/admin/Template';
import LoadingSimple from '~/components/Default/Loading/Simple';
import BillErrorBox from '~/components/Boxs/Bills/errors';

const ListErrorsForAdminHelp: React.FC<any> = (props: any) => {
    const { user, breadcrumbs, setBreadcrumbs } = useContext(GeneralContext);
    const [ready, setReady] = useState(false);
    const [errors, setErrors] = useState<any>(null);
    const [errorsList, setErrorsList] = useState<any>([]);
    const [search, setSearch] = useState('');
    const [messages, setMessages]    = useState<any>(<></>);
    function onChangeSearch(event: any) {
        let data = event.target.value;
        let errorsFilter = errorsList.filter((el: any) =>
            el.company.name.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").indexOf(data.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")) >= 0 ||
                (el.status === 'not_rated' && 'Não Avaliado'.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").indexOf(data.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")) >= 0) ||
                (el.status === 'validated' && 'Validado'.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").indexOf(data.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")) >= 0) ||
                (el.status === 'errors' && 'Com erros'.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").indexOf(data.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")) >= 0)
                ? true : false
        )

        setSearch(data)
        setErrors(errorsFilter);
    }

    async function delBill(id: number) {
        await api.delete(`/bills/errors/${id}`, {
            headers: { Authorization: user.token }
        }).then(resp => {
            setReady(true)
            setErrors(null)
            console.log('criar popUP')
        }).catch((error) => {
            console.log('criar popUP')
        })
    }


    useEffect(() => {
        if (errors === null) {
            setReady(false)

            api.get(`/bills/all/errors`, { headers: { Authorization: user.token } }).then(resp => setTimeout(() => {
                setErrorsList(resp.data)
                setErrors(resp.data)
                setReady(true)
            }, 2000))

        }
    }, [user, ready, errors]);

    // Breadcrumbs
    useEffect(() => {
        ready && !breadcrumbs && setBreadcrumbs({
            curr: 'Contas com erros do Cliente',
            links: [
                { name: 'Lista de Clientes', url: '/admin/s/clientes' }
            ]
        });
    }, [ready])

    // Unmount
    useEffect(() => () => setBreadcrumbs(null), [])

    return <Template page="Administrador - Clientes - Contas com erros" pageTitle="Gerenciamento de Contas com erros do Cliente" className="cliBillError">
        {
            ready ? (<>
                <div className="header">
                    {/* <h1>Contas com erros do Cliente {client.name.split('|').join(' ')}</h1> */}
                    <div className="messageInfo">
                        {messages}
                    </div>
                    <div className="searchBox">
                        <input type="text" name="search" id="search" placeholder='Filtrar Contas...' value={search} onChange={onChangeSearch} disabled={ready && errors ? false : true} />

                        {search !== '' && <button onClick={() => {
                            setSearch('')
                            setErrors(errorsList)
                        }}>
                            <FaTimes />
                        </button>}
                    </div>
                </div>

                <div className="billsErrorsBox">
                    <header>
                        <div className="blank"></div>
                        <div className="client">Cliente</div>
                        <div className="date">Data da solicitação</div>
                        <div className="btns"></div>
                    </header>

                    <section>
                        {
                            errors && errors.length > 0 ?
                                errors.map((el: any) => (
                                    <BillErrorBox
                                        key={`box_${el.id}`}
                                        bill={el.id}
                                        pdf={el.pdf}
                                        companyName={el.company.name}
                                        setReady={setErrors}
                                        date={el.updated_at}
                                        delBill={delBill}
                                        createBill={el}
                                        setMessages={setMessages}
                                    />
                                )) : <div className='noData'>Não foi possível localizar contas com erros para o clientes</div>
                        }
                    </section>
                </div>
            </>) : <LoadingSimple />
        }
    </Template>;
}

export default ListErrorsForAdminHelp;