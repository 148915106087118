import React, { useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import mobile from 'is-mobile';
import { GeneralContext, MessagesContext, MixPanelContext } from '~/contexts';
import './styles.css';

// Import Components
import Header from '~/components/Default/Header';
import Menu from '~/components/Default/Menu';
import MenuMobile from '~/components/Default/Menu/mobile';
import env from '~/utils/env';

const Template: React.FC<any> = (props) => {
    const {user, logged, checkBill} = useContext(GeneralContext);
    const {alert, alertClass}       = useContext(MessagesContext);
    const {pageView}                = useContext(MixPanelContext);
    const [ready, setReady]         = useState(false);
    const navigate                  = useNavigate();

    useEffect(() => {
        if (!logged || !user.token) navigate('/login', {replace: true})
        else checkBill().then((resp: any) => !resp && navigate('/onboarding', {replace: true}))
        setReady(true)

        // MixPanel Event
        if (props.page) pageView(user.id, props.page)
    }, [props.page]);

    return (
        <>
            <Helmet>
                <title>{props.pageTitle}</title>
                <link rel="canonical" href={env.host} />
            </Helmet>

            {
                alert && alert!=='' && (
                    <div className={`messageAlert ${alertClass}`}>
                        {alert}
                    </div>
                )
            }

            <Header />

            <section className={props.className ? ' ' + props.className : ''}>
                {!mobile() && <Menu />}

                <main className={props.classMain ? props.classMain : ''}>
                    { ready ? props.children : <></> }
                </main>
            </section>

            {mobile() && <MenuMobile />}
        </>
    );
}

export default Template;