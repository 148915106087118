import React, { useContext, useEffect, useState } from 'react';
import { FaPlus, FaTimes } from 'react-icons/fa';
import { GeneralContext, ModalContext } from '~/contexts';
import api from '~/services/api';
import './styles.css';

// Components
import Template from '~/pages/admin/Template';
import LoadingSimple from '~/components/Default/Loading/Simple';
import ProviderLine from '~/components/Boxs/Provider';
import { Button } from '~/components/Forms';
import CreatePlan from './Forms/create';

const PlansProviders: React.FC = () => {
    const {setModalOpen, setModalTitle, setModalBody} = useContext(ModalContext);
    const {user}                    = useContext(GeneralContext);
    const [ready, setReady]         = useState(false);
    const [plans, setPlans]         = useState<any>(null);
    const [PlansList, setPlansList] = useState<any>([]);
    const [search, setSearch]       = useState('');

    function onChangeSearch (event: any) {
        let data = event.target.value;
        let plansFilter = PlansList.filter((el: any) =>
            el.provider.name.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")
                .indexOf(data.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, ""))>=0
            ? true : false
        )

        setSearch(data)
        setPlans(plansFilter);
    }

    function openModal (data?: any) {
        setModalTitle(data ? 'Editando plano' : 'Adicionando novo plano')
        setModalBody(<CreatePlan data={data ? data : undefined} addPlan={addPlan} />)
        setModalOpen(true)
    }

    async function addPlan (data: any) {
        let error = 0;

        if (data.provider_id===0) error++;
        if (data.provider_name==='') error++;
        if (data.year_1==='') error++;
        if (data.value_1==='') error++;
        if (data.year_2==='') error++;
        if (data.value_2==='') error++;
        if (data.year_3==='') error++;
        if (data.value_3==='') error++;
        if (data.year_4==='') error++;
        if (data.value_4==='') error++;

        if (error>0) return error;

        await api.post('/plans', data, {headers: { Authorization: user.token }}).then(resp => {
            setModalOpen(false)
            setReady(false)
            setPlansList([])
            setPlans(null)
        })

        return true
    }

    function delPlan (id: number) {
        api.delete(`/plans/${id}`, {headers: { Authorization: user.token }}).then(resp => {
            setPlansList([])
            setPlans(null)
            setReady(false)
        })
    }

    function getNamePlan(plan: string) {
        switch (plan) {
            case 'access':
                return 'Acesso';
            case 'advanced':
                return 'Avançado';
            case 'premium':
                return 'Premium';
            case 'ultra':
                return 'Ultra';
        }
    }

    useEffect(() => {
        if (plans===null) {
            setReady(false)

            api.get(`/plans`, {headers: { Authorization: user.token }}).then(resp => {
                setTimeout(() => {
                    setPlansList(resp.data)
                    setPlans(resp.data)
                    setReady(true)
                }, 1000);
            })
        }
    }, [user, ready, plans]);

    return <Template page="Administrador - Planos e Distribuidoras" pageTitle="Gerenciamento de Planos/Distribuidoras" className="pla">
        <div className="header">
            <h1>Planos / Distribuidoras</h1>

            <div className="buttonsBox">
                <div className="searchBox">
                    <input type="text" name="search" id="search" placeholder='Filtrar Planos...' value={search} onChange={onChangeSearch} disabled={ready ? false : true} />

                    { search!=='' && <button onClick={() => {
                        setSearch('')
                        setPlans(PlansList)
                    }}>
                        <FaTimes />
                    </button> }
                </div>

                <Button
                    type="button"
                    func={() => openModal()}
                    className='box save'
                    icon={<FaPlus />}
                />
            </div>
        </div>

        <div className="plansBox">
            <header>
                <div className="provider">Distribuidora</div>
                <div className="type">Tipo</div>
                <div className="plan">Plano {plans && plans[0] ? getNamePlan(plans[0].plan1.name) : ''}</div>
                <div className="plan">Plano {plans && plans[0] ? getNamePlan(plans[0].plan2.name) : ''}</div>
                <div className="plan">Plano {plans && plans[0] ? getNamePlan(plans[0].plan3.name) : ''}</div>
                <div className="plan">Plano {plans && plans[0] ? getNamePlan(plans[0].plan4.name) : ''}</div>
                <div className="btns"></div>
            </header>

            <section>
                {
                    ready ? (
                        plans && plans.length>0 ?
                            plans.map((el: any) => <ProviderLine
                                key={`plans_${el.id}`}
                                id={el.id}
                                provider={el.provider}
                                type={el.type}
                                plan1={el.plan1}
                                plan2={el.plan2}
                                plan3={el.plan3}
                                plan4={el.plan4}
                                edit={openModal}
                                delete={delPlan}
                            />) :
                            <div className='noData'>Não foi possível localizar planos/distribuidoras</div>
                    ) : <LoadingSimple />
                }
            </section>
        </div>
    </Template>;
}

export default PlansProviders;