import React from 'react';
import numbers from '~/utils/numbers';

// Components
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import Step4 from './Step4';
import dates from '~/utils/dates';

const OpportunitiesModel: React.FC<any> = ({data, step, setStep}) => {
    console.log(data)
    function loadStep () {
        switch (step) {
            case 2:
                return <Step2 setStep={setStep} description={_treatDescription(data.opportunity.why)} />

            case 3:
                return <Step3 setStep={setStep} description={_treatDescription(data.opportunity.calculations)} />

            case 4:
                return <Step4 setStep={setStep} description={_treatDescription(data.opportunity.implementation)} />

            default:
                return <Step1 idOportunity={data.opportunity.id} setStep={setStep} description={_treatDescription(data.opportunity.which_is)} />
        }
    }

    function _treatDescription (description: string) {
        let desc     = '';

        // Items
        let dateRef  = dates.getDateCharts(data.bill.reference_date);
        desc = description.replace('{nome da distribuidora}', '');
        desc = desc.replace('{mês de referência}', dateRef.monthName.full + ' de ' + dateRef.year).replace('{mês selecionado}', dateRef.monthName.full + ' de ' + dateRef.year);
        desc = desc.replace('{valor de economia em R$}', numbers.money(data.economy, false));

        if (data.bill.tariff_modality==='Azul') {
            let demPeakCon      = data.bill.contracted_energy_demand.peakHours;
            let demOffPeakCon   = data.bill.contracted_energy_demand.offPeakHours;
            let demPeakFat      = data.bill.bill_items.demandPeak.amount;
            let demOffPeakFat   = data.bill.bill_items.demandOffPeak.amount;
            let demPeakOver     = data.bill.bill_items.demandOvertakingPeak ? data.bill.bill_items.demandOvertakingPeak.amount : 0;
            let demOffPeakOver  = data.bill.bill_items.demandOvertakingOffPeak ? data.bill.bill_items.demandOvertakingOffPeak.amount : 0;
            let demPeakWICMS    = data.bill.bill_items.demandNoIcmsPeak ? data.bill.bill_items.demandNoIcmsPeak.amount : 0;
            let demOffPeakWICMS = data.bill.bill_items.demandNoIcmsOffPeak ? data.bill.bill_items.demandNoIcmsOffPeak.amount : 0;

            desc = desc.replace('{demanda contratada + demanda ultrapassada}', `${demPeakOver>0 ? Math.round(demPeakCon + demPeakOver) + ' kW na ponta' + (demOffPeakCon>0 ? ' e ' : '') : ''}${demOffPeakOver>0 && Math.round(demOffPeakCon + demOffPeakOver) + ' kW na fora ponta'}`);
            desc = desc.replace('{demanda faturada + demanda s/ ICMS}', `${demPeakFat + demPeakWICMS} kW na ponta e ${demOffPeakFat + demOffPeakWICMS} kW na fora ponta`);
            desc = desc.replace('{demanda faturada}', `${demPeakFat} kW na ponta e ${demOffPeakFat} kW na fora ponta`);
            desc = desc.replace('{demanda s/ ICMS}', `${demPeakWICMS} kW na ponta e ${demOffPeakWICMS} kW na fora ponta`);
        } else {
            let demCon   = parseFloat(data.bill.contracted_energy_demand);
            let demFat   = data.bill.bill_items.demand.amount;
            let demWICMS = data.bill.bill_items.demandNoIcms ? data.bill.bill_items.demandNoIcms.amount : 0;

            desc = desc.replace('{demanda contratada + demanda ultrapassada}', (demCon + demWICMS) + ' kW');
            desc = desc.replace('{demanda faturada + demanda s/ ICMS}', (demFat + demWICMS) + ' kW');
            desc = desc.replace('{demanda faturada}', demFat + ' kW');
            desc = desc.replace('{demanda s/ ICMS}', demWICMS + ' kW');
        }

        return desc;
    }

    return <>
        <div className="headLine">
            <div className="perc">
                <div className="info">
                    <span className='lab'>Até</span>
                    <span className="number">{data.percentage}%</span>
                </div>
                <div className="label">Percentual de economia</div>
            </div>

            <div className="eco">
                <div className="info">{numbers.money(data.economy, true, false)}</div>
                <div className="label">Economia mensal estimada</div>
            </div>
        </div>

        <div className="content">
            { loadStep() }
        </div>

        <div className="progress">
            <div className={`point${step>=1 ? " active" : ""}`}>
                <div className="pin">{step<1 ? "1" : ""}</div>
                <div className="text">O que é?</div>
            </div>

            <div className={`line${step>1 ? ' active' : ''}`}></div>

            <div className={`point${step>=2 ? " active" : ""}`}>
                <div className="pin">{step<2 ? "2" : ""}</div>
                <div className="text">Por que?</div>
            </div>

            <div className={`line${step>2 ? ' active' : ''}`}></div>

            <div className={`point${step>=3 ? " active" : ""}`}>
                <div className="pin">{step<3 ? "3" : ""}</div>
                <div className="text">Como funciona<br /> os cálculos?</div>
            </div>

            <div className={`line${step>3 ? ' active' : ''}`}></div>

            <div className={`point${step>=4 ? " active" : ""}`}>
                <div className="pin">{step<4 ? "4" : ""}</div>
                <div className="text">Como<br /> implementar?</div>
            </div>
        </div>
    </>;
}

export default OpportunitiesModel;