import axios from 'axios';
import env from '../utils/env';

const api = axios.create({
    baseURL: env.server,
    headers: {
        "Access-Control-Allow-Origin": "*"
    }
});

export const apiTariffs = axios.create({
    baseURL: env.api.host,
    headers: {
        "Access-Control-Allow-Origin": "*"
    }
});

export const local = axios.create({
    baseURL: env.host,
    headers: {
        "Access-Control-Allow-Origin": "*"
    }
});

export default api;