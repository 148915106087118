import React, { ChangeEvent, FormEvent, useContext, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { FaBan, FaSave } from 'react-icons/fa';
import { GeneralContext } from '~/contexts';
import api from '~/services/api';

// Components
import Template from '~/pages/admin/Template';
import {Checkbox, Selectbox, InputLabel, Button, InputLabelMask} from '~/components/Forms';
import strings from '~/utils/strings';

// Init Data
const initData = {
    name: '',
    mail: '',
    phone: '',
    referral: 'Vendedor',
    type: 'user',
    razao: '',
    cnpj: ''
}

const CreateClients: React.FC = () => {
    const {user, checkToken, setBreadcrumbs} = useContext(GeneralContext);
    const submitRef           = useRef<HTMLButtonElement>(null);
    const [cnpjState, setCnpjState]       = useState<any>(0);
    const [data, setData]     = useState<any>(initData);
    const [cnpjData, setCnpjData]     = useState<any>('');
    const [errors, setErrors] = useState<any>(null);
    const navigate            = useNavigate();

    function onChange(event: ChangeEvent<HTMLInputElement|HTMLButtonElement>) {
        let {name, value} = event.target;
        change(name, value);
    }

    function change(name: string, value: string,) {
        // Set Data
        setData({...data, [name]:value});
    }

    async function onCnpj(event: ChangeEvent<HTMLInputElement>) {
        const {name, value} = event.target;
        change(name, value);
        setCnpjState(0);
        let tratedCnpj = strings.removeMaskCNPJ(value)

        if (tratedCnpj.length===14) {
            // setCnpjState(-1); /******************************************************************* */
            let ret = await getCnpj(tratedCnpj)

            if(ret.valid === true) { //Caso seja um cnpj válido e não exista nenhum cnpj na nossa base
                setErrors({...errors, cnpj:''})
                setCnpjState(1);
                setCnpjData(ret.data.razaoSocial)

            } else {
                console.log(ret.message)
                document?.getElementById("cnpj")?.focus();
                setErrors({...errors, cnpj: ret.message})
                setCnpjState(2);
            }
        }
    }

    async function getCnpj(cnpj:string) {
        let ret:any = []
        await api.get('/verify/cnpj/' + cnpj).then((resp: any) => {
            ret = resp.data
        }).catch(() => {

        });
        return ret
    }

    function onSubmit (event: FormEvent<HTMLFormElement>) {
		event.preventDefault();

        let dataSave = {
            name   : data.name,
            password   : '',
            mail   : data.mail,
            phone  : data.phone,
            company: {
                name: cnpjData,
                cnpj   : data.cnpj
            },
            referral: 'Vendedor',
            type: 'user',
            active: 1,
            created_by_admin: 1
        };

        sendData(dataSave)
	}

    async function sendData (dataSave: any) {
        await api.post('/users/new', dataSave, {
            headers: { Authorization: user.token }
        }).then(resp => {
            // console.log("🚀 ~ sendData ~ resp:", resp)
            navigate(`/admin/s/clientes`);
        }).catch(async err => {
            // console.log("🚀 ~ sendData ~ err:", err)
            if (err.response.status===400) setErrors(err.response.data.errors)
            else if (err.response.status===403) {
                let status = await checkToken(user)
                if (status) submitRef.current?.click()
                else navigate(`/login`, {replace: true})
            }
        })
    }

    // Unmount
    useEffect(() => () => setBreadcrumbs(null), [])
    // useEffect(() => () => console.log(errors), [errors])

    return <Template page="Administrador - Cliente - Edição de Dados" pageTitle="Gerenciamento de Clientes" className="cliEdit">
        <div className="header">
            <h1>Novo cliente</h1>
        </div>
        <form method="POST" onSubmit={onSubmit}>
            <div className="fields">
                <div className="infos">
                    <h2 className='text-3xl'>Informações da empresa</h2>

                    <InputLabelMask
                        name='cnpj'
                        value={data.cnpj}
                        type='text'
                        mask="99.999.999/9999-99"
                        placeholder='12.000.000/0001-09'
                        labelName='CNPJ'
                        onChange={onCnpj}
                        cnpj={cnpjState}
                        error={errors && errors['company.cnpj'] && errors['company.cnpj']}
                    />

                    <InputLabel
                        name='fantasy'
                        value={cnpjData}
                        type='text'
                        labelName='Razão social'
                        disabled={true}
                        error={errors && errors['company.name'] && errors['company.name']}
                    />

                </div>

                <div className="contact">
                    <h2 className='text-3xl'>Dados de contato</h2>

                    <InputLabel
                        name='name'
                        value={data.name}
                        type='text'
                        labelName='Nome do contato'
                        onChange={onChange}
                        error={errors && errors['name'] && errors['name']}
                    />

                    <InputLabel
                        name='mail'
                        value={data.mail}
                        type='mail'
                        labelName='E-mail do contato'
                        onChange={onChange}
                        error={errors && errors['mail'] && errors['mail']}
                    />

                    <InputLabelMask
                        name='phone'
                        value={data.phone}
                        type='string'
                        labelName='Telefone'
                        onChange={onChange}
                        placeholder='(00) 00000-0000'
                        mask={'(99) 99999-9999'}
                        error={errors && errors['phone'] && errors['phone']}
                    />
                </div>
            </div>

            <div className="btns">
                <Button
                    type="link"
                    link="/admin/s/clientes"
                    className='cancel'
                    icon={<FaBan />}
                    text="Cancelar"
                />
                <Button
                    type="submit"
                    className='save'
                    icon={<FaSave />}
                    text="Salvar Alterações"
                    refButton={submitRef}
                />
            </div>
        </form>
    </Template>;
}

export default CreateClients;