import dates from "~/utils/dates";
import numbers from "~/utils/numbers";

const Funcs = {
    renderYAxisLabel: ({ payload, x, y, width, height }: any) => {
		return <text orientation="left" width={width} height={height} x={x} y={y} stroke="none" fill="#555" fontSize="12" textAnchor="end">
			<tspan x={x-3} dy={`${(x/2)/100}em`}>
				{payload.value ? numbers.money(payload.value, false, false) : 0}
			</tspan>
		</text>
	},

    renderYAxisLabelCurr: ({ payload, x, y, width, height }: any, prefix?: boolean, digits?: boolean, digitsQtty?: number) => {
		prefix     = prefix=== undefined ? true : prefix;
		digits     = digits=== undefined ? true : digits;
		digitsQtty = digitsQtty=== undefined ? 2 : digitsQtty;

		return <text orientation="left" width={width} height={height} x={x} y={y} stroke="none" fill="#555" fontSize="12" textAnchor="end">
			<tspan x={x-3} dy={`${(x/2)/100}em`}>
				{numbers.money(payload.value, prefix, digits, digitsQtty)}
			</tspan>
		</text>
	},

	renderXAxisLabel: ({ payload, x, y }: any) => {
		let info = payload.value.split('-');
		return <text x={x} y={y} fill="#888" textAnchor="middle" fontSize="11">
			<tspan x={x} dy="0.9em">{dates.getShortNameMonth(info[1]) + '/' + info[0].slice(2)}</tspan>
		</text>;
	}
}

export default Funcs;