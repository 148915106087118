const ValidationFields = {
    fields: function (data: any) {
        let ret: any       = {};
        let billItems: any = {};

        // Infos
        if (data.provider==="") ret.provider = "Informe a distribuidora";
        if (data.total_value_due==="" || data.total_value_due===0) ret.total_value_due = "Informe o total da conta";
        if (data.reference_date==="") ret.reference_date = "Informe a data de referência";
        if (data.tax_liquot.icms==="" || data.tax_liquot.icms===0) ret.tax_liquot_icms = "Informe o percentual do ICMS";
        if (data.tax_liquot.pis==="" || data.tax_liquot.pis===0) ret.tax_liquot_pis = "Informe o percentual do PIS";
        if (data.tax_liquot.cofins==="" || data.tax_liquot.cofins===0) ret.tax_liquot_cofins = "Informe o percentual do COFINS";

        // Bill Items
        if (data.tariff_modality==="Verde") {
            let demand = this._checkBillItems(data.bill_items.demand);
            if (demand) billItems = {...billItems, demand};
            if (data.contracted_energy_demand==="" || data.contracted_energy_demand===0) ret.contracted_energy_demand = "Informe a demanda contratada";
        }
        else {
            let demandPeak    = this._checkBillItems(data.bill_items.demandPeak);
            let demandOffPeak = this._checkBillItems(data.bill_items.demandOffPeak);

            if (demandPeak) billItems    = {...billItems, demandPeak};
            if (demandOffPeak) billItems = {...billItems, demandOffPeak};

            if (!data.contracted_energy_demand_off_peak || data.contracted_energy_demand_off_peak==="" || data.contracted_energy_demand_off_peak===0) ret.contracted_energy_demand_off_peak = "Informe a demanda contratada fora ponta";
            if (!data.contracted_energy_demand_peak || data.contracted_energy_demand_peak==="" || data.contracted_energy_demand_peak===0) ret.contracted_energy_demand_peak                 = "Informe a demanda contratada ponta";

        }

        let consumptionPeakHoursTUSD    = this._checkBillItems(data.bill_items.consumptionPeakHoursTUSD);
        let consumptionOffPeakHoursTUSD = this._checkBillItems(data.bill_items.consumptionOffPeakHoursTUSD);

        if (consumptionPeakHoursTUSD) billItems    = {...billItems, consumptionPeakHoursTUSD};
        if (consumptionOffPeakHoursTUSD) billItems = {...billItems, consumptionOffPeakHoursTUSD};

        if (Object.keys(billItems).length>0) ret.bill_items = billItems;

        return Object.keys(ret).length===0 ? null : ret;
    },

    _checkBillItems: function (data: any) {
        let ret: any = {};

        if (data.amount==="" || data.amount===0) ret.amount = "Informar a quantidade consumida";
        // if (data.value.total==="" || data.value.total===0) ret.total = "Informar o valor total";

        return Object.keys(ret).length===0 ? null : ret;
    }
}

export default ValidationFields;