const data: any = {
    from: {
        reference_date: "11/2022",
        demand: 13065.926829268292,
        demand_amount: 721,
        demand_no_icms: 0,
        demand_no_icms_amount: 0,
        consumption_peak_hours: 2027.0890243902438,
        consumption_peak_hours_amount: 1050,
        consumption_off_peak_hours: 102385.61951219512,
        consumption_off_peak_hours_amount: 242200,
        tariff_flag: 1263.7134146341461,
        other_costs: 7153.471219512212,
        total_value: 125895.82,
        tariffs: {
            flag: 0.5195121951219511,
            demand: 14.86,
            consumption: 1.58306,
            consumptionOffTip: 0.34664
        }
    },
    to: {
        reference_date: "10/2022",
        demand: 10601.341463414634,
        demand_amount: 585,
        demand_no_icms: 891.5999999999999,
        demand_no_icms_amount: 60,
        consumption_peak_hours: 675.6963414634146,
        consumption_peak_hours_amount: 350,
        consumption_off_peak_hours: 20122.02926829268,
        consumption_off_peak_hours_amount: 47600,
        tariff_flag: 8303.536585365851,
        other_costs: 21873.276341463425,
        total_value: 62467.48,
        tariffs: {
            flag: 17.317073170731707,
            demand: 14.86,
            consumption: 1.58306,
            consumptionOffTip: 0.34664
        }
    }
}

export default data