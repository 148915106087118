import { createContext, ReactNode, useEffect, useState } from "react";
import mixpanel, { Mixpanel } from 'mixpanel-browser';
import env from "~/utils/env";

interface MixPanelContextData {
    onboarding : Function;
    logged     : Function;
    loggedOut  : Function;
    pageView   : Function;
    buttonClick: Function;
    error      : Function;
}

interface MixPanelProviderProps {
    children: ReactNode;
}

export const MixPanelContext = createContext({} as MixPanelContextData)

export function MixPanelProvider ({ children }: MixPanelProviderProps) {
    const [ready, setReady] = useState(false);

    async function onboarding (user_id: string | number, status: string, company_type: string) {
        if (ready) {
            // Update User Info
            await MixService.updateUser(user_id, {type: (company_type==='home' ? 'Home' : 'Enterprise')})

            // Update Super Props
            let noeb = await MixService.getProperty("number_of_electric_bills");
            if (typeof noeb==='number' && status!=='Fail') await MixService.updateSuperProps({number_of_electric_bills: noeb+1});

            // Send Event
            await MixService.sendEvent(
                user_id,
                'Engagement',
                status==='Fail' ? 'Onboarding Failed' : 'Onboarding Completed',
                status==='Fail' ? 'When the read of the electric bill failed' : 'When the user successfully completes the onboarding process and goes to the wizard',
            )
        }
    }

    async function logged (user: any) {
        ready && await MixService.sendEvent(
            user.id,
            'General',
            'Logged In',
            'When the user logs into their account',
            {accountType: user.role}
        );
    }

    async function loggedOut (user_id: string | number, accountType: string) {
        if (ready) {
            await MixService.sendEvent(
                user_id,
                'General',
                'Logged Out',
                'When the user logs out of their account',
                {accountType}
            );

            // Reset MixPanel
            mixpanel.reset();
        }
    }

    async function pageView (user_id: string | number, name: string) {
        ready && await MixService.sendEvent(
            user_id,
            'Engagement',
            'Page Viewed',
            'Any page is viewed',
            {
                page: name,
                variant: 'A/B'
            }
        )
    }

    async function buttonClick (user_id: string | number | null, page: string, button: string, others?: string) {
        ready && await MixService.sendEvent(
            user_id,
            'Engagement',
            'Button clicked',
            'When a button is clicked',
            {
                button_page: page,
                button_name: button,
                variant: 'A/B',
                others: others ? others : undefined
            }
        );
    }

    async function error (user_id: string | number, page: string, error: string) {
        ready && await MixService.sendEvent(
            user_id,
            'General',
            'Error Encountered',
            'When the user encounters an error anywhere in the product',
            {
                page,
                error,
            }
        );
    }

    useEffect(() => {
        if (!ready) MixService.init(setReady, true)
    }, [ready]);

    return (
        <MixPanelContext.Provider value={{
            onboarding,
            logged,
            loggedOut,
            pageView,
            buttonClick,
            error,
        }}>
            { children }
        </MixPanelContext.Provider>
    )
}

// MixPanel Services
interface MixServiceInt {
    mix             : Mixpanel | null;
    init            : Function;
    updateUser      : Function;
    identify        : Function;
    sendEvent       : Function;
    updateSuperProps: Function;
    getProperty     : Function;
}

const MixService: MixServiceInt = {
    mix: null,

    init: async function (setReady: Function, debug?: boolean) {
        let mix: Mixpanel | null = null;

        env.mixPanel!=='' && await mixpanel.init(env.mixPanel, {
            debug: (debug ? debug : false),
            loaded: resp => {
                mix = resp;
                setReady(true);
            }
        });

        this.mix = mix;
    },

    identify: async function (user: string | number) {
        this.mix && await this.mix.identify(`user_${user}`);
    },

    updateUser: async function (user: string | number, props: any) {
        this.identify(user);

        let data = {
            $name: props.user_name,
            $email: props.user_mail,
            $phone: props.user_phone,
            company_name: props.company_name,
            company_cnpj: props.company_cnpj
        };

        this.mix && await this.mix.people.set(data);
    },

    sendEvent: async function (user: string | number | null, category: string, event: string, description: string, props: any) {
        let userID = user!==null ? `user_${user}` : undefined;

        this.mix && await this.mix.track(event, {
            distinct_id: userID,
            category,
            description,
            ...props
        });
    },

    updateSuperProps: async function (props: any) {
        this.mix && await this.mix.register(props)
    },

    getProperty: async function (prop: string) {
        let data = await mixpanel.get_property(prop);
        return typeof data==='number' ? data : null;
    }
}