import React, { useContext, useEffect, useState } from 'react';
import { FaTimes } from 'react-icons/fa';
import api from '~/services/api';
import './styles.css';

// Components
import Template from '~/pages/admin/Template';
import Loading from '~/components/Default/Loading';
import ProposalBox from '~/components/Boxs/Proposal';
import {Checkbox} from '~/components/Forms';

// Context
import { GeneralContext, ModalContext } from '~/contexts';

const Historic: React.FC = () => {
    const {user}                          = useContext(GeneralContext);
    const [ready, setReady]               = useState(false);
    const [proposals, setProposals]       = useState<any>(null);
    const [proposalList, setProposalList] = useState<any>([]);
    const [search, setSearch]             = useState('');
    const [messages, setMessages]    = useState<any>(<></>);

    function onChangeSearch (event: any) {
        let data = event.target.value;
        let proposalsFilter = proposalList.filter((el: any) =>
            el.company.name.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").indexOf(data.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, ""))>=0 ||
            (el.plan_type && el.plan_type.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").indexOf(data.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, ""))>=0)
            ? true : false
        )
        setSearch(data)
        setProposals(proposalsFilter);
    }

    function onChangeActives (event: any) {
        if (event.target.value!=="all") {
            const dateCurr = new Date();
            let proposalsFilter = proposalList.filter((el: any) => {
                if (event.target.value==='actives')
                    return el.signed==='0' ? true : false
                else
                    return el.signed==='-1' ? true : false
            })

            setProposals(proposalsFilter);
        } else {
            setProposals(proposalList);
        }
    }

    // Get Data
    useEffect(() => {
        if (ready===false || proposalList===null) {
            setReady(false)

            api.get('/proposal', {
                headers: { Authorization: user.token }
            }).then(resp => {
                setTimeout(() => {
                    setProposalList(resp.data)
                    setProposals(resp.data)
                    setReady(true)
                }, 1000)
            })
        }
    }, [user, proposals, ready])

    return <Template page="Propostas Comerciais" pageTitle="Propostas" className="prop">
          {
              ready ? ( <>
               <div className="messageInfoP">
                         {messages}
                      </div>
                <div className="header">
                    <div className='titleAndButton'>
                        <h1>Propostas Comerciais</h1>
                    </div>

                    <div className="searchBox">
                        <select id="actives" name="actives" onChange={onChangeActives}>
                            <option value="all">Todas</option>
                            <option value="actives">Ativas</option>
                            <option value="inactives">Inativas</option>
                        </select>

                        <input type="text" name="search" id="search" placeholder='Filtrar Propostas...' value={search} onChange={onChangeSearch} disabled={ready ? false : true} />

                        { search!=='' && <button onClick={() => {
                            setSearch('')
                            setProposals(proposalList)
                        }}>
                            <FaTimes />
                        </button> }
                    </div>
                </div>

                { proposals && proposals.length>0 ?
                    proposals.map((el: any) => <ProposalBox
                        key={`box_${el.id}`}
                        client={el.company.name}
                        idProposal={el.id}
                        signed={el.signed}
                        type={el.plan_type.indexOf("{")>=0 ? JSON.parse(el.plan_type) : el.plan_type}
                        date={el.created_at}
                        path={el.path}
                        pathPpt={el.path_ppt}
                        setMessages={setMessages}
                        setReady={setReady}

                    />)
                    :
                    <div className='noData'>Não foi possível localizar propostas</div>
                }
                </>
            ) : <Loading />
        }
        
    </Template>;
}

export default Historic;