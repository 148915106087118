import React, { ChangeEvent, useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { GeneralContext } from '~/contexts';
import api from '~/services/api';
import dates from '~/utils/dates';
import './styles.css';

// Components
import Template from '~/pages/admin/Template';
import LoadingSimple from '~/components/Default/Loading/Simple';
import numbers from '~/utils/numbers';
import { Button, Selectbox } from '~/components/Forms';
import { FaBan, FaSave } from 'react-icons/fa';

const ClientsBillsCheck: React.FC<any> = (props: any) => {
    let params = useParams();
    let id     = params.id;
    let idBill = params.bill;

    // States
    const {user, breadcrumbs, setBreadcrumbs} = useContext(GeneralContext);
    const [ready, setReady]                   = useState(false);
    const [data, setData]                     = useState<any>({status: 'not_rated', description: ''});
    const [bill, setBill]                     = useState<any>(null);
    const navigate                            = useNavigate();

    function getTitleDate () {
        let dateDB = dates.getDateCharts(bill.date);
        return dateDB.monthName.full + ' de ' + dateDB.year;
    }

    function getType (type: string) {
        switch (type) {
            case 'Free Market':
                return 'Mercado Livre';

            default:
                return 'Cativo';
        }
    }

    function onChange(event: ChangeEvent<HTMLTextAreaElement>) {
        let {name, value, type} = event.target;
        change(name, value, type);
    }

    function change (name: string, value: string, type?: string) {
        // Treat Data
        let split    = name.split('.');
        let newData  = JSON.parse(JSON.stringify(data));
        if (split.length>1) newData[split[0]][split[1]] = value;
        else newData[name] = value;

        // Set Data
        setData({...data, ...newData});
    }

    async function saveData () {
        let dataSave = {
            id         : bill.id,
            status     : data.status,
            status_desc: data.status==='errors' ? data.description : null
        };

        await api.put('/bills/check', dataSave, {
            headers: { Authorization: user.token }
        }).then(resp => {
            navigate(`/admin/s/clientes/${id}/bills`);
        }).catch(async err => {
            // if (err.response.status===400) setErrors(err.response.data.errors)
            // else if (err.response.status===403) {
            //     let status = await checkToken(user)
            //     if (status) submitRef.current?.click()
            //     else navigate(`/login`, {replace: true})
            // }

            console.log('Errors')
            console.log(err.response)
        })
	}

    useEffect(() => {
        ready && !breadcrumbs && setBreadcrumbs({
            curr: 'Edição de Cliente',
            links: [
                { name: 'Lista de Clientes', url: '/admin/s/clientes'},
                { name: `Contas do Cliente ${props.client.name.split('|').join(' ')}`, url: `/admin/s/clientes/${id}/bills`}
            ]
        });

        if (bill===null) {
            setReady(false)

            api.get(`/bills/${idBill}`, {headers: { Authorization: user.token }}).then(resp => {
                setTimeout(() => {
                    setBill(resp.data)
                    setData({status: (resp.data.status==='not_rated' ? 'validated' : resp.data.status), description: resp.data.status_desc})
                    setReady(true)
                }, 1000);
            })
        }
    }, [idBill, user, ready, bill]);

    // Unmount
    useEffect(() => () => setBreadcrumbs(null), [])

    return <Template page="Administrador - Clientes - Análise de Conta" pageTitle="Analise da Conta do Cliente" className="cliBillCheck">
        {
            ready ? (
                <>
                    <div className="header">
                        <h1>Validação de Conta do Cliente {props.client.name.split('|').join(' ')}</h1>
                        <h3><b>Mês de Ref.:</b> {getTitleDate()}</h3>
                    </div>

                    <div className="billBox">
                        <section>
                            <h3>Dados Gerais</h3>

                            <div className="line">
                                <div>
                                    <label>Distribuidora</label>
                                    {bill.provider}
                                </div>

                                <div>
                                    <label>Tipo de Cliente</label>
                                    {getType(bill.type)}
                                </div>

                                <div>
                                    <label>Número da Nota</label>
                                    {bill.bill_number}
                                </div>

                                <div>
                                    <label>Preço da Nota</label>
                                    {numbers.money(bill.total_value_due)}
                                </div>
                            </div>

                            <div className="line">
                                <div>
                                    <label>Empresa</label>
                                    {bill.company.name}
                                </div>

                                <div>
                                    <label>Nome Fantasia</label>
                                    {bill.company.fantasy}
                                </div>

                                <div>
                                    <label>CNPJ</label>
                                    {bill.company.cnpj}
                                </div>

                                <div></div>
                            </div>

                            <div className="line">
                                <div>
                                    <label>Referência</label>
                                    {dates.getNameDate(bill.reference_date)}
                                </div>

                                <div>
                                    <label>Última Medição</label>
                                    {dates.getFormatDate(bill.read_dates_last, 'dd/mm/yyyy')}
                                </div>

                                <div>
                                    <label>Medição Atual</label>
                                    {dates.getFormatDate(bill.read_dates_current, 'dd/mm/yyyy')}
                                </div>

                                <div>
                                    <label>Próxima Medição</label>
                                    {dates.getFormatDate(bill.read_dates_next, 'dd/mm/yyyy')}
                                </div>
                            </div>

                            <div className="line">
                                <div>
                                    <label>Grupo</label>
                                    {bill.supplied_voltage_group}
                                </div>

                                <div>
                                    <label>Tipo</label>
                                    {bill.supplied_voltage_type}
                                </div>

                                <div>
                                    <label>Modalidade</label>
                                    {bill.tariff_modality}
                                </div>

                                <div>
                                    <label>Demanda Contratada</label>
                                    {bill.contracted_energy_demand}
                                </div>
                            </div>

                            <div className="line">
                                <div>
                                    <label>ICMS</label>
                                    {numbers.money(bill.icms, false)}%
                                </div>

                                <div>
                                    <label>PIS</label>
                                    {numbers.money(bill.pis, false)}%
                                </div>

                                <div>
                                    <label>COFINS</label>
                                    {numbers.money(bill.cofins, false)}%
                                </div>

                                <div>
                                    <label>PIS/COFINS</label>
                                    {numbers.money(bill.pis+bill.cofins, false)}%
                                </div>
                            </div>

                            <h3>Consumo</h3>
                            <div className="consumptions">
                                <div className="column">
                                    {
                                        bill.consumptions.slice(0, 3).map((el: any, idx: number) => <div key={`colMonth1_${idx}`} className="month">
                                            <h4>{dates.getNameDate(el.date)}</h4>

                                            <div className="infos">
                                                <div className="demand">
                                                    <b>Demanda:</b> {numbers.money(el.demand, false)}
                                                </div>

                                                <div className="peak_hours">
                                                    <b>Ponta:</b> {numbers.money(el.peak_hours, false)}
                                                </div>

                                                <div className="off_peak_hours">
                                                    <b>Fora Ponta:</b> {numbers.money(el.off_peak_hours, false)}
                                                </div>
                                            </div>
                                        </div>)
                                    }
                                </div>

                                <div className="column">
                                    {
                                        bill.consumptions.slice(3, 6).map((el: any, idx: number) => <div key={`colMonth2_${idx}`} className="month">
                                            <h4>{dates.getNameDate(el.date)}</h4>

                                            <div className="infos">
                                                <div className="demand">
                                                    <b>Demanda:</b> {numbers.money(el.demand, false)}
                                                </div>

                                                <div className="peak_hours">
                                                    <b>Ponta:</b> {numbers.money(el.peak_hours, false)}
                                                </div>

                                                <div className="off_peak_hours">
                                                    <b>Fora Ponta:</b> {numbers.money(el.off_peak_hours, false)}
                                                </div>
                                            </div>
                                        </div>)
                                    }
                                </div>

                                <div className="column">
                                    {
                                        bill.consumptions.slice(6, 9).map((el: any, idx: number) => <div key={`colMonth3_${idx}`} className="month">
                                            <h4>{dates.getNameDate(el.date)}</h4>

                                            <div className="infos">
                                                <div className="demand">
                                                    <b>Demanda:</b> {numbers.money(el.demand, false)}
                                                </div>

                                                <div className="peak_hours">
                                                    <b>Ponta:</b> {numbers.money(el.peak_hours, false)}
                                                </div>

                                                <div className="off_peak_hours">
                                                    <b>Fora Ponta:</b> {numbers.money(el.off_peak_hours, false)}
                                                </div>
                                            </div>
                                        </div>)
                                    }
                                </div>

                                <div className="column">
                                    {
                                        bill.consumptions.slice(9, 12).map((el: any, idx: number) => <div key={`colMonth4_${idx}`} className="month">
                                            <h4>{dates.getNameDate(el.date)}</h4>

                                            <div className="infos">
                                                <div className="demand">
                                                    <b>Demanda:</b> {numbers.money(el.demand, false)}
                                                </div>

                                                <div className="peak_hours">
                                                    <b>Ponta:</b> {numbers.money(el.peak_hours, false)}
                                                </div>

                                                <div className="off_peak_hours">
                                                    <b>Fora Ponta:</b> {numbers.money(el.off_peak_hours, false)}
                                                </div>
                                            </div>
                                        </div>)
                                    }
                                </div>
                            </div>

                            <h3>Outros Valores</h3>

                            <div className="othersLine">
                                <div className="demand">
                                    <h4>Demanda</h4>

                                    <div className='infos'>
                                        <div><b>Quantidade:</b> {bill.bill_items.demand ? numbers.money(bill.bill_items.demand.amount, false) : '-'}</div>
                                        <div><b>Valor:</b> {bill.bill_items.demand ? numbers.money(bill.bill_items.demand.value.total) : '-'}</div>
                                        <div><b>Tarifa:</b> {bill.bill_items.demand ? numbers.money(bill.bill_items.demand.tariff.icms, false, true, 4) : '-'}</div>
                                    </div>
                                </div>

                                <div className="demandICMS">
                                    <h4>Demanda S/ ICMS</h4>

                                    <div className="infos">
                                        <div><b>Quantidade:</b> {bill.bill_items.demandNoIcms ? numbers.money(bill.bill_items.demandNoIcms.amount, false) : '-'}</div>
                                        <div><b>Valor:</b> {bill.bill_items.demandNoIcms ? numbers.money(bill.bill_items.demandNoIcms.value.total) : '-'}</div>
                                        <div><b>Tarifa:</b> {bill.bill_items.demandNoIcms ? numbers.money(bill.bill_items.demandNoIcms.tariff.icms, false, true, 4) : '-'}</div>
                                    </div>
                                </div>

                                <div className="demandOvertaking">
                                    <h4>Ultrapassagem de Demanda</h4>

                                    <div className="infos">
                                        <div><b>Quantidade:</b> {bill.bill_items.demandOvertaking ? numbers.money(bill.bill_items.demandOvertaking.amount, false) : '-'}</div>
                                        <div><b>Valor:</b> {bill.bill_items.demandOvertaking ? numbers.money(bill.bill_items.demandOvertaking.value.total) : '-'}</div>
                                        <div><b>Tarifa:</b> {bill.bill_items.demandOvertaking ? numbers.money(bill.bill_items.demandOvertaking.tariff.icms, false, true, 4) : '-'}</div>
                                    </div>
                                </div>

                                <div className="others">
                                    <h4>Outros</h4>

                                    <div className="infos">
                                        <div><b>Juros:</b> {bill.bill_items.others.juros ? numbers.money(bill.bill_items.others.juros) : 'R$ 0,00'}</div>
                                        <div><b>Multas:</b> {bill.bill_items.others.multas ? numbers.money(bill.bill_items.others.multas) : 'R$ 0,00'}</div>
                                        <div><b>Correção:</b> {bill.bill_items.others.correcao ? numbers.money(bill.bill_items.others.correcao) : 'R$ 0,00'}</div>
                                    </div>
                                </div>
                            </div>

                            <div className="othersLine">
                                <div className="consumptionPeakHoursTE">
                                    <h4>Consumo Ponta TE</h4>

                                    <div className='infos'>
                                        <div><b>Quantidade:</b> {bill.bill_items.consumptionPeakHoursTE ? numbers.money(bill.bill_items.consumptionPeakHoursTE.amount, false) : '-'}</div>
                                        <div><b>Valor:</b> {bill.bill_items.consumptionPeakHoursTE ? numbers.money(bill.bill_items.consumptionPeakHoursTE.value.total) : '-'}</div>
                                        <div><b>Tarifa:</b> {bill.bill_items.consumptionPeakHoursTE ? numbers.money(bill.bill_items.consumptionPeakHoursTE.tariff.icms, false, true, 4) : '-'}</div>
                                    </div>
                                </div>

                                <div className="consumptionOffPeakHoursTE">
                                    <h4>Consumo Fora Ponta TE</h4>

                                    <div className="infos">
                                        <div><b>Quantidade:</b> {bill.bill_items.consumptionOffPeakHoursTE ? numbers.money(bill.bill_items.consumptionOffPeakHoursTE.amount, false) : '-'}</div>
                                        <div><b>Valor:</b> {bill.bill_items.consumptionOffPeakHoursTE ? numbers.money(bill.bill_items.consumptionOffPeakHoursTE.value.total) : '-'}</div>
                                        <div><b>Tarifa:</b> {bill.bill_items.consumptionOffPeakHoursTE ? numbers.money(bill.bill_items.consumptionOffPeakHoursTE.tariff.icms, false, true, 4) : '-'}</div>
                                    </div>
                                </div>

                                <div className="consumptionPeakHoursTUSD">
                                    <h4>Consumo Ponta TUSD</h4>

                                    <div className="infos">
                                        <div><b>Quantidade:</b> {bill.bill_items.consumptionPeakHoursTUSD ? numbers.money(bill.bill_items.consumptionPeakHoursTUSD.amount, false) : '-'}</div>
                                        <div><b>Valor:</b> {bill.bill_items.consumptionPeakHoursTUSD ? numbers.money(bill.bill_items.consumptionPeakHoursTUSD.value.total) : '-'}</div>
                                        <div><b>Tarifa:</b> {bill.bill_items.consumptionPeakHoursTUSD ? numbers.money(bill.bill_items.consumptionPeakHoursTUSD.tariff.icms, false, true, 4) : '-'}</div>
                                    </div>
                                </div>

                                <div className="consumptionOffPeakHoursTUSD">
                                    <h4>Consumo Fora Ponta TUSD</h4>

                                    <div className="infos">
                                        <div><b>Quantidade:</b> {bill.bill_items.consumptionOffPeakHoursTUSD ? numbers.money(bill.bill_items.consumptionOffPeakHoursTUSD.amount, false) : '-'}</div>
                                        <div><b>Valor:</b> {bill.bill_items.consumptionOffPeakHoursTUSD ? numbers.money(bill.bill_items.consumptionOffPeakHoursTUSD.value.total) : '-'}</div>
                                        <div><b>Tarifa:</b> {bill.bill_items.consumptionOffPeakHoursTUSD ? numbers.money(bill.bill_items.consumptionOffPeakHoursTUSD.tariff.icms, false, true, 4) : '-'}</div>
                                    </div>
                                </div>
                            </div>

                            <div className="othersLine">
                                <div className="reactiveEnergyPeakHours">
                                    <h4>Energia Reativa Ponta</h4>

                                    <div className='infos'>
                                        <div><b>Quantidade:</b> {bill.bill_items.reactiveEnergyPeakHours ? numbers.money(bill.bill_items.reactiveEnergyPeakHours.amount, false) : '-'}</div>
                                        <div><b>Valor:</b> {bill.bill_items.reactiveEnergyPeakHours ? numbers.money(bill.bill_items.reactiveEnergyPeakHours.value.total) : '-'}</div>
                                        <div><b>Tarifa:</b> {bill.bill_items.reactiveEnergyPeakHours ? numbers.money(bill.bill_items.reactiveEnergyPeakHours.tariff.icms, false, true, 4) : '-'}</div>
                                    </div>
                                </div>

                                <div className="reactiveEnergyOffPeakHours">
                                    <h4>Energia Reativa Fora Ponta</h4>

                                    <div className="infos">
                                        <div><b>Quantidade:</b> {bill.bill_items.reactiveEnergyOffPeakHours ? numbers.money(bill.bill_items.reactiveEnergyOffPeakHours.amount, false) : '-'}</div>
                                        <div><b>Valor:</b> {bill.bill_items.reactiveEnergyOffPeakHours ? numbers.money(bill.bill_items.reactiveEnergyOffPeakHours.value.total) : '-'}</div>
                                        <div><b>Tarifa:</b> {bill.bill_items.reactiveEnergyOffPeakHours ? numbers.money(bill.bill_items.reactiveEnergyOffPeakHours.tariff.icms, false, true, 4) : '-'}</div>
                                    </div>
                                </div>

                                <div className="blank"></div>
                                <div className="blank"></div>
                            </div>
                        </section>

                        <div className="formBox">
                            <div className="buttons">
                                <Selectbox
                                    name='status'
                                    onChange={change}
                                    values={[{
                                        name: 'Avaliado',
                                        value: 'validated'
                                    }, {
                                        name: 'Possui Erros',
                                        value: 'errors'
                                    }]}
                                    value={data.status}
                                />

                                <div className="line">
                                    <Button
                                        type="button"
                                        className='save'
                                        icon={<FaSave />}
                                        text="Salvar Alterações"
                                        func={saveData}
                                    />

                                    <Button
                                        type="link"
                                        link={`/admin/s/clientes/${id}/bills`}
                                        className='cancel'
                                        icon={<FaBan />}
                                        text="Cancelar"
                                    />
                                </div>
                            </div>

                            <div className="desc">
                                <label htmlFor="description">Em caso de erros informar aqui</label>
                                <textarea
                                    id="description"
                                    name="description"
                                    onChange={onChange}
                                    defaultValue={data.description ? data.description : ''}
                                    disabled={data.status==='errors' ? false : true}
                                ></textarea>
                            </div>
                        </div>
                    </div>
                </>
            ) : <LoadingSimple />
        }
    </Template>
}

export default ClientsBillsCheck;