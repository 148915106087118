import React, { ChangeEvent, FormEvent, useContext, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { FaBan, FaSave } from 'react-icons/fa';
import { GeneralContext } from '~/contexts';
import api from '~/services/api';

// Components
import Template from '~/pages/admin/Template';
import {Checkbox, Selectbox, InputLabel, Button, InputLabelMask} from '~/components/Forms';

// Init Data
const initData = {
    id    : 0,
    role  : '',
    active: 0,

    user: {
        first_name: '',
    },
    company: {
        id     : 0,
        name   : '',
        fantasy: '',
        cnpj   : ''
    },
    contact: {
        mail : '',
        phone: ''
    }
}

const ClientsEdit: React.FC = () => {
    const {user, checkToken, breadcrumbs, setBreadcrumbs} = useContext(GeneralContext);
    const submitRef           = useRef<HTMLButtonElement>(null);
    const [ready, setReady]   = useState(false);
    const [data, setData]     = useState<any>(null);
    const [errors, setErrors] = useState<any>(null);
    const navigate            = useNavigate();

    // Parameter
    let params = useParams();
    let id     = params.id;

    function onChange(event: ChangeEvent<HTMLInputElement|HTMLButtonElement>) {
        let {name, value, type} = event.target;
        change(name, value, type);
    }

    function onChangeCheckbox(event: any) {
        let isChecked = event.target.checked;
        setData({...data, active :isChecked ? 1 : 0})
    }

    function change(name: string, value: string, type?: string) {
        // Treat Data
        let split    = name.split('.');
        let newData  = JSON.parse(JSON.stringify(data));
        if (split.length>1) newData[split[0]][split[1]] = value;
        else newData[name] = type==='checkbox' ? Number(value) : value;

        // Set Data
        setData({...data, ...newData});
    }

    function onSubmit (event: FormEvent<HTMLFormElement>) {
		event.preventDefault();

        let dataSave = {
            id     : data.id,
            type   : data.role,
            active : data.active ? 1 : 0,
            name   : data.user.first_name,
            mail   : data.contact.mail,
            phone  : data.contact.phone,
            company: data.role==='user' ? {
                name   : data.company.name,
                fantasy: data.company.fantasy,
                cnpj   : data.company.cnpj
            } : {
                id: data.company.id
            }
        };

        sendData(dataSave)
	}

    async function sendData (dataSave: any) {
        await api.put('/users', dataSave, {
            headers: { Authorization: user.token }
        }).then(resp => {
            navigate(`/admin/s/clientes`);
        }).catch(async err => {
            if (err.response.status===400) setErrors(err.response.data)
            else if (err.response.status===403) {
                let status = await checkToken(user)
                if (status) submitRef.current?.click()
                else navigate(`/login`, {replace: true})
            }
        })
    }

    useEffect(() => {
        ready && !breadcrumbs && setBreadcrumbs({
            curr: 'Edição de Cliente',
            links: [
                { name: 'Lista de Clientes', url: '/admin/s/clientes'}
            ]
        });

        if (data===null) {
            setReady(false)

            api.get(`/users/${id}`, {headers: { Authorization: user.token }}).then(resp => {
                let base    = resp.data;
                let nameArr = base.name.split('|');

                // Set User Data
                initData.id              = base.id;
                initData.role            = base.type;
                initData.active          = base.active;
                initData.user.first_name = nameArr[0];
                initData.contact.mail    = base.mail;
                initData.contact.phone   = base.phone;
                initData.company.id      = base.company ? base.company.id : '';
                initData.company.name    = base.company ? base.company.name : '';
                initData.company.fantasy = base.company ? base.company.fantasy : '';
                initData.company.cnpj    = base.company ? base.company.cnpj : '';

                setTimeout(() => {
                    setData(initData)
                    setReady(true)
                }, 1000);
            })
        }
    }, [user, id, ready, data]);

    // Unmount
    useEffect(() => () => setBreadcrumbs(null), [])

    return <Template page="Administrador - Cliente - Edição de Dados" pageTitle="Gerenciamento de Clientes" className="cliEdit">
        <div className="header">
            <h1>Edição de Cliente</h1>
        </div>

        {
            ready ? (
                <form method="POST" onSubmit={onSubmit}>
                    <div className="fields">
                        <div className="infos">
                            <h2 className='text-3xl'>Informações Pessoais</h2>

                            <InputLabel
                                name='user.first_name'
                                value={data.user.first_name}
                                type='text'
                                labelName='Nome'
                                onChange={onChange}
                                error={errors && errors['user.first_name'] && errors['user.first_name'][0]}
                            />


                            <div className="line">
                                <Checkbox
                                    id='active'
                                    name='active'
                                    labelName='Ativo'
                                    onChange={onChangeCheckbox}
                                    checked={data.active ? 1 : 0}
                                />

                                <Selectbox
                                    name='role'
                                    onChange={change}
                                    values={[{
                                        name: 'Usuário',
                                        value: 'user'
                                    }, {
                                        name: 'Administrador',
                                        value: 'admin'
                                    }]}
                                    value={data.role}
                                />
                            </div>

                            {
                                data.role==='user' && (
                                    <>
                                        <hr />

                                        <InputLabel
                                            name='company.name'
                                            value={data.company.name}
                                            type='text'
                                            labelName='Razão social'
                                            disabled={true}
                                            onChange={onChange}
                                            error={errors && errors['company.name'] && errors['company.name'][0]}
                                        />

                                        <InputLabel
                                            name='company.cnpj'
                                            value={data.company.cnpj}
                                            type='text'
                                            placeholder='12.000.000/0001-09'
                                            labelName='CNPJ'
                                            disabled={true}
                                            onChange={onChange}
                                            error={errors && errors['company.cnpj'] && errors['company.cnpj'][0]}
                                        />
                                    </>
                                )
                            }
                        </div>

                        <div className="contact">
                            <h2 className='text-3xl'>Dados de Contato</h2>

                            <InputLabel
                                name='contact.mail'
                                value={data.contact.mail}
                                type='mail'
                                labelName='Email'
                                onChange={onChange}
                                error={errors && errors['contact.mail'] && errors['contact.mail'][0]}
                            />

                            <InputLabelMask
                                name='contact.phone'
                                value={data.contact.phone}
                                type='string'
                                placeholder='(00) 00000-0000'
                                mask={'(99) 99999-9999'}
                                labelName='Telefone'
                                onChange={onChange}
                                error={errors && errors['contact.phone'] && errors['contact.phone'][0]}
                            />
                        </div>
                    </div>

                    <div className="btns">
                        <Button
                            type="submit"
                            className='save'
                            icon={<FaSave />}
                            text="Salvar Alterações"
                            refButton={submitRef}
                        />

                        <Button
                            type="link"
                            link="/admin/s/clientes"
                            className='cancel'
                            icon={<FaBan />}
                            text="Cancelar"
                        />
                    </div>
                </form>
            ) : <></>
        }
    </Template>;
}

export default ClientsEdit;