import React, { ChangeEvent, useContext, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import mobile from 'is-mobile';
import ReactTooltip from 'react-tooltip';
import Lottie from 'react-lottie-player';
import { FaExchangeAlt, FaInfoCircle } from 'react-icons/fa';
import { GeneralContext, ModalContext } from '~/contexts';
import api from '~/services/api';
import Numbers from '~/utils/numbers';
import './styles.css';

// Functions
import Funcs from './funcs';

// Loading
import loading from './images/loading.json';
import up_icon from './images/up-icon.svg';
import down_icon from './images/down-icon.svg';
import equal_icon from './images/equal-icon.svg';
import numbers from '~/utils/numbers';

// Variables
let list: any = null;

const MonthlyReport:React.FC<any> = ({styles, dates, date}: any) => {
	const {setModalOpen, setModalTitle, setModalBody} = useContext(ModalContext);
	const {user, client}                              = useContext(GeneralContext);
	const [ready, setReady]                           = useState(false);
	const [data, setData]                             = useState<any>(null);
	const [statusBill, setStatusBill]                 = useState<any>('');
	const [dateTo, setDateTo]                         = useState<any>(null);
	const [open, setOpen]                             = useState<any>(false);

	// Refs
	const refTo    = useRef<HTMLSelectElement>(null);

	// Variables
	const dateFrom = date.year + '-' + date.month;

	async function fetchApi(){
		await api.get(`report/monthlyReport${user.role==='admin' ? `/c/${client}` : ''}/${dateFrom}/${dateTo}`, {
			headers: { Authorization: user.token }
		}).then((resp: any) => {
			if (resp.data.from.reference_date === resp.data.to.reference_date) setData(null)
			else {
				setTimeout(() => {
					setData(resp.data)
					setReady(true)
				}, 1000);
			}
		}).catch((err: any) => {
			console.log(err)
			setReady(true)
		});
	}

	function openModal (title?: string, valueFrom?:any, valueTo?:any, difference?:any, message?:any) {
		let diff = ''
		let diffLabel = ''
		if (valueFrom > valueTo){
			diff = 'aumento'
			diffLabel = 'foi maior'
		} else if(valueFrom < valueTo){
			diff = 'diminuição'
			diffLabel = 'foi menor'
		} else {
			diff = 'igualdade'
			diffLabel = 'foi a mesma'
		}

        setModalTitle(title)
        setModalBody(
			<div className='modalComparative'>
				<div>
					<div className={diff === 'aumento' ? 'up' : ( diff === 'diminuição' ? 'down' : 'equal')}>
						<label>Mês atual</label>
						<div>
							<span>{numbers.money(valueFrom, true, true, 2)}</span>
							<img alt={diff} src={diff === 'aumento'? down_icon :  ( diff === 'diminuição' ? up_icon : equal_icon)}></img>
						</div>
					</div>
					<FaExchangeAlt/>
					<div>
						<label>Mês comparado</label>
						<span>{numbers.money(valueTo, true, true, 2)}</span>
					</div>
				</div>
				<h1>{title?.replace('¹ -','')} {diffLabel}</h1>
				<h2>O que isso significa?</h2>
				<p>Do mês <b>{dateFrom}</b> ao mês <b>{dateTo}</b> houve <b>{diff}</b> no valor da conta.</p>
				{message && <p>Motivo: {message.replace('¹','.')}</p>}
			</div>
		)
        setModalOpen(true)
    }
	function modalTooltip () {

		if(mobile()){
			if(mobile()){
				setModalTitle('Explicação das comparações')
				setModalBody(
					<div id="monthlyReportInfo" className='infosBox' >
						<p className="warning"><b>IMPORTANTE:</b> Para entregar a melhor experiência em relatórios pedimos que envie pelo menos duas contas de luz.</p>
						<p>Este quadro compara diferentes faturas de energia de acordo com o mês selecionado.</p>
						<p>Para utilizá-lo basta selecionar o mês de referência no topo da sua tela e, logo após, selecionar no quadro outro mês para comparação.</p>
						<p>Para entender o motivo do aumento ou decréscimo, cliem em <b>"Detalhes"</b>.</p>
						<p>💡 Nota: O aumento percentual do valor da conta será representado em <b>vermelho</b> e a redução será representada em <b>verde</b>.</p>
					</div>
					)
				setModalOpen(true)
			}
		}
    }

	function getPercMonthlyCons () {
		let infos = Funcs.getPercMonthlyCons(data.from, data.to)
		if(statusBill === '') setStatusBill(infos.status)

		return infos.data
	}

	function getDates () {
		let name    = 'listTo';
		let key     = 'opt_to_';

		return <div className='selectBox'>
			<select name={name} ref={refTo} onChange={changeMonth} value={dateTo}>
				{
					list.map((el: any) => <option
						key={`${key}${el.id}`}
						value={el.date.slug}
					>
						{el.date.slug.split('-').reverse().join('/')}
					</option>)
				}
			</select>
		</div>
	}

	function changeMonth (event: ChangeEvent<HTMLSelectElement>) {
		let {value} = event.currentTarget;
		setReady(false);
		setData(null);
		setStatusBill('');
		setDateTo(value)
	}

	useEffect(() => {
		let dt = dates
		let idxDate = dt.findIndex((el: any) => el.date.slug===dateFrom)
		list        = dt.slice(idxDate+1, dt.length);

		// Set date or ready
		if (list.length===0) setReady(true)
		else setDateTo(list[0].date.slug)
	}, [dateFrom, dates])

	useEffect(() => {
		if (data === null && dateTo!==null && ready === false && dateFrom!==null && dateFrom === date.year + '-' + date.month) {

			(async () => {
				await fetchApi()
			})()

        }
	}, [dateTo])

  	return <div className="monthlyReport" style={styles}>
		<header>
			<div className="titles">
				<h3>Sua conta de luz {statusBill}</h3>
				<h4>Esse painel compara o valor da conta atual com a do mês selecionado</h4>
			</div>

			<div className="hover" data-tip="tooltip" data-for="monthlyReportInfo">
				<FaInfoCircle color='#c0c0c0' onClick={() => modalTooltip()}/>
				{!mobile()?
					<ReactTooltip id="monthlyReportInfo" className='infosBox' place="bottom" type="light" effect="solid" clickable={true} delayHide={1000}>
						<p className="warning"><b>IMPORTANTE:</b> Para entregar a melhor experiência em relatórios pedimos que envie pelo menos duas contas de luz.</p>
						<p>Este quadro compara diferentes faturas de energia de acordo com o mês selecionado.</p>
						<p>Para utilizá-lo basta selecionar o mês de referência no topo da sua tela e, logo após, selecionar no quadro outro mês para comparação.</p>
						<p>Para entender o motivo do aumento ou decréscimo, cliem em <b>"Detalhes"</b>.</p>
						<p>💡 Nota: O aumento percentual do valor da conta será representado em <b>vermelho</b> e a redução será representada em <b>verde</b>.</p>
					</ReactTooltip>
				:
					<></>
				}
			</div>
		</header>

		{
			ready ?
				data && list.length>0 ? (
					<section className='content'>
						<div className="line head">
							<div className="left">
								{getPercMonthlyCons()}
							</div>

							<div className="right">
								<span>Escolha um outro mês<br />para comparar</span>

										{/* MES ATUAL<label>De {dateFrom.split('-').reverse().join('/')}</label> */}
								<div className='boxs'>
									{/* <FaExchangeAlt /> */}

									<div className="select">

										{dates.length>=2 && getDates()}
									</div>
								</div>
							</div>
						</div>

						{mobile() && !open && (
							<div className="more" onClick={()=>setOpen(!open)}><span>+ Leia mais</span></div>
						)}

						{mobile() && open || !mobile() && !open ? (
							<div className='content_box'>
								<div className="infos">
									<p>Valor da sua conta de luz em {data.from.reference_date} : {Numbers.money(data.from.total_value)}</p>
									<p>Valor da sua conta de luz em {data.to.reference_date} : {Numbers.money(data.to.total_value)}</p>
								</div>

								<div className="upPeriod">
									<h5>{statusBill} no período pelos seguintes motivos:</h5>

									{ // Custo da Demanda Unica
									data.from.tariff_modality === 'Verde' ? Funcs.demandCost(data.from, data.to, openModal):<></>}

									{ // Custo da Demanda Ponta e fora ponta
										data.from.tariff_modality === 'Azul' ? <>
											{Funcs.demandCostPeak(data.from, data.to, openModal)}
											{Funcs.demandCostOffPeak(data.from, data.to, openModal)}
										</> : <></>
									}

									{ // Custo do consumo ponta
									Funcs.consumptionPeakHoursCost(data.from, data.to, openModal)}

									{ // Custo do consumo fora ponta
									Funcs.consumptionOffPeakHoursCost(data.from, data.to, openModal)}

									{ // Custo da bandeira tarifária
									Funcs.flagTariffCost(data.from, data.to, openModal)}

									{ // Outros custos
									Funcs.othersCost(data.from, data.to, openModal)}
								</div>

								<div className="notes">
									¹ - Energia reativa, PIS/COFINS, ICMS, multas e aditivos
								</div>
							</div>
						):<></>}

						{mobile() && open &&(
							<div className="more" onClick={()=>setOpen(!open)}><span>Ler menos</span></div>
						)}
					</section>
				) : <div className="alert">
						<p>Adicione novas contas para gerar um relatório</p>
						<Link to='/admin/historico' className='underline'>Enviar novas contas</Link>
					</div>
			: (
				<div className='loading'>
					<Lottie animationData={loading} loop play className="icon" />
				</div>
			)
		}
	</div>
}

export default MonthlyReport;