import React, { useContext } from 'react';
import { ShepherdTour } from "react-shepherd";
import mobile from 'is-mobile';
import { GeneralContext } from '~/contexts';
import "shepherd.js/dist/css/shepherd.css";
import './styles.css';

// Components
import OpportunitiesEconomy from '~/components/Boxs/OppsEconomy';
import MonthlyReport from '~/components/Boxs/MonthlyReport/wizard';
import ChartsBox from '~/components/Boxs/Charts/ChartsBox/wizard';
import TariffBox from '~/components/Boxs/Tariff';

// Shepherd Objects
import {tourOptions, steps as newSteps} from './wizard/steps'

// Data Examples
import data from './wizard/data';
import monthlyReportData from './wizard/monthlyReport';
import charts from './wizard/charts';
import { StartTour } from './wizard/startTour';

// Variables
let yearMonth = data.dates[0].date.slug;

const Wizard: React.FC<any> = ({setWizard}: {setWizard: Function}) => {
    const {user} = useContext(GeneralContext);

    return <ShepherdTour steps={newSteps} tourOptions={tourOptions}>
        <StartTour setWizard={setWizard} />

        <div className='header'>
            <h1>Relatórios - {user.company?.name}</h1>

            <div className='referenceDate'>
                {!mobile() && <h3>Mês de referência:</h3>}
                {
                    data.dates.length>=1 ? (
                        <select onChange={() => {}} value={data.dates[0].date.slug}>
                            {data.dates.map((el: any) => <option key={`sel_${el.id}`} value={el.date.slug}>{el.date.name}</option>)}
                        </select>
                    ) : <></>
                }
            </div>
        </div>

        <div className='container'>
            <div className="left">
                <MonthlyReport data={monthlyReportData} />
            </div>

            <div className="center">
                <OpportunitiesEconomy data={data} yearMonth={yearMonth} />

                <TariffBox style={{marginTop: 20}} bill={data.bill} />
            </div>

            <div id="chartsBox" className="right">
                <ChartsBox data={charts} />
            </div>
        </div>
    </ShepherdTour>
}

export default Wizard;