import React, { useContext } from 'react';
import { FaBug, FaEdit, FaList, FaTrash, FaFileSignature } from 'react-icons/fa';
import { GeneralContext } from '~/contexts';
import './styles.css';

// Components
import { Button } from '~/components/Forms';

// Images
import userImg from '~/assets/images/user.jpg';

// Props

interface PropsSpecialCondition {
    id: string | number;
    photo?: string;
    name: string;
    delete: Function;
}
interface Props extends PropsSpecialCondition{
    provider: string;
    modality: string;
    company_id: string | number;
    bills?: boolean;
    errors?: boolean;
}


const ClientLine:React.FC<Props> = (props: Props) => {
    const {user} = useContext(GeneralContext);

    return <div className="clientLine">
        <div className="image">
            <img src={props.photo ? props.photo :  userImg} alt={props.name} />
        </div>

        <div className="name">{props.name.split('|').join(' ')}</div>

        <div className="btns">
            <Button
                type="link"
                disabled={!props.company_id ? true : false}
                link={`/admin/s/especial/${props.company_id}`}
                className='condition'
                icon={<FaFileSignature />}
                text="Condição especial"
            />

            <Button
                type="link"
                link={`/admin/s/clientes/${props.id}`}
                className='edit'
                icon={<FaEdit />}
                text="Editar"
            />

            <Button
                type="link"
                link={`/admin/s/clientes/${props.id}/bills`}
                className='bills'
                icon={<FaList />}
                disabled={!props.bills ? true : false}
                text="Contas"
            />

            <Button
                type="link"
                link={`/admin/s/clientes/${props.id}/errors`}
                className='errors'
                icon={<FaBug />}
                disabled={!props.errors ? true : false}
                text="Erros"
            />

            <Button
                type="button"
                func={() => user.id!==props.id && props.delete(props.id)}
                className='del'
                icon={<FaTrash />}
                disabled={user.id===props.id ? true : false}
                text="Excluir"
            />
        </div>
    </div>;
}

export const ClientLineSpecialCondition:React.FC<PropsSpecialCondition> = (props: PropsSpecialCondition) => {
    const {user} = useContext(GeneralContext);

    return <div className="clientLine">
        <div className="image">
            <img src={props.photo ? props.photo :  userImg} alt={props.name} />
        </div>

        <div className="name">{props.name.split('|').join(' ')}</div>

        <div className="btns">
            <Button
                type="link"
                link={`/admin/s/especial/${props.id}`}
                className='edit'
                icon={<FaEdit />}
                text="Editar condição especial"
            />

            <Button
                type="button"
                func={() => user.id!==props.id && props.delete(props.id)}
                className='del'
                icon={<FaTrash />}
                disabled={user.id===props.id ? true : false}
                text="Excluir"
            />
        </div>
    </div>;
}

export default ClientLine;