import React, {useState} from 'react';
import './plans.css';

// Images
import access from '../../images/plans/access.png';
import advanced from '../../images/plans/advanced.png';
import premium from '../../images/plans/premium.png';
import ultra from '../../images/plans/ultra.png';
import ConditionsBox from '~/components/Boxs/Conditions';
import { Button } from '~/components/Forms';
import { FiLoader } from 'react-icons/fi';

const PlansStep: React.FC<any> = ({plans, setPlan, plan, down, toSign, adequacyCosting}) => {
    const [collapseOpen, setCollapseOpen] = useState(1);

    return <div className='plansOpps'>
        <h3>Selecione o seu plano</h3>
        <p>Selecione o plano que melhor se adequa à sua necessidade e o sistema gerará uma proposta para migrar para o Mercado Livre de Energia com a Energizou.</p>
        <div className="lineBox">
            <div className="plans">
                <button
                    className={`planBox${plan.name===plans.plan1.name ? ' active' : ''}`}
                    onClick={() => setPlan(plans.plan1)}
                >
                    <div className="select"><span /></div>

                    <div className="infos">
                        <div className="title">Plano Acesso</div>
                        <div className="year"><b>{plans.plan1.years} ano{plans.plan1.years>1 ? 's' : ''}</b> de contrato com</div>
                        <div className="economy"><b>{plans.plan1.value}% de economia</b> garantida</div>
                        {adequacyCosting === 1 && (<>
                            <div className="economy"><b>+ custeio</b> do sistema de medição</div>
                            <div className="economy">(até o limite de R$ 7 mil)</div>
                        </>)}
                    </div>

                    <div className="icon">
                        <img src={access} alt="Plano Acesso" />
                    </div>
                </button>

                <button
                    className={`planBox${plan.name===plans.plan2.name ? ' active' : ''}`}
                    onClick={() => setPlan(plans.plan2)}
                >
                    <div className="select"><span /></div>

                    <div className="infos">
                        <div className="title">Plano Avançado</div>
                        <div className="year"><b>{plans.plan2.years} anos</b> de contrato com</div>
                        <div className="economy"><b>{plans.plan2.value}% de economia</b> garantida</div>
                        {adequacyCosting === 1 && (<>
                            <div className="economy"><b>+ custeio</b> do sistema de medição</div>
                            <div className="economy">(até o limite de R$ 7 mil)</div>
                        </>)}
                    </div>

                    <div className="icon">
                        <img src={advanced} alt="Plano Avançado" />
                    </div>
                </button>

                <button
                    className={`planBox${plan.name===plans.plan3.name ? ' active' : ''}`}
                    onClick={() => setPlan(plans.plan3)}
                >
                    <div className="select"><span /></div>

                    <div className="infos">
                        <div className="title">Plano Premium</div>
                        <div className="year"><b>{plans.plan3.years} anos</b> de contrato com</div>
                        <div className="economy"><b>{plans.plan3.value}% de economia</b> garantida</div>
                        {adequacyCosting === 1 && (<>
                            <div className="economy"><b>+ custeio</b> do sistema de medição</div>
                            <div className="economy">(até o limite de R$ 7 mil)</div>
                        </>)}
                    </div>

                    <div className="icon">
                        <img src={premium} alt="Plano Premium" />
                    </div>
                </button>

                <button
                    className={`planBox${plan.name===plans.plan4.name ? ' active' : ''}`}
                    onClick={() => setPlan(plans.plan4)}
                >
                    <div className="select"><span /></div>

                    <div className="infos">
                        <div className="title">Plano Ultra</div>
                        <div className="year"><b>{plans.plan4.years} anos</b> de contrato com</div>
                        <div className="economy"><b>{plans.plan4.value}% de economia</b> garantida</div>
                        {adequacyCosting === 1 && (<>
                            <div className="economy"><b>+ custeio</b> do sistema de medição</div>
                            <div className="economy">(até o limite de R$ 7 mil)</div>
                        </>)}
                    </div>

                    <div className="icon">
                        <img src={ultra} alt="Plano Ultra" />
                    </div>
                </button>

                {
                    down ? <Button
                        type="button"
                        text="Selecionando..."
                        className="box load"
                        icon={<FiLoader size={12} />}
                        disabled={true}
                    /> : <Button
                        className="box"
                        text="Selecionar plano"
                        func={() => {
                            toSign(plan)
                        }}
                    />
                }

                {/* <Button
                    className="box"
                    text="Selecionar plano"
                    func={() => {
                        setStep(6)
                        toSign()
                    }}
                /> */}
            </div>

            <div className="conditions">
                <h4>Como funciona</h4>

                <ConditionsBox
                    index={1}
                    collapseOpen={collapseOpen}
                    setCollapseOpen={setCollapseOpen}
                    title="Prazo de contrato"
                    description="Duração total do plano para contratação de energia elétrica."
                />

                <ConditionsBox
                    index={2}
                    collapseOpen={collapseOpen}
                    setCollapseOpen={setCollapseOpen}
                    title="Economia garantida"
                    description="Percentual de economia garantido com relação a tarifa da sua distribuidora, independente do reajuste anual da mesma."
                />

                <ConditionsBox
                    index={4}
                    collapseOpen={collapseOpen}
                    setCollapseOpen={setCollapseOpen}
                    title="Atendimento exclusivo"
                    description="Equipe de especialistas disponível para garantir um atendimento personalizado à sua necessidade."
                />

                <ConditionsBox
                    index={5}
                    collapseOpen={collapseOpen}
                    setCollapseOpen={setCollapseOpen}
                    title="Implementação sem custo adicional"
                    description="Serviços de implementação e migração realizados pela equipe Energizou sem custo adicional."
                />

                <ConditionsBox
                    index={6}
                    collapseOpen={collapseOpen}
                    setCollapseOpen={setCollapseOpen}
                    title="Acesso a sistemas"
                    description="Acesso sem custo adicional aos sistemas de gestão e controle do seu contrato."
                />
            </div>
        </div>
        <p>A proposta gerada é indicativa e está sujeita à assinatura de um contrato definitivo de compra e venda de energia. A proposta possui validade de 5 dias corridos desde a sua criação.</p>
    </div>;
}

export default PlansStep;