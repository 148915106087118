import { FaStoreAlt } from "react-icons/fa";

const business = [
    {
        type: 'text',
        class: 'resp',
        icon: <FaStoreAlt />,
        text: 'Da minha empresa'
    },
    {
        type: 'delay',
        time: 5000
    },
    {
        type: 'text',
        text: 'Ok, agora eu preciso de algumas informações.'
    },
    {
        type: 'delay',
        time: 5000
    },
    {
        type: 'text',
        text: 'Nossa análise é personalizada com os dados específicos da sua conta de luz.'
    },
    {
        type: 'delay',
        time: 5000
    },
    {
        type: 'text',
        text: 'É necessário que você tenha uma fatura digital da sua conta de energia (aquela que você recebe pelo email, ou quando você faz um download do site da sua concessionária) para avançarmos.'
    },
    {
        type: 'delay',
        time: 5000
    },
    {
        type: 'text',
        text: 'Por favor, nos envie a sua última conta de energia para fazermos um diagnóstico.'
    },
    {
        type: 'delay',
        time: 5000
    },
    {
        type: 'question',
        name: 'file',
        items: [
            {
                icon: '',
                text: 'Selecionar um arquivo PDF...',
                file: true,
                click: (user: any, button: Function) => button(user.id, 'Onboarding', 'Selecionar Arquivo'),
                bill: 1
            }
        ]
    }
]

export default business