import React from 'react';

// Images
import icon from '../../images/step4.png';
import consumptionUtils from '~/utils/consumptionPlan';

const Step4: React.FC<any> = ({consumption, setStep, company}: {consumption: number, setStep: Function, company: any}) => {
    return <>
        <div className="infos">
            <div className="texts">
                <h1>Como entrar no Mercado de Energia Livre?</h1>

                    <div>
                        <p>Para entrar no Mercado Livre de Energia, é necessário buscar uma Comercializadora de energia autorizada para auxiliar na contratação de energia e prestação dos serviços necessários. A Energizou oferece pacotes de fornecimento de energia e auxilio em todas as etapas operacionais para garantir que o projeto do Mercado Livre de Energia seja um grande sucesso.</p>
                        { consumption <= consumptionUtils.getConsumptionLimit()  ?
                            <>
                                <p><b>Clique no botão abaixo visualize os planos de energia que criamos especialmente para o(a) {company?.name}.</b> Depois que selecionar um plano que melhor se adequa às suas necessidades, visualize a proposta indicativa gerada pela Energizou.</p>
                                <p>Após visualizar a proposta, em até 24 horas úteis, um vendedor da Energizou irá entrar em contato com você por telefone e e-mail para agendar uma apresentação da proposta e tirar todas as suas dúvidas.</p>
                            </>
                        :
                            <p><b>Clique no botão abaixo visualize a proposta comercial que criamos especialmente para o(a) {company?.name}.</b> Um vendedor irá entrar em contato com você por telefone e e-mail para agendar uma apresentação da proposta e tirar todas as suas dúvidas.</p>
                        }
                    </div>

            </div>

            <div className="buttons">
                <button className='prev' onClick={() => setStep(3)}>
                    Voltar
                </button>

                <button className='plans' onClick={() => setStep(5)}>
                    { consumption <=  consumptionUtils.getConsumptionLimit()  ? 'Ver Planos' : 'Ver Proposta' }
                </button>
            </div>
        </div>

        <div className="icon">
            <img src={icon} alt="Icone da Sessão" />
        </div>
    </>;
}

export default Step4;