import React from 'react';

// Images
import icon from '../../images/step2.png';

const Step2: React.FC<any> = ({setStep, company}: {setStep: Function, company:any}) => {
    return <>
        <div className="infos">
            <div className="texts">
                <h1>O que muda no dia a dia?</h1>
                <div>
                    <p>No Mercado Livre de Energia a distribuidora continua sendo a responsável pela entrega de energia até o estabelecimento, por meio da sua infraestrutura de cabos e equipamentos. O diferença é que o consumidor deixa de comprar energia da distribuidora, por meio de uma tarifa regulada, e passa a negociar preços e outras condições comerciais personalizadas diretamente com um novo fornecedor de energia.</p>
                    <p>Dessa forma, a única diferença é que os clientes Livres passam a ter dois provedores distintos, a distribuidora, para o serviço de entrega de energia, e o fornecedor, para a própria energia a ser consumida.</p>
                    <p>A Energizou cuida de toda a comunicação com a sua distribuidora para que a energia chegue até a sua empresa da mesma forma que antes.</p>
                </div>
            </div>

            <div className="buttons">
                <button className='prev' onClick={() => setStep(1)}>
                    Voltar
                </button>

                <button className='next' onClick={() => setStep(3)}>
                    Próximo
                </button>
            </div>
        </div>

        <div className="icon">
            <img src={icon} alt="Icone da Sessão" />
        </div>
    </>;
}

export default Step2;