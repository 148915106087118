const userHome = [
    {
        type: 'delay',
        time: 2000
    },
    {
        type: 'text',
        text: 'Olá #NAME#, tudo bem? Seja bem vindo ao Energizou App!'
    },
    {
        type: 'delay',
        time: 2000
    },
    {
        type: 'text',
        text: 'O Energizou App ainda não está disponível para usuários residenciais. Acompanhe as notícias mais recentes na nossa newsletter e website.'
    },
    {
        type: 'delay',
        time: 2000
    },
    {
        type: 'question',
        name: 'value',
        items: [
            {
                icon: '',
                text: 'Acessar website',
                click:()=>{window.location.replace('https://energizou.com.br/')}
            },
            {
                icon: '',
                text: 'Encerrar conta',
                step: 0.1
            }
        ]
    }
]

export default userHome