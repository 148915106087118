import React from 'react';
import dateFormat from "dateformat";
import { FaEdit, FaTrash } from 'react-icons/fa';
import './styles.css';

// Components
import { Button } from '~/components/Forms';

// Props
interface Props {
    id: string | number;
    name: string;
    date: string;
}

const OppsLine:React.FC<any> = (props: Props) => {
    function getTreatDate () {
        let date = new Date(props.date);
        return dateFormat(date, 'dd/mm/yyyy H:MM');
    }

    return <div className="oppLine">
        <div className="name">{props.name}</div>
        <div className="date">{getTreatDate()}</div>

        <div className="btns">
            <Button
                type="link"
                link={`/admin/s/oportunidades/${props.id}`}
                className='edit'
                icon={<FaEdit />}
                text="Editar"
            />

            <Button
                type="button"
                func={() => console.log(`Deletando Oportunidade ${props.id}`)}
                className='del'
                icon={<FaTrash />}
                text="Excluir"
            />
        </div>
    </div>;
}

export default OppsLine;