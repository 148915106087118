import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import mobile from 'is-mobile';
import { FaInfoCircle } from 'react-icons/fa';
import { MixPanelContext, GeneralContext, ModalContext } from '~/contexts';
import numbers from '~/utils/numbers';
import './styles.css';

const OpportunitiesEconomy:React.FC<any> = (props: any) => {
	const {setModalOpen, setModalTitle, setModalBody} = useContext(ModalContext);
	const {user}        = useContext(GeneralContext);
	const {buttonClick} = useContext(MixPanelContext);
    const [opportunities, setOpportunities] = useState(null)
    const [total, setTotal] = useState('')

	useEffect(() => {
		if (opportunities === null) {
			let amount = 0
			props.data.bill.opportunities.map((el: any) => amount+=el.economy)
			let tot = numbers.money(amount, true, false)
			setTotal(tot)
			setOpportunities(props.data.bill.opportunities.length)
		}
	}, [props, opportunities])

	function modalTooltip () {

		if(mobile()){
			setModalTitle('Oportunidades de economia')
			setModalBody(
				<p>Este quadro mostra quantas oportunidades de economia foram identificadas na conta de luz e a somatória aproximada do total de economia em reais.</p>
			)
			setModalOpen(true)
		}
    }

  	return <div className="oppsEcoBox" >
		<header>
			<div className="titles">
				<h3>Oportunidades de economia</h3>
			</div>

			<div className="hover" data-tip="tooltip" data-for="opportunitiesInfo">
				<FaInfoCircle color='#c0c0c0' onClick={() => modalTooltip()}/>
				{!mobile()?
					<ReactTooltip id="opportunitiesInfo" className='infosBox' place="bottom" type="light" effect="solid" clickable={true} delayHide={1000}>
						<p>Este quadro mostra quantas oportunidades de economia foram identificadas na conta de luz e a somatória aproximada do total de economia em reais.</p>
					</ReactTooltip>
				:
					<></>
				}
			</div>
		</header>

		<section className='content'>
			<div className="body">
				<div className="left">
					<span>Foram encontradas</span>
					<span className='number'>{opportunities ? opportunities : 0}</span>
					<span>oportunidades</span>
				</div>

				<div className="right">
					<span>Que equivalem a<br /> aproximadamente</span>
					<span className='value'>{total ? total : 'R$ 0,00'}</span>
				</div>
			</div>

			<Link to={`/admin${props.yearMonth !== undefined ? '/d/' + props.yearMonth : ''}`} id="btns" className="box edit" onClick={() => {
				buttonClick(user.id, 'Relatórios', 'Ver Oportunidades', props.yearMonth);
				console.log('Oportunidades')
			}}>
				Toque aqui e veja as oportunidades
			</Link>
		</section>
	</div>
}

export default OpportunitiesEconomy;