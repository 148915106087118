import React, { useEffect } from 'react';
import numbers from '~/utils/numbers';
import './styles.css';

// Props
interface Props {
    className?: string;
    scale: {init: string; end: string};
    month: string;
    monthValue: number;
}

let scale: any = {
    init: 25,
    middle: 50,
    end: 75
}

const MoonChart:React.FC<any> = (props: Props) => {
    if (props.scale) {
        scale.init   = Number(props.scale.init)
        scale.end    = Number(props.scale.end)
        scale.middle = parseInt(((scale.init+scale.end)/2).toFixed(0))
    }

    function getClass () {
        if (props.monthValue>Number(props.scale.init) && props.monthValue<Number(props.scale.end)) return 'green'
        return 'red'
    }

    function getPosition () {
        let end  = scale.end-scale.init;
        let perc = ((props.monthValue-scale.init)*100)/end;
        let sum = 0;

        if (perc<50 && perc>0) sum = 35
        if (perc<50 && perc<0) sum = 45
        else if (perc>50 && perc<100) sum = -35
        else if (perc>50 && perc>100) sum = -45

        return props.monthValue ? ((350*perc)/100)+sum : 0
    }

    function getClassMonth (month: string) {
        let short = month.slice(0, 3)
        return short.toLowerCase()
    }

    function getClassLegends (qtty: number, type: string) {
        let count     = qtty.toString().length;
        let classList = type;

        switch (count) {
            case 2:
                classList += ' two-units';
                break;

            case 3:
                classList += ' three-units';
                break;

            case 4:
                classList += ' four-units';
                break;

            case 5:
                classList += ' five-units';
                break;

            case 6:
                classList += ' six-units';
                break;

            case 7:
                classList += ' seven-units';
                break;

            case 8:
                classList += ' eight-units';
                break;

            case 9:
                classList += ' nine-units';
                break;

            default:
                classList += ' unit';
                break;
        }

        return classList;
    }

    useEffect(() => {
    }, [scale])

    return (
        <svg version="1.1" id="moonChart" className={(props.className ? props.className : '') + getClass()} xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="-92 293 450 98.5">
            {/* Horizontal Infos */}
            <g id="texts">
                <text transform="matrix(1 0 0 1 -50.4 391)" className={`legends ${getClassLegends(scale.init, 'left')}`}>{numbers.money(scale.init, false, false) }</text>
                <text transform="matrix(1 0 0 1 85 391)" className={`legends ${getClassLegends(scale.middle, 'middle')}`}>{numbers.money(scale.middle, false, false) }</text>
                <text transform="matrix(1 0 0 1 213 391)" className={`legends ${getClassLegends(scale.end, 'right')}`}>{numbers.money(scale.end, false, false) }</text>
            </g>

            {/* Mark and Month */}
            <g id="lineMonth" transform={`translate(${getPosition()}, 0)`}>
                <g className="monthMark">
                    <path className="bkg" d="M-53.3,294.5h52.9c5.5,0,9.9,4.4,9.9,9.9l0,0c0,5.5-4.4,9.9-9.9,9.9h-52.9c-5.5,0-9.9-4.4-9.9-9.9l0,0 C-63.3,299-58.8,294.5-53.3,294.5z"/>
                    <text transform="matrix(1 0 0 1 -42.4 308.9838)" className={`month ${getClassMonth(props.month)}`}>{ props.month }</text>
                </g>

                <g className='mark'>
                    <circle className="selectInt" cx="-80.8" cy="304.4" r="10.4"/>
                    <path className="select" d="M-69.6,304.4c0-6.2-5-11.3-11.2-11.3s-11.2,5-11.2,11.3c0,5.6,4.2,10.3,9.6,11.1v60.6h2v-60.4
                        C-74.4,315.5-69.6,310.5-69.6,304.4z M-80.8,313.7c-5.1,0-9.2-4.2-9.2-9.3c0-5.1,4.1-9.3,9.2-9.3s9.2,4.2,9.2,9.3
                        S-75.7,313.7-80.8,313.7z"/>
                </g>

                <text transform="matrix(1 0 0 1 -75 375)" className="selectPos">{ numbers.money(props.monthValue, false, false) }</text>
            </g>

            {/* Base Line */}
            <g className="barsCenter">
                <rect className="middleLine" x="92.5" y="371.1" width="2" height="5"/>
                <rect className="middleLine" x="92.5" y="355.9" width="2" height="5"/>
                <rect className="middleLine" x="92.5" y="363.5" width="2" height="5"/>
                <rect className="middleLine" x="92.5" y="348.3" width="2" height="5"/>
                <rect className="middleLine" x="92.5" y="340.7" width="2" height="5"/>
                <rect className="middleLine" x="92.5" y="333.1" width="2" height="5"/>
                <rect className="middleLine" x="92.5" y="325.4" width="2" height="5"/>
            </g>

            <polygon className="baseLines" points="283.6,350.8 277,346.2 277,349.8 -85.4,349.8 -85.4,351.8 277,351.8 277,355.4"/>

            <g className="bars">
                <rect className="baseLines" x="-47" y="325.4" width="2" height="50.7"/>
                <rect className="baseLines" x="233" y="325.4" width="2" height="50.7"/>
            </g>

            {/* KWH */}
            <path className="kwh" d="M293.1,345.5V356h-1.3v-10.5H293.1z M297.6,348.6l-3.2,3.5l-1.8,1.9l-0.1-1.3l1.3-1.5l2.3-2.4h1.5L297.6,348.6
                L297.6,348.6z M296.5,356l-2.6-3.5l0.7-1.1l3.5,4.7h-1.6V356z M301.7,353l2-6.9h1l-0.6,2.7l-2.1,7.3h-1L301.7,353z M299.7,346
                l1.6,6.8l0.5,3.1h-0.9l-2.4-10h1.2L299.7,346L299.7,346z M307.2,352.8l1.5-6.8h1.3l-2.4,10h-0.9L307.2,352.8z M304.8,346l1.9,6.9
                l0.7,3h-0.9l-2.1-7.3l-0.6-2.7L304.8,346L304.8,346z M312.6,345.5V356h-1.3v-10.5H312.6z M312.3,352h-0.5c0-0.5,0.1-1,0.2-1.4
                s0.4-0.8,0.6-1.1c0.3-0.3,0.6-0.6,0.9-0.7c0.4-0.2,0.8-0.3,1.2-0.3s0.7,0.1,1,0.2s0.5,0.3,0.7,0.5s0.4,0.5,0.5,0.8s0.2,0.7,0.2,1.2
                v4.8h-1.3v-4.9c0-0.4-0.1-0.7-0.2-0.9c-0.1-0.2-0.3-0.4-0.5-0.5s-0.5-0.2-0.8-0.2s-0.6,0.1-0.9,0.2s-0.5,0.3-0.7,0.5
                s-0.3,0.5-0.4,0.8C312.4,351.3,312.3,351.7,312.3,352z"/>
        </svg>
    );
}

export default MoonChart;