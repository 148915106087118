import React, { useEffect } from 'react';
import { Link, useSearchParams, useNavigate } from 'react-router-dom';
import './styles.css';
import TermsPrivacity from '~/components/Boxs/Terms/Privacity';

const Terms: React.FC<any> = () => {
    const [searchParams]    = useSearchParams();
    const navigate          = useNavigate();
    let term = searchParams.get("termo")

    useEffect(() => {
        if( term === 'privacidade' || term === 'consultoria'){
        }else navigate('/404', {replace: true})
    }, [term])

    function getTitleTerm () {
        switch (term) {
            case 'privacidade':
                return <h1>Termos de privacidade</h1>

            case 'consultoria':
                return <h1>Termos do contrato geral de assinatura</h1>

            default:
                return <Link to={'/admin'}>Voltar</Link>
        }
    }
    function getTextTerm () {
        switch (term) {
            case 'privacidade':
                return <TermsPrivacity/>

            case 'consultoria':
                return <TermsPrivacity/>

            default:
                return <></>
        }
    }

    return <main className='term'>
        {getTitleTerm()}
        <div className='body_term'>
            {getTextTerm()}
        </div>
    </main>
}

export default Terms;
