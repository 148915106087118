import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { FaTimes } from 'react-icons/fa';
import { GeneralContext } from '~/contexts';
import api from '~/services/api';

// Components
import Template from '~/pages/admin/Template';
import LoadingSimple from '~/components/Default/Loading/Simple';
import BillBox from '~/components/Boxs/Bills';

const ClientBills: React.FC<any> = (props: any) => {
    let params = useParams();
    let id     = params.id;

    // States
    const {user, breadcrumbs, setBreadcrumbs} = useContext(GeneralContext);
    const [ready, setReady]                   = useState(false);
    const [bills, setBills]                   = useState<any>(null);
    const [billsList, setBillsList]           = useState<any>([]);
    const [search, setSearch]                 = useState('');

    function onChangeSearch (event: any) {
        let data = event.target.value;
        let billsFilter = billsList.filter((el: any) =>
            el.company.name.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").indexOf(data.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, ""))>=0 ||
            (el.status==='not_rated' && 'Não Avaliado'.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").indexOf(data.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, ""))>=0) ||
            (el.status==='validated' && 'Validado'.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").indexOf(data.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, ""))>=0) ||
            (el.status==='errors' && 'Com erros'.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").indexOf(data.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, ""))>=0)
            ? true : false
        )

        setSearch(data)
        setBills(billsFilter);
    }

    function delBill (id: number) {
        api.delete(`/bills/${id}`, {
            headers: { Authorization: user.token }
        }).then(resp => {
            setTimeout(() => {
                setBills(resp.data)
                setReady(true)
            }, 2000)
        })
    }

    useEffect(() => {
        ready && !breadcrumbs && setBreadcrumbs({
            curr: 'Contas do Cliente',
            links: [
                { name: 'Lista de Clientes', url: '/admin/s/clientes'}
            ]
        });

        if (bills===null) {
            setReady(false)

            api.get(`/bills/l/${id}`, {headers: { Authorization: user.token }}).then(resp => {
                setTimeout(() => {
                    setBillsList(resp.data)
                    setBills(resp.data)
                    setReady(true)
                }, 1000);
            })

        }
    }, [id, user, ready, bills]);

    // Unmount
    useEffect(() => () => setBreadcrumbs(null), [])

    return <Template page="Administrador - Clientes - Contas" pageTitle="Gerenciamento de Contas do Cliente" className="cliBill">
        <div className="header">
            <h1>Contas do Cliente {props.client.name.split('|').join(' ')}</h1>

            <div className="searchBox">
                <input type="text" name="search" id="search" placeholder='Filtrar Contas...' value={search} onChange={onChangeSearch} disabled={ready && bills ? false : true} />

                { search!=='' && <button onClick={() => {
                    setSearch('')
                    setBills(billsList)
                }}>
                    <FaTimes />
                </button> }
            </div>
        </div>

        <div className="billsBox">
            <header>
                <div className="blank"></div>
                <div className="provider">Distribuidora</div>
                <div className="modality">Modalidade</div>
                <div className="date">Data da Conta</div>
                <div className="status">Status</div>
                <div className="btns"></div>
            </header>

            <section>
                {
                    ready ? (
                        bills && bills.length>0 ?
                            bills.map((el: any) => <BillBox
                                key={`box_${id}_${el.id}`}
                                id={id}
                                bill={el.id}
                                date={el.reference_date}
                                company={el.company}
                                provider={el.provider}
                                modality={el.tariff_modality}
                                status={el.status}
                                path={el.path}
                                delBill={bills.length>1 ? delBill : null}
                            />) :
                            <div className='noData'>Não foi possível localizar contas para o clientes</div>
                    ) : <LoadingSimple />
                }
            </section>
        </div>
    </Template>;
}

export default ClientBills;