import React, {useContext} from 'react';
import { Link } from 'react-router-dom';
import { MixPanelContext, GeneralContext } from '~/contexts';

const Button: React.FC<any> = ({link, text, type}: any) => {
    const {user}       = useContext(GeneralContext);
    const {onboarding} = useContext(MixPanelContext);

    return <Link
        to={link}
        onClick={() => onboarding(user.id, 'Complete', 'business')}
    >{text}</Link>
}

export default Button;