import React, { useState, useContext, ChangeEvent, useEffect } from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import ptBRLocale from 'date-fns/locale/pt-BR';
import api, { apiTariffs } from '~/services/api';
import Dates from '~/utils/dates';
import sort_by from '~/utils/sort';
import env from '~/utils/env';
import Validation from './validation';
import './styles.css';

// Contexts
import { GeneralContext, ModalContext } from '~/contexts';

// Componentes
import CurrencyInput from '~/components/Modules/CurrencyMask';
import { Button } from '~/components/Forms';
import { FiLoader } from 'react-icons/fi';
// Current Date
let newDate = new Date();

// Base Data
let billDataDefault = {
    company_id: 0,
    provider: '',
    type: 'Cative',
    tariff_modality: 'Verde',
    supplied_voltage: 'A4',
    contracted_energy_demand: '',
    total_value_due: '',
    bill_number: `Manual_${newDate.getUTCFullYear() + '-' + ((newDate.getUTCMonth() + 1) <= 9 ? '0' : '') + (newDate.getUTCMonth() + 1) + '-' + ((newDate.getUTCDate() + 1) <= 9 ? '0' : '') + newDate.getUTCDate()}`,
    reference_date: '',
    read_dates: {
        last: '',
        current: '',
        next: ''
    },
    tax_liquot: {
        icms: '',
        pis: '',
        cofins: ''
    },
    bill_items: {
        demand: {
            amount: 0,
            tariff: {
                icms: 0,
                noicms: 0
            },
            value: {
                icms: 0,
                total: 0
            },
        },
        demandNoIcms: {
            amount: 0,
            tariff: {
                icms: 0,
                noicms: 0
            },
            value: {
                icms: 0,
                total: 0
            },
        },
        demandPeak: {
            amount: 0,
            tariff: {
                icms: 0,
                noicms: 0
            },
            value: {
                icms: 0,
                total: 0
            },
        },
        demandOffPeak: {
            amount: 0,
            tariff: {
                icms: 0,
                noicms: 0
            },
            value: {
                icms: 0,
                total: 0
            },
        },
        demandNoIcmsPeak: {
            amount: 0,
            tariff: {
                icms: 0,
                noicms: 0
            },
            value: {
                icms: 0,
                total: 0
            },
        },
        demandNoIcmsOffPeak: {
            amount: 0,
            tariff: {
                icms: 0,
                noicms: 0
            },
            value: {
                icms: 0,
                total: 0
            },
        },
        consumptionPeakHoursTE: {
            amount: 0,
            tariff: {
                icms: 0,
                noicms: 0
            },
            value: {
                icms: 0,
                total: 0
            },
        },
        consumptionOffPeakHoursTE: {
            amount: 0,
            tariff: {
                icms: 0,
                noicms: 0
            },
            value: {
                icms: 0,
                total: 0
            },
        },
        consumptionPeakHoursTUSD: {
            amount: 0,
            tariff: {
                icms: 0,
                noicms: 0
            },
            value: {
                icms: 0,
                total: 0
            },
        },
        consumptionOffPeakHoursTUSD: {
            amount: 0,
            tariff: {
                icms: 0,
                noicms: 0
            },
            value: {
                icms: 0,
                total: 0
            },
        },
        demandOvertaking: {
            amount: 0,
            tariff: {
                icms: 0,
                noicms: 0
            },
            value: {
                icms: 0,
                total: 0
            },
        },
        reactiveEnergyPeakHours: {
            amount: 0,
            tariff: {
                icms: 0,
                noicms: 0
            },
            value: {
                icms: 0,
                total: 0
            },
        },
        reactiveEnergyOffPeakHours: {
            amount: 0,
            tariff: {
                icms: 0,
                noicms: 0
            },
            value: {
                icms: 0,
                total: 0
            },
        },
        freeMarketValues: {
            tariffBenefit: 0,
            covid: 0
        },
        others: {
            publicIllumination: 0,
            fee: 0,
            interest: 0,
            currencyCorrection: 0,
            discounts: 0
        }
    },
    consumptions: {
    }
}

const Form: React.FC<any> = ({ setReady, bill, setMessages }) => {
    const [content, setContent] = useState<string>('general');
    const [billData, setBillData] = useState<any>(billDataDefault);
    const [historic, setHistoric] = useState(Array(12).fill({ consumptionPeakHours: '', consumptionOffPeakHours: '' }));
    const [errors, setErrors] = useState<any>(null);
    const [errorsApi, setErrorsApi] = useState<any>(null);
    const [providers, setProviders] = useState<any>([]);
    const [inputDates, setInputDates] = useState<any>({ reference: '', last: '', current: '', next: '' });
    const [dateOpen, setDatesOpen] = useState<any>({ reference: false, last: false, current: false, next: false });
    const [buttonReady, setButtonReady] = useState<boolean>(true);

    // Contexts
    const { user, client } = useContext(GeneralContext);
    const Modal = useContext(ModalContext);

    function changeTab(tab: string) {
        if (tab !== content) setContent(tab)
    }

    function setDateOpen(type: string, status: boolean) {
        setDatesOpen({ ...dateOpen, [type]: status })
    }

    function save() {
        setButtonReady(false)
        let validations = Validation.fields(billData);
        let dataApi = {
            ...billData,
            consumptions: historic,
            contracted_energy_demand: (billData.tariff_modality === "Azul") ?
                { peakHours: billData.contracted_energy_demand_peak, offPeakHours: billData.contracted_energy_demand_off_peak } :
                billData.contracted_energy_demand
        }
        setErrors(validations)

        if (!validations) {
            api.post('bills/manual', dataApi, {
                headers: { Authorization: user.token }
            }).then((resp: any) => {
                if (resp.data.valid) {
                    setMessages(<div className="msg">Cadastro Manual realizado com sucesso!</div>)
                    setTimeout(() => setMessages(<></>), 3000);
                    bill ? setReady(null) : setReady(false)
                    Modal.setModalOpen(false)
                } else {
                    setMessages(<div className="msg err">Ocorreu um erro ao salvar Dados manual!</div>)
                    setTimeout(() => setMessages(<></>), 3000);
                    setErrorsApi(resp.data.errors)
                }
            }).catch(err => {
                console.log(err);
            });
        }
        setButtonReady(true)

    }

    function onChange(event: ChangeEvent<HTMLInputElement | HTMLSelectElement>) {
        const { name, value } = event.target;
        const data = { [name]: (name === 'active' ? Number(value) : value) };
        setBillData({ ...billData, ...data });
    }

    function onChangeFloat(event: ChangeEvent<HTMLInputElement | HTMLSelectElement>, originalValue: any, maskedValue?: any) {
        let { name } = event.target
        let data: any = { [name]: originalValue }
        let newData = JSON.parse(JSON.stringify(billData))

        // Check if is a object
        if (name.indexOf('.') >= 0) {
            let nBase = name.split('.')
            name = nBase[0]

            if (nBase.length === 2) {
                data = { [nBase[0]]: { [nBase[1]]: originalValue } }
            } else if (nBase.length === 3) {
                if (nBase[1] === 'consumptionPeakHoursTUSD') {
                    data = { [nBase[0]]: { 'consumptionPeakHoursTUSD': { [nBase[2]]: originalValue }, 'consumptionPeakHoursTE': { [nBase[2]]: originalValue } } }
                } else if (nBase[1] === 'consumptionOffPeakHoursTUSD') {
                    data = { [nBase[0]]: { 'consumptionOffPeakHoursTUSD': { [nBase[2]]: originalValue }, 'consumptionOffPeakHoursTE': { [nBase[2]]: originalValue } } }
                } else {
                    data = { [nBase[0]]: { [nBase[1]]: { [nBase[2]]: originalValue } } }
                }
            } else if (nBase.length === 4) {
                data = { [nBase[0]]: { [nBase[1]]: { [nBase[2]]: { [nBase[3]]: originalValue } } } }
            }
        }

        mergeDeep(newData, data)
        // console.log(newData)
        setBillData(newData)
    }

    function onChangeHistoric(event: ChangeEvent<HTMLInputElement | HTMLSelectElement>, originalValue: any, maskedValue?: any) {
        let nameBase = event.target.name.split('.');
        let idx = Number(nameBase[0]);
        let name = nameBase[1];

        const updatedHistoric = [...historic];

        // Atualiza os dados do mês específico
        updatedHistoric[idx] = {
            ...updatedHistoric[idx],
            [name]: originalValue,
        };

        setHistoric(updatedHistoric);
    }

    function onChangeDate(date: any, type: string) {
        let data = {}
        let newData = JSON.parse(JSON.stringify(billData))
        let newDate = Dates.getDateDBFormatted(date, true)

        if (type === 'reference') data = { reference_date: newDate }
        else data = { read_dates: { [type]: newDate } }

        mergeDeep(newData, data)
        setBillData(newData)
        setInputDates({ ...inputDates, [type]: Dates.getFormatDate(date, (type === 'reference' ? 'mm/yyyy' : 'dd/mm/yyyy')) })
    }

    function isObject(item: any) {
        return (item && typeof item === 'object' && !Array.isArray(item));
    }

    function mergeDeep(target: any, ...sources: any) {
        if (!sources.length) return target;
        const source = sources.shift();

        if (isObject(target) && isObject(source)) {
            for (const key in source) {
                if (isObject(source[key])) {
                    if (!target[key]) Object.assign(target, { [key]: {} });
                    mergeDeep(target[key], source[key]);
                } else {
                    Object.assign(target, { [key]: source[key] });
                }
            }
        }

        return target;
    }

    function getHistoricFields(itemIndex: number) {
        const item = historic[itemIndex];

        return (
            <>
                <div className="input-group">
                    <label htmlFor={`[${itemIndex}].consumptionPeakHours`}>Consumo Ponta</label>
                    <CurrencyInput
                        id={`${itemIndex}.consumptionPeakHours`}
                        name={`${itemIndex}.consumptionPeakHours`}
                        placeholder="0,00"
                        onChangeValue={onChangeHistoric}
                        value={item?.consumptionPeakHours}
                        hideSymbol
                    />
                </div>

                <div className="input-group">
                    <label htmlFor={`[${itemIndex}].consumptionOffPeakHours`}>Consumo Fora Ponta</label>
                    <CurrencyInput
                        id={`${itemIndex}.consumptionOffPeakHours`}
                        name={`${itemIndex}.consumptionOffPeakHours`}
                        placeholder="0,00"
                        onChangeValue={onChangeHistoric}
                        value={item?.consumptionOffPeakHours}
                        hideSymbol
                    />
                </div>
            </>
        );
    }

    function renderHistoricItems() {
        const componentMonths = [];

        for (let i = 0; i < 12; i += 2) {
            componentMonths.push(
                <div className="box" key={`line_${i}`}>
                    <div className="col">
                        <h4>Mês {i + 1}</h4>
                        <div className="form-group no-margin">
                            {getHistoricFields(i)}
                        </div>
                    </div>
                    {i + 1 < 12 && (
                        <div className="col">
                            <h4>Mês {i + 2}</h4>
                            <div className="form-group no-margin">
                                {getHistoricFields(i + 1)}
                            </div>
                        </div>
                    )}
                </div>
            );
        }

        return componentMonths;
    }

    function renderErrorsApi() {
        if (errorsApi || errors) {
            return (<>
                {errors && <p>Preencha todos os campos</p>}
                {errorsApi?.date && <p>Já existe uma conta cadastrada pro mês selecionado</p>}
                {errorsApi?.tariff_modality && <p>Você está tentando cadastrar uma modalidade tarifária diferente</p>}
            </>)
        }
        return <></>
    }

    function getContent() {
        if (content === 'historic') {
            return <>
                {
                    billData.tariff_modality === "Azul" && <div className="box">
                        <div className="col">
                            <h3>Demanda Faturada Ponta <sup title="Campo obrigatório">*</sup></h3>
                            <div className="input-group no-margin">
                                <CurrencyInput
                                    id="bill_items.demandPeak.amount"
                                    className={errors && errors.bill_items && errors.bill_items['demandPeak'] && errors.bill_items['demandPeak'].amount ? "error" : ""}
                                    name={`bill_items.demandPeak.amount`} placeholder="0,00"
                                    onChangeValue={onChangeFloat}
                                    value={billData.bill_items['demandPeak'] && billData.bill_items['demandPeak'].amount ? billData.bill_items['demandPeak'].amount : ''}
                                    hideSymbol
                                />
                            </div>
                        </div>

                        <div className="col">
                            <h3>Demanda Faturada Fora Ponta <sup title="Campo obrigatório">*</sup></h3>
                            <div className="input-group no-margin">
                                <CurrencyInput
                                    id="bill_items.demandOffPeak.amount"
                                    className={errors && errors.bill_items && errors.bill_items['demandOffPeak'] && errors.bill_items['demandOffPeak'].amount ? "error" : ""}
                                    name={`bill_items.demandOffPeak.amount`} placeholder="0,00"
                                    onChangeValue={onChangeFloat}
                                    value={billData.bill_items['demandOffPeak'] && billData.bill_items['demandOffPeak'].amount ? billData.bill_items['demandOffPeak'].amount : ''}
                                    hideSymbol
                                />
                            </div>
                        </div>
                    </div>
                }

                <div className="box">
                    {
                        billData.tariff_modality === "Verde" && <div className="col">
                            <h3>Demanda Faturada <sup title="Campo obrigatório">*</sup></h3>
                            <div className="input-group no-margin">
                                <CurrencyInput
                                    id="bill_items.demand.amount"
                                    className={errors && errors.bill_items && errors.bill_items['demand'] && errors.bill_items['demand'].amount ? "error" : ""}
                                    name={`bill_items.demand.amount`} placeholder="0,00"
                                    onChangeValue={onChangeFloat}
                                    value={billData.bill_items['demand'] && billData.bill_items['demand'].amount ? billData.bill_items['demand'].amount : ''}
                                    hideSymbol
                                />
                            </div>
                        </div>
                    }
                    <div className="col">
                        <h3>Consumo Ponta <sup title="Campo obrigatório">*</sup></h3>
                        <div className="input-group no-margin">
                            <CurrencyInput
                                id="bill_items.consumptionPeakHoursTUSD.amount"
                                className={errors && errors.bill_items && errors.bill_items['consumptionPeakHoursTUSD'] && errors.bill_items['consumptionPeakHoursTUSD'].amount ? "error" : ""}
                                name={`bill_items.consumptionPeakHoursTUSD.amount`} placeholder="0,00"
                                onChangeValue={onChangeFloat}
                                value={billData.bill_items['consumptionPeakHoursTUSD'] && billData.bill_items['consumptionPeakHoursTUSD'].amount ? billData.bill_items['consumptionPeakHoursTUSD'].amount : ''}
                                hideSymbol
                            />
                        </div>
                    </div>

                    <div className="col">
                        <h3>Consumo Fora Ponta <sup title="Campo obrigatório">*</sup></h3>
                        <div className="input-group no-margin">
                            <CurrencyInput
                                id="bill_items.consumptionOffPeakHoursTUSD.amount"
                                className={errors && errors.bill_items && errors.bill_items['consumptionOffPeakHoursTUSD'] && errors.bill_items['consumptionOffPeakHoursTUSD'].amount ? "error" : ""}
                                name={`bill_items.consumptionOffPeakHoursTUSD.amount`} placeholder="0,00"
                                onChangeValue={onChangeFloat}
                                value={billData.bill_items['consumptionOffPeakHoursTUSD'] && billData.bill_items['consumptionOffPeakHoursTUSD'].amount ? billData.bill_items['consumptionOffPeakHoursTUSD'].amount : ''}
                                hideSymbol
                            />
                        </div>
                    </div>
                </div>

                <hr />

                {renderHistoricItems()}

            </>
        } else if (content === 'bill') {
            return <>
                <div className="form-group">
                    {billData.tariff_modality === "Azul" && <>
                        <div className="input-group" title={errors && errors.contracted_energy_demand_peak ? errors.contracted_energy_demand_peak : ""}>
                            <label htmlFor="contracted_energy_demand_peak">Demanda Contratada Ponta <sup title="Campo obrigatório">*</sup></label>
                            <CurrencyInput
                                id="contracted_energy_demand_peak"
                                className={errors && errors.contracted_energy_demand_peak ? "error" : ""}
                                name="contracted_energy_demand_peak" placeholder="0,00"
                                onChangeValue={onChangeFloat}
                                value={billData.contracted_energy_demand_peak ? billData.contracted_energy_demand_peak : ''}
                                hideSymbol
                            />
                        </div>
                        <div className="input-group" title={errors && errors.contracted_energy_demand_off_peak ? errors.contracted_energy_demand_off_peak : ""}>
                            <label htmlFor="contracted_energy_demand_off_peak">Demanda Contratada Fora Ponta <sup title="Campo obrigatório">*</sup></label>
                            <CurrencyInput
                                id="contracted_energy_demand_off_peak"
                                className={errors && errors.contracted_energy_demand_off_peak ? "error" : ""}
                                name="contracted_energy_demand_off_peak" placeholder="0,00"
                                onChangeValue={onChangeFloat}
                                value={billData.contracted_energy_demand_off_peak ? billData.contracted_energy_demand_off_peak : ''}
                                hideSymbol
                            />
                        </div>
                    </>}

                    {billData.tariff_modality === "Verde" &&
                        <div className="input-group" title={errors && errors.contracted_energy_demand ? errors.contracted_energy_demand : ""}>
                            <label htmlFor="contracted_energy_demand">Demanda Contratada Única <sup title="Campo obrigatório">*</sup></label>
                            <CurrencyInput
                                id="contracted_energy_demand"
                                className={errors && errors.contracted_energy_demand ? "error" : ""}
                                name="contracted_energy_demand" placeholder="0,00"
                                onChangeValue={onChangeFloat}
                                value={billData.contracted_energy_demand ? billData.contracted_energy_demand : ''}
                                hideSymbol
                            />
                        </div>
                    }

                    <div className="input-group" title={errors && errors.total_value_due ? errors.total_value_due : ""}>
                        <label htmlFor="total_value_due">Valor Total da Fatura (R$) <sup title="Campo obrigatório">*</sup></label>
                        <CurrencyInput
                            id="total_value_due"
                            className={errors && errors.total_value_due ? "error" : ""}
                            name="total_value_due" placeholder="0,00"
                            onChangeValue={onChangeFloat}
                            value={billData.total_value_due ? billData.total_value_due : ''}
                            hideSymbol
                        />
                    </div>
                </div>

                <div className="form-group">
                    <div className="input-group" title={errors && errors.tax_liquot_icms ? errors.tax_liquot_icms : ""}>
                        <label htmlFor="tax_liquot.icms">ICMS (%) <sup title="Campo obrigatório">*</sup></label>
                        <CurrencyInput
                            id="tax_liquot.icms"
                            className={errors && errors.tax_liquot_icms ? "error" : ""}
                            name="tax_liquot.icms" placeholder="0,00"
                            onChangeValue={onChangeFloat}
                            value={billData.tax_liquot.icms ? billData.tax_liquot.icms : ''}
                            hideSymbol
                        />
                    </div>

                    <div className="input-group" title={errors && errors.tax_liquot_pis ? errors.tax_liquot_pis : ""}>
                        <label htmlFor="tax_liquot.pis">PIS (%) <sup title="Campo obrigatório">*</sup></label>
                        <CurrencyInput
                            id="tax_liquot.pis"
                            className={errors && errors.tax_liquot_pis ? "error" : ""}
                            name="tax_liquot.pis" placeholder="0,00"
                            onChangeValue={onChangeFloat}
                            value={billData.tax_liquot.pis ? billData.tax_liquot.pis : ''}
                            hideSymbol
                        />
                    </div>

                    <div className="input-group" title={errors && errors.tax_liquot_cofins ? errors.tax_liquot_cofins : ""}>
                        <label htmlFor="tax_liquot.cofins">Cofins (%) <sup title="Campo obrigatório">*</sup></label>
                        <CurrencyInput
                            id="tax_liquot.cofins"
                            className={errors && errors.tax_liquot_cofins ? "error" : ""}
                            name="tax_liquot.cofins" placeholder="0,00"
                            onChangeValue={onChangeFloat}
                            value={billData.tax_liquot.cofins ? billData.tax_liquot.cofins : ''}
                            hideSymbol
                        />
                    </div>
                </div>
            </>
        }

        return <>
            <div className="form-group no-margin">
                <div className="input-group">
                    <label htmlFor="provider">Distribuidora <sup title="Campo obrigatório">*</sup></label>
                    <select name="provider" id="provider" onChange={onChange} value={billData.provider} required={true} className={errors && errors.provider ? "error" : ""}>
                        <option disabled value="" >- Selecione uma Distribuidora -</option>
                        {
                            providers.length > 0 ?
                                providers.map((el: any) => <option key={`prov_${el.id}`} value={el.name}>{el.name}</option>) :
                                <></>
                        }
                    </select>
                </div>

                <div className="input-group" title={errors && errors.reference_date ? errors.reference_date : ""}>
                    <label htmlFor="reference_date">Data de Referência da Fatura <sup title="Campo obrigatório">*</sup></label>
                    <input id="reference_date" className={errors && errors.reference_date ? "error" : ""} name="reference_date" type="text" placeholder='10/2020' data-type="date" value={inputDates.reference} onClick={() => { setDateOpen('reference', !dateOpen.reference) }} readOnly />
                    <DatePicker
                        format='MM/yyyy'
                        open={dateOpen.reference}
                        onOpen={() => setDateOpen('reference', true)}
                        onClose={() => setDateOpen('reference', false)}
                        value={new Date(billData.reference_date)}
                        disableFuture
                        slotProps={{ textField: { size: 'small' } }}
                        onChange={(date) => onChangeDate(date, 'reference')}
                        views={['year', 'month']}
                    />
                </div>
            </div>

            <div className="form-group">
                <div className="input-group">
                    <label htmlFor="supplied_voltage">Grupo Tarifário <sup title="Campo obrigatório">*</sup></label>
                    <select name="supplied_voltage" id="supplied_voltage" onChange={onChange} value={billData.supplied_voltage}>
                        <option value="A2">A2</option>
                        <option value="A3">A3</option>
                        <option value="A3a">A3a</option>
                        <option value="A4">A4</option>
                        <option value="AS">AS</option>
                    </select>
                </div>

                <div className="input-group">
                    <label htmlFor="tariff_modality">Modalidade Tarifária <sup title="Campo obrigatório">*</sup></label>
                    <select name="tariff_modality" id="tariff_modality" onChange={onChange} value={billData.tariff_modality}>
                        <option value="Azul">Azul</option>
                        <option value="Verde">Verde</option>
                    </select>
                </div>
            </div>
        </>
    }

    useEffect(() => {
        providers.length === 0 && api.get(`plans/providers/all`, {
            headers: { Authorization: user.token }
        }).then(resp => {
            let dataSort = resp.data.sort(sort_by({ name: 'name' }));
            setProviders(dataSort)
        });

        if (bill) {
            billData.company_id === 0 && setBillData({ ...billData, company_id: bill.company.id, billErrors: bill })
        } else {
            billData.company_id === 0 && setBillData({ ...billData, company_id: client })
        }

    }, [user, billData, providers, errors])

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ptBRLocale}>
            <div className="tabs">
                <div className={`tab-general${content === 'general' ? ' active' : ''}`} onClick={() => changeTab('general')}>Gerais</div>
                <div className={`tab-historic${content === 'historic' ? ' active' : ''}`} onClick={() => changeTab('historic')}>Histórico</div>
                <div className={`tab-bill${content === 'bill' ? ' active' : ''}`} onClick={() => changeTab('bill')}>Fatura</div>
            </div>

            <form method="post" onSubmit={(event: React.FormEvent<HTMLFormElement>) => event.preventDefault()} className="editBills">
                {getContent()}

                <div className="errors">
                    {renderErrorsApi()}
                </div>
            </form>
            <footer>
                <Button
                    type="button"
                    id="btnSave"
                    func={() => save()}
                    text={buttonReady ? 'Salvar' : 'Salvando...'}
                    disabled={buttonReady ? false : true}
                    icon={!buttonReady && <FiLoader size={10} />}
                    className={`${buttonReady ? 'btnSave' : 'btnLoad'}`}
                />
                {/* <button 
                id="btnSave" 
                type="button" 
                onClick={() => save()}>Salvar
                </button> */}
                <button id="btnCancel" type="button" onClick={() => Modal.setModalOpen(false)}>Cancelar</button>
            </footer>
        </LocalizationProvider>
    );
}

export default Form;