import React, { useContext, useEffect, useState } from 'react';
import '../main.css';
import './styles.css';
import ReactTooltip from 'react-tooltip';
import mobile from 'is-mobile';
import { Bar, XAxis, YAxis, CartesianGrid, Tooltip, Line, ComposedChart, ResponsiveContainer, Legend } from 'recharts';
import { FaInfoCircle } from 'react-icons/fa';
import { ModalContext } from '~/contexts';
import dates from '~/utils/dates';
import numbers from '~/utils/numbers';
import Funcs from '../funcs';

const DemandAnalysis:React.FC<any> = ({className, styles, months, infos, type}: any) => {
    const {setModalOpen, setModalTitle, setModalBody} = useContext(ModalContext);
	const [data, setData]         = useState<any>(null);
	const [interval, setInterval] = useState(0);
	const [month, setMonth]       = useState<any>(null);

	// Chart Funcions
	const renderCustomTooltip = ({ active, payload }: any) => {
		let billed: any = null;
		let demand: any    = null;

		payload.map((el: any, idx: number) => {
			if (idx===0) billed = el;
			else demand = el;
		})

		// Treat Title
		let dateBase = billed ? billed.payload.date.split('-') : null;
		let title    = billed ? dates.getNameMonth(dateBase[1]) + '/' + dateBase[0] : '';
		let modality = billed ? billed.payload.tariff_modality : '';

		return <div className='tooltipChart'>
			<h4>{title}</h4>
			{modality ? <h5><b>Modalidade:</b> {modality}</h5> : <></>}
			{
				modality ? <>
					<div style={{color: demand ? demand.color : '#A81111'}}><b>Contratada:</b> <span>{demand && demand.value>0 ? numbers.money(demand.value, false, false) : '0'}</span></div>
					<div style={{color: billed ? billed.color : '#657A90'}}><b>Faturada:</b> <span>{billed && billed.value>0 ? numbers.money(billed.value, false, false) : '0'}</span></div>
				</> : <div className='alert'>Não há dados<br />para este mês!</div>
			}
		</div>
	}

	const renderCustomLegend = ({ active, payload }: any) => {
		let billed: any = null;
		let demand: any    = null;

		payload.map((el: any, idx: number) => {
			if (idx===0) billed = el;
			else demand = el;
		})

		return <>
			<div style={{color: '#666666'}}>
				<span className="color" style={{backgroundColor: billed ? billed.color : '#A0A0A0'}}></span>
				Demanda Ultilizada
			</div>

			<div style={{color: '#666666'}}>
				<span className="color" style={{backgroundColor: demand ? demand.color : '#888888'}}></span>
				Demanda Contratada
			</div>
		</>
	}

	function demandType () {
		if (type && type==='peak') return 'Ponta';
		else if (type && type==='offPeak') return 'Fora Ponta';
		return '';
	}

	function demandKey () {
		if (type && type==='peak') return 'demand.peak';
		return 'demand.offPeak';
	}

	function valueKey () {
		if (type && type==='peak') return 'value.peak';
		return 'value.offPeak';
	}

	// Mount Functions
	useEffect(() => {
		setMonth(months)

		if (infos!==undefined) {
			let dataInfo = JSON.parse(JSON.stringify(infos)).reverse();
			switch (month) {
				case 12:
					setData(dataInfo)
					setInterval(2)
					break
				case 6:
					setData(dataInfo.slice(dataInfo.length-6))
					setInterval(1)
					break
				case 3:
					setData(dataInfo.slice(dataInfo.length-3))
					setInterval(0)
					break
			}
		}
    }, [month, months, infos]);

	function modalTooltip () {
		if (mobile()) {
			setModalTitle('Análise de Demanda (em kWh)')
			setModalBody(<>
				<p>Este gráfico compara a demanda Contratada com a Utilizada.</p>
				<ul>
					<li><b>Demanda Contratada:</b> Potência contratada com a distribuidora de energia.</li>
					<li><b>Demanda Utilizada:</b> Potência efetivamente utilizada pela empresa.</li>
					<li><a href="https://energizou.com.br" target="_blank" rel="noreferrer"><b>Saiba mais</b> sobre Demanda</a></li>
				</ul>
				<p className="warning">Este gráfico apresenta o valor total da contas de luz enviadas.</p>
				<p className="notes">Para a melhor experiência em <b>gráficos</b> faça o upload de pelo menos <b>3 Contas de Luz na página "Histórico"</b>.</p>
			</>)
			setModalOpen(true)
		}
    }

    return <div  className={`${className ? className : ''} demandAnalysis`} style={styles}>
		<header>
			<div className="titles">
				<h3>Análise de Demanda {demandType()} (em kW)</h3>
				<h4>Demanda Utilizada vs. Demanda Faturada</h4>
			</div>

			<div className="hover" data-tip="tooltip" data-for="demandAnalysisInfo">
				<FaInfoCircle color='#c0c0c0' onClick={() => modalTooltip()}/>

				{
					!mobile()?
					<ReactTooltip id="demandAnalysisInfo" className='infosBox big' place="left" type="light" effect="solid" clickable={true} delayHide={1000}>
						<p>Este gráfico compara a demanda Contratada com a Utilizada.</p>
						<ul>
							<li><b>Demanda Contratada:</b> Potência contratada com a distribuidora de energia.</li>
							<li><b>Demanda Utilizada:</b> Potência efetivamente utilizada pela empresa.</li>
							<li><a href="https://energizou.com.br" target="_blank" rel="noreferrer"><b>Saiba mais</b> sobre Demanda</a></li>
						</ul>
						<p className="warning">Este gráfico apresenta o valor total da contas de luz enviadas.</p>
						<p className="notes">Para a melhor experiência em <b>gráficos</b> faça o upload de pelo menos <b>3 Contas de Luz na página "Histórico"</b>.</p>
					</ReactTooltip> :
					<></>
				}
			</div>
		</header>

		<section className="content">
			<ResponsiveContainer height={mobile() ? 250 : 350}>
				<ComposedChart
					data={data}
					margin={{
						top: 20,
						right: 30,
						left: 20,
						bottom: 5,
					}}
				>
					<CartesianGrid vertical={false}/>
					<XAxis dataKey="date" interval={interval} tick={Funcs.renderXAxisLabel} />
					<YAxis tick={Funcs.renderYAxisLabel}/>
					<Tooltip content={renderCustomTooltip} />
					{!mobile() && <Legend content={renderCustomLegend} />}

					<Bar dataKey={valueKey()} fill="#ADC7E0" />
					<Line dataKey={demandKey()} type="monotone" stroke="#DF0D0D" strokeDasharray="10 5" strokeWidth={1.5} dot={{ stroke: 'red', strokeWidth: 2.5, r: 1 }} />
				</ComposedChart>
			</ResponsiveContainer>

			{
				mobile() && (
					<div className="legends">
						<div style={{color: '#666666'}}>
							<span className="color" style={{backgroundColor: '#ADC7E0'}}></span>
							Demanda ultilizada
						</div>

						<div style={{color: '#666666'}}>
							<span className="color" style={{backgroundColor: '#DF0D0D'}}></span>
							Demanda Contratada
						</div>
					</div>
				)
			}
		</section>
	</div>
}

export default DemandAnalysis;