import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { FaTimes } from 'react-icons/fa';
import { GeneralContext } from '~/contexts';
import api from '~/services/api';
import './styles.css';

// Components
import Template from '~/pages/admin/Template';
import LoadingSimple from '~/components/Default/Loading/Simple';
import BillErrorBox from '~/components/Boxs/Bills/errors';

const ClientBillsErrors: React.FC<any> = (props: any) => {
    let params = useParams();
    let id     = params.id;

    // States
    const {user, breadcrumbs, setBreadcrumbs} = useContext(GeneralContext);
    const [ready, setReady]                   = useState(false);
    const [client, setClient]                 = useState<any>(null);
    const [bills, setBills]                   = useState<any>(null);
    const [billsList, setBillsList]           = useState<any>([]);
    const [search, setSearch]                 = useState('');

    function onChangeSearch (event: any) {
        let data = event.target.value;
        let billsFilter = billsList.filter((el: any) =>
            el.company.name.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").indexOf(data.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, ""))>=0 ||
            (el.status==='not_rated' && 'Não Avaliado'.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").indexOf(data.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, ""))>=0) ||
            (el.status==='validated' && 'Validado'.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").indexOf(data.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, ""))>=0) ||
            (el.status==='errors' && 'Com erros'.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").indexOf(data.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, ""))>=0)
            ? true : false
        )

        setSearch(data)
        setBills(billsFilter);
    }

    function delBill (id: number) {
        api.delete(`/bills/${id}`, {
            headers: { Authorization: user.token }
        }).then(resp => {
            setTimeout(() => {
                setBills(resp.data)
                setReady(true)
            }, 2000)
        })
    }

    useEffect(() => {
        // Set User Data
        client===null && api.get(`/users/${id}`, {headers: { Authorization: user.token }}).then(resp => setClient(resp.data))

        // Set Client Errors
        if (client && bills===null) {
            setReady(false)

            api.get(`/bills/errors/${id}`, {headers: { Authorization: user.token }}).then(resp => setTimeout(() => {
                setBillsList(resp.data)
                setBills(resp.data)
                setReady(true)
            }, 1000))

        }
    }, [id, user, ready, client, bills]);

    // Breadcrumbs
    useEffect(() => {
        ready && !breadcrumbs && setBreadcrumbs({
            curr: 'Contas com erros do Cliente',
            links: [
                { name: 'Lista de Clientes', url: '/admin/s/clientes'}
            ]
        });
    }, [ready])

    // Unmount
    useEffect(() => () => setBreadcrumbs(null), [])

    return <Template page="Administrador - Clientes - Contas com erros" pageTitle="Gerenciamento de Contas com erros do Cliente" className="cliBillError">
        {
            ready ? (<>
                <div className="header">
                    <h1>Contas com erros do Cliente {client.name.split('|').join(' ')}</h1>

                    <div className="searchBox">
                        <input type="text" name="search" id="search" placeholder='Filtrar Contas...' value={search} onChange={onChangeSearch} disabled={ready && bills ? false : true} />

                        { search!=='' && <button onClick={() => {
                            setSearch('')
                            setBills(billsList)
                        }}>
                            <FaTimes />
                        </button> }
                    </div>
                </div>

                <div className="billsErrorsBox">
                    <header>
                        <div className="blank"></div>
                        <div className="client">Cliente</div>
                        <div className="date">Data do Erro</div>
                        <div className="btns"></div>
                    </header>

                    <section>
                        {
                            bills && bills.length>0 ?
                            bills.map((el: any) => (
                                <BillErrorBox
                                    key={`box_${id}_${el.id}`}
                                    id={id}
                                    bill={el.id}
                                    pdf={el.pdf}
                                    json={el.json || null}
                                    date={el.date}
                                    delBill={delBill}
                                />
                            )) : <div className='noData'>Não foi possível localizar contas com erros para o clientes</div>
                        }
                    </section>
                </div>
            </>) : <LoadingSimple />
        }
    </Template>;
}

export default ClientBillsErrors;