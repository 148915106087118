import React, { useContext, useEffect, useState } from 'react';
import { apiTariffs } from '~/services/api';
import env from '~/utils/env';
import './styles.css';

// Contexts
import { ModalContext } from '~/contexts';

// Components
import { Button, InputLabel } from '~/components/Forms';

const inputsBase = {
    id: null,
    provider_id: 0,
    provider_name: "",
    year_1: 1,
    value_1: 0,
    year_2: 2,
    value_2: 0,
    year_3: 3,
    value_3: 0,
    year_4: 4,
    value_4: 0
}

const CreatePlan: React.FC<any> = (props) => {
    const {setModalButton}          = useContext(ModalContext);
    const [ready, setReady]         = useState(false);
    const [providers, setProviders] = useState<any>(null);
    const [inputs, setInputs]       = useState<any>(null);
    const [saving, setSaving]       = useState<any>(false);

    function onChangeProvider (event: any) {
        let data     = event.target.value;
        let dataBase = data.split('|');
        let id       = parseInt(dataBase[0]);
        let name     = dataBase[1];

        let inputValues = JSON.parse(JSON.stringify(inputs));
        setInputs({...inputValues, provider_id: id, provider_name: name});
    }

    function onChange (event: any) {
        const {name, value} = event.target;
        let inputValues = JSON.parse(JSON.stringify(inputs));
        inputValues[`${name}`] = parseFloat(value);
        setInputs(inputValues);
    }

    async function savePlan () {
        setModalButton(false)
        setSaving(true)
        console.log(await props.addPlan(inputs))
        setModalButton(true)
        setSaving(false)
    }

    useEffect(() => {
        inputs===null && setInputs(props.data ? {
            id: props.data.id,
            provider_id: props.data.provider.id,
            provider_name: props.data.provider.name,
            year_1: props.data.plan1.years,
            value_1: props.data.plan1.value,
            year_2: props.data.plan2.years,
            value_2: props.data.plan2.value,
            year_3: props.data.plan3.years,
            value_3: props.data.plan3.value,
            year_4: props.data.plan4.years,
            value_4: props.data.plan4.value
        } : inputsBase)

        if (providers===null) {
            apiTariffs.get(`/api?ret=agents`, {headers: { Authorization: env.api.token }}).then(resp => {
                setTimeout(() => {
                    let ret: any = [];
                    resp.data.map((el: any) => ret.push({id: el.id, ref: el.ref_id, name: el.name}));
                    setProviders(ret)
                    setReady(true)
                }, 1000);
            })
        }
    }, [ready, providers, inputs, props])

    useEffect(() => {
        console.log('Internal Saving')
        console.log(saving)
    }, [saving]);

    return ready && inputs ? (<>
        <div className="inputLabel">
            <label htmlFor="providers">Distribuidora</label>
            <select name="providers" id="providers" onChange={onChangeProvider} value={inputs.provider_id + '|' + inputs.provider_name} disabled={saving ? true : false}>
                <option value="0|">Selecione...</option>
                <optgroup label="Distribuidoras">
                    { providers.map((el: any) => <option key={`prov_${el.id}`} value={el.id + '|' + el.name}>{el.name}</option>) }
                </optgroup>
            </select>
        </div>

        <div className="planLine">
            <div className='planGroup'>
                <InputLabel
                    name="year_1"
                    type="number"
                    labelName='Anos 1'
                    value={inputs.year_1}
                    onChange={onChange}
                    disabled={saving ? true : false}
                />

                <InputLabel
                    name="value_1"
                    type="number"
                    labelName='Valor 1 (%)'
                    value={inputs.value_1}
                    onChange={onChange}
                    disabled={saving ? true : false}
                />

                <InputLabel
                    name="year_2"
                    type="number"
                    labelName='Anos 2'
                    value={inputs.year_2}
                    onChange={onChange}
                    disabled={saving ? true : false}
                />

                <InputLabel
                    name="value_2"
                    type="number"
                    labelName='Valor 2 (%)'
                    value={inputs.value_2}
                    onChange={onChange}
                    disabled={saving ? true : false}
                />
            </div>

            <div className='planGroup'>
                <InputLabel
                    name="year_3"
                    type="number"
                    labelName='Anos 3'
                    value={inputs.year_3}
                    onChange={onChange}
                    disabled={saving ? true : false}
                />

                <InputLabel
                    name="value_3"
                    type="number"
                    labelName='Valor 3 (%)'
                    value={inputs.value_3}
                    onChange={onChange}
                    disabled={saving ? true : false}
                />

                <InputLabel
                    name="year_4"
                    type="number"
                    labelName='Anos 4'
                    value={inputs.year_4}
                    onChange={onChange}
                    disabled={saving ? true : false}
                />

                <InputLabel
                    name="value_4"
                    type="number"
                    labelName='Valor 4 (%)'
                    value={inputs.value_4}
                    onChange={onChange}
                    disabled={saving ? true : false}
                />
            </div>
        </div>

        {
            !saving ?
            <Button
                func={() => savePlan()}
                className="box save"
                text="Salvar"
            /> :
            <Button
                type="button"
                className='box saving'
                text="Salvando..."
            />
        }
    </>) : <></>;
}

export default CreatePlan;


