import React from 'react';
import Lottie from 'react-lottie-player';
import './styles.css';

// Images
import clock from './clock.json';

const Points: React.FC = () => {
    return <div className='clock'>
        <Lottie
            animationData={clock}
            loop play
            speed={0.5}
            className="icon"
        />
    </div>;
}

export default Points;