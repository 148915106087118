import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import mobile from 'is-mobile';
import { MixPanelContext, GeneralContext, ModalContext } from '~/contexts';
import api from '~/services/api';
import './styles.css';

// Components
import Loading from '~/components/Default/Loading';
import OpportunitiesEconomy from '~/components/Boxs/OppsEconomy';
import MonthlyReport from '~/components/Boxs/MonthlyReport';
import ChartsBox from '~/components/Boxs/Charts/ChartsBox';
import TariffBox from '~/components/Boxs/Tariff';
import { FaInfoCircle } from 'react-icons/fa';

// Images
import logo from '~/assets/images/logo.svg';

const Dashboard: React.FC<any> = ({wizard}) => {
    const {user, client} = useContext(GeneralContext);
    const {error}        = useContext(MixPanelContext);
    const {setModalOpen, setModalBody} = useContext(ModalContext);
    const navigate       = useNavigate();
    const location       = useLocation();
    let   chartsRef: any = null;
    // Variables
    const params    = useParams();
    const [yearMonth, setYearMonth]           = useState(params.yearMonth);

    // States
    const [ready, setReady]       = useState(false);
    const [data, setData]         = useState<any>(null);
    const [chartRef, setChartRef] = useState<any>(null);

    function changeMonth(event: any) {
        let value = event.target.value;

        if (value!=='' && value!==yearMonth) {
            setYearMonth(value)
            if (value===data.lastDate) navigate('/admin/relatorios', {replace: true});
            else navigate(`/admin/relatorios/${value}`, {replace: true});

            setReady(false)
            setData(null)
        }
    }

    function CompleteWizard(){
        api.put(`users/wizard`, '', {
            headers: { Authorization: user.token }
        }).then((resp: any) => {
            setModalOpen(false)
        })
    }

    function ModalWizardMobile () {
        // setModalTitle('Bem-vindo aos Relatórios!')
        setModalBody(<div className='wizard_mobile'>
            <img alt="logo" src={logo}/>
            <h3>Bem-vindo aos Relatórios!</h3>
            <p>Vamos mostrar como os recursos dessa página podem ajudá-lo a gerenciar e analisar o valor da sua conta de luz.</p>
            <p>Preparamos uma breve explicação, <br/> basta clicar no<br/><FaInfoCircle/></p>
            <button onClick={() => CompleteWizard()}>Fechar</button>
        </div>)
        setModalOpen(true)
    }

    useEffect(() => {
        if (ready && chartsRef) setChartRef(chartsRef)

        if (data===null) {
            let url = user.role==='admin' ? `bills/c/${client}` : 'bills/m';
            api.get(url + (yearMonth ? `/${yearMonth}` : ''), {
                headers: { Authorization: user.token }
            }).then((resp: any) => {
                setTimeout(() => {
                    setData(resp.data)
                    setReady(true)
                }, 2000);
            }).catch((err: any) => {
                const { status } = err.response;
                if (status===400) {
                    console.log('Error')
                    console.log(err.response)

                    // Ready
                    setTimeout(() => setReady(true), 2000);

                    // Amplitude Event
                    error(user.id, 'Relatórios', err.response)
                }
            });
        }
    }, [user, ready, data, yearMonth, chartsRef]);

    useEffect(() => {
        if (chartRef && location.hash.indexOf('#/graficos')>=0) {
            navigate('/admin/relatorios', {replace: true});
            setTimeout(() => {
                const winScroll = document.getElementsByTagName('main')[0];
                winScroll && winScroll.scrollTo({top: chartRef.offsetTop, behavior: 'smooth'})
            }, 2000);
        }
    }, [chartRef])

    useEffect(() => {
        if (wizard) {
            setTimeout(() => {
                ModalWizardMobile()
            }, 2000);
        }
    }, [wizard])

    return <>
        {
            ready ? (<>
                <div className='header'>
                    <h1>Relatórios - {data?.bill?.company.name}</h1>
                    <div>
                        {!mobile() && <h3>Mês de referência:</h3>}
                        {
                            data && data.dates.length>=1 ? (
                                <select onChange={changeMonth} value={yearMonth ? yearMonth : ''}>
                                    {data.dates.map((el: any) => <option key={`sel_${el.id}`} value={el.date.slug}>{el.date.name}</option>)}
                                </select>
                            ) : <></>
                        }
                    </div>
                </div>

                {
                    data ? <div className='container'>
                        <div className="left">
                            <MonthlyReport dates={data.dates} date={data.currDate} />
                        </div>

                        <div className="center">
                            <OpportunitiesEconomy data={data} yearMonth={yearMonth} />

                            <TariffBox style={{marginTop: 20}} bill={data.bill} yearMonth={yearMonth} />
                        </div>

                        <div id="chartsBox" className="right" ref={ref=>chartsRef=ref}>
                            <ChartsBox data={data} date={data.currDate.year + '-' + data.currDate.month} />
                        </div>
                    </div> : <div className='container alert'>
                        Não há dados para o período solicitado
                    </div>
                }
            </>) : <Loading />
        }
    </>
}

export default Dashboard;