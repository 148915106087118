const numbers = {
    money: function (curr: number, prefix?: boolean, digits?: boolean, digitsQtty?: number) {
        if (!curr) return (prefix===true ? 'R$ ' : '') + '0' + (digits===false ? '' : ',' + this._treatDigits(digitsQtty ? digitsQtty : 2));
        let config: any = {style: 'currency', currency: 'BRL'};

        if (prefix===false) {
            config = digits===false ? {style: 'currency', currency: 'BRL', minimumFractionDigits: 0, maximumFractionDigits: 0} : {style: 'currency', currency: 'BRL', minimumFractionDigits: (digitsQtty ? digitsQtty : 2)};
            return curr.toLocaleString('pt-br', config).replace('R$', '').trim();
        } else {
            config = digits===false ? {style: 'currency', currency: 'BRL', minimumFractionDigits: 0, maximumFractionDigits: 0} : {style: 'currency', currency: 'BRL', minimumFractionDigits: (digitsQtty ? digitsQtty : 2)};
            return curr.toLocaleString('pt-br', config);
        }
    },

    _treatDigits: function (qtty: number) {
        let ret = '';

        for (let i=0; i<qtty; i++) {
            ret += '0'
        }

        return ret;
    },
}

export default numbers;